import { ActionIcon, Button, Center, Group, Paper, Stack, Table, Tabs, Text, useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import { Edit, Settings, Trash } from "tabler-icons-react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading } from "@aws-amplify/ui-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";

export default function ConfigureInvoices() {
    const { height } = useViewportSize();

    const { state } = useContext(AuthContext);

    const [r, setR] = useState(false);

    const theme = useMantineTheme();

    const [config, setConfig] = useState(null)

    useEffect(() => {
        axios.get("/disconnection-rates/getRate", {headers: {'Authorization': `Bearer ${state?.userToken}`}}).then(function(res){
            if(res.status === 200){
                if(res.data.data){
                    setConfig(res.data.data);
                }
            }
        }).catch(function(error){
            // handle error
            //toast.error("Could not fetch your disconnection configurations")
        });
    }, [])

    const CategoriesList = () => {
        const [data, setData] = useState([
            {name: "Water Bill", mode: "Dynamic", amount: "", createdAt: state.userData.createdAt, updatedAt: state.userData.createdAt},
            {name: "Disconnection", mode: "Fixed", amount: config?.fee || "",  createdAt: config?.createdAt, updatedAt: config?.createdAt},
        ]);

        const InvoicesList = ({ invoices }) => {
            return (
                invoices.map((itm, idx) => {
                    return (
                        <tr key={`row-${idx}`}>
                            <td>
                                {itm?.name}
                            </td>
                            <td>
                                {itm?.mode}
                            </td>
                            <td>
                                {itm?.amount}
                            </td>
                            <td>
                                {new Date(itm?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                                {new Date(itm?.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                            {idx > 1 ? (
                                <Group noWrap>
                                    <ActionIcon disabled={idx < 2}>
                                        <Edit />
                                    </ActionIcon>

                                    <ActionIcon disabled={idx < 2}>
                                        <Trash color="red" />
                                    </ActionIcon>
                                </Group>
                            ) : null}
                            </td>
                        </tr>
                    )
                })
            )
        }

        return (
            <Paper p="md">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Invoice Categories</title>
                </Helmet>

                <Group mb={20} position="apart">
                    <Group position="left">
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Invoice Categories</Heading>
                    </Group>

                    <Group position="right">
                        <Button>New Category</Button>
                    </Group>
                </Group>

                <div style={{overflowX: "auto"}}>
                    <Table highlightOnHover fontSize={"xs"} style={{borderBottom: '1px solid #E9ECEF'}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Mode</th>
                                <th>Amount</th>
                                <th>Created On</th>
                                <th>Last Modified</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <InvoicesList invoices={data} />
                        </tbody>
                    </Table>
                </div>
            </Paper>
        )

    }

    return (
        <>
        <Tabs defaultValue={"list"}>
            <Tabs.List>
                <Tabs.Tab value="list">
                   Invoice Categories
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="list">
                    <CategoriesList />
            </Tabs.Panel>

        </Tabs>
        </>
    )
}