import {
    TextInput,
    PasswordInput,
    Anchor,
    Container,
    Group,
    Button,
    Center,
    ActionIcon,
    Radio,
    Paper,
    Text,
    Divider,
  } from '@mantine/core';
  import {  useContext, useState, useMemo, useEffect} from 'react';
  import toast from 'react-hot-toast';
  import { Lock } from 'tabler-icons-react';
  import styles from "../../login/login.module.css";
  import { useLocation,  Link } from 'react-router-dom';
  import axios from '../../../utils/axios';
  import { AuthContext } from '../../../App';
  import { SESSION_DATA } from '../../../constants';
  import { useForm } from "@mantine/form";
  import { Helmet } from 'react-helmet';
  
  const CustomerLogin = () => {
    const { state, dispatch } = useContext(AuthContext);
    const form = useForm({
      initialValues: { email: "", password: ""},
      validate: {
        email: (value) => (value.length > 0 ? null : 'Invalid meter number'),
        password: (value) => (value.length < 2 ? 'Password must have at least 8 characters' : null),
      },
      validateInputOnBlur: true
    });
  
    const onSubmit = async (obj) => {
      try {
        const body = {
          username: obj.email,
          password: obj.password
        };
  
        axios.post("/customers/login", body).then(({
          data: {
            data: accountData,
            token: accessToken
          },
        }) => {
          localStorage.setItem(SESSION_DATA, accessToken);
          dispatch({type: "SIGN_IN", token: accessToken, data: accountData});
        }).catch((error) => {
          toast.error(error?.response?.data?.message || error.message)
        })
      } catch(error){
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  
    return (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Customer Sign In | E-mita - the robust end-to-end water management system</title>
        <meta name='description' content='E-mita is a robust water utility information management system that provides a powerful approach to a comprehensive water supply management.' />
        </Helmet>
      <Container size={600} my={20}>
      <h1 className={styles.title}>Customer Login</h1>
      <Center mb={20} mt={10} >
      <ActionIcon size="lg" radius={28} style={{backgroundColor: 'red', cursor: 'text'}} >
        <Lock color='white' />
      </ActionIcon>
      </Center>

      <form onSubmit={form.onSubmit((values) => {onSubmit(values)})}>
      <TextInput {...form.getInputProps("email")} placeholder="" label="Meter Number"/>
      <PasswordInput {...form.getInputProps("password")} label="Password" mt="md" />
      <Group position="apart" mt="md">
      <></>
        <Anchor component={Link} to="/account/forgot-password/" size="sm">
          Forgot password?
        </Anchor>
      </Group>
      <Button mb={20} type='submit' fullWidth mt="xl">
        Login
      </Button>
      </form>
  
  </Container>
  </>
    );
  };
  
  export default function(){
    return (
        <CustomerLogin />
    )
  };
  