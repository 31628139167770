import { Anchor, Box, Button, Center, Group, Paper, TextInput, Title, useMantineTheme } from "@mantine/core";
import { IconAlertCircle, IconArrowLeft, IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Alert } from "@aws-amplify/ui-react";
import ReactGA from "react-ga4";

export default function CreateDMA(){
    const { state, dispatch } = useContext(AuthContext);
    const [name, setName] = useState("");
    const [success, setSuccess] = useState({success: false, message: ""});
    const [error, setError] = useState({error: false, message: ""})

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const createDMA = () => {
        try {
            const body = {
                name: name,
                username: state.userData._id
            }

            axios.post("/dmas/create", body).then(function(res){
                if(res.status === 200){
                    setSuccess({success: true, message: `DMA/Zone ${name} has been configured successfully.`});
                    setName("");
                }
            }).catch(function(error){
                setError({error: true, message: error.message})
            });
        } catch(error){
            setError({error: true, message: error.message})
        }
    }

    const theme = useMantineTheme();

    return(
        <Paper p="md" >
        {success.success ? (
            <Alert marginTop={-10} marginLeft={-10} marginRight={-10} marginBottom={20} variation="success" hasIcon={true} isDismissible={false}>
                {success.message}
          </Alert>
        ) : null}

        {error.error ? (
            <Alert marginTop={-20} marginLeft={-20} marginRight={-10} marginBottom={20} variation="error" hasIcon={true} isDismissible={false}>
                {error.message}
          </Alert>
        ) : null}
        <Group position="apart" mb={20}>
            <Group position="left">
                <Title weight={300}>Create New DMA/Zone</Title>
            </Group>
        </Group>
        <TextInput value={name} onChange={(e) => {setName(e.currentTarget.value)}} label="DMA Name" description="Name of the region e.g Ngara" mb={10} />

        <Anchor mr={20} component={Link} to={`/app/configure/dma#${state.userData._id}`}>
            <Center inline>
                <IconArrowLeft size={14} />
                <Box ml={5}>Back to DMAs Page</Box>
            </Center>
            </Anchor>
        <Button disabled={name === "" ? true : false} onClick={() => {createDMA()}} mt={10} radius={28}>Create DMA</Button>
        </Paper>
    )
}