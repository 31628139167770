import { Alert, Button, Card, Center, Container, Divider, Drawer, Group, Paper, Select, Stack, Switch, Table, Tabs, Text, TextInput } from '@mantine/core';
import classes from './SwitchesCard.module.css';
import { useContext, useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { AuthContext } from '../../../App';
import toast from 'react-hot-toast';
import { useViewportSize } from '@mantine/hooks';
import { InfoCircle, X } from 'tabler-icons-react';
import swal from 'sweetalert';

export function ConfigureSMS() {
  const { state, dispatch } = useContext(AuthContext);
  /**
   * NEVER CHANGE ANYTHING ON THE DATA ARRAY.
   * Checking the configuration is based on the specific titles, and hence its position/index
   */
  const [data, setData] = useState([
    { title: 'Meter Reading', checked: false, description: 'Send an SMS once a consumption history has been registered.' },
    { title: 'Disconnection', checked: false, description: 'Send an SMS once a disconnection or re-connection has been initiated.' },
    { title: 'Payment', checked: false, description: 'Send an SMS acknowledging payments made.' },
    {
      title: 'Billing & Disconnected Customers',
      checked: false,
      description: 'Continue charging disconnected customers and send them invoices during the billing process',
    },
  ]);
  
  const [configs, setConfigs] = useState([]);
  const [r, setR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setID] = useState("");
  const [providers, setProviders] = useState(null);
  const [opened, setOpened] = useState(false);
  const [name, setName] = useState("");
  const [api, setAPI] = useState("");
  const [endpoint, setEndPoint] = useState("");
  const [username, setUsername] = useState("");
  const [shortcode, setShortCode] = useState("");
  const [responseType, setResponseType] = useState("json");
  const [drawer_steps, setDrawerSteps] = useState(0);

  const [partner_id, setPartnerID] = useState("");

  const { height, width } = useViewportSize();

  const createProvider = () => {
    setLoading(true);
    axios.post("/sms-provider/create", {name: name, api_key: api, username: username, endpoint: endpoint, shortcode: shortcode, responseType: partner_id, parent: state.userData._id}, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        toast.success("Your sms provider was configured successfully");
        setR(!r)
        setLoading(false);
        setOpened(false);
      }
    }).catch(function(error){
      toast.error("Your sms provider could not be configured.")
      setLoading(false);
    })
  }

  const deleteProvider = (id) => {
    axios.post("/sms-provider/delete", {id: id}, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        toast.success("Your sms provider was deleted successfully");
        setR(!r)
      }
    }).catch(function(error){
      toast.error("Your sms provider could not be deleted.")
    })
  }

  const askDelete = (id) => {
    swal({
      title: "Warning!",
      text: "Are you sure you want to delete the provider?",
      icon: "warning",
      buttons: {
          cancel: "No, Cancel Please!",
          continue: {
              text: "Yes, I'm sure about this!",
              value: "continue"
          }
      }
  }).then(async (value) => {
    if(value === "continue"){
      deleteProvider(id);
    }
})
  }

  useEffect(() => {
    axios.get("/sms-provider/configs", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        setProviders(res.data.data);
      }
    }).catch(function(error){
      toast.error("An issue occured while fetching your data...");
    })
  }, [r])

  useEffect(() => {
    axios.get("/sms-configs/get", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        let obj = res.data.data;

        if(obj){
          setConfigs(obj?.configs);
          setID(obj?._id);
        }
      }
    }).catch(function(error){
      console.log(error);
    })
  }, [r])

  const handleChange = (val, idx) => {
    data[idx].checked = val;
    setData([...data]);
  }

  const handleChange2 = (val, idx) => {
    configs[idx].checked = val;
    setConfigs([...configs]);
  }

  const createConfigs = () => {
    setLoading(true);
    axios.post("/sms-configs/create", {configs: data, parent: state.userData._id}, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        toast.success("Your sms configurations were created successfully!");
        setR(!r);
        setLoading(false);
      }
    }).catch(function(error){
      toast.error("An issue occured while creating the configs");
      setLoading(false);
    })
  }

  const saveChanges = () => {
    setLoading(true);

    axios.post("/sms-configs/update", {update: {configs: configs}, id: id}, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        setR(!r);
        setLoading(false);
        toast.success("The changes were saved successfully");
      }
    }).catch(function(error){
      toast.error("An issue occured while updating the configs");
      setLoading(false);
    })
  }

  const items = data.map((item, idx) => (
    <Group position='apart' align='center' mb="md" key={item.title}>
      <div>
        <Text mb="xs" style={{fontWeight: "550"}}>{item.title}</Text>
        <Text size="sm">
          {item.description}
        </Text>
      </div>
      <Switch size="lg" checked={item.checked} onChange={(e) => {handleChange(e.currentTarget.checked, idx)}} onLabel="ON" offLabel="OFF" />
    </Group>
  ));


  const items2 = configs.map((item, idx) => (
    <>
    <Group align="center" position='apart' mb="md" key={item.title}>
      <div>
        <Text mb="xs" style={{fontWeight: "550"}}>{item.title}</Text>
        <Text size="sm">
          {item.description}
        </Text>
      </div>
      <Switch size="lg" checked={item.checked} onChange={(e) => {handleChange2(e.currentTarget.checked, idx)}} onLabel="ON" offLabel="OFF" />
    </Group>
  </>
  ));

  return (
    <>
    <Alert title="About SMS Providers" icon={<InfoCircle />}>
      SMS Providers allows you to send sms to your clients. You can configure various triggers that activate sms. Also note that you need to keep on checking your wallet from your provider's portal. Emita does not take liability in case your messages do not get delivered.
    </Alert>
    <Tabs defaultValue={"providers"}>
      <Tabs.List>
        <Tabs.Tab value="providers">SMS Providers</Tabs.Tab>
        <Tabs.Tab value="triggers">Triggers</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel pt="xl" value='providers'>
      {providers === null ? (
        <>
              <Center mt={"5%"}>
                    <img src={require("../../../assets/waiting_hourglass.png")} />
        </Center>
        <Text size={"sm"} mb="md" align='center'>Configured SMS Providers will appear here.</Text>
        <Center>
          <Button variant='outline' onClick={() => {setOpened(true)}}>Configure New SMS Service Provider</Button>
        </Center>
        </>
      ) : (
        <Table fontSize={"xs"}>
          <thead>
            <tr>
              <th>Provider</th>
              <th>API</th>
              <th>Endpoint</th>
              <th>Sender Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{providers?.name}</td>
              <td>{providers?.api_key}</td>
              <td>{providers?.endpoint}</td>
              <td>{providers?.shortcode}</td>
              <td><Button variant='light' color='red' onClick={() => {askDelete(providers?._id)}}>Delete</Button></td>
            </tr>
          </tbody>
        </Table>
      )}

<Drawer opened={opened} onClose={() => {setOpened(false)}} position="right" title="Add SMS Provider" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
        <div>
          {drawer_steps === 0 ? (
        <Select mb="sm" label="Provider" value={name} onChange={(val) => {setName(val)}} data={[{label: "Crowdcomm", value: "crowdcomm"}, {label: "Mobitech", value: "mobitech"}, {label: "TextSMS", value: "textsms"}, {label: "AfricasTalking", value: "africastalking"}]} />
          ) : drawer_steps === 1 ? (
            <TextInput mb="sm" label="Endpoint" description="The url element of the api that Emita can send sms requests to." value={endpoint} onChange={(e) => {setEndPoint(e.currentTarget.value)}}/>
          ) : (
            <>
          
            <TextInput mb="sm" label="Sender ID/Shortcode" description="Your unique sender name as provided by the sms provider." value={shortcode} onChange={(e) => {setShortCode(e.currentTarget.value)}} />

            <TextInput mb="sm" label={"API Key"} description={"The api key value. Can be found in your account in the provider's website."} value={api} onChange={(e) => {setAPI(e.currentTarget.value)}}/>

            {name === "textsms" ? (
                <TextInput mb="sm" label="Partner ID" description="" value={partner_id} onChange={(e) => {setPartnerID(e.currentTarget.value)}} />
            ) : null}

            {name === "africastalking" ? (
              <TextInput mb="sm" label={"Username"} description={"Your Africa’s Talking application username."} value={username} onChange={(e) => {setUsername(e.currentTarget.value)}}/>
            ) : null}
            </>
          )}
        </div>
          {drawer_steps === 0 ? (
                <Group>
                    <Button size='sm' radius={28} variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
                    <Button size='sm' radius={28} disabled={name === ""} onClick={() => {setDrawerSteps(1)}}>Continue</Button>
                </Group>
          ) : drawer_steps === 1 ? (
            <Group>
            <Button size='sm' radius={28} variant="default" onClick={() => {setDrawerSteps(0)}}>Previous</Button>
            <Button size='sm' disabled={endpoint.trim().length < 2} radius={28} onClick={() => {setDrawerSteps(2)}} >Continue</Button>
        </Group>
          ) : (
            <Group noWrap>
            <Button size='sm' radius={28} variant="default" onClick={() => {setDrawerSteps(1)}}>Previous</Button>
            <Button disabled={api.trim().length < 2 || shortcode.trim().length < 2} size='sm' radius={28} onClick={() => {createProvider()}} loading={loading}>{loading ? "creating..." : "Add SMS Provider"}</Button>
        </Group>
          )}
        </Stack>
      </Drawer>
      </Tabs.Panel>
      <Tabs.Panel pt="xl" value='triggers'>
      {configs.length === 0 ? items : items2}

    <Group mt={40} position='right'>
        {configs.length === 0 ? <Button onClick={() => {createConfigs()}} loading={loading} radius={28}>Create SMS Configs</Button> : <Button loading={loading} onClick={() => {saveChanges()}} radius={28}>Save Changes</Button>}
    </Group>
      </Tabs.Panel>
    </Tabs>
    </>
  );
}