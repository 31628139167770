import {
    createStyles,
    Paper,
    Title,
    Text,
    TextInput,
    Button,
    Group,
    Anchor,
    Center,
    Box,
    PasswordInput,
    MediaQuery,
    BackgroundImage,
    SimpleGrid,
    Space,
    Divider,
    Menu
  } from '@mantine/core';
  import { IconArrowLeft } from '@tabler/icons';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import axios from "../../utils/axios";
import { useQuery } from '../login/login';
import { Helmet } from 'react-helmet';
import Autumn2 from "../../assets/bg.jpg";
import { useViewportSize } from '@mantine/hooks';
import { World } from 'tabler-icons-react';
import ReactGA from "react-ga";
import { GuestCompanyLogo } from '../dashboard/layout/companyLogo';

  const useStyles = createStyles((theme) => ({
    title: {
      fontSize: 26,
      fontWeight: 900,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
  
    controls: {
      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        width: '100%',
        textAlign: 'center',
      },
    },
  }));
  
  export default function ForgotPassword() {
    const { classes } = useStyles();
    const [email, setEmail] = useState("");
    let query = useQuery();
    const [token, setToken] = useState(false);
    const [token2, setToken2] = useState(query.get("token") || "");
    const [username, setUsername] = useState(query.get("username") || "");
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [img, setImg] = useState(1);
    const [loading, setLoading] = useState(false);
    const { height, width} = useViewportSize();

    useEffect(() => {
      ReactGA.pageview("/forgot-password")
    }, [])

    useEffect(() => {
        const timer = setInterval(function(){
            setImg(Math.floor(Math.random() * 3) + 1)
        }, 5000);
  
        return () => {
            clearInterval(timer);
        }
    }, []);
    const navigate = useNavigate();
    
    useEffect(() => {
      if(token2 !== ""){
        axios.post("/forgotPassword/getToken", {
          username: username,
          creatorID: token2
        }).then(function(res){
          if(res.status === 200){
            if(res.data.data){
              setToken(true);
              axios.post("/tokens/delete", {
                username: username
              }).then(function(res){
                if(res.status === 200){
                  // do nothing
                }
              }).catch(function(error){
                toast.error("Somthing wrong is happening!")
              })
            }
          }
        }).catch(function(error){
          console.log(error);
          toast.error("Something wrong happened!")
        })
      }
    }, [token2]);

    const resetPassword = () => {
      if(pass1.length < 8){
        toast.error("The password should have a minimum of 8 characters");
        return false;
      }
      if(pass1 !== pass2){
        toast.error("The two passwords do not match");
        return false;
      }

      axios.post("/customers/reset", {
        role: 'admin',
        username: username,
        password: pass1
      }).then(function(res){
        if(res.status === 200){
          toast.success("Your password has been updated successfully!");
          navigate("/account/login");
        } else {
          toast.error("The account associated with this email was not found!");
        }
      }).catch(function(error){
        console.log(error);
        toast.error("Something wrong happened!");
      })
    }

    const sendLink = async () => {
        if(email === ""){
          toast.error("Email is empty");
            return false;
        }

        await axios.post("/auth/getUser", {
          username: email
        }).then(function(res){
          if(res.status !== 200){
            toast.error("There is no account associated with the provided email.");
            return false;
          } else {
            const token = nanoid(64);

            const body = {
              username: email,
              creatorID: token,
              expireAt: 1000 * 60 * 10 // 10 minutes
            };
    
            axios.post("/forgotPassword/createToken", body).then(({data: {data: tkn}, }) => {
              const body2 = {
                username: email,
                creatorID: token
              };
    
              axios.post("/forgotPassword/sendmail", body2).then(({data: {data: senddata}, }) => {
                toast.success("An email with reset link has been sent to "+ email)
              })
            })
          }
        }).catch(function(error){
          toast.error("Something wrong happened!");
          return false;
        })

    }
  
    return (
      <>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Forgot Password | Emita </title>
      <meta name='description' content='E-mita is a robust water utility information management system that provides a powerful approach to a comprehensive water supply management.' />
      </Helmet>
      <SimpleGrid
      cols={2}
      spacing="lg"
      breakpoints={[
        { maxWidth: 980, cols: 1, spacing: 'md' }
      ]}
    >
      <MediaQuery smallerThan="md" styles={{display: 'none'}}>
      <BackgroundImage src={Autumn2} h={height}>
                <div style={{height: height}}>
                <Center mb={20} pt={height * 0.35}>
            <Title color='#ffffff'>Scalable, Modular & Flexible</Title>
        </Center>
        <Center>
        <Text size="xl" style={{textAlign: 'center'}} color='#ffffff'>Grow your water utility with Confidence. Experience efficiency,
speed and convenience in a unified platform.</Text>
        </Center>
                </div>
                </BackgroundImage>
      </MediaQuery>

      <Paper p="lg">
      <main className="w-full h-screen-2xl items-center justify-center py-10">
        <GuestCompanyLogo link={"/account/forgot-password"} />
        <Text mb={20} mt={20}>
          {!token ? "Forgot your password? Provide your account's email and details on how to recover the password will be send to you." : "Reset your password."}
        </Text>
        <Text inline>
          Back to <Anchor component={Link} to="/">Login</Anchor> page
        </Text>
        <Space h="sm" />
    <Divider />
    <Space h="sm" />
        <>
          {!token ? (
            <TextInput value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} label="Account's email" placeholder="" required />
          ) : (
            <>
            <PasswordInput mb="md" value={pass1} onChange={(e) => {setPass1(e.currentTarget.value)}} label="New Password" required />
            <PasswordInput value={pass2} onChange={(e) => {setPass2(e.currentTarget.value)}} label="Confirm Password" required />
            </>
          )}
            <Button loading={loading} mt="lg" height={45} fullWidth onClick={() => {token ? resetPassword() : sendLink()}} className={classes.control}>{token ? "Reset Password" : "Request Password Reset Instructions"}</Button>

          <Group mt={10} position='apart'>
      <Group position='left'>
      <Text size="xs" component='a' href='#'>Privacy Terms</Text>
      <Text size="xs"  component='a' href='#'>Data Security</Text>
      <Text size="xs"  component='a' href='#'>Preference</Text>
      </Group>

      <Group position='right'>
        <Group spacing={2}>
          <World size={13} />
          <Menu>
              <Menu.Target>
              <Text size="xs">English(US)</Text>
              </Menu.Target>
              <Menu.Dropdown>
              <Menu.Item>
                <Text size="xs">English(UK)</Text>
                </Menu.Item>
                <Menu.Item>
                <Text size="xs">French</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
        </Group>
        <Text size="xs">Support & Resources</Text>
      </Group>
    </Group>
        </>
            </main>
        </Paper>
      </SimpleGrid>
      </>
    );
  }