import React from 'react';
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading} from "@aws-amplify/ui-react";
import { useMantineTheme } from "@mantine/styles";
import { Anchor, Button, Center, Container, Divider, Grid, Group, Paper, Select, Text } from "@mantine/core";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from 'chart.js';
import {  Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AlertTriangle } from 'tabler-icons-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);


const options = {
  borderWidth: 2,
  pointRadius: 1,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Task',
    },
  },
  scales: {
    x: {
    title: {
      display: false,
      text: 'Date',
    },
    ticks: {
      source: 'auto', // Use 'auto' to automatically determine the time scale
    },
    grid: {
      display: false
    }
    },
    y: {
      title: {
          display: true,
          text: 'Accounts',
      },
      grid: {
          display: false
        }
    },
  },
};

export default function Task(){
    const { state } = useContext(AuthContext);
    const theme = useMantineTheme();
    const { id } = useParams();
    const [task, setTask] = useState(null);
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [loading, setLoading] = useState(false);
    const [okay, setOkay] = useState(false);

    const [chart1, setChart1] = useLocalStorage({
      key: "emita-chart-type",
      defaultValue: "bar",
      getInitialValueInEffect: true
    });

    const navigate = useNavigate();

    const fetchTask = () => {
        setLoading(true);

        const body = {
            uuid: id
        };

        axios.get("/tasks/task",{params: body}).then(function(res){
            if(res.status === 200){
                if(res?.data?.data === null){
                    throw new Error("Task ID provided does not exist.")
                }
                setTitle(res?.data?.data?.title);
                setDesc(res?.data?.data?.description);
                setTask(res?.data?.data);

                setLoading(false);
                setOkay(true);
            }
        }).catch(function(error){
            toast.error(error.message, {
                position: "bottom-right"
            });
            setLoading(false);
            setOkay(false);
        })

    }

    useEffect(() => {
        if(typeof(id) === "string"){
            fetchTask();
        } else {
            setLoading(false);
            setOkay(false);
        }
    }, [id]);

    const data = {
        labels: ["Unread", "Read"],
        datasets: [
          {
            label: 'Accounts',
            data: [task?.building?.length, task?.completeBuildings?.length],
            backgroundColor: [
              'red',
              'green',
            ],
            borderColor: [
              'red',
              'green',
            ],
            borderWidth: 1,
          },
        ],
      };

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | {title}</title>
        </Helmet>
        {loading ? (
            <Center mt="10%" mb="10%">
                <FontAwesomeIcon icon={faSpinner} spin />
            </Center>
        ) : !loading && !okay ? (
            <Container mt={"10%"} size={700}>
                <Paper withBorder p="md" >
                    <Center mt={40} mb={40}>
                        <AlertTriangle color="red" size={50} />
                    </Center>
                    <Text mb={40} align='center'>There appears to be an issue with the task url that you have entered. <Anchor href='#'>Open support ticket</Anchor> if you believe this error is originating from our end.</Text>
                
                <Button fullWidth variant='outlined' onClick={() => {navigate("/app/tasks")}}>Go Home</Button> 
                </Paper>
            </Container>
        ) : (
            <>
            <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>{title}</Heading>
            <Text mb={20}>{desc}</Text>
    
            <Divider ml={-10} mr={-10} />
    
            <Grid>
                <Grid.Col mt={8} sm={12} lg={12}>
                <Group position='apart'>
                <Heading fontWeight={500} marginBottom={20} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Comparison between read and unread accounts.</Heading>
                <Select data={[
                    {label: "Bar", value: "bar"}
                ]} value={chart1} onChange={(val) => {setChart1(val)}} />
                </Group>
                <Paper mt="lg" p="md" style={{height: 300, width: '100%',}}>
                <Bar options={options} data={data} width={580} />
                </Paper>
                </Grid.Col>

            </Grid>
            </>
        )}
        </>
    )
}