import { Badge, Button, Center, Menu, Paper, ScrollArea, Table, Title } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChevronDown } from "tabler-icons-react";
import axios from "../../utils/axios";

export default function CustomerPaymentsHistory(){
    let { id } = useParams();
    const [data, setData] = useState([]);
    const [size, ] = useState(5);
    const [page, ] = useState(1);
    const [, setPagination] = useState(0);
    const [, setScrolled] = useState(false);
    const { height } = useViewportSize();

    useEffect(() => {
        if(typeof(id) !== undefined && id !== ""){
            axios.post("/payments/get-payments", {
                username: id
            }).then(function(res){
                if(res.status === 200){
                    setData(res.data.data);
                    setPagination(Math.ceil(res.data.data.length / size));
                }
            })
        }
    }, [id]);

    const paginate = (a, pageIndex, pageSize) => {
        pageIndex = pageIndex - 1;
        var endIndex = Math.min((pageIndex + 1) * pageSize, a.length);
        return a.slice(Math.max(endIndex - pageSize, 0), endIndex)
    }

    const rows = paginate(data, page, size).map((item, index) =>{
        return (
            <tr key={`payment-row-${index}`}>
                <td>
                    {item.transactionID}
                </td>
                <td>
                   {item.amount} 
                </td>
                <td>
                    <Badge color={item.status === "1" ? "green" : "red"}>{item.status === "1" ? "Successful" : "Failed"}</Badge>
                </td>
                <td>
                    {new Date(item.createdAt).toDateString()}
                </td>
                <td>
                    <Menu>
                        <Menu.Target>
                            <Button rightIcon={<ChevronDown />}>Actions</Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item>Refund</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </td>
            </tr>
        )
    });

    return (
        <Paper p="lg">
            <Title order={3} mb={20}>Payment History</Title>
            <ScrollArea sx={{height: data.length > 0 ? (height * 0.55) : 100}} onScrollPositionChange={({y}) => setScrolled(y !== 0)}>
                <div style={{overflowX: 'auto'}}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Transaction ID</th>
                                <th>Amount Received</th>
                                <th>Transaction Status</th>
                                <th>Transaction Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 ? (
                                <tr>
                                    <td colSpan={5}>
                                        <Center>
                                            No data found
                                        </Center>
                                    </td>
                                </tr>
                            ) : rows}
                        </tbody>
                    </Table>
                </div>
            </ScrollArea>
        </Paper>
    )
}