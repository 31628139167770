import { jsPDF } from "jspdf-invoice-template";

function appendZero(phoneNumber) {
    // Get the last 9 digits of the phone number.
    const phoneNumberDigits = phoneNumber.slice(-9);

    return "0"+phoneNumberDigits;
}  

export default function generateStatement(props) {
    const options = {
        orientation: props.orientationLandscape ? "landscape" : "",
        compress: true
    };


    var doc = new jsPDF(options);

    doc.setDocumentProperties({
        author: `Emita`,
        title: `${props.customer_name} Emita Account Statement`,
    });

    const splitTextAndGetHeight = (text, size) => {
        var lines = doc.splitTextToSize(text, size);

        return {text: lines, height: doc.getTextDimensions(lines).h + 3}
    }

    var docWidth = doc.internal.pageSize.width;

    var black_color = "#000000";

    var current_height = 10;

    var pdf_config = {
        logo: 20,
        headerTextSize: 14,
        labelTextSize: 12,
        fieldTextSize:9,
        lineHeight: 6,
        subLineHeight: 4,
        tableHeight: 2
    };

    doc.setFontSize(pdf_config.headerTextSize);
    doc.setTextColor(black_color);
    doc.setFont(undefined, "bold");

    // letter head
    doc.text(docWidth * 0.5, current_height, `CUSTOMER STATEMENT`, {align: 'center'});
    current_height += pdf_config.subLineHeight;
    current_height += pdf_config.subLineHeight;

    doc.setFont(undefined, "normal");

    doc.setFontSize(pdf_config.labelTextSize);
    doc.text(docWidth * 0.5, current_height, props.water_service_name, {align: 'center'});
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth * 0.5, current_height, `${props.company_email} / ${props.company_phone} / ${props.alternative_company_phone}`, {align: 'center'});

    // line break
    current_height += pdf_config.subLineHeight;
    doc.line(10, current_height, docWidth-10, current_height);

    doc.setFontSize(pdf_config.fieldTextSize);
    // contact
    current_height += pdf_config.lineHeight;
    doc.text(10, current_height, `To:${props.customer_name}`);
    doc.text(docWidth - 70, current_height, "Statement Date:", "left");
    doc.text(docWidth - 30, current_height, new Date().toLocaleDateString(), "left");
    current_height += pdf_config.subLineHeight;

    // logo
    var stampImage = '';
    if (typeof window === "undefined") {
      stampImage = props.stamp.src;
    } else {
      stampImage = new Image();
      stampImage.src = props.stamp.src;
    }
    doc.addImage(stampImage, 10, current_height, 20, 20)
    doc.text(docWidth - 70, current_height, "Tel:", "left");
    doc.text(docWidth - 30, current_height, appendZero(props.customer_phone), "left");
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth - 70, current_height, "Account#:", "left");
    doc.text(docWidth - 30, current_height, props.account_number, "left");
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth - 70, current_height, "Meter#:", "left");
    doc.text(docWidth - 30, current_height, props.meter_number, "left");
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth - 70, current_height, "Account Status:", "left");
    doc.text(docWidth - 30, current_height, props.account_status, "left");
    current_height += pdf_config.subLineHeight;

    current_height += pdf_config.subLineHeight;

    doc.setFont(undefined, "bold");
    doc.setFontSize(pdf_config.labelTextSize);
    doc.text(docWidth - 70, current_height, "Balance:", "left");
    doc.text(docWidth - 30, current_height, props.balance, "left");
    doc.setFont(undefined, "normal");
    doc.setFontSize(pdf_config.fieldTextSize);
    current_height += pdf_config.subLineHeight;

    // line breaker
    current_height += pdf_config.subLineHeight;
    doc.line(10, current_height, docWidth - 10, current_height);

    // balance brought forward
    current_height += pdf_config.lineHeight;
    doc.setFont(undefined, "bold");
    doc.text(10, current_height, `Consumption History:`);
    doc.text(docWidth - 30, current_height, ``);
    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "normal");

      //#region PAGE BREAKER
  var checkAndAddPageLandscape = function () {
    if (!props.orientationLandscape && current_height + invDescSize > 250) {
      doc.addPage();
      current_height = 10;
    }
  }

  var checkAndAddPageNotLandscape = function (heightLimit = 173) {
    if (props.orientationLandscape && current_height + invDescSize > heightLimit) {
      doc.addPage();
      current_height = 10;
    }
  }
  var checkAndAddPage = function () {
    checkAndAddPageNotLandscape();
    checkAndAddPageLandscape();
  }

  checkAndAddPage()

    // consumption history table
    var td_width = (doc.getPageWidth() - 20) / 4;
    var headers = ["ID", "Date", "Previous Reading", "Present Reading", "Consumption", "Technician", "Comments"];

    // add style for 2 or more columns
    var customColumnNo = headers.map(x => x?.style?.width || 0).filter(x => x > 0);
    var customWidthOfAllColumns = customColumnNo.reduce((a, b) => a+b, 0);
    td_width = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (headers.length - customColumnNo.length);

    // table border header
    var addTableHeaderBorder = () => {
        current_height += 2;
        const line_height = 7;
        let start_width = 0;

        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width ||  td_width;
            if(i === 0){
                doc.rect(10, current_height, current_td_width, line_height);
            } else {
                const previous_td_width = headers[i-1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, line_height);
            }
        }

        current_height -= 2;
    };

    // table body border
    var addTableBodyBorder = (lineHeight) => {
        let start_width = 0;
        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width || td_width;
            if(i === 0) {
                doc.rect(10, current_height, current_td_width, lineHeight);
            } else {
                const previous_td_width = headers[i - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, lineHeight);
            }
        }
    };

    // table header
    var addTableHeader = () => {
        addTableHeaderBorder();

        current_height += pdf_config.subLineHeight;
        doc.setFontSize(pdf_config.fieldTextSize);
        doc.setDrawColor(black_color);
        current_height += 2;

        let start_width = 0;
        headers.forEach(function (row, index) {
            if(index === 0){
                doc.text(row, 11, current_height);
            } else {
                const current_td_width = row?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(row, start_width + 11, current_height);
            }
        });

        current_height += pdf_config.subLineHeight - 1;
    }

    addTableHeader();

    

    const consumption_data = props.consumption_history;

    consumption_data.forEach(function(row) {
        doc.line(10, current_height, docWidth - 10, current_height);

        // get max height for the current row
        var getRowsHeight = function(){
            let rowsHeight = [];

            row.forEach(function(rr, index){
                const width_to_use = headers[index]?.style?.width || td_width;

                let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix the padding issues between borders
                rowsHeight.push(item.height)
            });

            return rowsHeight;
        }

        var maxHeight = Math.max(...getRowsHeight());

        // body borders
        addTableBodyBorder(maxHeight + 1);

        let start_width = 0;

        row.forEach(function(rr, index) {
            const width_to_use = headers[index]?.style?.width || td_width;
            let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix padding issues between borders
            
            if(index === 0){
                doc.text(item.text, 11, current_height + 4);
            } else {
                const current_td_width = rr?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(item.text, 11+start_width, current_height + 4);
            }
        });

        current_height += maxHeight - 4;

        // td border height
        current_height += 5;
    });

    current_height += pdf_config.lineHeight;

    doc.addPage();
    current_height = 10;

    doc.setFont(undefined, "bold");
    doc.text(10, current_height, `Invoicing History:`);
    doc.text(docWidth - 30, current_height, '');
    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "normal");

    // invoicing history table
    var td_width = (doc.getPageWidth() - 20) / 4;
    var headers = ["ID", "Date", "Invoice#", "Bill(Ksh)", "Arrears(Ksh)", "Total Bill(Ksh)", "Category", "Due Date"];

    // add style for 2 or more columns
    var customColumnNo = headers.map(x => x?.style?.width || 0).filter(x => x > 0);
    var customWidthOfAllColumns = customColumnNo.reduce((a, b) => a+b, 0);
    td_width = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (headers.length - customColumnNo.length);

    // table border header
    var addTableHeaderBorder = () => {
        current_height += 2;
        const line_height = 7;
        let start_width = 0;

        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width ||  td_width;
            if(i === 0){
                doc.rect(10, current_height, current_td_width, line_height);
            } else {
                const previous_td_width = headers[i-1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, line_height);
            }
        }

        current_height -= 2;
    };

    // table body border
    var addTableBodyBorder = (lineHeight) => {
        let start_width = 0;
        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width || td_width;
            if(i === 0) {
                doc.rect(10, current_height, current_td_width, lineHeight);
            } else {
                const previous_td_width = headers[i - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, lineHeight);
            }
        }
    };

   
    // table header
    var addTableHeader = () => {
        addTableHeaderBorder();

        current_height += pdf_config.subLineHeight;
        doc.setFontSize(pdf_config.fieldTextSize);
        doc.setDrawColor(black_color);
        current_height += 2;

        let start_width = 0;
        headers.forEach(function (row, index) {
            if(index === 0){
                doc.text(row, 11, current_height);
            } else {
                const current_td_width = row?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(row, start_width + 11, current_height);
            }
        });

        current_height += pdf_config.subLineHeight - 1;
    }
    
    addTableHeader();

    const invoicing_data = props.invoicing_history;

    invoicing_data.forEach(function(row) {
        doc.line(10, current_height, docWidth - 10, current_height);

        // get max height for the current row
        var getRowsHeight = function(){
            let rowsHeight = [];

            row.forEach(function(rr, index){
                const width_to_use = headers[index]?.style?.width || td_width;

                let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix the padding issues between borders
                rowsHeight.push(item.height)
            });

            return rowsHeight;
        }

        var maxHeight = Math.max(...getRowsHeight());

        // body borders
        addTableBodyBorder(maxHeight + 1);

        let start_width = 0;

        row.forEach(function(rr, index) {
            const width_to_use = headers[index]?.style?.width || td_width;
            let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix padding issues between borders
            
            if(index === 0){
                doc.text(item.text, 11, current_height + 4);
            } else {
                const current_td_width = rr?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(item.text, 11+start_width, current_height + 4);
            }
        });

        
        current_height += maxHeight - 4;

        // td border height
        current_height += 5;
    })

    current_height += pdf_config.lineHeight;

    //checkAndAddPage();

    doc.addPage();
    current_height = 10;

    doc.setFont(undefined, "bold");
    doc.text(10, current_height, `Payments History:`);
    doc.text(docWidth - 30, current_height, '');
    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "normal");

    // payment history table
    var td_width = (doc.getPageWidth() - 20) / 4;
    var headers = ["ID", "Date", "Transaction ID", "Amount(Ksh)", "Short Code", "MSISDN", "First Name"];

    // add style for 2 or more columns
    var customColumnNo = headers.map(x => x?.style?.width || 0).filter(x => x > 0);
    var customWidthOfAllColumns = customColumnNo.reduce((a, b) => a+b, 0);
    td_width = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (headers.length - customColumnNo.length);

    // table border header
    var addTableHeaderBorder = () => {
        current_height += 2;
        const line_height = 7;
        let start_width = 0;

        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width ||  td_width;
            if(i === 0){
                doc.rect(10, current_height, current_td_width, line_height);
            } else {
                const previous_td_width = headers[i-1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, line_height);
            }
        }

        current_height -= 2;
    };

    // table body border
    var addTableBodyBorder = (lineHeight) => {
        let start_width = 0;
        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width || td_width;
            if(i === 0) {
                doc.rect(10, current_height, current_td_width, lineHeight);
            } else {
                const previous_td_width = headers[i - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, lineHeight);
            }
        }
    };

    // table header
    var addTableHeader = () => {
        checkAndAddPage()
        addTableHeaderBorder();

        current_height += pdf_config.subLineHeight;
        doc.setFontSize(pdf_config.fieldTextSize);
        doc.setDrawColor(black_color);
        current_height += 2;

        let start_width = 0;
        headers.forEach(function (row, index) {
            if(index === 0){
                doc.text(row, 11, current_height);
            } else {
                const current_td_width = row?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(row, start_width + 11, current_height);
            }
        });

        current_height += pdf_config.subLineHeight - 1;
    }
    
    addTableHeader();

    const payments_data = props.payments_history;

    payments_data.forEach(function(row){
        checkAndAddPage();
        doc.line(10, current_height, docWidth - 10, current_height);

        // get max height for the current row
        var getRowsHeight = function(){
            let rowsHeight = [];

            row.forEach(function(rr, index){
                const width_to_use = headers[index]?.style?.width || td_width;

                let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix the padding issues between borders
                rowsHeight.push(item.height)
            });

            return rowsHeight;
        }

        var maxHeight = Math.max(...getRowsHeight());

        // body borders
        addTableBodyBorder(maxHeight + 1);

        let start_width = 0;

        row.forEach(function(rr, index) {
            checkAndAddPage()
            const width_to_use = headers[index]?.style?.width || td_width;
            let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix padding issues between borders
            
            if(index === 0){
                doc.text(item.text, 11, current_height + 4);
            } else {
                const current_td_width = rr?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(item.text, 11+start_width, current_height + 4);
            }
        });

        current_height += maxHeight - 4;

        // td border height
        current_height += 5;
    });


    let return_obj = {
        pagesNumber: doc.getNumberOfPages(),
    };

    if(props.return_jspdfdoc_object){
        return_obj = {
            ...return_obj,
            jsPDFDocObject: doc
        };
    }

    var invDescSize = splitTextAndGetHeight(
        `Dear ${props.customer_name}, thank you for choosing ${props.water_service_name} for your water utility needs. Should you have any question regarding your account activities, please contact us immediately. Please note that all payments should be made on due time to avoid disconnections. For cheque payments, always attach your customer account number at the back of the cheque. For payments through the bank, forward the deposit slips/credit advices to our office for receipting. You can also access more information about your account by downloading the Emita customer app at Google Play Store.`,
        docWidth / 2
      ).height;
    

    if (doc.getNumberOfPages() > 1) {
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
      doc.setFontSize(pdf_config.fieldTextSize - 2);
      doc.setTextColor("#000000");

      if (props.pageEnable) {
        doc.text(10, doc.internal.pageSize.height - 6, props.footer.text);
        doc.setPage(i);
        doc.text(
          props.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
          docWidth - 20,
          doc.internal.pageSize.height - 6
        );
      }

      checkAndAddPageNotLandscape(183);
      checkAndAddPageLandscape();
      //addStamp();
    }
  }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && props.pageEnable) {
        doc.setFontSize(pdf_config.fieldTextSize - 2);
        doc.setTextColor("#000000");
        doc.text(10, doc.internal.pageSize.height - 6, props.footer.text);
        doc.text(
          props.pageLabel + "1 / 1",
          docWidth - 20,
          doc.internal.pageSize.height - 6
        );
      }

    switch(props.output_type){
        case "save":
            doc.save(props.filename);
            break;
        case "blob":
            const blob_output = doc.output("blob");
            return_obj = {
                ...return_obj,
                blob: blob_output
            };
            break;
        case "datauristring":
            return_obj = {
                ...return_obj,
                dataUriString: doc.output("datauristring", {
                    filename: props.filename
                }),
            };
            break;
        case "arraybuffer":
            return_obj = {
                ...return_obj,
                arrayBuffer: doc.output("arraybuffer"),
            };
            break;
        default:
            doc.output(props.output_type, {
                filename: props.filename
            });
    }

    return return_obj;
}