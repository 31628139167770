// calculates the cost of water consumed.
/**
 * 
 * @param {number} quantity is the quantity of water consumed by the customer
 * @param {[]} consumptionBlocks is an array of the consumption blocks.
 * @param {number} standing_charges is the cost of standing charges.
 */
export function createWaterCost(quantity, consumptionBlocks,){
    let i=1; 
    quantity = parseFloat(quantity.toFixed(2));

    let sum = 0.00; // sum is the total cost. This has been added separately on the table.

    // guessing from the first array item

    let keys = Object.keys(consumptionBlocks[0]);

    if(keys.includes("upper") || keys.includes("lower")){
        for(let k=0; k<consumptionBlocks.length; k++){
            if(consumptionBlocks[k].upper < quantity){
                i += 1;
            } else {
                break;
            }
        }
    } else {
        // this tarrif has no consumption blocks
        sum = parseFloat(sum) + parseFloat((quantity * consumptionBlocks[0].cost).toFixed(2));
        return sum
    }

    // in the first step, we get the the total number of loops we need to make to calculate the total
    // cost. Definately it is incremented by one.

    // lets loop over it to calculate the cost now.

    for(let j=0; j<i; j++){
        if(j !== (i - 1)){
            // it is not the last loop we are making
            // as the last loop's upper limit is now the quantity consumed by the customer
            if(consumptionBlocks[j].flatRate){
                sum = parseFloat(sum) + parseFloat(consumptionBlocks[j].cost.toFixed(2));

            } else {
                // remember to add one so that the lower limit is inclusive
                sum = parseFloat(sum) + parseFloat((consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1.00)).toFixed(2))
            }

        } else {
            // check if it is the flat rate
            if(consumptionBlocks[j].flatRate){
                sum = parseFloat(sum) + parseFloat(consumptionBlocks[j].cost.toFixed(2))
            } else {
                // remember to add one so that the lower limit is inclusive
                sum = parseFloat(sum) + parseFloat(consumptionBlocks[j].cost * (quantity - consumptionBlocks[j].lower + 1.00).toFixed(2));
            }

            break;
        }
    }

    return sum;
}

/**
 * 
 * @param {number} quantity is the quantity to calculate cost from
 * @param {[]} consumptionBlocks is the tarrif schema to use.
 * @returns {number} cost
 */
export function createSewerCost(quantity, consumptionBlocks){
    let i=1; 
    quantity = parseFloat(quantity.toFixed(2));
    let sum = 0.00; // sum is the total cost

    // guessing from the first array item

    let keys = Object.keys(consumptionBlocks[0]);

    if(keys.includes("upper") || keys.includes("lower")){
        for(let k=0; k<consumptionBlocks.length; k++){
            if(consumptionBlocks[k].upper < quantity){
                i += 1;
            } else {
                break;
            }
        }
    } else {
        // this tarrif has no consumption blocks
        sum = parseFloat(sum) + parseFloat((quantity * consumptionBlocks[0].cost).toFixed(2));
        return sum;
    }

    // in the first step, we get the the total number of loops we need to make to calculate the total
    // cost. Definately it is incremented by one.

    // lets loop over it to calculate the cost now.

    for(let j=0; j<i; j++){
        if(j !== (i - 1)){
            // it is not the last loop we are making
            // as the last loop's upper limit is now the quantity consumed by the customer
            if(consumptionBlocks[j].flatRate){
                sum =parseFloat(sum) + parseFloat(consumptionBlocks[j].cost.toFixed(2));

            } else {
                // remember to add one so that the lower limit is inclusive
                sum =parseFloat(sum) + parseFloat((consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1.00)).toFixed(2))
            }

        } else {
            // check if it is the flat rate
            if(consumptionBlocks[j].flatRate){
                sum =parseFloat(sum)  + parseFloat(consumptionBlocks[j].cost.toFixed(2))
            } else {
                // remember to add one so that the lower limit is inclusive
                sum =parseFloat(sum) + parseFloat((consumptionBlocks[j].cost * (quantity - consumptionBlocks[j].lower + 1.00)).toFixed(2));
            }

            break;
        }
    }

    return sum;
}

export function createWaterConsumptionBlocks(quantity, consumptionBlocks){
    let loops = 1;
    let arr = [];

    quantity = parseFloat(quantity.toFixed(2));

    if(quantity <= 0){
        arr.push(["0", "0.00", "0.00"]);
        return arr;
    }

    // guessing from the first array items to establish if it has blocks or not
    let keys = Object.keys(consumptionBlocks[0]);

    if(keys.includes("upper") || keys.includes("lower")){
        for(let k=0; k<consumptionBlocks.length; k++){
            if(consumptionBlocks[k].upper < quantity){
                loops += 1;
            } else {
                break;
            }
        }
    } else {
        let arr2 = []
        arr2.push(''+(quantity));
        arr2.push(''+(consumptionBlocks[0].cost).toFixed(2));
        arr2.push(''+(quantity * consumptionBlocks[0].cost).toFixed(2))

        arr.push(arr2);

        return arr;
    }

    // we have confirmed that it has blocks.

    for(let j=0; j<loops; j++){
        if(j !== (loops - 1)){
            if(j===0){
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push(''+(consumptionBlocks[j].upper - consumptionBlocks[j].lower));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
    
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push(''+(consumptionBlocks[j].upper - consumptionBlocks[j].lower));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1)).toFixed(2));
    
                    arr.push(chunk);
                }
            } else {
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push(''+(consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
    
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push(''+(consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1)).toFixed(2));
    
                    arr.push(chunk);
                }
            }
        } else {
            if(j===0){
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push(''+(quantity - consumptionBlocks[j].lower));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
    
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push(''+(quantity - consumptionBlocks[j].lower));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1)).toFixed(2));
    
                    arr.push(chunk);
                }
            } else {
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push(''+(quantity - consumptionBlocks[j].lower + 1));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
        
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push(''+(quantity - consumptionBlocks[j].lower + 1));
                    chunk.push(''+(consumptionBlocks[j].cost).toFixed(2));
                    chunk.push(''+(consumptionBlocks[j].cost * (quantity - consumptionBlocks[j].lower + 1)).toFixed(2));
        
                    arr.push(chunk);
                }
            }

            break;
        }
    }

    return arr;
}

export function createSewerConsumptionBlocks(quantity, consumptionBlocks){
    let loops = 1;
    let arr = [];

    quantity = parseFloat(quantity.toFixed(2));

    if(quantity <= 0){
        arr.push(["0", "0.00", "0.00"]);
        return arr;
    }

    // guessing from the first array items to establish if it has blocks or not
    let keys = Object.keys(consumptionBlocks[0]);

    if(keys.includes("upper") || keys.includes("lower")){
        for(let k=0; k<consumptionBlocks.length; k++){
            if(consumptionBlocks[k].upper < quantity){
                loops += 1;
            } else {
                break;
            }
        }
    } else {
        let r = [];

        r.push((quantity).toFixed(2));
        r.push((consumptionBlocks[0].cost).toFixed(2));
        r.push((quantity * consumptionBlocks[0].cost).toFixed(2))

        arr.push(r);
        
        return arr;
    }

    // we have confirmed that it has blocks.

    for(let j=0; j<loops; j++){
        if(j !== (loops - 1)){
            if(j===0){
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push((consumptionBlocks[j].upper - consumptionBlocks[j].lower).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
    
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push((consumptionBlocks[j].upper - consumptionBlocks[j].lower).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1.00)).toFixed(2));
    
                    arr.push(chunk);
                }
            } else {
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push((consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1.00).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
    
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push((consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower + 1.00)).toFixed(2));
    
                    arr.push(chunk);
                }
            }
        } else {
            if(j===0){
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push((quantity - consumptionBlocks[j].lower).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
    
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push((quantity - consumptionBlocks[j].lower).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost * (consumptionBlocks[j].upper - consumptionBlocks[j].lower)).toFixed(2));
    
                    arr.push(chunk);
                }
            } else {
                if(consumptionBlocks[j].flatRate){
                    let chunk = [];
                    chunk.push((quantity - consumptionBlocks[j].lower + 1.00).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
        
                    arr.push(chunk);
                } else {
                    let chunk = [];
                    chunk.push((quantity - consumptionBlocks[j].lower + 1.00).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost).toFixed(2));
                    chunk.push((consumptionBlocks[j].cost * (quantity - consumptionBlocks[j].lower + 1.00)).toFixed(2));
        
                    arr.push(chunk);
                }
            }

            break;
        }
    }

    return arr;
}