/**
 * 
 * @param {number} num is the number of months to take to derive the cycle.
 * @returns [ ] cycles as array
 */
export default function getCycles(num) {
    let arr = [];
    let temp = 1;
  
    for (let i = 0; i <= num; i++) {
      const today = new Date();
  
      // Calculate the correct month and year, considering the new year transition
      let month = today.getMonth() - temp;
      let year = today.getFullYear();
      if (month < 0) {
        // just for clarity, but should typically be 11
        month = 12 + month; // Adjust for negative month
        year--; // Decrement year for previous year
      }
  
      let result = "";
  
      switch(month){
        case 0:
            result = `January ${year.toString()}`;
            break;
        case 1:
            result = `February ${year.toString()}`;
            break;
        case 2:
            result = `March ${year.toString()}`;
            break;
        case 3:
            result = `April ${year.toString()}`;
            break;
        case 4:
            result = `May ${year.toString()}`;
            break;
        case 5:
            result = `June ${year.toString()}`;
            break;
        case 6:
            result = `July ${year.toString()}`;
            break;
        case 7:
            result = `August ${year.toString()}`;
            break;
        case 8:
            result = `September ${year.toString()}`;
            break;
        case 9:
            result = `October ${year.toString()}`;
            break;
        case 10:
            result = `November ${year.toString()}`;
            break;
        case 11:
            result = `December ${year.toString()}`;
            break;

        default:
            result ="[Loading...]"
    }
  
      arr.push({ label: result, value: result });
      temp += 1;
    }
  
    return arr;
}

export function getPaymentsAndInvoiceCycles(num){
    let arr = [];
    let temp = 0;
  
    for (let i = 0; i <= num; i++) {
      const today = new Date();
  
      // Calculate the correct month and year, considering the new year transition
      let month = today.getMonth() - temp;
      let year = today.getFullYear();
      if (month < 0) {
        // just for clarity, but should typically be 11
        month = 12 + month; // Adjust for negative month
        year--; // Decrement year for previous year
      }
  
      let result = "";
  
      switch(month){
        case 0:
            result = `January ${year.toString()}`;
            break;
        case 1:
            result = `February ${year.toString()}`;
            break;
        case 2:
            result = `March ${year.toString()}`;
            break;
        case 3:
            result = `April ${year.toString()}`;
            break;
        case 4:
            result = `May ${year.toString()}`;
            break;
        case 5:
            result = `June ${year.toString()}`;
            break;
        case 6:
            result = `July ${year.toString()}`;
            break;
        case 7:
            result = `August ${year.toString()}`;
            break;
        case 8:
            result = `September ${year.toString()}`;
            break;
        case 9:
            result = `October ${year.toString()}`;
            break;
        case 10:
            result = `November ${year.toString()}`;
            break;
        case 11:
            result = `December ${year.toString()}`;
            break;

        default:
            result ="[Loading...]"
    }
  
      arr.push({ label: result, value: result });
      temp += 1;
    }
  
    return arr;
}

export const parseCycle = (cycle) => {
    if(typeof(cycle) !== "string"){
        return false;
    }

    if(typeof(cycle.split(" ")[0]) !== "string"){
        return false;
    }

    let result = 0;

    switch(cycle.split(" ")[0]){
        case "January":
            result = 0;
            break;
        case "February":
            result = 1;
            break;
        case "March":
            result = 2;
            break;
        case "April":
            result = 3;
            break;
        case "May":
            result = 4;
            break;
        case "June":
            result = 5;
            break;
        case "July":
            result = 6;
            break;
        case "August":
            result = 7;
            break;
        case "September":
            result = 8;
            break;
        case "October":
            result = 9;
            break;
        case "November":
            result = 10;
            break;
        case "December":
            result = 11;
            break;

        default:
            result = 0;
    }

    return {month: result, year: parseInt(cycle.split(" ")[1])};
}