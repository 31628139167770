import axios from 'axios'

export async function getBalance(api, partner){
    return new Promise((resolve, reject) => {
        const body = JSON.stringify({
            "apikey":api,
            "partnerID":partner
          });

          axios.post("https://sms.textsms.co.ke/api/services/getbalance/", body, {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
          }).then(function(res){
              if(res.status === 200){
                resolve(res);
              }
          })
          .catch(function(err){
            reject(err);
          })
    })
}