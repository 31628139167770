import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../../App";
import { Heading} from "@aws-amplify/ui-react";
import { ActionIcon, Alert, Badge, Button, Center, Checkbox, Divider, Grid, Group, NavLink, Paper, PasswordInput, Radio, Select, SimpleGrid, Switch, Table, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faList } from "@fortawesome/free-solid-svg-icons";
import axios from "../../../utils/axios";
import { Book, InfoCircle, List, Plus, Trash } from "tabler-icons-react";
import ReactGA from "react-ga4";
import swal from "sweetalert";
import { toast } from "react-hot-toast";

export default function BillingMethod(){
    const { state, dispatch } = useContext(AuthContext);
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    const [new_billing, setNewBilling] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sms, setSMS] = useState(true);
    const [email, setEmail] = useState(false);
    const [, setBoth] = useState(false);
    const [short_code, setShortCode] = useState("");
    const [short_code_type, setShortCodeType] = useState("");
    const [dma, setDMA] = useState("*");
    const [dmas, setDMAs] = useState([])
    const [codes, setCodes] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [alert_open, setAlertOpen] = useState(true);
    const [consumer_key, setConsumerKey] = useState("");
    const [consumer_secret, setConsumerSecret] = useState("");

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchCodes = () => {
        setLoading(true);
        try{
            axios.post("/short-codes/get-short-codes", {
                parent: state.userData._id
            }).then(function(res){
                if(res.status === 200){
                    setCodes(res.data.data);
                    setLoading(false);
                }
            })
        } catch(error){
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCodes();
    }, [refresh])

    const fetchDMAs = () => {
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                let arr = [{label: "All DMAs", value: "*"}];
                for(let i=0; i<res.data.data.length; i++){
                    let obj = res.data.data[i];

                    arr.push({label: obj.name, value: obj.name});
                }

                setDMAs(arr);
            }
        })
    }

    useEffect(() => {
        fetchDMAs();
    }, [])

    const saveShortCode = () => {
        setLoading(true);
        try{
            setLoading(true);
            axios.post("/short-codes/create", {
                short_code: short_code,
                parent: state.userData._id,
                short_code_type: short_code_type,
                dma: dma,
                billing_method: sms ? "sms" : email ? "email" : "whatsapp",
                consumer_key: consumer_key,
                consumer_secret: consumer_secret
            }).then(function(res){
                if(res.status === 200){
                    setLoading(false);
                    setRefresh(!refresh);
                    setNewBilling(false);
                    setShortCode("");
                    setShortCodeType("");
                    setDMA("*");
                    setConsumerKey("");
                    setConsumerSecret("");

                    toast.success("The shortcode has been configured successfully!");
                } else {
                    setLoading(false);
                    toast.error("Something wrong happened!");
                }
            }).catch(function(error){
                setLoading(false);
                toast.error(error.message);
            })
        } catch(error){
            setLoading(false);
            toast.error(error.message);
        }
    }

    const deleteShortCode = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to continue? Before deleting this shortcode, make sure to delete Emita confirmation and validation URLS from your Daraja account.",
            icon: "warning",
            buttons: {
                cancel: "No, Cancel Please!",
                continue: {
                    text: "Yes, delete this shortcode",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                const body = {
                    id: id
                }

                axios.post("/short-codes/delete-short-code", body).then(function(res){
                    if(res.status === 200){
                        setRefresh(!refresh);
                        toast.success("The short code has been removed from Emita");
                    }
                }).catch(function(error){
                    toast.error(error.message);
                })
            }
        })
    }

    return (
        <Paper p="md" >
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Configure Billing Method</title>
        </Helmet>

        <Heading level={3} fontWeight={650} marginBottom={20} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Billing Methods</Heading>
        <Text mb={20}>Configure the billing methods to be used by your clients.</Text>
        <Grid>
        <Grid.Col sm={12} lg={3}>
            <NavLink mb={20} label="Payment Pipelines" description="List of your configured short codes" icon={<List size={13} />} active={!new_billing} onClick={() => {setNewBilling(false)}} />
            <NavLink mb={20} label="New Payment Pipeline" description="Configure new payment option" icon={<Plus size={13} />} active={new_billing} onClick={() => {setNewBilling(true)}} />
            <NavLink mb={20} label="Guides" description="Guides on how to go live with your short code" icon={<Book size={13} />} />
        </Grid.Col>
        {!new_billing && codes.length === 0 ? (
                    <Grid.Col sm={12} lg={9} style={{height: (height - HEADER_HEIGHT - 160)}}>
        
                    <Group position="center" mt="20%">
                        <FontAwesomeIcon icon={loading ? faGear : faList} spin={loading} size={loading ? "xs" : "2xl"}/>
                    </Group>
        
                    <Text size={loading ? "xs" : "sm"} align="center" mb={30} mt={30}>{loading ? "Please wait..." : "No billing methods have been set. SMS billing will be used by default."}</Text>
        
                    {!loading ? (
                        <Center>
                        <Button onClick={() => {setNewBilling(true)}} radius={28}>Configure Mpesa Pipeline</Button>
                    </Center>
                    ) : null}
                    </Grid.Col>
        ) : !new_billing && codes.length > 0 ? (
                <Grid.Col sm={12} lg={9} style={{borderLeft: "1px solid gray"}}>
                <div style={{overflowX: 'auto', marginTop: 20}}>
                <Table mb={50} fontSize="xs" style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                        <tr>
                            <th>ShortCode</th>
                            <th>ShortCode Type</th>
                            <th>DMA</th>
                            <th>Registered</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {codes.map((item, idx) => {
                        return (
                            <tr key={`code-${idx}`}>
                            <td>{item.short_code}</td>
                            <td>{item.short_code_type}</td>
                            <td>{item.dma === "*" ? "All" : item.dma}</td>
                            <td><Badge color={item.registered ? "green" : "red"}>{item.registered ? "Yes" : "No"}</Badge></td>
                            <td>
                                <ActionIcon onClick={() => {deleteShortCode(item._id)}}><Trash size={15} color="red" /></ActionIcon>
                            </td>
                        </tr>
                        )
                    })}
                    </tbody>
                </Table>
        </div>
                </Grid.Col>
        ) : (
                <Grid.Col sm={12} lg={9} style={{borderLeft: "1px solid gray"}}>
                    <Paper mb={20}>
                        <TextInput value={short_code} onChange={(e) => {setShortCode(e.currentTarget.value)}} label="ShortCode" withAsterisk mb={10} />
                        <Radio.Group mb={10} value={short_code_type} onChange={(val) => {setShortCodeType(val)}} label="Type of ShortCode" withAsterisk>
                            <Radio value="PayBill" label="PayBill" />
                            <Radio value="BuyGoods" label="BuyGoods" />
                        </Radio.Group>
                        <SimpleGrid mb={10} cols={2}>
                        <TextInput value={consumer_key} onChange={(e) => {setConsumerKey(e.currentTarget.value)}} label="Consumer Key" description="You can find this value in your Safaricom Daraja account. If you don't have an account, please create one and make sure that you've enabled your shortcode to operate in a live production environment. " withAsterisk />
                        <PasswordInput value={consumer_secret} onChange={(e) => {setConsumerSecret(e.currentTarget.value)}} label="Consumer Secret" description="This value can also be found in your Safaricom Daraja account. Please note that this value is unique to you and should not be shared with anyone else, not even Safaricom staff." withAsterisk />
                        </SimpleGrid>
                        <Text mb={10} size="xs" color="green">Consumer key value is encrypted and no one except you, not even Emita, can know its value.</Text>
                        <Select value={dma} onChange={(val) => {setDMA(val)}} label="DMA" description="DMA to enforce the payment configuration" data={dmas} />
                    </Paper>
                    <Button loading={loading} onClick={() => {saveShortCode()}} radius={28}>Create Pipeline</Button>
                </Grid.Col>
        )}
        </Grid>
        </Paper>
    )
}