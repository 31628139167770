/**
 * 
 * @param {*} currentBill 
 * @param {*} previousBill 
 * @param {*} currentPayment 
 * @param {*} previousPayment 
 * @returns trend - billing percentage efficiency compared to the previous cycle.
 */
export default function billEfficiencyTrend(
    currentBill, previousBill, currentPayment, previousPayment
){
    if(typeof(currentBill) !== "number" && typeof(previousBill) !== "number" && typeof(currentPayment) !== "number" && typeof(previousPayment) !== "number"){
        return new Error("Param values should be numbers.")
    }

    if(currentBill === 0){
        return 0;
    }

    if(previousBill === 0){
        return 0;
    }
    let current_trend = (currentPayment / currentBill);

    let previous_trend = (previousPayment / previousBill);

    let trend = ((current_trend - previous_trend) / previous_trend) * 100;

    return trend;
}

/**
 * 
 * @param {*} currentBill 
 * @param {*} currentPayment 
 * @returns 
 */
export function billEfficiency(
    currentBill, currentPayment
){
    if(currentBill === 0){
        return 0;
    }
    return (currentPayment / currentBill) * 100;
}