import { Avatar, Button, Text, Space, Center, Divider, Grid, Group, Paper, Table, SimpleGrid, Anchor, Box, Indicator, useMantineTheme, NavLink, Badge, Drawer, TextInput, Radio, ScrollArea, NumberInput, Stack, Select, Checkbox, Textarea, Tabs, Alert, Autocomplete, Modal, Notification } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, BrandWhatsapp, ChevronDown, Download, Edit, EditCircle, InfoCircle, Message, Trash, X, } from "tabler-icons-react";
import axios from "../../../utils/axios";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../../App";
import { useLocalStorage, useSessionStorage, useViewportSize } from "@mantine/hooks";
import { getCurrentReadingMonth, getDate, getLastReadingMonth, getPeriodEnding } from "../../../utils/getMonth";
import ReactGA from "react-ga4";
import { generateInvoice } from "../../../utils/invoice2";
import { createSewerConsumptionBlocks, createSewerCost, createWaterConsumptionBlocks, createWaterCost } from "../../../utils/createWaterCost";
import { generateInvoiceNumber } from "../../../utils/generate_invoice_number";
import { DEFAULT_METER_READING_CYCLE } from "../../../constants";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import swal from "sweetalert";
import { Heading, SelectField } from "@aws-amplify/ui-react";
import { customAlphabet } from "nanoid";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
  } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import generateStatement from "../../../utils/statement";
import { obfuscateEmail, obfuscatePhoneNumber } from "../../../utils/obfuscation";
import OTPInput from "otp-input-react";
import Receipt from "../../../utils/receipt";

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    annotationPlugin
);

const invoice_map = [
    {"Disconnection/Suspension/Reconnection Fee" : "disconnection"},
    {"Illegal Connection" : "illegal connection"},
    {"Repairs" : "repairs"},
    {"Monthly Water Charges/bill": "batch-download"},
    {"Late Payment": "invoice defaulting"},
    {"Registration": "registration"},
    {"Meter Deposit": "meter deposit"},
     {"Others": "others"},
]

function findKeyByValue(arr, targetValue) {
    for (let obj of arr) {
      for (let key in obj) {
        if (obj[key] === targetValue) {
          return key;
        }
      }
    }
    return ""; // Return null if the value isn't found
  }
  
  
export default function CustomerView(){
    let id  = decodeURIComponent(useParams()?.id);
    const { state, dispatch} = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [consumption, setConsumption] = useState([]);
    const theme = useMantineTheme();
    const [active, setActive] = useState("profile");
    const [invoices, setInvoices] = useState([]);
    const [payments, setPayments] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [drawer1, setDrawer1] = useState(false);
    const [drawer2, setDrawer2] = useState(false);
    const [drawer3, setDrawer3] = useState(false);
    const [drawer4, setDrawer4] = useState(false);
    const [drawer5, setDrawer5] = useState(false);
    const [name, setName] = useState("");
    const [disconnected, setDisconnected] = useState("");
    const [account, setAccount] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhone] = useState("");
    const [previous_balance, setBalance] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [county, setCounty] = useState("");
    const [country, setCountry] = useState("");
    const [username, setUsername] = useState("");
    const [meter_type, setMeter] = useState("");
    const [dma, setDMA] = useState("");
    const [tarrif, setTarrif] = useState("");
    const [previous_reading, setPreviousReading] = useState("");
    const [last_reading, setLastReading] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [sewer, setSewer] = useState("");
    const [sewer_type, setSewerType] = useState("")

    const [loading, setLoading] = useState(false);
    const [dmas, setDMAs] = useState([]);
    const [tarrifs, setTarrifs] = useState([]);

    const [invoice_id, setID] = useState("");
    const [opened, setOpened] = useState(false);
    const [amount, setAmount] = useState(0);

    // updating consumption
    const [consumption_id, setConsumptionId] = useState("");
    const [consumption_last_reading, setConsumptionLastReading] = useState("");
    const [consumption_current_reading, setConsumptionCurrentReading] = useState("");
    const [consumption_drawer, setConsumptionDrawer] = useState(false);
    const [consumption_comment, setConsumptionComment] = useState(""); 

    const [sewer_tarrifs, setSewerTarrifs] = useState([]);

    const [standing_charges, setStandingCharges] = useState(0);

    const [t, setT] = useState([]);

    const [dates, setDates] = useState(null);
    const [startsIn, setStartsIn] = useState(null);
    const [endsIn, setEndsIn] = useState(null);

    const [total_invoicing_amount, setTotalInvoicingAmount] = useState(1);

    const [send_invoice, setSendInvoice] = useState(false);

    const [open_update_payment, setOpenUpdatePymant] = useState(false);
    const [open_create_payment, setOpenCreatePayment] = useState(false);

    const [payment_id, setPaymentID] = useState("");
    const [p_amount, setPAmount] = useState(0);

    const [mode, setMode] = useState("");

    const [receipt, setReceipt] = useState({
        opened: false,
        amount: "0",
        name: "",
        date: "",
        mode: "Cash",
        id: ""
    });

    // payments details
    const [transaction_type, setTransactionType] = useState("Cash");
    const [transaction_id, setTransactionID] = useState("");
    const [generate_automatically, setGenerateAutomatically] = useState(true);
    const [transaction_amount, setTransactionAmount] = useState(0);
    const [business_short_code, setBusinessShortCode] = useState("");
    const [invoice_number, setInvoiceNumber] = useState("");
    const [MSISDN, setMSISDN] = useState(data?.phonenumber);
    const [first_name, setFirstName] = useState(data?.name.split(" ")[0]);
    const [last_name, setLastName] = useState(data?.name.split(" ")[1]);
    const [middle_name, setMiddleName] = useState("");
    const [payment_date, setPaymentDate] = useState(new Date());

    const [mode2, setMode2] = useState("batch-download");

    const [mode3, setMode3] = useState("batch-download");

    const [settlement_description, setSettlementDescription] = useState("");

    // consumption
    const [c_last_reading, setCLastReading] = useState("");
    const [open_c, setCOpened] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // disconnection
    const [disconnection_configs, setDisconnectionConfigs] = useState(null);

    const [short_codes, setShortCodes] = useState([]);

    const [sms_drawer, setSMSDrawer] = useState(false);

    const [message, setMessage] = useState("");

    const [loading2, setLoading2] = useState(false);

    const [sms_list, setSMSList] = useState([]);

    const [fa_step, setFAStep] = useState(0);

    const [otp, setOTP] = useState("");

    const [otp_loading, setOTPLoading] = useState(false);

    const [otp_active, setOTPActive] = useState(false);

    const [mfa_session, setMFASession] = useSessionStorage({
        defaultValue: "0",
        getInitialValueInEffect: true,
        key: "emita-2fa-session"
    });

    useEffect(() => {
        if(otp.length === 6){
            setOTPActive(true);
        } else {
            setOTPActive(false);
        }
    }, [otp])

    const verifyOTP = () => {
        setOTPLoading(true);
        // verify
        axios.post("/mfa/verify", {
            otp_token: otp
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                setOTPLoading(false);
                setFAStep(1);
                setMFASession("1");
                setOTP("");
            }
        }).catch(function(err){
            toast.error("The token entered has already expired.");
            setOTP("");
            setOTPLoading(false);
        })
    }


    function processPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input phone number
        const cleanNumber = phoneNumber.replace(/\D/g, '');
    
        // Check if the cleaned number is at least nine digits long
        if (cleanNumber.length < 9) {
            // Handle the case where the phone number is too short
            return ;
        }
        // Take the last nine digits of the cleaned number
        const lastNineDigits = cleanNumber.slice(-9);
    
        // Add the country code "+254" to the last nine digits
        const formattedNumber = "254" + lastNineDigits;
    
        return formattedNumber;
    }

    function sendSMS(){
        setLoading2(true);
        axios.post("/sms/mobitech-sms", {
            to: processPhoneNumber(data?.phonenumber || ""),
            sms: message,
            parent: state.userData?._id,
            saveable: true,
            account: data?.account
        }).then(function(res){
            if(res.status === 200){
                toast.success("Your message was delivered");
                setLoading2(false);
                setSMSDrawer(false);
                setRefresh(!refresh);
            }
        }).catch(function(error){
            toast.error("SMS services are not available right now.")
            setLoading2(false);
            setSMSDrawer(false)
        })  
    }
    const labels = consumption.map((data) => {
        var d = new Date(data.createdAt);
    
        var date = d.getDate();
        var month = d.getMonth() + 1; 
        var year = d.getFullYear();
        var newDate = date + "/" + month + "/" + year;
    
        return newDate;
    });

    const labels2 = invoices.map((data) => {
        var d = new Date(data.createdAt);
    
        var date = d.getDate();
        var month = d.getMonth() + 1; 
        var year = d.getFullYear();
        var newDate = date + "/" + month + "/" + year;
    
        return newDate;
    });

    const labels3 = payments.map((data) => {
        var d = new Date(data.createdAt);
    
        var date = d.getDate();
        var month = d.getMonth() + 1; 
        var year = d.getFullYear();
        var newDate = date + "/" + month + "/" + year;
    
        return newDate;
    });

    const consumptionUnits = consumption.map((data) => data.currReading - data.prevReading);

    const invoiceData = invoices.map((data) => data.invoices.total);

    const paymentData = payments.map((data) => data.transaction_amount);

    const calculateAverage = () => {
        let total = consumptionUnits.reduce((a, b) => a + b, 0);

        return Math.round(total / consumptionUnits.length)
    }

    const options = {
        borderWidth: 2,
        pointRadius: 1,
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: calculateAverage(),
                yMax: calculateAverage(),
                borderColor: theme.colors.red[9],
                borderWidth: 2,
              }
            }
          },
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Consumption',
          },
        },
        scales: {
          x: {
          title: {
            display: true,
            text: 'Date',
          },
          ticks: {
            source: 'auto', // Use 'auto' to automatically determine the time scale
          },
          grid: {
            display: false
          }
          },
          y: {
            title: {
                display: true,
                text: 'Consumption',
            },
            grid: {
                display: false
              }
          },
        },
      };

      const options2 = {
        borderWidth: 2,
        pointRadius: 1,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Invoice',
          },
        },
        scales: {
          x: {
          title: {
            display: true,
            text: 'Date',
          },
          ticks: {
            source: 'auto', // Use 'auto' to automatically determine the time scale
          },
          grid: {
            display: false
          }
          },
          y: {
            title: {
                display: true,
                text: 'Invoice',
            },
            grid: {
                display: false
              }
          },
        },
      };

      const options3 = {
        borderWidth: 2,
        pointRadius: 1,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Payment',
          },
        },
        scales: {
          x: {
          title: {
            display: true,
            text: 'Date',
          },
          ticks: {
            source: 'auto', // Use 'auto' to automatically determine the time scale
          },
          grid: {
            display: false
          }
          },
          y: {
            title: {
                display: true,
                text: 'Amount(KSH)',
            },
            grid: {
                display: false
              }
          },
        },
      };
      
      useEffect(() => {
        axios.get("/disconnection-rates/getRate", {headers: {'Authorization': `Bearer ${state?.userToken}`}}).then(function(res){
            if(res.status === 200){
                if(res.data.data){
                    setDisconnectionConfigs(res.data.data);
                }
            }
        }).catch(function(error){
            toast.error("Could not fetch your disconnection configurations")
        });
    }, []);

    const fetchShortCodes = () => {
        const body = {
          parent: state.userData._id
        }; 
  
        axios.post("/short-codes/get-short-codes", body).then(function (res) {
            if (res.status === 200) {
              let arr = res.data.data;
                setShortCodes(arr);
            }
          }).catch(function (error) {
            console.debug("An error occured while fetching paybill numbers")
          })
      }

      useEffect(() => {
        fetchShortCodes();
      }, [])

    const [chart1, setChart1] = useLocalStorage({
        key: "emita-chart-type",
        defaultValue: "line",
        getInitialValueInEffect: true
      });

    const submitCheckedData = async () => {
        setSubmitting(true);

        axios.post("/readings/create", {
          meter: account,
          parent: state.userData._id,
          reading: parseFloat(c_last_reading),
          photo: "",
          coordinate: [],
          comments: "Consumption submitted by Admin"
        }).then(function(res){
          if(res.status === 200){
            axios.post("/customers/getCustomer", {
              account: account
          }).then(function(res){
                  axios.post("/customers/update", {
                      id: res.data.data._id,
                      update: {last_reading: parseFloat(c_last_reading),previous_reading: parseFloat(last_reading)}
                  }).then(function(res){
                      if(res.status === 200){
                          axios.post("/consumption/create", {
                              prevReading: parseFloat(last_reading),
                              currReading: parseFloat(c_last_reading),
                              meter: account,
                              photo: "",
                              parent: state.userData._id,
                              year: new Date().getFullYear(),
                              month: new Date().getMonth() + 1,
                              day: new Date().getDate()
                          }).then(function(res){
                              if(res.status === 200){
                                setRefresh(!refresh);
                                setCOpened(false);
                                setSubmitting(false);
                                toast.success("Consumption unit added successfully", {
                                    position: "bottom-right"
                                });
                              }
                          }).catch(function(error){
                              setSubmitting(false);
                          })
                      }
                  }).catch(function(error){
                      setSubmitting(false);
                  })
          }).catch(function(error){
            setSubmitting(false);
        })
          }
        }).catch(function(error){
          setSubmitting(false);
      })
    
      }

    useEffect(() => {
        if(generate_automatically){
            setTransactionID(nanoid(10).toUpperCase())
        } else {
            setTransactionID("")
        }
    }, [generate_automatically])

    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const matchPaybill = (dma) => {
        let idx = short_codes.findIndex((obj) => obj.dma === dma);
  
        if(idx !== -1){
          let item = short_codes[idx];
          return item.short_code
        } else {
          if(short_codes.length > 0){
            let idx = short_codes.findIndex((obj) => obj?.dma === "*"); // all paybilss

            if(idx !== -1){
              let item = short_codes[idx];
              return item.short_code
            } else {
                return short_codes[0]; // the first short code
            }
          } else {
            return "N/A"
          }
        }
      }

    const openUpdateDrawer= (id) => {
        setPaymentID(id);

        let idx = payments.findIndex((obj) => obj._id == id);

        if(idx !== -1){
            setPAmount(payments[idx].transaction_amount);
            setMode3(payments[idx].mode);
            setOpenUpdatePymant(true);
        }
    }

    const createPayment = () => {
        setLoading(true);

        const date = new Date();

        axios.post('/payments/create', {
            transaction_type: transaction_type === "Settlement" ? transaction_type +"-"+ settlement_description : transaction_type,
            transaction_id: "EM"+transaction_id,
            transaction_time: date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2) + ("0" + date.getHours() ).slice(-2) + ("0" + date.getMinutes()).slice(-2) + ("0" + date.getSeconds()).slice(-2),
            transaction_amount: transaction_amount,
            business_short_code: business_short_code,
            account: account,
            invoice_number: generateInvoiceNumber(date.getTime()),
            organization_account_balance: "",
            third_party_transaction_id: "",
            MSISDN: MSISDN,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            parent: state.userData?._id,
            meter_number: username,
            dma: dma,
            mode: mode2,
            createdAt: payment_date,
            updatedAt: payment_date
        }).then(function(res){
            if(res.status === 200){
                toast.success("Payment created successfully.")
                setOpenCreatePayment(false);
                setRefresh(!refresh)
                setLoading(false);
            }
        }).catch(function(error){
            console.log(error);
            toast.error("Something wrong happened.");
            setLoading(false);
        })
    }

    const savePaymentUpdates = () => {
        setLoading(true);

        axios.post("/payments/update", {
            id: payment_id,
            update: {
                transaction_amount: p_amount,
                mode: mode3
            }
        }).then(function(res){
            if(res.status === 200){
                toast.success("Payment updated successfully!", {
                    position: "bottom-right",
                    duration: 5000
                });
                
                setRefresh(!refresh);
                setLoading(false);
                setOpenUpdatePymant(false);
            }
        }).catch(function(error){
            toast.error("Something wrong happened!", {
                position: "bottom-right",
                duration: 5000
            });
            setLoading(false);
        })
    }

    useEffect(() => {
        const body = {
          parent: state.userData._id
        }; 
      
        axios.post("/dates/get-dates", body)
          .then(function (res) {
            if (res.status === 200) {
              let dates = res.data.data;
              if(dates === null){
                dates = DEFAULT_METER_READING_CYCLE;
              }
              setDates(dates);
              let today = new Date();
              let startsOn = dates?.starts;
              let duration = dates?.duration;
              let review_window = dates?.reviewWindow;
      
              let currentMonth = today.getMonth() + 1;
              let currentYear = today.getFullYear();
      
              // Calculate the start and end dates of the current meter reading cycle
              let cycleStart;
              let cycleEnd;
              if (startsOn > today.getDate()) {
                // Cycle spans across two months
                cycleStart = new Date(currentYear, currentMonth - 2, startsOn);
                cycleEnd = new Date(currentYear, currentMonth - 2, startsOn + duration + review_window);
              } else {
                // Cycle starts and ends within the same month
                cycleStart = new Date(currentYear, currentMonth - 1, startsOn);
                cycleEnd = new Date(currentYear, currentMonth - 1, startsOn + duration + review_window);
              }
    

              setStartsIn(cycleStart);
              setEndsIn(cycleEnd);
      
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }, []);

    useEffect(() => {
        const body = {
            parent: state.userData._id
        }; 

        axios.post("/standing-charges/get-standing-charge", body).then(function (res) {
            if (res.status === 200) {
              let arr = res.data.data;
              let total_cost = 0;
              for (let i = 0; i < arr.length; i++) {
                total_cost += arr[i].cost
              }
              setStandingCharges(parseFloat(total_cost.toFixed(2)));
            }
          }).catch(function (error) {
            console.log(error);
          })
    }, []);

    useEffect(() => {
        const body = {
            parent: state.userData._id
        }; 

        axios.post("/sewer-tarrifs/gettarrifs", body).then(({ data: { data: x }, }) => {
            setSewerTarrifs(x);
          }).catch(function (error) {
            toast.error(error.message, {
              position: "bottom-right"
            })
          })
    }, []);

    
    useEffect(() => {
        const body = {
            parent: state.userData._id
          };         
        axios.post("/tarrifs/gettarrifs", body).then(({ data: { data: x }, }) => {
            setT(x);
        }).catch(function(error){
            console.log(error);
        })
    }, [])

    useEffect(() => {
        let _invoices = 0;
        let _payments = 0;

        for(let i=0; i<invoices.length; i++){
            _invoices += parseFloat(invoices[i].invoices.total);
        }

        for(let k=0; k<payments.length; k++){
            _payments += parseFloat(payments[k].transaction_amount);
        }

        let b = (_invoices - _payments);

        setBalance(""+b);

    }, [invoices, payments])

    useEffect(() => {
        if(typeof(id) !== undefined && id !== ""){
            axios.post("/customers/getCustomer", {
                username: id
            }).then(function(res){
                if(res.status === 200){
                    if(res.data.data === null){
                        navigate("/app/customers/customers-list")
                    } else {
                        setData(res.data.data);

                        let item = res.data.data;
                        setName(item.name);
                        setDisconnected(!item?.disconnected ? "Connected" : "Disconnected");
                        setAccount(item?.account);
                        setEmail(item?.email);
                        setPhone(item?.phonenumber);
                        setBalance(""+item?.previous_balance);
                        setAddress(item?.address);
                        setZip(item?.zip);
                        setCounty(item?.county);
                        setCountry(item?.country);
                        setUsername(item?.username);
                        setMeter(item?.meter_type);
                        setDMA(item?.dma);
                        setTarrif(item?.tarrif);
                        setPreviousReading(""+item?.previous_reading);
                        setLastReading(""+item?.last_reading);
                        setLatitude(""+item?.coordinates[0]);
                        setLongitude(""+item?.coordinates[1]);
                        setSewer(item?.sewer ? "Connected" : "Not connected");
                        setSewerType(item?.sewer_type);
                    }
                }
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                })
            })

            axios.post("/dmas/getdmas", {username: state.userData._id}).then(function(res){
                if(res.status === 200){
                    let arr = []
                    for(let i=0; i<res.data.data.length; i++){
                        let item = res.data.data[i];
                        
                        arr.push(item.name)
                    }

                    setDMAs(arr);
                    
                }
            }).catch(function(error){
                toast.error(error.message);
            })

            axios.post("/tarrifs/gettarrifs", {parent: state.userData._id}).then(({data: {data: t}, }) => {
                let arr = [];
                for(let i=0; i<t.length; i++){
                    let item = t[i];
                    let tt = item.tarrif;
                    arr.push(tt);
                }
                setTarrifs(arr);
            }).catch(function(error){
                toast.error(error.message);
            })
        }

    }, [id, refresh]);

    useEffect(() => {
        if(data){
            axios.post("/consumption/customer", {
                meter: data?.account
            }).then(function(res){
                if(res.status === 200){
                    setConsumption(res.data.data.reverse());
                }
            }).catch(function(error){
                console.log(error);
            })

            axios.get("/sms/customer-sms", {
                params: {
                    account: data?.account
                },
                headers: {
                    Authorization: `Bearer ${state.userToken}`
                }
            }).then(function(res){
                if(res.status === 200){
                    if(Array.isArray(res.data.data)){
                        setSMSList(res.data.data.reverse())
                    }
                }
            }).catch(function(error){
                console.log(error);
            })
        }
    }, [data, refresh]);

    const updateConsumption = (id, l, c, comment) => {
        setConsumptionId(id);
        setConsumptionLastReading(l);
        setConsumptionCurrentReading(c);
        setConsumptionComment(comment)
        setConsumptionDrawer(true);
    }

    const saveConsumptionEdits = () => {
        let toastID = toast.loading("Updating history...");

        axios.post("/consumption/update", {
            id: consumption_id,
            update: {
                prevReading: parseFloat(consumption_last_reading),
                currReading: parseFloat(consumption_current_reading),
                comment: consumption_comment.trim()
            }
        }).then(function(res){
            if(res.status === 200){
                toast.success("Consumption history updated successfully", {
                    position: "bottom-right",
                    id: toastID
                });
                setConsumptionId("");
                setConsumptionDrawer(false);
                setRefresh(!refresh)
            }
        }).catch(function(error){
            toast.error(error.message, {
                position: "bottom-right",
                id: toastID
            })
        })
    }

    const deleteConsumptionHistory = (history_id) => {
        swal({
            title: "Warning",
            text: "Are you sure you want to delete this consumption history? Deleting might affect other data such as the current and previous readings of the customer on the profile",
            buttons: {
                cancel: "Cancel",
                continue: {
                    text: "Delete",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                deleteConsumptionHistoryAction(history_id);
            }
        })
    }

    const deleteConsumptionHistoryAction = (history_id) => {
        let toastID = toast.loading("Deleting history...", {
            position: "bottom-right"
        });

        axios.post("/consumption/delete", {
            id: history_id
        }).then(function(res){
            if(res.status === 200){
                toast.success("History successfully deleted!", {
                    id: toastID
                });
                setRefresh(!refresh)
            }
        }).catch(function(error){
            toast.error(error.message, {
                id: toastID
            })
        })
    }

    useEffect(() => {
        if(data){
            axios.post("/master-invoices/customer-invoices", {
                account: data?.account
            }).then(function(res){
                if(res.status === 200){
                    setInvoices(res.data.data.reverse())
                }
            }).catch(function(error){
                console.log(error);
            });
        }
    }, [data])

    useEffect(() => {
        if(data){
            axios.post("/payments/customer-payments", {
                account: data?.account
            }).then(function(res){
                if(res.status === 200){
                    setPayments(res.data.data.reverse())
                }
            }).catch(function(err){
                console.log(err)
            })

            setMSISDN(data?.phonenumber);
            setFirstName(data?.name.split(" ")[0]);
            setLastName(data?.name.split(" ")[1]);
        }
    }, [data])


const editCustomer1 = () => {
    if(loading){
        return false;
    }

    setLoading(true);

    axios.post("/customers/update", {
        id: data._id,
        update: {
            name: name,
            disconnected: disconnected === "Disconnected" ? true : false
        }
    }).then(function(res){
        if(res.status === 200){
            setRefresh(!refresh);
            setDrawer1(false);
            setLoading(false);
        }
    }).catch(function(error){
        toast.error("Details could not be saved due to internal issues.", {
            position: "bottom-right",
            duration: 5000
        });
        setDrawer1(false);
        setLoading(false);
    })
}

const editCustomer2 = () => {
    if(loading){
        return false;
    }

    setLoading(true);

    axios.post("/customers/update", {
        id: data._id,
        update: {
            account: account,
            email: email,
            phonenumber: phonenumber
        }
    }).then(function(res){
        if(res.status === 200){
            setRefresh(!refresh);
            setDrawer2(false);
            setLoading(false);
        }
    }).catch(function(error){
        toast.error("Details could not be saved due to internal issues.", {
            position: "bottom-right",
            duration: 5000
        });
        setDrawer2(false);
        setLoading(false);
    })
}

const editCustomer3 = () => {
    if(loading){
        return false;
    }

    setLoading(true);

    axios.post("/customers/update", {
        id: data._id,
        update: {
            address: address,
            zip: zip,
            county: county,
            country: country
        }
    }).then(function(res){
        if(res.status === 200){
            setRefresh(!refresh);
            setDrawer3(false);
            setLoading(false);
        }
    }).catch(function(error){
        toast.error("Details could not be saved due to internal issues.", {
            position: "bottom-right",
            duration: 5000
        });
        setDrawer3(false);
        setLoading(false);
    })
}

const editCustomer4 = () => {
    if(loading){
        return false;
    }

    if(tarrifs.indexOf(tarrif) === -1){
        toast.error("The tarrif provided does not exist. Please configure it before proceeding to edit the customer.", {
            position: "bottom-right",
            duration: 5000
        });
        return false;
    }

    if(dmas.indexOf(dma) === -1){
        toast.error("The DMA provided does not exist. Please configure it before proceeding to edit the customer.", {
            position: "bottom-right",
            duration: 5000
        });
        return false;
    }

    setLoading(true);

    axios.post("/customers/update", {
        id: data._id,
        update: {
            username: username,
            meter_type: meter_type,
            dma: dma,
            tarrif: tarrif,
            previous_reading: parseFloat(previous_reading),
            last_reading: parseFloat(last_reading),
            coordinates: [parseFloat(latitude), parseFloat(longitude)]
        }
    }).then(function(res){
        if(res.status === 200){
            setRefresh(!refresh);
            setDrawer4(false);
            setLoading(false);
        }
    }).catch(function(error){
        toast.error("Details could not be saved due to internal issues.", {
            position: "bottom-right",
            duration: 5000
        });
        setDrawer4(false);
        setLoading(false);
    })
}

const editCustomer5 = () => {
    if(loading){
        return false;
    }

    setLoading(true);

    axios.post("/customers/update", {
        id: data._id,
        update: {
            sewer: sewer === "Connected" ? true : false,
            sewer_type: sewer_type
        }
    }).then(function(res){
        if(res.status === 200){
            setRefresh(!refresh);
            setDrawer5(false);
            setLoading(false);
        }
    }).catch(function(error){
        toast.error("Details could not be saved due to internal issues.", {
            position: "bottom-right",
            duration: 5000
        });
        setDrawer5(false);
        setLoading(false);
    })
}

const editInvoice = (id, amount) => {
    setID(id);
    setAmount(amount);
    setOpened(true);
}

const saveEdits = () => {
    setOpened(true);

    let idx = invoices.findIndex((obj) => obj._id === invoice_id);

    if(idx !== -1){
        axios.post("/master-invoices/update", {
            id: invoice_id,
            update: {
                invoices: {...invoices[idx].invoices, total: amount}
            }
        }).then(function(res){
            axios.post("/bills/update", {
                period: getDate(),
                account: invoices[idx].account,
                update: {
                    total_cost: parseFloat(amount)
                }
            }).then(function(res){
                if(res.status === 200){
                    setID("");
                    setOpened(false);
                    setRefresh(!refresh);
                }
            }).catch(function(error){
                console.log(error);
            })
        }).catch(function(error){
            toast.error("Internal issues occured.", {
                position: "bottom-right",
                duration: 5000
            })
        })
    }
}

const createCost = (qty, tt) => {
    if(t.length === 0){
        return 0
    }

    if(qty <= 0){
        return 0;
    }

    let idx = t.findIndex((obj => obj.tarrif == tt));
    if(idx === -1){
        return 0
    }
    let item = t[idx];
    let blocks = item.blocks;

    return createWaterCost(parseFloat(qty), blocks, standing_charges);
}

const createSwrCost = (qty, t) => {
    if(sewer_tarrifs.length === 0){
        return 0;
    }

    if(qty <= 0){
        return 0;
    }

    let idx = sewer_tarrifs.findIndex((obj => obj.tarrif == t));
    if(idx === -1){
        return 0;
    }

    let item = sewer_tarrifs[idx];
    let blocks = item.blocks;

    return createSewerCost((0.7 * parseFloat(qty)), blocks);
}

const createWaterBlocks = (qty, tt) => {
    if(t.length === 0){
        return [["0", "0.00", "0.00"]]
    }

    let idx = t.findIndex((obj => obj.tarrif == tt));
    if(idx === -1){
        return [["0", "0.00", "0.00"]]
    }

    let item = t[idx];
    let blocks = item.blocks;

    return createWaterConsumptionBlocks(parseFloat(qty), blocks);
}

const createSewerBlocks = (qty, t) => {
    if(sewer_tarrifs.length === 0){
        return [["0", "0.00", "0.00"]]
    }

    let idx = sewer_tarrifs.findIndex((obj => obj.tarrif == t));
    if(idx === -1){
        return [["0", "0.00", "0.00"]]
    }

    let item = sewer_tarrifs[idx];
    let blocks = item.blocks;

    return createSewerConsumptionBlocks(((item.percentage / 100) * parseFloat(qty)), blocks);
}

// create an invoice and save it.
const getPdfAsArrayBuffer = async () => {
    const cost = tarrifs.length > 0 ? createCost((data.last_reading - data.previous_reading), data.tarrif) : 0;

    const sewer_cost = data.sewer && sewer_tarrifs.length > 0 ? createSwrCost((data.last_reading - data.previous_reading), data.tarrif) : 0;

    const table2 = t.length > 0 ? createWaterBlocks((data.last_reading - data.previous_reading), data.tarrif) : [["0.00", "0.00", "0.00"]];

    const table3 = data.sewer && sewer_tarrifs.length > 0 ? createSewerBlocks((data.last_reading - data.previous_reading), data.tarrif) : [];

    const total_amount_due = cost + sewer_cost + standing_charges + (data?.previous_balance || 0);

    const index = new Date().getTime();
    
    let invoice_generated = generateInvoice({
        water_service_name: state.userData.waterServiceName,
        company_phone: state.userData?.phonenumber,
        company_email: state.userData?.username,
        company_address: state.userData?.address || "N/A",
        customer_name: data?.name,
        customer_address: data?.address.length > 0 ? data?.address.split(" ")[0] : "N/A",
        bill_month: getDate(),
        customer_phone: data.phonenumber,
        invoice_number: generateInvoiceNumber(index),
        account_number: data?.account || "",
        meter_number: data.username,
        due_date: new Date(new Date().getTime()+(14 * 24 * 60 * 60 * 1000)).toLocaleDateString(),
        previous_bill_date: getPeriodEnding(),
        defaulted_balance: data.previous_balance === null ? "0.00" : data?.previous_balance.toFixed(2),
        tables_data: [[data.previous_reading !== null ? data.previous_reading.toFixed(2) : "0", data.last_reading !== null ? data?.last_reading.toFixed(2) : "0", ((data?.last_reading || 0) - (data?.previous_reading || 0)).toFixed(2)]],
        water_blocks: table2,
        total_water_charges: cost.toFixed(2),
        has_standing_charges: standing_charges > 0,
        standing_charges: standing_charges,
        sub_total_amount: total_amount_due.toFixed(2),
        total: total_invoicing_amount.toFixed(2),
        vat_percentage: "0",
        vat_amount: "0.00",
        total_charges: total_invoicing_amount.toFixed(2),
        reading_date: startsIn.getDate().toString(),
        last_reading_month: getLastReadingMonth(),
        current_reading_month: getCurrentReadingMonth(),
        current_year: startsIn.getFullYear().toString(),
        has_sewer_charges: sewer_tarrifs.length > 0 && data.sewer,
        sewer_blocks: table3,
        total_sewer_charges: data.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
        vat: "0",
        output_type: "arraybuffer",
        filename: data.name + "-" + generateInvoiceNumber(index),
        return_jspdfdoc_obj: true,
        account_status: data.disconnected ? "Disconnected" : "Connected"
    });

    let invoiceData = {
        pdf: {
        water_service_name: state.userData.waterServiceName,
        company_phone: state.userData?.phonenumber,
        company_email: state.userData?.username,
        company_address: state.userData?.address || "N/A",
        customer_name: data?.name,
        customer_address: data?.address.length > 0 ? data?.address.split(" ")[0] : "N/A",
        bill_month: getDate(),
        customer_phone: data.phonenumber,
        invoice_number: generateInvoiceNumber(index),
        account_number: data?.account,
        meter_number: data.username,
        due_date: new Date(new Date().getTime()+(14 * 24 * 60 * 60 * 1000)).toLocaleDateString(),
        previous_bill_date: getPeriodEnding(),
        defaulted_balance: data.previous_balance === null ? "0.00" : data?.previous_balance.toFixed(2),
        tables_data: [[data.previous_reading !== null ? data.previous_reading.toFixed(2) : "0", data.last_reading !== null ? data?.last_reading.toFixed(2) : "0", ((data?.last_reading || 0) - (data?.previous_reading || 0)).toFixed(2)]],
        water_blocks: table2,
        total_water_charges: cost.toFixed(2),
        has_standing_charges: standing_charges > 0,
        standing_charges: standing_charges,
        sub_total_amount: total_amount_due.toFixed(2),
        total: total_invoicing_amount.toFixed(2),
        vat_percentage: "0",
        vat_amount: "0.00",
        total_charges: total_invoicing_amount.toFixed(2),
        reading_date: startsIn.getDate().toString(),
        last_reading_month: getLastReadingMonth(),
        current_reading_month: getCurrentReadingMonth(),
        current_year: startsIn.getFullYear().toString(),
        has_sewer_charges: sewer_tarrifs.length > 0 && data.sewer,
        sewer_blocks: table3,
        total_sewer_charges: data.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
        vat: "0",
        output_type: "arraybuffer",
        filename: data.name + "-" + generateInvoiceNumber(index),
        return_jspdfdoc_obj: true,
        account_status: data.disconnected ? "Disconnected" : "Connected"
        },
        customer: data
    }

    return {pdf: invoice_generated.arrayBuffer, invoice: invoiceData};
}

const createAndSendInvoice = async() =>{
    let item = (await getPdfAsArrayBuffer()).invoice;

    setLoading(true);

    await axios.post("/master-invoices/create", {
        isBulk: false,
        invoice: {
            parent: state.userData._id,
            period: getDate(),
            invoices: item.pdf,
            account: data?.account,
            mode: mode
        }
    }, {
        headers: {
            'Authorization': `Bearer ${state.userToken}`
        }
    }).then(async function(res){
        if(res.status === 200){
            await axios.post("/bills/create", {
                isBulk: false,
                bill: {
                    name: item.customer.name,
                    account: item.customer.account,
                    meter: item.customer.username,
                    dma: item.customer.dma,
                    status: item.pdf.account_status,
                    last_reading: item.customer.previous_reading,
                    current_reading: item.customer.last_reading,
                    units: (item.customer.last_reading - item.customer.previous_reading),
                    previous_balance: item.customer.previous_balance,
                    water_cost: parseFloat(item.pdf.total_water_charges),
                    has_sewer: item.pdf.has_sewer_charges,
                    sewer_cost: parseFloat(item.pdf.total_Sewer_charges),
                    total_cost: parseFloat(item.pdf.total),
                    period: getDate(),
                    bill_number: item.pdf.invoice_number,
                    parent: state.userData._id
                }
            }, {
                headers: {
                    'Authorization': `Bearer ${state.userToken}`
                }
            }).then(function(res){
                if(res.status === 200){
                    axios.post("/sms/mobitech-sms", {
                        to: processPhoneNumber(data?.phonenumber || ""),
                        sms: `Dear ${data?.name},an invoice of KSH ${total_invoicing_amount} has been added to your account${mode !== "others" ? " for" : " for other charges"} ${ mode !== "others" ? findKeyByValue(invoice_map, mode) : ""}. For inquiries, contact us. Thank you.`,
                        parent: state.userData?._id,
                        saveable: true,
                        account: data?.account
                    }).then(function(res){
                        if(res.status === 200){
                            toast.success("Invoice created successfully", {
                                position: "bottom-right",
                                duration: 5000
                            });
                            setLoading(false);
                            setSendInvoice(false);
                            setRefresh(!refresh);
                        }
                    }).catch(function(error){
                        toast.error("SMS services are not available right now.")
                        setLoading(false);
                    }) 
                }
            }).catch(function(err){
                toast.error("An error occured while sending the invoice.", {
                    position: "bottom-right",
                    duration: 5000
                });
                setLoading(false);
            })
        }
    }).catch(function(err){
        toast.error("An error occured!", {
            position: "bottom-right",
            duration: 5000
        });
    })
}

const formatPhoneNumber =(phoneNumber) => {
    // Remove any non-numeric characters
    const numbers = phoneNumber.replace(/\D/g, "");
  
    // Check if the number is long enough
    if (numbers.length < 9) {
      toast.error("The phonenumber of the customer is invalid.");
    }
  
    // Take the last 9 digits
    const last9Digits = numbers.slice(-9);
  
    // Prepend the international format for Kenya
    return `254${last9Digits}`;
  }
  

const sendWhatsApp = (pdf) => {
    let text = `Dear ${pdf?.customer_name}, A/C ${pdf?.account_number}. This is your water bill for the current month of ${pdf?.bill_month}. Previous Reading: ${pdf?.tables_data[0][0]}, Current Reading: ${pdf?.tables_data[0][1]}, Units Consumed: ${pdf?.tables_data[0][2]}. Previous arrears were ${pdf?.defaulted_balance}. Total water charges are ${pdf?.total}. Total amount due is ${parseInt(pdf?.defaulted_balance) + parseInt(pdf?.total)}. Pay by ${pdf?.due_date} to avoid Kes ${disconnection_configs?.fee} disconnection fee. Paybill ${matchPaybill(dma)} Account ${pdf?.account_number}. Helpline ${state.userData?.phonenumber}/${state.userData?.alternative_phonenumber}.`;
    
    const encodedText = encodeURIComponent(text);

    window.open(`https://web.whatsapp.com/send?phone=${formatPhoneNumber(phonenumber)}&text=${encodedText}`);

}
const askDelete = (id, t=2, bill_number) => {
    swal(`Are you sure you want to delete ${t === 2 ? "the payment" : "the invoice"}? This action cannot be reverted`, {
        buttons: {
            cancel: 'Cancel',
            continue: {
                text: "Delete",
                value: 'continue'
            }
        }
    }).then(async (value) => {
        if(value === "continue"){
            t === 2 ? deletePayment(id) : deleteInvoice(id, bill_number)
        }
    })
}

const deletePayment = (id) => {
    axios.post("/payments/delete", {
        id: id
    }).then(function(res){
        if(res.status === 200){
            toast.success("Payment deleted successfully!", {
                position: "bottom-right",
                duration: 5000
            });
            setRefresh(!refresh);
        }
    }).catch(function(error){
        toast.error("Something wrong happened!", {
            position: "bottom-right",
            duration: 5000
        });
    })
}

const deleteInvoice = (id, bill_number) => {
    axios.post("/master-invoices/delete-invoice", {
        id: id
    }).then(function(res){
        if(res.status === 200){
            axios.post("/bills/delete-bill", {
                bill_number: bill_number
            }).then(function(res){
                if(res.status === 200){
                    toast.success("Invoice deleted successfully.", {
                        position: "bottom-right",
                        duration: 5000
                    })

                    setRefresh(!refresh);
                }
            }).catch(function(error){
                toast.error("Something wrong occured", {
                    position: "bottom-right",
                    duration: 5000
                })
            })
        }
    }).catch(function(error){
        toast.error("Something wrong occured", {
            position: "bottom-right",
            duration: 5000
        })
    })
}

const saveInvoice = async (pdf) => {
    pdf.output_type = "save"
    generateInvoice(pdf);
}

const { height, width } = useViewportSize();

const consumptionData = {
    labels: labels,
    datasets: [
      {
        label: 'Consumption',
        data: consumptionUnits,
        borderColor: theme.colors.green[9],
        backgroundColor: theme.colors.green[9],
      },
    ],
  };

  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: 'Consumption',
        data: invoiceData,
        borderColor: theme.colors.green[9],
        backgroundColor: theme.colors.green[9],
      },
    ],
  };

  const data3 = {
    labels: labels3,
    datasets: [
      {
        label: 'Payments',
        data: paymentData,
        borderColor: theme.colors.green[9],
        backgroundColor: theme.colors.green[9],
      },
    ],
  };

  const downloadStatements = () => {
    const obj = new Object();

    obj.water_service_name = state.userData?.waterServiceName;

    obj["company_phone"] = state.userData?.phonenumber;

    obj["alternative_company_phone"] = state.userData?.alternative_phonenumber;

    obj["company_address"] = state.userData?.address || "N/A";

    obj["company_email"] = state.userData?.username;

    obj["customer_name"] = data?.name;

    obj["customer_phone"] = obfuscatePhoneNumber(data?.phonenumber);

    obj["customer_address"] = data?.address;

    obj["account_number"] = data?.account;

    obj["meter_number"] = data?.username;

    obj["account_status"] = data?.disconnected ? "Disconnected" : "Connected";

    obj["balance"] = `KSH.${previous_balance}`;

    obj["consumption_history_length"] = ""+consumption.length;

    var arr1 = [];

    //var headers = ["ID", "Date", "Previous", "Present", "Consumption"];

    for(let i=0; i<consumption.length; i++){
        let item = consumption[i];

        var _arr = [`${i+1}`, `${new Date(item.createdAt).toLocaleDateString()}`, `${item.prevReading}`, `${item.currReading}`, `${item.currReading - item.prevReading}`, `${item?.technician ? obfuscateEmail(item?.technician) : "N/A"}`, `${item?.comment || "N/A"}`];

        arr1.push(_arr);

    }

    obj["consumption_history"] = arr1;

    obj["invoice_history_length"] = ""+invoices.length;

    // var headers = ["ID", "Date", "Invoice#", "Bill(Ksh)", "Arrears(Ksh)", "Total Bill(Ksh)", "Category", "Due Date"];

    var arr2 = [];

    for(let i=0; i<invoices.length; i++){
        let item = invoices[i];

        var _arr = [`${i+1}`, `${new Date(item.createdAt).toLocaleDateString()}`, `${item.invoices.invoice_number}`, `${item.invoices.total}`, `${item.invoices?.defaulted_balance || 0}`, `${(parseFloat(item.invoices.total) + parseFloat(item.invoices?.defaulted_balance || 0)).toFixed(0)}`, `${item.mode === "disconnection" ? "Suspension/Reconnection" : item?.mode === "invoice defaulting" ? "Late Payment" : item?.mode === "repairs" ? "Repairs" : item?.mode === "illegal connection" ? "Illegal Connection" : item?.mode === "registration" ? "Registration" : item?.mode === "meter deposit" ? "Meter Deposit" : item?.mode === "sms" || item?.mode === "batch-download" || item?.mode === "email" ? "Monthly Bill" : "Others"}`, `${item.invoices.due_date}`];

        arr2.push(_arr);
    }

    obj["invoicing_history"] = arr2;

    obj["payments_history_length"] = ""+payments.length;

    var arr3 = [];

    // var headers = ["ID", "Date", "Transaction ID", "Amount(Ksh)", "Short Code", "MSISDN", "First Name"];

    for(let i=0; i<payments.length; i++){
        let item = payments[i];

        var _arr = [`${i+1}`, `${new Date(item.createdAt).toLocaleDateString()}`, `${item.transaction_id}`, `${item.transaction_amount}`, `${item.business_short_code === "" ? item.transaction_type : item.business_short_code}`, `${item.MSISDN === "" ? phonenumber : item.MSISDN}`, `${item.first_name === "" ? name.split(" ")[0].toUpperCase() : item.first_name.toUpperCase()}`];

        arr3.push(_arr);
    }
    obj["payments_history"] = arr3;

    obj["startDate"] = "January 2023";

    obj["endDate"] = "May 2024";

    obj["filename"] = `${data?.name} Emita Account Statement-${new Date().toLocaleDateString()}`;

    obj["orientationLandscape"] = true;

    obj["pageEnable"] = true;

    obj["output_type"] = "save";

    obj["footer"] = {
        text: "Prepared by Emita - https://emitasuite.com"
    }

    obj["stamp"] = {
        src: "https://app.emitasuite.com/emita-email-logo.png"
    }

    obj["pageLabel"] = ""

    generateStatement(obj);
}

    return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{state.userData.waterServiceName + " | " + data?.name}</title>
    </Helmet>

    <Drawer size={"xl"} opened={sms_drawer} onClose={() => {setSMSDrawer(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Send SMS</Heading>
            <Alert icon={<InfoCircle />} title="SMS Charges" color="orange">
                Your wallet needs to be charged before sending an SMS. Long SMS(greater than 160 characters) may be charged as two units of SMS.
            </Alert>
            {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <Textarea mt="md" label="Message" value={message} onChange={(e) => {setMessage(e.currentTarget.value)}} />
                )
            ) : (
                <Textarea mt="md" label="Message" value={message} onChange={(e) => {setMessage(e.currentTarget.value)}} />
            )}
           
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} variant="default" onClick={() => {setSMSDrawer(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} onClick={() => {sendSMS()}} loading={loading2}>Send SMS</Button>
        </Group>
        </Stack>
    </Drawer>

    <Drawer opened={open_create_payment} onClose={() => {setOpenCreatePayment(false)}} position="right" title="" size="xl" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
       

            {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <>
                     <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Add Payment</Heading>
                                <Text mb={30}>Create payment for this customer. Before proceeding to save the information, please verify that the information that you are entering is okay and valid.</Text>
            <SimpleGrid cols={2}>
            <Select label="Transaction type" value={transaction_type} onChange={(val) => {setTransactionType(val)}} data={[{label: "Pay Bill", value:"Pay Bill"}, {label: "Buy Goods", value: 'Buy Goods'}, {label: "Cash", value: "Cash"}, {label: "Bank", value: "Cheque"}, {label: "Settlement", value: "Settlement"}]} mb={"md"} />
            {transaction_type === "Pay Bill" || transaction_type === "Buy Goods" ? (
                <TextInput value={business_short_code} onChange={(e) => {setBusinessShortCode(e.currentTarget.value)}} label="Business Short Code" />
            ) : transaction_type === "Settlement" ? (
                <TextInput label="Description" value={settlement_description} onChange={(e) => {setSettlementDescription(e.currentTarget.value)}} placeholder="Settlement description" mb="md" />
            ) : null}
            <NumberInput hideControls label="Amount Paid" value={transaction_amount} onChange={(val) => {setTransactionAmount(val)}} mb="mb" />
            <Checkbox label="Generate transaction ID automatically" checked={generate_automatically} onChange={(e) => {setGenerateAutomatically(e.currentTarget.checked)}} />
            <TextInput label="Transaction ID" value={transaction_id} onChange={(e) => {setTransactionID(e.currentTarget.value)}} />
            <TextInput label="Phone Number" value={MSISDN} onChange={(e) => {setMSISDN(e.currentTarget.value)}} />
            <Select label="Payment Category" onChange={(val) => {setMode2(val)}} value={mode2} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />
            <TextInput type="date" label="Date" value={payment_date} onChange={(e) => {setPaymentDate(e.currentTarget.value)}} />
            <TextInput value={first_name} onChange={(e) => {setFirstName(e.currentTarget.value)}} label="First Name" />
            <TextInput value={last_name} onChange={(e) => {setLastName(e.currentTarget.value)}} label="Last Name" />
            
            </SimpleGrid>
                    </>
                )
            ) : (
                <>
                 <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Add Payment</Heading>
                            <Text mb={30}>Create payment for this customer. Before proceeding to save the information, please verify that the information that you are entering is okay and valid.</Text>
            <SimpleGrid cols={2}>
            <Select label="Transaction type" value={transaction_type} onChange={(val) => {setTransactionType(val)}} data={[{label: "Pay Bill", value:"Pay Bill"}, {label: "Buy Goods", value: 'Buy Goods'}, {label: "Cash", value: "Cash"}, {label: "Bank", value: "Cheque"}, {label: "Settlement", value: "Settlement"}]} mb={"md"} />
            {transaction_type === "Pay Bill" || transaction_type === "Buy Goods" ? (
                <TextInput value={business_short_code} onChange={(e) => {setBusinessShortCode(e.currentTarget.value)}} label="Business Short Code" />
            ) : transaction_type === "Settlement" ? (
                <TextInput label="Description" value={settlement_description} onChange={(e) => {setSettlementDescription(e.currentTarget.value)}} placeholder="Settlement description" mb="md" />
            ) : null}
            <NumberInput hideControls label="Amount Paid" value={transaction_amount} onChange={(val) => {setTransactionAmount(val)}} mb="mb" />
            <Checkbox label="Generate transaction ID automatically" checked={generate_automatically} onChange={(e) => {setGenerateAutomatically(e.currentTarget.checked)}} />
            <TextInput label="Transaction ID" value={transaction_id} onChange={(e) => {setTransactionID(e.currentTarget.value)}} />
            <TextInput label="Phone Number" value={MSISDN} onChange={(e) => {setMSISDN(e.currentTarget.value)}} />
            <TextInput type="date" label="Date" value={payment_date} onChange={(e) => {setPaymentDate(e.currentTarget.value)}} />
            <Select label="Payment Category" onChange={(val) => {setMode2(val)}} value={mode2} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />
            <TextInput value={first_name} onChange={(e) => {setFirstName(e.currentTarget.value)}} label="First Name" />
            <TextInput value={last_name} onChange={(e) => {setLastName(e.currentTarget.value)}} label="Last Name" />
            
            </SimpleGrid>
                </>
            )}
        <Group>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setOpenCreatePayment(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} onClick={() => {createPayment()}} loading={loading}>Add Payment</Button>
        </Group>
        </Stack>
      </Drawer>
      <Drawer size={"xl"} opened={open_c} onClose={() => {setCOpened(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Add Latest Customer Meter reading</Heading>
            {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <TextInput label="Current Reading" value={c_last_reading} onChange={(e) => {setCLastReading(e.currentTarget.value)}} mb="mb" />
                )
            ) : (
                <TextInput label="Current Reading" value={c_last_reading} onChange={(e) => {setCLastReading(e.currentTarget.value)}} mb="mb" />
            )}
            
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setCOpened(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} onClick={() => {submitCheckedData()}} loading={submitting}>Add Reading</Button>
        </Group>
        </Stack>
      </Drawer>
    <Drawer size={"xl"} opened={open_update_payment} onClose={() => {setOpenUpdatePymant(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Update Payment</Heading>
            {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <>
                    <Text mb={30}>Disclaimer: Make sure that you update customer payment information that you really intend to update. Updating this information may have consequences on other items.</Text>
                    <NumberInput hideControls label="Amount Paid" value={p_amount} onChange={(val) => {setPAmount(val)}} mb="mb" />
                    
                    <Select mt="md" label="Payment Category" onChange={(val) => {setMode3(val)}} value={mode3} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />
                    </>
                )
            ) : (
                <>
                <Text mb={30}>Disclaimer: Make sure that you update customer payment information that you really intend to update. Updating this information may have consequences on other items.</Text>
                <NumberInput hideControls label="Amount Paid" value={p_amount} onChange={(val) => {setPAmount(val)}} mb="mb" />
                <Select mt="md" label="Payment Category" onChange={(val) => {setMode3(val)}} value={mode3} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />
                </>
            )}

            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setOpenUpdatePymant(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} onClick={() => {savePaymentUpdates()}} loading={loading}>Update Payment</Button>
        </Group>
        </Stack>
      </Drawer>
    <Drawer size={"xl"} opened={send_invoice} onClose={() => {setSendInvoice(false)}} position="right" title="Add Invoice" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
        {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <div>
                <NumberInput min={1} required hideControls label="Invoice Amount" value={total_invoicing_amount} onChange={(val) => {setTotalInvoicingAmount(val)}} />

                <Select required mt={"lg"} label="Invoice Category" onChange={(val) => {setMode(val)}} value={mode} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />

                    {mode === "disconnection" ? (
                        <Notification disallowClose color="red" mt={"md"} title="Warning">
                            If disconnection fee is enabled, an invoice is automatically added to the customer. Please verify that you are not invoicing twice.
                        </Notification>
                    ) : null}
                </div>
                )
            ) : (
                <div>
                <NumberInput required hideControls label="Invoice Amount" value={total_invoicing_amount} onChange={(val) => {setTotalInvoicingAmount(val)}} min={1} />

                <Select required mt={"lg"} label="Invoice Category" onChange={(val) => {setMode(val)}} value={mode} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                        {label: "Others", value: "others"},
                    ]} />

                    {mode === "disconnection" ? (
                        <Notification color="red" mt={"md"} title="Warning">
                            If disconnection fee is enabled, an invoice is usually automatically added to the customer. Please verify that you are not invoicing twice.
                        </Notification>
                    ) : null}
                </div>
            )}
        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setSendInvoice(false)}}>Cancel</Button>
            <Button size='sm' disabled={mode === "" || total_invoicing_amount < 1 || (state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") )} radius={28} onClick={() => {createAndSendInvoice()}} loading={loading}>Create Invoice</Button>
        </Group>
        </Stack>
      </Drawer>
    <Drawer size={"xl"} opened={consumption_drawer} onClose={() => {setConsumptionDrawer(false)}} position="right" title="Edit consumption" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
    {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <>
                    <TextInput label="Last Reading" placeholder="Input last reading" value={consumption_last_reading} onChange={(e) => {setConsumptionLastReading(e.currentTarget.value)}} />
                    <TextInput mt="md" mb="md" label="Current Reading" placeholder="Input current reading" value={consumption_current_reading} onChange={(e) => {setConsumptionCurrentReading(e.currentTarget.value)}} />
                    <TextInput mt="md" mb="md" label="Comment" value={consumption_comment} onChange={(e) => {setConsumptionComment(e.currentTarget.value)}} />
                    </>
                )
            ) : (
                <>
                <TextInput label="Last Reading" placeholder="Input last reading" value={consumption_last_reading} onChange={(e) => {setConsumptionLastReading(e.currentTarget.value)}} />
                <TextInput mt="md" mb="md" label="Current Reading" placeholder="Input current reading" value={consumption_current_reading} onChange={(e) => {setConsumptionCurrentReading(e.currentTarget.value)}} />
                <TextInput mt="md" mb="md" label="Comment" value={consumption_comment} onChange={(e) => {setConsumptionComment(e.currentTarget.value)}} />
                </>
            )}

        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setConsumptionDrawer(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} onClick={() => {saveConsumptionEdits()}}>Save Details</Button>
        </Group>
      </Drawer>
    <Drawer size={"xl"} opened={drawer1} onClose={() => {setDrawer1(false)}} position="right" title="Edit customer" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
    {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <TextInput label="Name" placeholder="Customer name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} />
                )
            ) : (
                <TextInput label="Name" placeholder="Customer name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} />
            )}
        

        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setDrawer1(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} loading={loading} onClick={() => {editCustomer1()}}>Save Details</Button>
        </Group>
      </Drawer>

      <Drawer size={"xl"} opened={drawer2} onClose={() => {setDrawer2(false)}} position="right" title="Edit customer" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
      {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <div>
        <TextInput label="Account" placeholder="Customer account" value={account} onChange={(e) => {setAccount(e.currentTarget.value)}} />
        <TextInput mt="md" label="Email" placeholder="Customer email" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} />
        <TextInput mt="md" label="Phonenumber" placeholder="Customer phonenumber" value={phonenumber} onChange={(e) => {setPhone(e.currentTarget.value)}} />
                    </div>
                )
            ) : (
                <div>
                            <TextInput label="Account" placeholder="Customer account" value={account} onChange={(e) => {setAccount(e.currentTarget.value)}} />
        <TextInput mt="md" label="Email" placeholder="Customer email" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} />
        <TextInput mt="md" label="Phonenumber" placeholder="Customer phonenumber" value={phonenumber} onChange={(e) => {setPhone(e.currentTarget.value)}} />
                </div>
            )}


        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setDrawer2(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} loading={loading} onClick={() => {editCustomer2()}}>Save Details</Button>
        </Group>
      </Drawer>

      <Drawer size={"xl"} opened={drawer3} onClose={() => {setDrawer3(false)}} position="right" title="Edit customer" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
      {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <div>
                    <TextInput label="Address" placeholder="Customer address" value={address} onChange={(e) => {setAddress(e.currentTarget.value)}} />
<TextInput mt="md" label="Zip Code" placeholder="Customer zip code" value={zip} onChange={(e) => {setZip(e.currentTarget.value)}} />
<TextInput mt="md" label="County" placeholder="Customer county" value={county} onChange={(e) => {setCounty(e.currentTarget.value)}} />
<TextInput mt="md" label="Country" placeholder="Customer country" value={country} onChange={(e) => {setCountry(e.currentTarget.value)}} />
        </div>
                )
            ) : (
                <div>
                            <TextInput label="Address" placeholder="Customer address" value={address} onChange={(e) => {setAddress(e.currentTarget.value)}} />
        <TextInput mt="md" label="Zip Code" placeholder="Customer zip code" value={zip} onChange={(e) => {setZip(e.currentTarget.value)}} />
        <TextInput mt="md" label="County" placeholder="Customer county" value={county} onChange={(e) => {setCounty(e.currentTarget.value)}} />
        <TextInput mt="md" label="Country" placeholder="Customer country" value={country} onChange={(e) => {setCountry(e.currentTarget.value)}} />
                </div>
            )}


        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setDrawer3(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} loading={loading} onClick={() => {editCustomer3()}}>Save Details</Button>
        </Group>
      </Drawer>

      <Drawer size={"xl"} opened={drawer4} scrollAreaComponent={ScrollArea.Autosize} onClose={() => {setDrawer4(false)}} position="right" title="Edit customer" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
      {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <div>
                                <TextInput label="Meter Number" placeholder="Customer meter number" value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} />
        <TextInput mt="md" label="Meter Type" placeholder="Customer meter type" value={meter_type} onChange={(e) => {setMeter(e.currentTarget.value)}} />
        <Autocomplete mt="md" label="DMA" placeholder="Customer dma" value={dma} data={dmas} dropdownPosition="flip" onChange={(val) => {setDMA(val)}} />
        <Autocomplete data={tarrifs} mt="md" label="Tarrif"  placeholder="Customer tarrif" value={tarrif} onChange={(val) => {setTarrif(val)}} dropdownPosition="flip"/>
        <Group grow noWrap>
        <TextInput mt="md" label="Last Reading" placeholder="Customer meter last reading" value={previous_reading !== null ? previous_reading : "0.00"} onChange={(e) => {setPreviousReading(e.currentTarget.value)}} />
        <TextInput mt="md" label="Current Reading" placeholder="Customer meter current reading" value={ last_reading !== null ? last_reading : "0.00"} onChange={(e) => {setLastReading(e.currentTarget.value)}} />
        </Group>
        <Group grow noWrap>
        <TextInput mt="md" label="Latitude" placeholder="Customer meter latitude" value={latitude} onChange={(e) => {setLatitude(e.currentTarget.value)}} />
        <TextInput mt="md" label="Longitude" placeholder="Customer meter longitude" value={longitude} onChange={(e) => {setLongitude(e.currentTarget.value)}} />
        </Group>
                    </div>
                )
            ) : (
                <div>

<TextInput label="Meter Number" placeholder="Customer meter number" value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} />
        <TextInput mt="md" label="Meter Type" placeholder="Customer meter type" value={meter_type} onChange={(e) => {setMeter(e.currentTarget.value)}} />
        <Autocomplete mt="md" label="DMA" placeholder="Customer dma" value={dma} data={dmas} dropdownPosition="flip" onChange={(val) => {setDMA(val)}} />
        <Autocomplete data={tarrifs} mt="md" label="Tarrif"  placeholder="Customer tarrif" value={tarrif} onChange={(val) => {setTarrif(val)}} dropdownPosition="flip"/>
        <Group grow noWrap>
        <TextInput mt="md" label="Last Reading" placeholder="Customer meter last reading" value={previous_reading !== null ? previous_reading : "0.00"} onChange={(e) => {setPreviousReading(e.currentTarget.value)}} />
        <TextInput mt="md" label="Current Reading" placeholder="Customer meter current reading" value={ last_reading !== null ? last_reading : "0.00"} onChange={(e) => {setLastReading(e.currentTarget.value)}} />
        </Group>
        <Group grow noWrap>
        <TextInput mt="md" label="Latitude" placeholder="Customer meter latitude" value={latitude} onChange={(e) => {setLatitude(e.currentTarget.value)}} />
        <TextInput mt="md" label="Longitude" placeholder="Customer meter longitude" value={longitude} onChange={(e) => {setLongitude(e.currentTarget.value)}} />
        </Group>
                </div>
            )}

        <Group mt={"md"}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setDrawer4(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} loading={loading} onClick={() => {editCustomer4()}}>Save Details</Button>
        </Group>
      </Drawer>

      <Drawer size={"xl"} opened={drawer5} onClose={() => {setDrawer5(false)}} position="right" title="Edit customer" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
      {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <div>
                    <Radio.Group mt="md" label="Sewer Connection" onChange={setSewer} value={sewer} orientation="vertical">
      <Radio label="Not Connected" value="Not connected" />
      <Radio label="Connected" value="Connected" />
  </Radio.Group>

  <TextInput label="Sewer Type" placeholder="e.g Toilet" value={sewer_type} onChange={(e) => {setSewerType(e.currentTarget.value)}} />
          </div>
                )
            ) : (
                <div>
                          <Radio.Group mt="md" label="Sewer Connection" onChange={setSewer} value={sewer} orientation="vertical">
            <Radio label="Not Connected" value="Not connected" />
            <Radio label="Connected" value="Connected" />
        </Radio.Group>

        <TextInput label="Sewer Type" placeholder="e.g Toilet" value={sewer_type} onChange={(e) => {setSewerType(e.currentTarget.value)}} />
                </div>
            )}

        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setDrawer5(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} loading={loading} onClick={() => {editCustomer5()}}>Save Details</Button>
        </Group>
      </Drawer>

      <Drawer size={"xl"} opened={opened} onClose={() => {setOpened(false)}} position="right" title="Edit invoice" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
      {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <TextInput label="Amount" placeholder="" value={amount} onChange={(e) => {setAmount(e.currentTarget.value)}} />
                )
            ) : (
                <TextInput label="Amount" placeholder="" value={amount} onChange={(e) => {setAmount(e.currentTarget.value)}} />
            )}

        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
            <Button disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} size='sm' radius={28} loading={loading} onClick={() => {saveEdits()}}>Save Details</Button>
        </Group>
      </Drawer>
      <Alert mb={20} icon={<InfoCircle />} title="Customer Application">
            We are rolling out the customer application for android device by the end of June 2024. This feature will be available to customers on premium version.
      </Alert>

      <Drawer position="right" opened={receipt.opened} onClose={() => {setReceipt({opened: false, amount: "", name: "", date: ""})}} centered size={"lg"}>
        <Receipt email={state.userData.username} contact={`${state.userData.phonenumber}/${state.userData.alternative_phonenumber}`} county={state.userData.county[0]} address={state.userData.address} customerName={receipt.name} status={data?.disconnected ? "Disconnected" : "Connected"} amount={receipt.amount} date={receipt.date} phone={data?.phonenumber ? obfuscatePhoneNumber(data?.phonenumber) : ""} mode={receipt.mode} utility={state.userData.waterServiceName} id={receipt.id} />
      </Drawer>
    <Grid p={0}>
        <Grid.Col p={0} mt={-10} sm={12} lg={2}>
            <Stack style={{height: (height - HEADER_HEIGHT) * 0.95}} justify="space-between">
            <div>
            <NavLink mt={20} styles={{root: { borderRadius: 28}}} mb={20} label="Profile" active={active === "profile"} onClick={() => {setActive("profile")}} />
            <NavLink styles={{root: { borderRadius: 28}}} mb={20} label="Map Location" active={active === "map"} onClick={() => {setActive("map")}}  />
            <NavLink styles={{root: { borderRadius: 28}}} mb={20} label="Consumption history" active={active === "consumption"} onClick={() => {setActive("consumption")}}  />
            <NavLink styles={{root: { borderRadius: 28}}} mb={20} label="Invoicing history" active={active === "invoicing"} onClick={() => {setActive("invoicing")}}  />
            <NavLink styles={{root: { borderRadius: 28}}} mb={20} label="Payments history" active={active === "payments"} onClick={() => {setActive("payments")}}  />
            <NavLink styles={{root: { borderRadius: 28}}} mb={20} label="SMS history" active={active === "sms"} onClick={() => {setActive("sms")}}  />
            <Divider mt={10} mb={10} />
            <Group mt="md" mb="md">
                <Button variant="default" onClick={() => {downloadStatements()}} radius={28}>Download Full Statement</Button>
            </Group>
            </div>

                <div>
                <Divider mt={20} mb={20} />
            <Anchor component={Link} to={`/app/customers/customers-list#${state.userData._id}`}>
                <Center inline>
                    <ArrowLeft/>
                    <Box ml={5}>All Customers</Box>
                </Center>
            </Anchor>
                </div>
            </Stack>
        </Grid.Col>

        <Grid.Col sm={12} lg={10} style={{height: (height - HEADER_HEIGHT) * 0.95, overflowY: "auto"}}>
            {active === "profile" ? (
                <Paper p="md" style={{background: "transparent"}}>
                <Heading marginTop={-10} marginBottom={20} level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Profile</Heading>
                <Group position="apart" mb={20}>
                <Group position="left">
                <Indicator inline size={16} offset={7} position="bottom-end" color={!data?.disconnected ? "#00ED64" : "red"} withBorder>
                    <Avatar src={state.userData.avatar} radius="xl" />
            </Indicator>
            <div>
                    <Group spacing={2}><Text>{data?.name}</Text></Group>
                    <Text size="xs">{!data?.disconnected ? "connected" : "disconnected"}</Text>
                </div>
                </Group>

                <Group position="right">
                    <Button size="sm" variant="default" onClick={() => {setDrawer1(true)}} rightIcon={<Edit size={13} />} radius={20}>Edit</Button>
                </Group>
                </Group>
                <Space h="sm" />
                    <Divider />
                    <Space h="sm" />
                    <Group position="apart" mb={20}>
                        <Text style={{fontWeight: 500}}>Personal Information</Text>
                        <Button size="sm" onClick={() => {setDrawer2(true)}} variant="default" rightIcon={<Edit size={13} />} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2} mb={20}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Account #</Text>
                            <Text size="sm">{data?.account}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Email Address</Text>
                            <Text size="sm">{data?.email === "" ? "N/A" : data?.email}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Phone</Text>
                            <Text size="sm">{data?.phonenumber ? obfuscatePhoneNumber(data?.phonenumber) : ""}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Balance</Text>
                            <Text size="sm">{previous_balance}</Text>
                        </div>
                    </SimpleGrid>
                    <Space h="sm" />
                    <Divider />
                    <Space h="sm" />
                    <Group position="apart" mb={20}>
                        <Text style={{fontWeight: 500}}>Address</Text>
                        <Button size="sm" variant="default" rightIcon={<Edit size={13} />} onClick={() => {setDrawer3(true)}} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2} mb={20}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Address</Text>
                            <Text size="sm">{data?.address}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Zip Code</Text>
                            <Text size="sm">{data?.zip}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">County</Text>
                            <Text size="sm">{data?.county}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Country</Text>
                            <Text size="sm">{data?.country}</Text>
                        </div>
                    </SimpleGrid>
                    <Space h="sm" />
                    <Divider />
                    <Space h="sm" />
                    <Group position="apart" mb={20}>
                        <Text style={{fontWeight: 500}}>Meter</Text>
                        <Button size="sm" variant="default" rightIcon={<Edit size={13} />} onClick={() => {setDrawer4(true)}} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2} mb={20}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Meter #</Text>
                            <Text size="sm">{data?.username}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Meter type</Text>
                            <Text size="sm">{data?.meter_type}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">DMA</Text>
                            <Text size="sm">{data?.dma}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Tarrif</Text>
                            <Text size="sm">{data?.tarrif}</Text>
                        </div>

                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Previous Reading</Text>
                            <Text size="sm">{data?.previous_reading !== null ? data?.previous_reading : "0.00"}</Text>
                        </div>

                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Current Reading</Text>
                            <Text size="sm">{data?.last_reading !== null ? data?.last_reading : "0.00"}</Text>
                        </div>

                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Last Billed Consumption</Text>
                            <Text size="sm">{parseInt(data?.last_reading || 0) - parseInt(data?.previous_reading || 0)}</Text>
                        </div>

                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Overall Mean Consumption</Text>
                            <Text size="sm">{calculateAverage()}</Text>
                        </div>

                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Latitude</Text>
                            <Text size="sm">{data?.coordinates[0]}</Text>
                        </div>

                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Longitude</Text>
                            <Text size="sm">{data?.coordinates[1]}</Text>
                        </div>
                    </SimpleGrid>
                    <Space h="sm" />
                    <Divider />
                    <Space h="sm" />
                    <Group position="apart" mb={20}>
                        <Text style={{fontWeight: 500}}>Sewer Connection</Text>
                        <Button size="sm" variant="default" rightIcon={<Edit size={13} />} onClick={() => {setDrawer5(true)}} radius={20}>Edit</Button>
                    </Group>

                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2}mb={20}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Sewer connection</Text>
                            <Text size="sm">{data?.sewer ? "Connected" : "Not connected"}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Sewer Type</Text>
                            <Text size="sm">{data?.sewer_type}</Text>
                        </div>
                    </SimpleGrid>
                </Paper>
            ) : active === "map" ? (
                <Paper p="md" style={{height: (height - HEADER_HEIGHT) * 0.85}}>
                    <Heading marginBottom={20} level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Customer Location</Heading>
                    <MapContainer center={[parseFloat(latitude), parseFloat(longitude)]} zoom={21} style={{height: "100%", width: "100%"}}>
                    <TileLayer
      attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    />

    <Marker position={[parseFloat(latitude), parseFloat(longitude)]} />
                    </MapContainer>
                </Paper>
            ) : active === "consumption" ? (
                    <Tabs defaultValue={"table"}>
                        <Tabs.List>
                            <Tabs.Tab value="table">Tabular View</Tabs.Tab>
                            <Tabs.Tab value="graphical">Graphical View</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel pt="md" value="table">
                        <Paper p="md" style={{background: "transparent"}}>
                    <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Consumption History</Heading>
                    </Group>
                    <Alert icon={<InfoCircle />} title="Deleting/Updating Consumption History">
                        Deleting or updating a consumption history might have some impacts on the other dataset. Specifically, when deleting the latest consumption history, be sure to update also the previous and current meter readings in the profile section of the customer.
                    </Alert>
                        <Table fontSize="xs" highlightOnHover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Previous Reading</th>
                                <th>Current Reading</th>
                                <th>Consumption</th>
                                <th>Photo</th>
                                <th>Technician</th>
                                <th>Comments</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consumption.length > 5 ? (
                                                            <tr>
                                                            <td colSpan={8}>
                                                                <Center>
                                                                    <Button radius={28} variant="default" onClick={() => {
                                                                        setCOpened(true);
                                                                    }}>Add Consumption Reading</Button>
                                                                </Center>
                                                            </td>
                                                        </tr>
                            ) : null}
                            {consumption.length === 0 ? (
                                <tr>
                                    <td colSpan={6}>No data found</td>
                                </tr>
                            ) : (
                                consumption.map((item, index) => {
                                    return (
                                        index === 0 ? (
                                            <tr key={`consumption-${index}`}>
                                                <td>{index + 1}</td>
                                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td>{item.prevReading}</td>
                                            <td>{item.currReading}</td>
                                            <td>{item.currReading - item.prevReading}</td>
                                            <td><Anchor target="_blank" href={item?.photo}>image link</Anchor></td>
                                            <td>{item.technician}</td>
                                            <td>{item?.comment || "N/A"}</td>
                                            <td>
                                                <Group noWrap>
                                                    <Button radius={28} size="xs" variant="default" leftIcon={<Edit size={13} />} onClick={() => {updateConsumption(item._id, item?.prevReading?.toFixed(0), item?.currReading?.toFixed(0), item?.comment)}}>Update</Button>
                                                    <Button radius={28} size="xs" variant="default" leftIcon={<Trash size={13} color="red" />} onClick={() => {deleteConsumptionHistory(item._id)}}>Delete</Button>
                                                </Group>
                                            </td>
                                        </tr>
                                        ) : (
                                            <tr key={`consumption-${index}`}>
                                                <td>{index + 1}</td>
                                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td>{item.prevReading}</td>
                                            <td>{item.currReading}</td>
                                            <td>{item.currReading - item.prevReading}</td>
                                            <td>
                                            <Anchor target="_blank" href={item.photo}>image link</Anchor>
                                            </td>
                                            <td>{item.technician}</td>
                                            <td>
                                            <Text size={"sm"}>{item?.comment || "N/A"}</Text>
                                            </td>
                                            <td>
                                            <Group noWrap>
                                                    <Button radius={28} size="xs" variant="default" leftIcon={<Edit size={13} />} onClick={() => {updateConsumption(item._id, item?.prevReading?.toFixed(0), item?.currReading?.toFixed(0), item?.comment)}}>Update</Button>
                                                    <Button radius={28} size="xs" variant="default" leftIcon={<Trash size={13} color="red" />} onClick={() => {deleteConsumptionHistory(item._id)}}>Delete</Button>
                                                </Group>
                                            </td>
                                        </tr>
                                        )
                                    )
                                })
                            )}
                            {consumption.length < 6 ? (
                                                            <tr>
                                                            <td colSpan={8}>
                                                                <Center>
                                                                    <Button radius={28} variant="default" onClick={() => {
                                                                        setCOpened(true);
                                                                    }}>Add Consumption Reading</Button>
                                                                </Center>
                                                            </td>
                                                        </tr>
                            ) : null}
                        </tbody>
                    </Table>
                    </Paper>
                        </Tabs.Panel>

                        <Tabs.Panel value="graphical">
                        <Paper p="md" style={{background: "transparent"}}>
                        <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Consumption History</Heading>
                        <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Line</option>
                      <option value="bar">Bar</option>
                  </SelectField>
                        </Group>
                        <Alert icon={<InfoCircle />} title="Deleting/Updating Consumption History">
                        Deleting or updating a consumption history might have some impacts on the other dataset. Specifically, when deleting the latest consumption history, be sure to update also the previous and current meter readings in the profile section of the customer.
                    </Alert>
                        <Paper p="md" style={{height: 300, width: '100%', overflowX: 'auto'}}>
                            {chart1 === "line" ? (
                                <Line width={580} data={consumptionData} options={options} />
                            ) : (
                                <Bar width={580} data={consumptionData} options={options} />
                            )}

        <Group position='apart' mb={20} mt={20}>
          <Group spacing="xl" position='left'>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid ${theme.colors.red[9]}`}}></div>
              <Text color="dimmed"  size='xs' >Mean Consumption:<strong>{calculateAverage()}</strong></Text>
              </Group>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid ${theme.colors.green[9]}`}}></div>
              <Text color="dimmed"  size='xs' >Consumption Units</Text>
              </Group>
          </Group>
          </Group>
                          </Paper>
                        </Paper>
                        </Tabs.Panel>
                    </Tabs>
            ) : active === "invoicing" ? (
                <Tabs defaultValue={"table"}>
                    <Tabs.List>
                        <Tabs.Tab value="table">Tabular View</Tabs.Tab>
                        <Tabs.Tab value="graphical">Graphical View</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel pt="xl" value="table">
                    <Paper style={{background: "transparent"}} p="md">
                    <Group position="apart"  mb={20}>
                    <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Invoicing History</Heading>
                    </Group>
                    </Group>
                    <Alert icon={<InfoCircle />} title="Deleting/Updating Invoice">
                        Deleting or updating a particular invoice might have consequences on other sections including your overall billing performance and the customer overall balance. Be sure to cross-check before undertaking either of these actions.
                    </Alert>
                    <Table fontSize="xs" highlightOnHover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Cost</th>
                                <th>Arrears</th>
                                <th>Total</th>
                                <th>Category</th>
                                <th>Due</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {invoices.length > 5 ? (
                                                                <tr>
                                                                <td colSpan={8}>
                                                                    <Center>
                                                                    <Button radius={28} variant="default" onClick={() => {setSendInvoice(true)}}>Add Invoice</Button>
                                                                    </Center>
                                                                </td>
                                                            </tr>
                                ) : null}
                            {invoices.length === 0 ? (
                                <tr>
                                    <td colSpan={8}>No data found</td>
                                </tr>
                            ) : (
                                invoices.map((item, index) => {
                                    return (
                                        <tr key={`invoice-${index}`}>
                                            <td>{index + 1}</td>
                                            <td>{item.invoices.total}</td>
                                            <td>{item.mode !== "disconnection" ? item.invoices?.defaulted_balance || 0 : 0}</td>
                                            <td>{(parseFloat(item.invoices.total) + parseFloat(item.mode !== "disconnection" ? item.invoices?.defaulted_balance || 0 : 0)).toFixed(0)}</td>
                                            <td>
                                                <Badge style={{textTransform: "none"}} size="xs" color={item.mode === "disconnection" ? "yellow" : item?.mode === "invoice defaulting" ? "red" : item?.mode === "sms" || item?.mode === "batch-download" || item?.mode === "email" ? "green" : "pink"}>{item.mode === "disconnection" ? "Suspension/Reconnection" : item?.mode === "invoice defaulting" ? "Late Payment" : item?.mode === "repairs" ? "Repairs" : item?.mode === "illegal connection" ? "Illegal Connection" : item?.mode === "registration" ? "Registration" : item?.mode === "meter deposit" ? "Meter Deposit" : item?.mode === "sms" || item?.mode === "batch-download" || item?.mode === "email" ? "Monthly Bill" : "Others"}</Badge>
                                            </td>
                                            <td>{item.invoices.due_date}</td>
                                            <td>
                                                <Group noWrap>
                                                    <Button leftIcon={<EditCircle size={13} />} variant="default" size="xs" radius={28} onClick={() => {editInvoice(item._id, item.invoices.total)}}>Edit</Button>
                                                    <Button leftIcon={<BrandWhatsapp size={13} />} size="xs" radius={28} variant="default" onClick={() => {sendWhatsApp(item.invoices)}}>Whatsapp</Button>
                                                    <Button leftIcon={<Download size={13} />} size="xs" radius={28} variant="default" onClick={() => {saveInvoice(item.invoices)}}>Save</Button>
                                                    <Button leftIcon={<Trash size={13} color="red" />} size="xs" radius={28} variant="default" onClick={() => {askDelete(item._id,1, item.invoices.invoice_number)}}>Delete</Button>
                                                </Group>

                                            </td>
                                        </tr>
                                    )
                                })
                            )}
                                {invoices.length < 6 ? (
                                                                <tr>
                                                                <td colSpan={8}>
                                                                    <Center>
                                                                    <Button radius={28} variant="default" onClick={() => {setSendInvoice(true)}}>Add Invoice</Button>
                                                                    </Center>
                                                                </td>
                                                            </tr>
                                ) : null}
                        </tbody>
                    </Table>
                </Paper>
                    </Tabs.Panel>

                    <Tabs.Panel value="graphical">
                    <Paper style={{background: "transparent"}} p="md">
                    <Group position="apart"  mb={20}>
                    <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Invoicing History</Heading>
                    </Group>
                    <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Line</option>
                      <option value="bar">Bar</option>
                  </SelectField>
                    </Group>
                    <Alert icon={<InfoCircle />} title="Deleting/Updating Invoice">
                        Deleting or updating a particular invoice might have consequences on other sections including your overall billing performance and the customer overall balance. Be sure to cross-check before undertaking either of these actions.
                    </Alert>
                    <Paper p="md" style={{height: 300, width: '100%', overflowX: 'auto'}}>
                            {chart1 === "line" ? (
                                <Line width={580} data={data2} options={options2} />
                            ) : (
                                <Bar width={580} data={data2} options={options2} />
                            )}

        <Group position='apart' mb={20} mt={20}>
          <Group spacing="xl" position='left'>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid ${theme.colors.green[9]}`}}></div>
              <Text color="dimmed"  size='xs' >Invoice Total</Text>
              </Group>
          </Group>
          </Group>
                          </Paper>
                    </Paper>
                    </Tabs.Panel>
                </Tabs>
            ) : active === "sms" ? (
                <Tabs defaultValue={"table"}>
                    <Tabs.List>
                        <Tabs.Tab value="table">
                            SMS List
                        </Tabs.Tab>
                        <Tabs.Tab value="send">
                            Send SMS
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="table">
                    <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>SMS History</Heading>
                    </Group>

                    <Alert icon={<InfoCircle />} title="Delivering SMS">
                        Emita does not deliver SMS to you clients. Instead, it utilizes your configured SMS providers to send messages. 
                    </Alert>
                        <Table fontSize={"xs"} highlightOnHover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th colSpan={3}>SMS</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sms_list.length === 0 ? (
                                                                    <tr>
                                                                    <td colSpan={4}>
                                                                        <Text align="center">No sms were found.</Text>
                                                                    </td>
                                                                </tr>
                                ) : (
                                    sms_list.map((itm, idx) => {
                                        return (
                                            <tr key={`sms-${idx}`}>
                                                <td>
                                                    {idx + 1}
                                                </td>
                                                <td>{new Date(itm.createdAt).toLocaleDateString()}</td>
                                                <td colSpan={3}>
                                                    {itm.text}
                                                </td>
                                                <td>
                                                    <Group noWrap>
                                                        <Button onClick={() => {
                                                            setMessage(itm.text);
                                                            setSMSDrawer(true);
                                                        }} size="xs" variant="default">Resend</Button>
                                                    </Group>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </Table>
                    </Tabs.Panel>

                    <Tabs.Panel value="send">
                    <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>New SMS</Heading>
                    </Group>

                    <Alert icon={<InfoCircle />} title="Delivering SMS">
                        Please check your SMS account to ascertain that you have enough balance to send an SMS.
                    </Alert>
                        <Center mt={30}>
                            <Button onClick={() => {setSMSDrawer(true)}}>Create SMS</Button>
                        </Center>
                    </Tabs.Panel>
                </Tabs>
            ) : (
                <Tabs defaultValue={"table"}>
                    <Tabs.List>
                        <Tabs.Tab value="table">
                            Tabular View
                        </Tabs.Tab>

                        <Tabs.Tab value="graphical">Graphical View</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="table">
                    <Paper style={{background: "transparent"}} p="md">
                    <Group position="apart" mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Payments History</Heading>
                    </Group>

                    <Alert icon={<InfoCircle />} title="Deleting/Updating Payment">
                        Deleting, updating or add a payment information might have some impacts on the dataset of the customer. Please make sure that you've cross-checked your action again.
                    </Alert>

                    <Table fontSize="xs" highlightOnHover>
                        <thead>
                            <tr>
                                <th>TransactionID</th>
                                <th>Amount</th>
                                <th>Short Code</th>
                                <th>MSISDN</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Category</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {payments.length > 5 ? (
                                                            <tr>
                                                            <td colSpan={7}>
                                                                <Center>
                                                                <Button radius={28} onClick={() => {setOpenCreatePayment(true)}} variant="default">Add Payment</Button>
                                                                </Center>
                                                            </td>
                                                        </tr>
                            ) : null}
                            {payments.length === 0 ? (
                                <tr>
                                    <td colSpan={8}>No data found</td>
                                </tr>
                            ) : (
                                payments.map((item, index) => {
                                    return (
                                        <tr key={`payments-${index}`}>
                                            <td>{item.transaction_id}</td>
                                            <td>{item.transaction_amount}</td>
                                            <td>{item.business_short_code === "" ? item.transaction_type : item.business_short_code}</td>
                                            <td>{item.MSISDN === "" ? phonenumber : item.MSISDN}</td>
                                            <td>{item.first_name === "" ? name.split(" ")[0].toUpperCase() : item.first_name.toUpperCase()}</td>
                                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td>
                                                <Badge style={{textTransform: "none"}} size="xs" color={item.mode === "disconnection" ? "yellow" : item?.mode === "invoice defaulting" ? "red" : item?.mode === "sms" || item?.mode === "batch-download" || item?.mode === "email" ? "green" : "pink"}>{item.mode === "disconnection" ? "Suspension/Reconnection" : item?.mode === "invoice defaulting" ? "Late Payment" : item?.mode === "repairs" ? "Repairs" : item?.mode === "illegal connection" ? "Illegal Connection" : item?.mode === "registration" ? "Registration" : item?.mode === "meter deposit" ? "Meter Deposit" : item?.mode === "sms" || item?.mode === "batch-download" || item?.mode === "email" ? "Monthly Bill" : "Others"}</Badge>
                                            </td>
                                            <td>
                                                <Group noWrap>
                                                    <Button radius={28} leftIcon={<Edit size={13} />} size="xs" variant="default" onClick={() => {openUpdateDrawer(item._id, item?.mode)}}>Update</Button>
                                                    <Button radius={28} leftIcon={<Download size={13} />} size="xs" variant="default" onClick={() => {setReceipt({name: data?.name, date: new Date(item.createdAt).toLocaleDateString(), amount: item.transaction_amount, opened: true, mode:item.business_short_code === "" ? item.transaction_type : item.business_short_code, id: item.transaction_id })}}>Receipt</Button>
                                                    <Button radius={28} leftIcon={<Trash color="red" size={13} />} size="xs" variant="default" onClick={() => {askDelete(item._id, 2, "")}}>Delete</Button>
                                                </Group>
                                            </td>
                                        </tr>
                                    )
                                })
                            )}

                            {payments.length < 6 ? (
                                                            <tr>
                                                            <td colSpan={8}>
                                                                <Center>
                                                                <Button radius={28} onClick={() => {setOpenCreatePayment(true)}} variant="default">Add Payment</Button>
                                                                </Center>
                                                            </td>
                                                        </tr>
                            ) : null}
                        </tbody>
                    </Table>
                </Paper>
                    </Tabs.Panel>

                    <Tabs.Panel value="graphical">
                    <Paper style={{background: "transparent"}} p="md">
                    <Group position="apart"  mb={20}>
                    <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Payments History</Heading>
                    <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Line</option>
                      <option value="bar">Bar</option>
                  </SelectField>
                    </Group>
                    <Alert icon={<InfoCircle />} title="Deleting/Updating Payment">
                        Deleting, updating or add a payment information might have some impacts on the dataset of the customer. Please make sure that you've cross-checked your action again.
                    </Alert>
                    <Paper p="md" style={{height: 300, width: '100%', overflowX: 'auto'}}>
                            {chart1 === "line" ? (
                                <Line width={580} data={data3} options={options3} />
                            ) : (
                                <Bar width={580} data={data3} options={options3} />
                            )}

        <Group position='apart' mb={20} mt={20}>
          <Group spacing="xl" position='left'>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid ${theme.colors.green[9]}`}}></div>
              <Text color="dimmed"  size='xs' >Transaction Amount</Text>
              </Group>
          </Group>
          </Group>
                          </Paper>
                    </Paper>
                    </Tabs.Panel>
                </Tabs>
            )}
        </Grid.Col>
    </Grid>
    </>
             
    )
}
