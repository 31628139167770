import { useLocalStorage } from "@mantine/hooks";
import React, { useReducer } from "react";
import { SearchbarContext } from "./searchbar.context";

export const SearchbarProvider = ({ children }) => {
    const [query, setQuery] = useLocalStorage({
        key: "searchbar-query-string",
        defaultValue: '',
        getInitialValueInEffect: true
    });

    const reducer = (state, action) => {
        switch(action.type) {
            case 'CLOSE':
                return {
                    ...state,
                    active: false
                };

            case 'OPEN':
                return {
                    ...state,
                    active: true
                };

            default:
                return state;
        }
    }

    const initialState = {
        active: false,
        query: query
    };

    const [ state, dispatch ] = useReducer(reducer, initialState);

    return (
        <SearchbarContext.Provider value={{ state, dispatch}}>
            {children}
        </SearchbarContext.Provider>
    )
}