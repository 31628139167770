import { ActionIcon, Alert, Anchor, Button, Center, Drawer, Grid, Group, Paper, Select, Stack, Table, Tabs, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import { Edit, Settings, Trash } from "tabler-icons-react";
import { IconInfoCircle } from "@tabler/icons";

export default function Devices(){
    const theme = useMantineTheme();
    const { state, dispatch } = useContext(AuthContext);
    const { height, width } = useViewportSize();
    const [opened, setOpened] = useState(false);

    const [type, setType] = useState("Printer");
    const [name, setName] = useState("");
    const [mac, setMac] = useState("");

    const [creating, setCreating] = useState(false);

    const [r, setR] = useState(false);

    const [devices, setDevices] = useState([]);

    const createDevice = () => {
        try{
            setCreating(true);
            axios.post("/devices/create", {
                parent: state.userData._id,
                name: name,
                macAddress: mac,
                deviceType: type
            }, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
                if(res.status === 200){
                    toast.success("The device was added successfully.");
                    setCreating(false);
                    setR(!r);
                    setOpened(false);
                }
            }).catch(function(err){
                toast.error("Could not create the device");
                setCreating(false);
            })
        } catch(error){
            toast.error("Could not create the device");
            setCreating(false);
        }
    }

    const fetchDevices = () => {
        axios.get("/devices/devices",{headers: {'Authorization': `Bearer ${state.userToken}`}, params: {parent: state.userData._id}}).then(function(res){
            if(res.status === 200){
                setDevices(res.data.data);
            }
        }).catch(function(err){
            toast.error("Could not fetch your devices");

        })
    }

    useEffect(() => {
        fetchDevices();
    }, [r]);

    return (
        <>
        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />} title="Device Management">
            This section provides you with a simple device management section. For a comprehensive view about smart meters, visit our <Anchor href="#">Smart Meter Device Management(SMDM)</Anchor> platform.
        </Alert>

        <Tabs defaultValue={"smart_meters"}>
            <Tabs.List>
                <Tabs.Tab value="smart_meters">
                    Smart Meters
                </Tabs.Tab>

                <Tabs.Tab value={"printers"}>
                    Field Printers
                </Tabs.Tab>

                <Tabs.Tab value="tims">
                    KRA TIMS
                </Tabs.Tab>

                <Tabs.Tab value="settings">
                    Settings
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="printers">
            <Grid>
            {devices.length === 0 ? (
                            <Grid.Col sm={12} lg={12}>            
                            <Center mt={"15%"}>
                                <img src={require("../../assets/waiting_hourglass.png")} />
                            </Center>
            
                        <Text align="center" mt={30}>No devices were found. <br />Devices connected with Emita will be listed here.</Text>
                        <Center mt={20}>
                            <Button onClick={() => {setOpened(true)}}>Add New Device</Button>
                        </Center>
        
            </Grid.Col>
            ) : (
                <Grid.Col sm={12} lg={12}>
                <Table fontSize={"xs"}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Mac Address</th>
                            <th>Category</th>
                            <th>Added On</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {devices.map((item, idx) => {
                                return (
                                    <tr key={`device-${idx}`}>
                                        <td>{item.name}</td>
                                        <td>{item.macAddress}</td>
                                        <td>{item.deviceType}</td>
                                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                        <td>
                                            <Group>
                                                <ActionIcon>
                                                    <Edit color="teal" size={15} />
                                                </ActionIcon>
                                                <ActionIcon>
                                                    <Trash color="red" size={15} />
                                                </ActionIcon>
                                            </Group>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                </Table>

                <Center mt={20} mb={20}>
               <Button onClick={() => {setOpened(true)}}>Add New Device</Button>
               </Center>
                </Grid.Col>
            )}
        </Grid>
            </Tabs.Panel>

        <Tabs.Panel value="tims">
            <Stack justify="center" h={(height - HEADER_HEIGHT) * 0.85}>
            <Center>
            <img src={require("../../assets/waiting_hourglass.png")} />
            </Center>
            <Text align="center" mt={20}>No linked TIMS were found. <br />KRA TIMS linked to Emita will appear here.</Text>
            <Center mt={20} mb={20}>
               <Button>Link New TIMS</Button>
               </Center>
        </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="smart_meters">
            <Stack justify="center" h={(height - HEADER_HEIGHT) * 0.85}>
            <Center>
            <img src={require("../../assets/waiting_hourglass.png")} />
            </Center>
            <Text align="center" mt={20}>No smart meters were found. <br />Smart Meters linked to Emita will appear here.</Text>
        
            <Center mt={20} mb={20}>
               <Button>Link New Smart Meter</Button>
               </Center>
        </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="settings">
      <Stack justify="center" h={(height - HEADER_HEIGHT) * 0.85}>
            <Center>
                <Settings />
            </Center>
            <Text align="center" mt={20}>Configuration options for various devices will appear here.</Text>
        </Stack>
      </Tabs.Panel>
        </Tabs>

        
        <Drawer padding={"md"} opened={opened} onClose={() => {setOpened(false)}} withCloseButton={false} position="right" size="lg" withOverlay={false}>
                            <Stack justify="space-between" style={{height: height * 0.7}}>
                                <div>
                                <Text mb={20} style={{fontWeight: "bold"}} >Add New Device</Text>
                                <Select label="Device Category" value={type} onChange={(val) => {setType(val)}} data={[{label: "Thermal Printer", value: "Printer"}]} mb="md" />
                                <TextInput label="Name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} description="Name of the device" mb="md" />
                                <TextInput label="Mac Address" value={mac} onChange={(e) => {setMac(e.currentTarget.value)}} description="Mac Address number seperated by colons" />
                                </div>
            
                                <Group>
                                    <Button variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
                                    <Button loading={creating} onClick={() => {createDevice()}}>Add Device</Button>
                                </Group>
                            </Stack>
                        </Drawer>
        </>
    )
}