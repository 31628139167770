import React, { useState } from "react";
import { Text, Checkbox, Table, Menu as MantineMenu, Group, Button, Avatar, NumberInput, Center, Select, Badge, useMantineTheme, Space, Divider, Progress, MediaQuery, Drawer, Stack, TextInput, Paper } from "@mantine/core";
import { Plus, } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { useContext } from "react";
import axios from "../../../utils/axios";
import { useEffect } from "react";
import useStyles from "./staffList.style";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { Heading, SearchField } from "@aws-amplify/ui-react";
import { StaffContext } from "../staff-context/staff.context";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import { COLORS, FRONTEND_URL } from "../../../constants";
import ReactGA from "react-ga4";
import { nanoid } from "nanoid";
import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";

export default function StaffList(params){
    const { state } = useContext(AuthContext);
    const { dispatch: navDispatch} = useContext(StaffContext);
    const [selection, setSelection] = useState([]);
    const [data, setData] = useState([]);
    const [, setPagination] = useState(0);
    const [, setRawData] = useState([]);
    const { classes, cx } = useStyles();
    const [refresh, setRefresh] = useState(false);
    const [size, setSize] = useState(10);
    const [tasks, setTasks] = useState([]);
    const [name, setName] = useState("");
    const [opened, setOpened] = useState(false);
    const [id, setID] = useState("");
    const [username, setUsername] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [category, setCategory] = useState("");
    const [phone, setPhone] = useState("")

    const openEditDrawer = (id, u, f, l, c, p) => {
        setID(id);
        setUsername(u);
        setFirstName(f);
        setLastName(l);
        setCategory(c);
        setPhone(p);

        setOpened(true);
    }

    const saveEdits = () => {
        const  body = {
            username: username,
            title: category,
            firstname: firstname,
            lastname: lastname,
            phone: phone
        }

        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
            params: {
                id: id
            }
        }
        try{
            axios.patch("/staff/update",body, config).then(function(res){
                if(res.status === 200){
                    toast.success("The edits have been saved successfully", {
                        position: "bottom-right"
                    });
                    setRefresh(!refresh);
                    setOpened(false);
                }
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            });
            
        }
    }

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchTasks = () => {
        const body = {
            parent: state.userData._id
        };

        axios.post("/tasks/gettasks", body).then(function(res){
            setTasks(res.data.data);
        }).catch(function(error){
            console.log(error);
        })
    }

    useEffect(() => {
        fetchTasks();
    }, [])

    const getNumberOfStaffTasks = (username) => {
        let sum = 0;
        for(let i=0; i<tasks.length; i++){
            let staff = tasks[i].staff;
            
            if(staff.includes(username)){
                sum += 1;
            }
        }

        return sum;
    }

    const resendPasswordResetLink = (u, f, l) => {

        const id = nanoid(64);

        axios.post("/tokens/create", {
            username: u,
            token: id
        }).then(function(res){
            if(res.status === 200){
                axios.post("/verify/send", {
                    fullname: f + " " + l,
                    username: u,
                    link: `${FRONTEND_URL}/staff/verify?token=${id}&email=${u}`
                }).then(function(res){
                    if(res.status === 200){
                        toast.success("A password reset link has been send to the staff.");
                    }
                }).catch(function(error){
                    toast.error(error.message)
                })
            }
        }).catch(function(error){
            toast.error(error.message);
        })
    }

    const deleteStaff = (id) => {
        swal('Are you sure you want to continue?', {
            buttons: {
                cancel: 'Cancel',
                continue: {
                    text: 'Continue',
                    value: 'continue'
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                try {
                    axios.post("/staff/deletestaff", {
                        variant: "id",
                        id: id
                    }).then(function(res){
                        console.log(res)
                        toast.success("Staff deleted!", {
                            position: "bottom-right"
                        });
                        setRefresh(!refresh);
        
                    }).catch(function(error){
                        toast.error(error.message, {
                            position: "bottom-right"
                        });
                    })
                } catch(error){
                    toast.error(error.message);
                }
            }
        })
    }
    const fetchData = () => {
        try {
            const body = {
                parent: state.userData._id
            };

            axios.post("/staff/getstaffs", body).then(function(res){
                setRawData(res.data.data);
                setData(res.data.data);
                setPagination(Math.ceil(res.data.data.length / size))
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                });
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [refresh]);

    const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
  const toggleAll = () =>
    setSelection((current) => (current.length === data.length ? [] : data.map((item) => item._id)));

    const [color, setColor] = useLocalStorage({
        key: COLORS,
        defaultValue: "blue",
        getInitialValueInEffect: true
    })

    const theme = useMantineTheme();
    
const rows = data.filter((item) => {
    if(name !== ""){
        if(item.firstname.toLowerCase().includes(name) || item.lastname.toLowerCase().includes(name) || item.username.toLowerCase().includes(name)){
            return item;
        }
    } else {
        return item;
    }
}).map((item ,index) => {
    const selected = selection.includes(item._id);
    return (
        <tr key={index} className={cx({ [classes.rowSelected]: selected })} >
                    <td>
                    <Checkbox
                        checked={selection.includes(item._id)}
                        onChange={() => toggleRow(item._id)}
                        transitionDuration={0}
                    />
                    </td>
                        <td>
                            <Avatar title={item.firstname} color={color} variant="filled" radius={40} size={40} >{item.firstname.split("")[0].toUpperCase()}</Avatar>
                        </td>
                        <td>
                            
                            {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            
                        </td>
                        <td>
                            
                            {item.username}
                            
                        </td>
                        <td>
                            
                            {item.phone}
                            
                        </td>
                        <td>
                           
                           {item.firstname}
                           
                        </td>
                        <td>
                            
                            {item.lastname}
                            
                        </td>
                        <td>
                        <Group position="apart">
                            <Text size="xs" color="teal" weight={700}>
                            {tasks.length === 0 ? "0" : ''+getNumberOfStaffTasks(item.username)}
                            </Text>
                        </Group>
                            <Progress size="xs" classNames={{ bar: classes.progressBar}} sections={[{value: tasks.length === 0 ? 100 : ((getNumberOfStaffTasks(item.username) / tasks.length) * 100), color: 'green'}]} />
                        </td>
                        <td>
                            <Badge style={{textTransform: 'none'}} color={item.active ? "green" : "yellow"} size="xs">{item.active ? "Active" : "Inactive"}</Badge>
                        </td>
                        <td>
                            <Menu>
                            <MenuButton>
                                Actions <span aria-hidden>▾</span>
                            </MenuButton>
                            <MenuList style={{backgroundColor: theme.colorScheme === "light" ? "#ffffff" : "#000000", color: theme.colorScheme === "light" ? "#000000" : "#ffffff"}}>
                                    <MenuItem onSelect={() => {openEditDrawer(item._id, item.username, item.firstname, item.lastname, item.title, item.phone)}}>Edit</MenuItem>
                                    <MenuItem onSelect={() => {resendPasswordResetLink(item.username, item.firstname, item.lastname)}} >Resend Password Reset Link</MenuItem>
                                    <MenuItem onSelect={() => {deleteStaff(item._id)}}>Delete</MenuItem>
                            </MenuList>
                            </Menu>
                        </td>
                    </tr>
    )
})

    const { height } = useViewportSize();

    return(
        <Paper p="md">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | staff</title>
        </Helmet>

        <Drawer padding={"md"} opened={opened} onClose={() => {setOpened(false)}} withCloseButton={false} position="right" withOverlay={false}>
            <Stack justify="space-between" style={{height: height * 0.7}}>
                <div>
                    <Text mb={20} style={{fontWeight: "bold"}} >Edit staff details</Text>
                    <Select label="Staff Category/Title" data={[{label: "Technician", value: "technician"}]} value={category} onChange={(val) => {setCategory(val)}} mb="md" />
                    <TextInput label="Email Address" value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} mb="md" />
                    <TextInput label="Firstname" value={firstname} onChange={(e) => {setFirstName(e.currentTarget.value)}} mb="md" />
                    <TextInput label="Lastname" value={lastname} onChange={(e) => {setLastName(e.currentTarget.value)}} mb="md" />
                    <TextInput label="Phone" value={phone} onChange={(e) => {setPhone(e.currentTarget.value)}} />
                </div>

                <Group>
                    <Button variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
                    <Button onClick={() => {saveEdits()}} radius={28}>Save Edits</Button>
                </Group>
            </Stack>
        </Drawer>

        <Group mb={20} mt={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Staff</Heading>
      </Group>
      <Text mb={20}>This list displays your staff onboard Emita platforms. You can always review and manage your staff. Importantly, explore which staff is doing what, and at what rate.</Text>
      <Divider ml={-10} mr={-10} />
            <MediaQuery smallerThan="md" styles={{display: "none"}}>
            <Group position="apart" mt={20} noWrap>
                <SearchField inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' size="small" placeholder="Type to search staff" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}}/>
                    <MantineMenu>
                        <MantineMenu.Target>
                        <Button disabled={selection.length === 0 ? true : false} variant="default">Bulk Actions</Button>
                        </MantineMenu.Target>
                        <MantineMenu.Dropdown>
                        <MantineMenu.Label>- Choose Action -</MantineMenu.Label>
                        <MantineMenu.Item disabled={selection.length === 0 ? true : false}>Suspend Selected Staff</MantineMenu.Item>
                        <MantineMenu.Item disabled={selection.length === 0 ? true : false}>Resume Selected Staff</MantineMenu.Item>
                        <MantineMenu.Item disabled={selection.length === 0 ? true : false}>Lay Off Selected Staff</MantineMenu.Item>
                        </MantineMenu.Dropdown>
                    </MantineMenu>
                    <Button onClick={() => {params.handleTab("new")}} leftIcon={<Plus />}>Onboard New Staff</Button>
                        <NumberInput value={size} onChange={(val) => {setSize(val)}} min={5} step={5} />
            </Group>
            </MediaQuery>
            <MediaQuery largerThan="md" styles={{display: "none"}}>
            <Group mt={20} style={{overflowX: "auto"}} >
                <SearchField inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' size="small" placeholder="Type to search staff" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}}/>
                <MantineMenu>
                        <MantineMenu.Target>
                        <Button disabled={selection.length === 0 ? true : false} variant="default">Bulk Actions</Button>
                        </MantineMenu.Target>
                        <MantineMenu.Dropdown>
                        <MantineMenu.Label>- Choose Action -</MantineMenu.Label>
                        <MantineMenu.Item disabled={selection.length === 0 ? true : false}>Suspend Selected Staff</MantineMenu.Item>
                        <MantineMenu.Item disabled={selection.length === 0 ? true : false}>Resume Selected Staff</MantineMenu.Item>
                        <MantineMenu.Item disabled={selection.length === 0 ? true : false}>Lay Off Selected Staff</MantineMenu.Item>
                        </MantineMenu.Dropdown>
                    </MantineMenu>
                    <Button onClick={() => {navDispatch({type: "NAVIGATE", step: "1"})}} leftIcon={<Plus />}>Onboard New Staff</Button>
                        <NumberInput value={size} onChange={(val) => {setSize(val)}} min={5} step={5} />
            </Group>
            </MediaQuery>
            <Space h="md" />
            <Divider />
            <Space h="md" />

                <div style={{overflowX: 'auto'}} >
                <Table fontSize='xs' style={{borderBottom: '1px solid #E9ECEF'}} >
                    <thead>
                        <tr>
                        <th style={{ width: 40 }}>
                            <Checkbox
                                onChange={toggleAll}
                                checked={selection.length === data.length}
                                indeterminate={selection.length > 0 && selection.length !== data.length}
                                transitionDuration={0}
                            />
                            </th>
                            <th>
                                #
                            </th>
                            <th>
                                Title
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Phone
                            </th>
                            <th>
                                First Name
                            </th>
                            <th>
                                Last Name
                            </th>
                            <th>
                               Task Assignment Distribution
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </thead>
                        <tbody>
                            {data.length === 0 ?                             
                            <tr>
                                <td colSpan={8} >
                                    <Center>
                                        <Text>No data available</Text>
                                    </Center>
                                </td>
                            </tr> : (
                                rows
                            )}
                            </tbody>
                </Table>
                </div>
        </Paper>
    )
}