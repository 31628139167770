import { useLocalStorage } from "@mantine/hooks"
import { useReducer } from "react";
import { NavbarContext } from "./navbar.context";

export const NavbarProvider = ({ children }) => {
    const [opened, setOpened] = useLocalStorage({
        key: "navbar-state",
        defaultValue: true,
        getInitialValueInEffect: true
    });

    const reducer = (state, action) => {
        switch(action.type){
            case 'TOGGLE':
                setOpened(!state.opened)
                return {
                    ...state,
                    opened: !state.opened
                };

            default:
                return state;
        }
    }

    const initialState = {
        opened: opened
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <NavbarContext.Provider value={{state, dispatch}}>
            {children}
        </NavbarContext.Provider>
    )
}