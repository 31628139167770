import { useState, useContext, useEffect } from 'react';
import {Navbar,NavLink,ScrollArea,Divider, Badge, Button } from '@mantine/core';
import {IconUser, IconLogout, IconHelp,IconHome,IconUsers, IconGitFork, IconAnalyze, IconActivity, IconSettings, IconRefreshAlert, IconAlertTriangle, IconFileReport, IconDeviceAnalytics, IconChartBar,} from '@tabler/icons';
import useStyles from "./navbar.style";
import { SidebarContext } from '../context/sidebar/sidebar.context';
import {  Link, useLocation } from 'react-router-dom';
import {  Adjustments, AdjustmentsHorizontal, Clock, Folders, StackPush} from 'tabler-icons-react';
import { AuthContext } from '../../../../App';
import { HEADER_HEIGHT } from '../header/header.style';
import { useViewportSize } from '@mantine/hooks';
import axios from '../../../../utils/axios';
import swal from "sweetalert";

export function NavbarSmall() {
    const { classes } = useStyles();
    const { state, } = useContext(SidebarContext);
    const { state: authState, dispatch:authDispatch } = useContext(AuthContext);
    const { height } = useViewportSize();
    const [opened, setOpened] = useState({
      label: "",
      opened: false
    });

    const [inventory, setInventory] = useState(0);

    const location = useLocation();

    const fetchData = () => {
      axios.post("/customers-meters/get-data", {
          parent: authState?.userData?._id
      }).then(function(res){
          if(res.status === 200){
              if(Array.isArray(res.data.data)){
                setInventory(res.data.data.length);
              } else {
                setInventory(0);
              }
          }
      }).catch(function(error){
          console.log(error);
      });
  }
  
  useEffect(() => {
      fetchData();
  }, [location])

    const links = [
      { icon: IconHome, label: 'Home', href: `/app/account-summary` },
      { icon: IconUsers, label: 'Customers', href: `/app/customers/customers-list`, links: [
        {label: "Summary", href: `/app/customers/summary`},
        {label: "Customers", href: `/app/customers/customers-list`},
        {label: "Connection Requests", href: `/app/customers/requests`},
      ]},
      { icon: IconAnalyze, label: 'Billing', href: `/app/billing/payments`, links:[
        {label: "Payments", href: `/app/billing/payments`},
        {label: "Invoices", href: `/app/billing/invoices`},
        {label: "Bill Manager", href: `/app/billing/mtd`},
        {label: "Histories", href: `/app/billing/histories`},
      ] },
      { icon: IconUser, label: 'Staff', href: `/app/staff`},
      { icon: IconRefreshAlert, label: 'Tasks', href: `/app/tasks`},
      { icon: IconChartBar, label:"Supply History", href: "/app/supply"},
      { icon: IconFileReport, label: 'Reports', href: `/app/reports`},
      //{ icon: IconReportAnalytics, label: 'NRW Spectrum', href: `/app/spectral-analysis` },
      { icon: IconDeviceAnalytics, label: 'Devices', href: `/app/devices`},
      { icon: IconGitFork, label: 'Configurations', href: '/app/configure/tarrifs', description: {label: "Sync water sources, infrastructures and other key inventory "}, links: [
        {label: "Water Tarrifs", href: `/app/configure/tarrifs`},
        {label: "Billing Methods", href: `/app/configure/billing-methods`},
        {label: "Standing Charges", href: `/app/configure/standing-charges`},
        {label: "Invoices", href: `/app/configure/invoices`},
        {label: "Billing Cycle", href: `/app/configure/dates`},
        {label: "DMAs/Zones", href: `/app/configure/dma`},
        {label: "SMS & Triggers", href: `/app/configure/sms`},
        {label: "Other Services", href: `/app/configure/services`},
        {label: "Disconnection", href: "/app/configure/disconnection"},
        {label: "Defaulting", href: "/app/configure/invoice-defaulting"},
        {label: "Sewer Tarrifs", href: `/app/configure/sewer-tarrifs`},
        {label: "Recurring Tasks", href: `/app/configure/recurring-tasks`},
        //{label: "Infrastructure", href: `/app/configure/infrastructure#${authState.userData._id}`},
      ] },
      { icon: IconAlertTriangle, label: 'Incidents', href: `/app/incidents`, notifications: 0 },
      { icon: IconActivity, label: 'Logs', href: `/app/activity-log`, },
      //{ icon: IconTool, label: 'NRW Flow Balance', href: `/app/${authState.userData._id}/production` },
      { icon: IconHelp, label: 'Support', href: `/app/support`, },
      { icon: IconSettings, label: 'Settings', href: `/app/settings/profile`, },
    ];

    const itemLinksLight = links.map((link, idx) => {
      const hasLinks = Array.isArray(link.links);
      return(
        hasLinks ? (
          <NavLink variant='filled' opened={(opened.opened && opened.label === link.label) || location.pathname.includes(link.href)} onClick={() => {opened.opened && opened.label === link.label ? setOpened({label: "", opened: false}) : setOpened({opened: true, label: link.label})}} active={window.location.href.includes(link.href)} component={Link} to={link.href} key={idx} label={link.label} icon={<link.icon size={18}/>}
          >
            {link.links.map((itm) => {
              return (
                <NavLink
                component={Link}
                active={window.location.href.includes(itm.href)}
                to={itm.href}
                key={itm.label}
                label={itm.label}
                rightSection={itm.label === "Histories" ? (
                  <Badge style={{textTransform: "none"}} size='xs'>New</Badge>
                ) : null}
                />
              )
            })}
          </NavLink>
        ) : (
          <NavLink
          component={Link}
          active={window.location.href.includes(link.href)}
          to={link.href}
          description={link.description?.label}
          label={link.label}
          icon={<link.icon size={18} />}
          key={link.label}
          variant='filled'
          onClick={() => {setOpened({opened: false, label: ""})}}
          />
        )
      )
    })

    const chargeLateFee = () => {
      swal({
        title: "Warning",
        text: "Are you sure you want to charge additional invoice to your customers?",
        buttons: {
            cancel: "Cancel",
            continue: {
                text: "Charge Late Fee",
                value: "cont"
            }
        }
    }).then(async (val) => {
        if(val === "cont"){
          axios.post("/custom-scripts//kiarutara-defaulting").then(function(res){
            if(res.status === 200){
              console.log("done")
            }
          }).catch(function(err){
            console.log(err);
          })
        }
    })
    }

    return (
      <Navbar zIndex={1} hiddenBreakpoint="sm" height={height - HEADER_HEIGHT} hidden={state.opened} width={{ sm: state.width }} sx={(theme) => ({[theme.fn.smallerThan('sm')]: { display: 'none' }, height: '100%', })} p="md" className={classes.navbar}> 
        <Navbar.Section mt={-15} grow component={ScrollArea} sx={(theme) => ({ [theme.fn.smallerThan('sm')]: { display: 'none' }, height: '100%' })} className={classes.section}>
        <div className={classes.mainLinks}>{itemLinksLight}</div>
        </Navbar.Section>
        <Navbar.Section style={{bottom: 10, marginBottom: 20}} className={classes.lastSection}>
        <Divider mb={10} mr={10} ml={10} />

        {authState.userData._id === "665d721262d3bb2e570735b4" ? (
                    <NavLink
                    onClick={() => {chargeLateFee()}}
                    label="Charge Late Fee"
                    icon={<Clock size={18} />}
                    rightSection={
                      <IconAlertTriangle color="red" />
                    }
                    />
          ) : null}
        <NavLink
          component={Link}
          to={'/app/inventory-mapper'}
          label="Inventory Mapper"
          icon={<StackPush size={18} />}
          rightSection={
            <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
              {inventory > 9 ? "9+" : inventory}
            </Badge>
          }
          />
        <NavLink
          component={Link}
          to={'/app/infrastructure'}
          label="Utility Assets"
          icon={<Folders size={18} />}
          />

          <NavLink
          component={Link}
          to={'/app/account-access'}
          label="Access"
          icon={<AdjustmentsHorizontal size={18} />}
          active={location.pathname === "/app/account-access"}
          variant='filled'
          rightSection={<Badge>Beta</Badge>}
          />

          <NavLink
          component={Link}
          to={'/app/mfa'}
          label="2FA"
          icon={<Adjustments size={18} />}
          active={location.pathname === "/app/mfa"}
          variant='filled'
          rightSection={<Badge>New</Badge>}
          />

          <NavLink
            onClick={() => {authDispatch({type: "SIGN_OUT"})}}
          label="Sign Out"
          icon={<IconLogout color="red" size={18} />}
          />

        </Navbar.Section>
  
      </Navbar>
    );
  }
