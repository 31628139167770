import { useLocalStorage } from "@mantine/hooks";
import React, { useReducer } from "react";
import { ThemeContext } from "./theme.context";
import { THEME_KEY } from "../../../../../App";

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useLocalStorage({
        key: THEME_KEY,
        defaultValue: "light",
        getInitialValueInEffect: true
    });

      const reducer = (state, action) => {
        switch(action.type) {
            case 'DARK':
                setTheme("dark");
                return {
                    ...state,
                    theme: "dark"
                };
                
            case 'LIGHT':
                setTheme("light")
                return {
                    ...state,
                    theme: "light"
                };
    
            default:
                return state;
        }
    }

    const initialState = {
        theme: theme
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <ThemeContext.Provider value={{state, dispatch}}>
            {children}
        </ThemeContext.Provider>
    )
}