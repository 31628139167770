import { ActionIcon, Anchor, Button, Center, Container, CopyButton, Grid, Group, Input, List, Loader, NumberInput, Paper, Stack, Switch, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import VerifyImage from "../../assets/authentication.png";
import { Link } from "react-router-dom";
import OTPInput from "otp-input-react";
import { Copy } from "tabler-icons-react";
import axios from "../../utils/axios";
import toast from "react-hot-toast";

export default function MFA(){
    const { state, dispatch } = useContext(AuthContext);

    const { height } = useViewportSize();

    const [secret, setSecret] = useState("");

    const [qr_url, setQRURL] = useState("");

    const [OTP, setOTP] = useState("");

    const [loading, setLoading] = useState(false);

    const [active, setActive] = useState(false);

    useEffect(() => {
        if(!state.userData?.mfa?.enabled && qr_url === "" && secret === ""){
            axios.post("/mfa/enable", {}, {
                headers: {
                    'Authorization': `Bearer ${state.userToken}`
                }
            }).then(function(res){
                if(res.status === 200){
                    let obj = res.data.data;
    
                    setSecret(obj?.secret);
                    setQRURL(obj?.qrCodeURL);
                }
            }).catch(function(err){
                console.log(err);
            })
        }
    }, [state, qr_url, secret]);

    useEffect(() => {
        if(OTP.length === 6){
            setActive(true);
        } else {
            setActive(false)
        }
    }, [OTP])

    const verify = () => {
        setLoading(true);
        // verify
        axios.post("/mfa/verify", {
            otp_token: OTP
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                setLoading(false);
                toast.success("2FA Authentication succeeded. You will need to login again... signing out...");
                dispatch({type: 'SIGN_OUT'})
            }
        }).catch(function(err){
            toast.error("2FA Authentication failed");
            setLoading(false);
        })
    }
    return (
        <>
            <Helmet>
                <title>2FA | Emita</title>
            </Helmet>

            <Paper p="xl" radius={"md"}>
                {state.userData?.mfa?.enabled ? (
                    <Stack justify="center" align="center" h={(height - HEADER_HEIGHT)*0.8}>
                        <img src={VerifyImage} className="h-36 w-auto mb-2" />
                        <Title align="center" order={5}>
                            2FA
                        </Title>

                        <Text mt={10} mb={20} align="center">
                        2FA has already been enabled for this account. To reverse this action, please create a <Anchor component={Link} to={"/app/support"}>support ticket</Anchor> <br />. About 2fa, <Anchor href="#" target="_blank">Learn more</Anchor>
                        
                        </Text>

                        <Button radius={"xl"} component={Link} to={"/app/"}>&larr; {' '}Navigate to Dashboard</Button>
                    </Stack>
                ) : (
                    <Stack mb={50} h={(height - HEADER_HEIGHT)*0.8}>

                        <Group noWrap>
                            <div>
                            <Title mb={20} order={4}>Enable 2FA</Title>
                            <Text fw='600'>
                            1. You will need an authenticator mobile app to complete this process, such as one of the following:
                        </Text>
                            <div className="p-4">
                            <Anchor component={Text} target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US">1. Google Authenticator</Anchor> <br />
                        <Anchor component={Text} target="_blank" href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en">2. Twilio Authy</Anchor> <br />
                        <Anchor component={Text} target="_blank" href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en">3. Microsoft Authenticator</Anchor>
                            </div>
                            </div>

                    
                            <img src={qr_url} className="h-64 w-auto" />
                            
                        </Group>

        
                            <TextInput label="2. Scan the QR Code above with your authenticator app" description=" If you cannot scan the QR Code, you can manually enter this secret key into your authenticator app."
                             mb={10} readOnly value={secret} rightSectionWidth={80} rightSection={
                                <CopyButton value={secret}>
                                {({ copied, copy }) => (
                                    <Button leftIcon={<Copy size={13} />} size="xs" radius={"lg"} color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Copied' : 'Copy'}
                                    </Button>
                                )}
                                </CopyButton>
                             } />
                            <Text fw='600' mb={10}>
                                3. After scanning the QR Code above, enter the six-digit code generated by the authenticator app.
                            </Text>

                            <Group mb={30} noWrap>
                            <OTPInput inputStyles={{width: 54, color: "#000000"}} value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={loading} />
                            <Button disabled={!active} loading={loading} onClick={() => {verify()}}>Verify Token</Button>
                            </Group>
                    </Stack>
                )}
            </Paper>
        </>
    )
}