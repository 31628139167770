import { Center, Grid, Text, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";

export default function WorkOrder(){
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    return (
        <Grid>
            <Grid.Col sm={12} lg={12}>            
                <Center mt={"15%"}>
                    <img src={require("../../assets/waiting_hourglass.png")} />
                </Center>

            <Text align="center" mt={30}>Dispatched work orders will appear here.</Text>
</Grid.Col>
        </Grid>
    )
}