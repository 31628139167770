import { ActionIcon, Button, Center, Table, Group, NumberInput, Paper, TextInput, Title, Tooltip, Menu, useMantineTheme } from "@mantine/core";
import { CirclePlus, Dots, Edit, FileExport, FileImport, Plus, Trash } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import { toast } from "react-hot-toast";
import { Alert } from "@aws-amplify/ui-react";
import swal from "sweetalert";
import ReactGA from "react-ga4";

export default function SewerTarrif(){
    const [tarrifs, setTarrifs] = useState([]);
    const [refresh, setRefresh] = useState([]);
    const { state, dispatch } = useContext(AuthContext);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchData = () => {
        setTarrifs([]);
        
        try{
            const body = {
                parent: state.userData._id
            };

            axios.post("/sewer-tarrifs/gettarrifs", body).then(({data: {data: tarrifs}, }) => {
                setTarrifs(tarrifs);

            })
        } catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [refresh]);

    const deleteTarrif = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to continue?",
            icon: "warning",
            buttons: {
                cancel: 'No, Cancel Please!',
                continue: {
                    text: "Yes, I'm sure about this!",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                const body = {
                    id: id
                };
    
                axios.post("/sewer-tarrifs/deletetarrif", body).then(function(res){
                    toast.success("The sewer tarrif has been removed permanently.")
                    setRefresh(!refresh)
                }).catch(function(error){
                    toast.error(error.message);
                })
            }
        })
    }

    const TarrifsList = ({tarrifs}) => {
        return (
                tarrifs.map((item, index) => {
                    return (
                        <tr key={`sewer-tarrif-${index}`} >
                            <td>
                                {item.tarrif}
                            </td>
                            <td>
                                {item.has_blocks ? (
                                    <Table fontSize="xs" withColumnBorders striped>
                                        <thead>
                                            <tr>
                                                <th>Lower</th>
                                                <th>Upper</th>
                                                <th>Cost</th>
                                                <th>Flat Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.blocks.map((item, index) => {
                                                return (
                                                    <tr key={`sewer-block-${index}`}>
                                                        <td>{item.lower}</td>
                                                        <td>{item.upper}</td>
                                                        <td>{item.cost}</td>
                                                        <td>{item.flatRate ? "Yes" : "No"}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <Table fontSize="xs" withColumnBorders striped>
                                        <thead>
                                            <tr>
                                                <th>Cost/Unit</th>
                                                <th>Flat Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{item.blocks[0].cost}</td>
                                                <td>Yes</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </td>
                            <td>
                                {item.percentage}
                            </td>
                            <td>{new Date(item.createdAt).toDateString()}</td>
                            <td>{new Date(item.updatedAt).toDateString()}</td>
                            <td>
                                <Menu >
                                    <Menu.Target>
                                        <ActionIcon size="xs" >
                                            <Dots />
                                        </ActionIcon>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item icon={<Edit size={13} />}>Edit</Menu.Item>
                                        <Menu.Item onClick={() => {deleteTarrif(item._id)}} icon={<Trash size={13} />}>Delete</Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                            </td>
                        </tr>
                    )
                })
    
        )
    }

    const theme = useMantineTheme();

    return (
        <Paper p="md">
            <Title weight={300}>Sewer Tarrifs</Title>

            <Group mb={20} position="right">
                <Button variant="default" component={Link} to='/app/configure/sewer-tarrifs/create-sewer-tarrif' leftIcon={<CirclePlus />}>New Sewer Tarrif</Button>
            </Group>

            <div style={{overflowX: 'auto', marginTop: 20}}>
                <Table size="xs" style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th><Center>Blocks</Center></th>
                        <th>Percentage</th>
                        <th>Configured on</th>
                        <th>Last Modified</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {tarrifs.length > 0 ? (
                            <TarrifsList tarrifs={tarrifs} />
                        ): (
                        <tr>
                            <td colSpan={6}>
                                <Center>No data found</Center>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </Paper>
    )
}