import { Alert, Button, Center, Grid, Stack, Table, Tabs, Text, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { IconInfoCircle } from "@tabler/icons";

export default function Reports(){
    const theme = useMantineTheme();
    const { state, dispatch } = useContext(AuthContext);
    const { height, width } = useViewportSize();
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);

    const splitURL = (url) => {
        let parts = url.split("/");
        return parts[parts.length - 1];
    }

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }
        axios.get('/reports/reports', config).then(function(res){
            if(res.status === 200){
                setReports(res.data.data);
                setLoading(false);
            }
        }).catch(function(error){
            console.log(error);
            setLoading(false);
        });
    }, []);
    return (
        <>
        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />} title="Reports">
        Emita provides scheduled reports, both for third-party consumption as well as the general performance of a utility. Third-party users of KPI reports can link their system with Emita to pull data at their own time preference, but at your own consent.
        </Alert>

        <Tabs defaultValue={"summarized"}>
            <Tabs.List>
                <Tabs.Tab value="summarized">
                    Summarized Reports
                </Tabs.Tab>

                <Tabs.Tab value="kpi">
                    KPI Reports
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="summarized">
            <Grid>
            <Grid.Col sm={12} lg={12}>            
            {reports.length === 0 && !loading ? (
                <>
                                <Center mt={"15%"}>
                    <img src={require("../../assets/waiting_hourglass.png")} />
                </Center>

            <Text align="center" mt={30}>Reports once ready will appear here.</Text>
                </>
            ) : reports.length === 0 && loading ? (
                <Center mt={"15%"}>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </Center>
            ) : (
                <Table fontSize={"xs"} striped>
                    <thead>
                        <tr>
                            <th>Report</th>
                            <th>Period</th>
                            <th>Category</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((item, idx) => {
                            return (
                                <tr key={`report-${idx}`}>
                                    <td>
                                        <a href={item.path}>/{splitURL(item.path)}</a>
                                    </td>
                                    <td>{item.reportFor}</td>
                                    <td>{`${item.category} Report`}</td>
                                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        <Button component="a" href={item.path} size="xs">Download</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            )}
</Grid.Col>
        </Grid>
            </Tabs.Panel>

            <Tabs.Panel value="kpi">
            <Stack justify="center" h={(height - HEADER_HEIGHT) * 0.85}>
            <Center>
            <img src={require("../../assets/waiting_hourglass.png")} />
            </Center>
            <Text align="center" mt={20}>KPI reports will appear here.</Text>
        </Stack>
            </Tabs.Panel>
        </Tabs>
        </>
    )
}