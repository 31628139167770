import {useContext, useCallback, useState } from 'react';
import { Container, UnstyledButton, Group, MediaQuery, Burger, Indicator, Menu, Button, ActionIcon, Autocomplete, Progress } from '@mantine/core';
import { Bell, Calendar, Coin, Settings, Wifi} from "tabler-icons-react";
import useStyles from './header.style';
import { SidebarContext } from '../context/sidebar/sidebar.context';
import { UserInfo } from '../userInfo';
import Comment from '../../../comments';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useViewportSize } from '@mantine/hooks';
import axios from '../../../../utils/axios';
import { AuthContext } from '../../../../App';
import { CompanyLogo } from '../companyLogo';
import { LanguagePicker } from '../../../internalization/LanguagePicker';
import { ToggleTheme } from '../../../theme';
import { getBalance } from '../../../../utils/sms_balance';
import { NavbarContext } from '../context/navbar/navbar.context';

export function Header({img}) {
  const { classes, theme} = useStyles();
  const { state, dispatch } = useContext(SidebarContext);
  const { state: navState, dispatch: navDispatch } = useContext(NavbarContext);
  const { state: authState, dispatch: authDispatch} = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const { height, width } = useViewportSize();

  const [visible, setVisible] = useState(false);

  const [sms_bal, setSMSBal] = useState("Loading...");

  useEffect(() => {
    axios.get("/sms-provider/configs", {headers: {'Authorization': `Bearer ${authState.userToken}`}}).then(async function(res){
      if(res.status === 200){

        let obj = res.data.data;

        if(obj?.name === "textsms"){
          var sms_bal = await getBalance(obj?.api_key, obj?.responseType);

          console.log(sms_bal);

        } else {
          setSMSBal("Unknown")
        }
      }
    }).catch(function(error){
      //toast.error("An issue occured while fetching your data...");
    })
  }, [location])

  useEffect(() => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, location.pathname === "/app" ? 20000 : location.pathname === "/app/payments" ? 10000 : 3000);

  }, [location.pathname])

const navigateFunc = (value) => {
  if(value !== ""){
    switch(value){
      case "Customers":
        navigate('/app/customers/customers-list');
        break;
      case "Payments":
        navigate('/app/payments');
        break;
      case "Tasks":
        navigate("/app/tasks");
        break;
      case "Tarrifs":
        navigate("/app/configure/tarrifs");
        break;
      default:
        // do nothing

    }
  }
}

  useEffect(() => {
    if(!state.opened){
      dispatch({
        type: 'TOGGLE',
      });
    }
  }, [location.pathname])

  const toggleHandler = useCallback(() => {
    dispatch({
      type: 'TOGGLE',
    });
  }, [dispatch]);

  // notifications handler
  useEffect(() => {
    const fetchNotifications = () => {
      axios.post("/notifications/get", {
        username: authState.userData.username
      }).then(function(res){
        if(res.status === 200){
          let data = res.data.data;
  
          setAllNotifications(data.reverse()); // reverse the array to get the most recent appear first
  
          let arr = [];
  
          for(let i=0; i<data.length; i++){
            let item = data[i];
  
            if(!item.viewed){
              arr.push(item);
            }
          }
  
          setNotifications(arr);
  
          if(data.length === 0){
            axios.post("/notifications/create", {
              username: authState.userData.username,
              content: "Get started by configurring your staff, customers, DMAs, infrastructure etc.",
              title: `${authState.userData.waterServiceName}, welcome to E-mita`,
              category: 'Greetings',
              link: "/app/onboarding?step=infrastructure&category=zones"
            }).then(function(res){
              if(res.status === 200){
                setRefresh(!refresh);
              }
            })
          }
        } 
      }).catch(function(error){
        //do something with this error.
        // TODO: Create logs event and a monitoring system.
  
      })
    }

    fetchNotifications()
  }, [refresh]);

  const notificationHandler = () => {
    for(let i=0; i<notifications.length; i++){
      axios.post("/notifications/update", {
        id: notifications[i]._id,
        update: {viewed: true}
      }).then(function(res){
        if(res.status === 200){
          // do something
          setRefresh(!refresh)
        }
      }).catch(function(error){
        // do something with the error
      })
    }
  }

  return (
    <>
    <div className={classes.header} >
      <Container size={width} px="md" className={classes.inner}>
        <Group noWrap style={{marginRight: 'auto',}} >
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={!state.opened}
                onClick={toggleHandler}
                size="sm"
                color="#868E96"
                mr="xl"
              />
            </MediaQuery>

            <Group position='apart' noWrap sx={(theme) => ({marginLeft: 20})} >
          <CompanyLogo />

          </Group>
          <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <ToggleTheme />
          </MediaQuery>
          <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <LanguagePicker />
          </MediaQuery>
          <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Autocomplete placeholder="Navigate to..." data={["Customers", "Payments", "Tasks", "Tarrifs"]} dropdownPosition="flip" onChange={(val) => {navigateFunc(val)}} style={{minWidth: 230, marginRight: 20}} />
          </MediaQuery>
        </Group>

        <Group style={{marginLeft: 'auto',}} noWrap spacing="xs" >
          <MediaQuery smallerThan="md" styles={{display: 'none'}}>
              <Menu trigger='hover'>
                <Menu.Target>
                <Button>
                Apps & Resources
              </Button>
                </Menu.Target>

                <Menu.Dropdown>
                <Menu.Label>Mobile Applications</Menu.Label>
                <Menu.Label>Latest</Menu.Label>
                <Menu.Item component={"a"} href="/mobile-apps/v3/emitafield.apk" download="Emita Field" >Emita Field -  Onboard more customers, map your infrastructure assets etc</Menu.Item>

                <Menu.Item component="a" href="/mobile-apps/v1/emitabilling.apk" download={"Emita Billing"}>Emita Billing - For meter reading and billing.</Menu.Item>
                <Menu.Label>Resources</Menu.Label>
              </Menu.Dropdown>
              </Menu>
                </MediaQuery>
            <Menu trigger='hover' closeOnClickOutside closeOnEscape closeOnItemClick onClose={() => {notificationHandler()}} >
              <Menu.Target>
              {notifications.length === 0 ? (
                <Indicator title="Notifications" label={0} mt={10} inline size={17}>
                  <Bell strokeWidth={1} size={30} />
                </Indicator>
              ) : (
                <Indicator title="Notifications" label={notifications.length} mt={10} inline size={17}>
                <Bell strokeWidth={1} size={30} />
                  </Indicator>
              )}
              </Menu.Target>
              <Menu.Dropdown>
                <div style={{maxHeight: height -200, overflowY: 'auto'}} className='scroll'>
                {notifications.length > 0 ? (
                    <Menu.Label>You have {notifications.length} new {notifications.length > 1 ? "notifications" : "notification"}</Menu.Label>
                ) : null}
                {allNotifications.map((item, index) => {
                  return (
                    <Menu.Item key={`comment-${index}`} component={Link} to={item.link} >
                    <UnstyledButton mb={10}>
                    <Comment postedAt={new Date(item.createdAt).toLocaleDateString()} body={item.content} title={item.title} recent={item.viewed ? false : true} image={item.image} />
                    </UnstyledButton>
                    </Menu.Item>
                  )
                })}
                </div>
              </Menu.Dropdown>
            </Menu>

            <UserInfo/>
                  </Group>
      </Container>
      {visible ? (
              <div className='w-full'>
              <div className='h-0.5 w-full bg-blue-100 overflow-hidden'>
                <div className='progress w-full h-full bg-blue-600 left-right'></div>
              </div>
        </div>
      ) : null}
    </div>
    </>
  );
}