import { useContext } from "react"
import { AuthContext } from "../../App"
import { Helmet } from "react-helmet";

export default function EmitaBilling(){
    const { state, dispatch } = useContext(AuthContext);

    return (
        <>
        <Helmet>
            <title>Emita Billing | {state.userData.waterServiceName}</title>
        </Helmet>
        </>
    )
}