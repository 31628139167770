import { Avatar, Button, Divider, Grid, Group, Indicator, NavLink, Paper, SimpleGrid, Space, Text, Title, useMantineTheme } from "@mantine/core";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import { Heading } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { IconBell, IconChecklist, IconCreditCard, IconLogout, IconMail, IconPencil, IconSatellite, IconSettings, IconShieldCheck, IconUser, IconWifi } from "@tabler/icons";
import textClip from "../../utils/textClip";
import { splitText } from "../../utils/splitText";
import { Edit } from "tabler-icons-react";
import ReactGA from "react-ga4";

export default function AppSettings(){
    const { state, dispatch } = useContext(AuthContext);
    const theme = useMantineTheme();

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Account Settings</title>
        </Helmet>
        <Grid>
            <Grid.Col sm={12} lg={3} style={{borderRight: theme.colorScheme === "dark" ? `2px solid ${theme.colors.dark[6]}` : `2px solid ${theme.colors.gray[4]}`}}>
            <Paper p="md" withBorder radius={20}>
                <Group mb={20}>
                <Indicator inline size={16} offset={7} position="bottom-end" color={state.userData.verified ? "#00ED64" : "red"} withBorder>
                    <Avatar src={state.userData.avatar} radius="xl" />
                </Indicator>
                <div>
                    <Text size="sm" style={{fontWeight: 550}}>{state.userData.waterServiceName}</Text>
                </div>
                </Group>

                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/profile")}
                styles={{root: {borderRadius: 20}}}
                to={"/app/settings/profile"}
                label="Public Profile"
                icon={<IconUser strokeWidth={1} size={18} />}
                />

                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/admin")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Account"
                icon={<IconSettings strokeWidth={1} size={18} />}
                />

                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/appearance")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Appearance"
                icon={<IconPencil strokeWidth={1} size={18} />}
                />

                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/notifications")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Notifications"
                icon={<IconBell strokeWidth={1} size={18} />}
                />

                <Divider mt={20} mb={20} />
                <Text size="xs" color="dimmed">Access</Text>

                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/billing")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Billing and plans"
                icon={<IconCreditCard strokeWidth={1} size={18} />}
                />
                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/emails")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Emails"
                icon={<IconMail strokeWidth={1} size={18} />}
                />
                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/security")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Password and Authentication"
                icon={<IconShieldCheck strokeWidth={1} size={18} />}
                />
                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/sessions")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Sessions"
                icon={<IconSatellite strokeWidth={1} size={18} />}
                />

                <Divider mt={20} mb={20} />
                <Text size="xs" color="dimmed">Tasks, planning and automation</Text>

                <NavLink
                component={Link}
                active={window.location.href.includes("/app/settings/meter_readings")}
                styles={{root: {borderRadius: 20}}}
                to="#"
                label="Meter readings"
                icon={<IconChecklist strokeWidth={1} size={18} />}
                />
                <Divider mt={20} mb={20} />
                <NavLink
                styles={{root: {borderRadius: 20}}}
                label="Sign Out"
                onClick={() => {dispatch({type: "SIGN_OUT"})}}
                icon={<IconLogout strokeWidth={1} size={18} />}
                />
                </Paper>
            </Grid.Col>

            <Grid.Col sm={12} lg={9}>
            <Text style={{fontWeight: 500}} mb={20}>Profile</Text>
                <Paper withBorder p="md" radius={20} mb={10}>
                <Group position="apart">
                <Group position="left">
                <Indicator inline size={16} offset={7} position="bottom-end" color={state.userData.verified ? "#00ED64" : "red"} withBorder>
                    <Avatar src={state.userData.avatar} radius="xl" />
            </Indicator>
            <div>
                    <Group spacing={2}><Text>{state.userData.waterServiceName}</Text></Group>
                    <Text size="xs">{state.userData.verified ? "Active" : "Inactive"}</Text>
                </div>
                </Group>

                <Group position="right">
                    <Button size="sm" variant="default" rightIcon={<Edit size={13} />} radius={20}>Edit</Button>
                </Group>
                </Group>
                </Paper>
                <Paper p="md" withBorder radius={20} mb={10}>
                    <Group position="apart">
                        <Text style={{fontWeight: 500}}>Utility Information</Text>
                        <Button size="sm" variant="default" rightIcon={<Edit size={13} />} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Email Address</Text>
                            <Text size="sm">{state.userData?.username}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Phone</Text>
                            <Text size="sm">{state.userData?.phonenumber}</Text>
                        </div>
                    </SimpleGrid>
                </Paper>

                <Paper p="md" withBorder radius={20} mb={10}>
                    <Group position="apart">
                        <Text style={{fontWeight: 500}}>Utility Address</Text>
                        <Button size="sm" variant="default" rightIcon={<Edit size={13} />} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Address</Text>
                            <Text size="sm">{state.userData?.address}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Zip Code</Text>
                            <Text size="sm">00200</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">County</Text>
                            <Text size="sm">{typeof(state.userData.county) === "object" ? state.userData?.county[0] : state.userData?.county}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Country</Text>
                            <Text size="sm">{state.userData?.country}</Text>
                        </div>
                    </SimpleGrid>
                </Paper>

            </Grid.Col>
        </Grid>
        </>
    )
}