// worker.js

export default () => {
    self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
        if(!e) return;

        const customers = e.data.rawdata;

        const name= e.data.name;

        if(!Array.isArray(customers)){
            return;
        }

        const filteredCustomers = customers.filter((item) => {
            if(item.name.toLowerCase().includes(name) || item.username.toLowerCase().includes(name) || item.account.toLowerCase().includes(name) || item.dma.toLowerCase().includes(name)){
                return item
            }

        });

        postMessage(filteredCustomers);
    })
}