import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  createStyles,
  Menu,
  Divider,
  Anchor,
  Space,
  Indicator,
} from '@mantine/core';
import {  useLocalStorage } from '@mantine/hooks';
import {  IconChevronRight } from '@tabler/icons';
import { useContext } from 'react';
import { Link } from "react-router-dom";
import { AuthContext } from '../../../App';
import textClip from '../../../utils/textClip';
import { COLORS } from '../../../constants';

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor: "transparent"
    },
  },
}));

export function UserInfo({ image, name, email, icon, ...others }) {
  const { classes, theme } = useStyles();
  const { state, dispatch } = useContext(AuthContext);

  const [color, setColor] = useLocalStorage({
    key: COLORS,
    defaultValue: "blue",
    getInitialValueInEffect: true
  })

  return (
    <Group position='center'>
    <UnstyledButton title={!state.userData.verified ? "Your account is not yet verified." : "You are using the Emita premium version"} className={classes.user} {...others} component={Link} to={`/app/settings/profile`} >
      <Group noWrap spacing={3} sx={(theme) => ({ [theme.fn.smallerThan('lg')]: { display: 'none' } })} >
      <Indicator inline size={16} offset={7} position="bottom-end" color={state.userData.verified ? "#00ED64" : "red"} withBorder>
      <Avatar variant='filled' radius="xl" color={color} >{state.userData.waterServiceName[0].toUpperCase()}</Avatar>
      </Indicator>
        <div style={{ flex: 1 }}>
          <Text inline lineClamp={1} size="sm" weight={750}>
            {state.userData.waterServiceName.charAt(0).toUpperCase() + state.userData.waterServiceName.slice(1).toLowerCase()}
          </Text>
        </div>

        {icon || <IconChevronRight size={14} stroke={1.5} />}
      </Group>
      <Group sx={(theme) => ({ [theme.fn.largerThan('md')]: { display: 'none' } })} >
      <Indicator inline size={16} offset={7} position="bottom-end" color={state.userData.verified ? "#00ED64" : "red"} withBorder>
        <Avatar variant='filled' radius="xl" color={color} >{state.userData.waterServiceName[0].toUpperCase() + state.userData.waterServiceName[1].toUpperCase()}</Avatar>
      </Indicator>
      </Group>
    </UnstyledButton>
    </Group>
  );
}