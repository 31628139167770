import AppShellLayoutLayout from './appshell';
import { HEADER_HEIGHT } from './header/header.style';
import { SidebarProvider } from './context/sidebar/sidebar.provider';
import { Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { useLocation} from 'react-router-dom';
import { SearchbarProvider } from './context/searchbar/searchbar.provider';
import { SystemProvider } from './context/system/system.provider';
import { useMantineTheme } from '@mantine/styles';
import { ThemeProvider } from './context/theme/theme.provider';
import { NavbarProvider } from './context/navbar/navbar.provider';

export function AppLayout({img}) {
  const location = useLocation();
  const theme = useMantineTheme();
  return (
      <SidebarProvider>
        <NavbarProvider>
          <SearchbarProvider>
            <SystemProvider>
              <ThemeProvider>
          <AppShellLayoutLayout img={img}>
          <main style={{ paddingTop: HEADER_HEIGHT  }}><Outlet /></main>
          </AppShellLayoutLayout>
          <Toaster toastOptions={{
              style: {
                borderRadius: '10px',
                background: theme.colorScheme === "dark" ? '#333' : "#fff",
                color: theme.colorScheme === "light" ? '#333' : "#fff",
              },
          }} />
          </ThemeProvider>
          </SystemProvider>
          </SearchbarProvider>
        </NavbarProvider>
        </SidebarProvider>
  );
}