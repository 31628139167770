import { Anchor, Box, Button, Center, Group, NumberInput, Paper, Title, useMantineTheme } from "@mantine/core";
import { IconAlertCircle, IconArrowLeft, IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Alert } from "@aws-amplify/ui-react";
import ReactGA from "react-ga4";

export default function CreateStandingCharge(){
    const { state, dispatch } = useContext(AuthContext);
    const [cost, setCost] = useState(0);
    const [success, setSuccess] = useState({success: false, message: ""});
    const [error, setError] = useState({error: false, message: ""})

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);
    
    useEffect(() => {
        axios.post("/standing-charges/get-standing-charge", {
            parent: state.userData._id
        }).then(function(res){
            if(res.status === 200){
                if(res.data.data.length > 0){
                    setSuccess({success: success, message: "You have already configured a standing charge. Please delete/edit it to manage it."})
                    setCost(res.data.data[0].cost)
                }
            }
        }).catch(function(error){
        })
    }, []);
    const createCharge = (e) => {
        e.preventDefault();

        axios.post("/standing-charges/create", {
            parent: state.userData._id,
            cost: cost
        }).then(function(res){
            if(res.status === 200){
                setSuccess({success: true, message: "The standing order has been created successfully. The amount configured will be added to customer's monthly water charges. You can always edit or delete the standing charge."})
            }
        }).catch(function(error){
            setError({error: true, message: error.message})
        })
    }

    const theme = useMantineTheme();

    return (
        <Paper p="md" >
        {success.success ? (
            <Alert marginTop={-10} marginLeft={-10} marginRight={-10} marginBottom={20} variation="success" hasIcon={true} isDismissible={false}>
                {success.message}
          </Alert>
        ) : null}

        {error.error ? (
            <Alert marginTop={-20} marginLeft={-20} marginRight={-10} marginBottom={20} variation="error" hasIcon={true} isDismissible={false}>
                {error.message}
          </Alert>
        ) : null}
            <Group position="apart" mb={20}>
                <Group position="left">
                    <Title order={4} weight={300}>Create Standing Charge</Title>
                </Group>
            </Group>

            <NumberInput disabled={error.error || success.success} value={cost} onChange={(val) => {setCost(val)}} hideControls step={1} min={0} size="xs" mb={10} label="Cost(KSH)" />

            <Anchor mr={30} component={Link} to={`/app/configure/standing-charges#${state.userData._id}`}>
                <Center inline>
                    <IconArrowLeft size={14} />
                    <Box ml={5}>Back to Standing Charges Page</Box>
                </Center>
            </Anchor>
            <Button disabled={cost <= 0 || error.error || success.success } mt={10} onClick={(e) => {createCharge(e)}} radius={28}>Add Standing Charge</Button>
        </Paper>
    )
}