import { Alert, Center, Grid, Text, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons";

export default function SpectralAnalysis(){
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    return (
        <Grid>
            <Grid.Col sm={12} lg={12}>     
            <Alert mt={-20} ml={-20} mb={10} mr={-15} icon={<IconInfoCircle />}>
              Spectral analysis is Emita's advanced method for detecting sources of NRW in the pipeline networks. By using advanced techniques and remote sensing, Emita routinely monitors your pipeline networks to detect underground leakages.
        </Alert>       
                <Center mt={"10%"}>
                    <img src={require("../../assets/waiting_hourglass.png")} />
                </Center>

            <Text align="center" mt={30}>Once an updated imagery lands into our server, your spectral analysis results will appear here.</Text>
</Grid.Col>
        </Grid>
    )
}