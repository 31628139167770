import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import { Heading} from "@aws-amplify/ui-react";
import { useMantineTheme } from "@mantine/styles";
import { Accordion, Divider, Grid, Group, MediaQuery, Paper, Radio, Select, Switch, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools, faGear, faSync, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { IconChevronDown } from "@tabler/icons";
import axios from "../../utils/axios";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
  } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { getMonth, getMonthAsNums, getYear} from "../../utils/getMonth";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  );
  
  const options = {
    responsive: true,
    maintainAspectRation: false,
    borderWidth: 2,
    pointRadius: 0,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
  
      title: {
        display: true,
        text: 'Water consumption trend',
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  };

export default function Consumption(){
    const { state, dispatch} = useContext(AuthContext);
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    const [dmas, setDMAS] = useState([]);
    const [consumption, setConsumption] = useState([]);
    const [jan, setJan] = useState(0);
    const [feb, setFeb] = useState(0);
    const [mar, setMar] = useState(0);
    const [apr, setApr] = useState(0);
    const [may, setMay] = useState(0);
    const [jun, setJun] = useState(0);
    const [jul, setJul] = useState(0);
    const [aug, setAug] = useState(0);
    const [sep, setSep] = useState(0);
    const [oct, setOct] = useState(0);
    const [nov, setNov] = useState(0);
    const [dec, setDec] = useState(0);
    const [jann, setJann] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState(null);

    const months = getMonthAsNums();
    let years = getYear();

    
    useEffect(() => {
        setLoading(true);
        axios.post("/consumption/get", {
          parent: state.userData._id
        }).then(function(res){
          if(res.status === 200){
            setConsumption(res.data.data)
          }
        }).catch(function(error){
          //toast.error("Something wrong is happening!");
          console.log(error);
          setLoading(false);
        })
    }, []);

  useEffect(() => {
    setJan(0);
    setFeb(0);
    setMar(0);
    setApr(0);
    setMay(0);
    setJun(0);
    setJul(0);
    setAug(0);
    setSep(0);
    setOct(0);
    setNov(0);
    setDec(0);
    setJann(0);

    let startsOn = dates?.starts;
    let duration = dates?.duration;
    let today = new Date();

  const funcA = async() => {
    for(let i=0; i<months.length; i++){
      for(let k=0; k<years.length; k++){
        if(i === k){
          let month = months[i] - 1;
          let year = years[k];

          today.setFullYear(year);
          today.setMonth(month);
          today.setDate(startsOn);
          today.setHours(0);
          today.setMinutes(0);
          today.setSeconds(0); // 0000hrs

          let endsIn = new Date(today.getTime() + (duration * 24 * 60 *60 * 1000));
  
          let filteredData = consumption.filter((obj) => (new Date(obj.createdAt) >= today) && (new Date(obj.createdAt) <= endsIn));
  
          if(filteredData.length > 0){
  
            let cons = filteredData.reduce((sum, obj) => sum + (obj.currReading - obj.prevReading), 0);
  
            switch(i){
              case 0:
                setJan(cons);
                break;
              case 1:
                setFeb(cons);
                break;
              case 2:
                setMar(cons);
                break;
              case 3:
                setApr(cons);
                break;
              case 4:
                setMay(cons);
                break;
              case 5:
                setJun(cons);
                break;
              case 6:
                setJul(cons);
                break;
              case 7:
                setAug(cons);
                break;
              case 8:
                setSep(cons);
                break;
              case 9:
                setOct(cons);
                break;
              case 10:
                setNov(cons);
                break;
              case 11:
                setDec(cons);
                break;
              case 12:
                setJann(cons); 
                break;
              default:
                // handle default
            }
          }
        }
      }
    }

    setLoading(false);
  
  }
  
  if(consumption.length > 0){
    funcA();
  } 
  
  }, [consumption]);

    const fetchDMAs = () => {
        setDMAS([]);
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                let arr = [{label:"All Zones", value: "all"}];

                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];
                    arr.push({label: item.name, value: item.name});
                    //setDMAS(prevArr => ([...prevArr, item.name]));
                }

                setDMAS(arr);
                
            }
        }).catch(function(error){
            console.warn(error.message);
        })
    }

    useEffect(() => {
        fetchDMAs();
    }, []);

    const labels = getMonth(12);

    const data = {
        labels,
        datasets: [
          {
            fill: true,
            lineTension: 0.4,
            label: 'Consumption',
            data: [jan, feb,mar, apr, may, jun, jul,aug, sep, oct, nov, dec, jann],
            borderColor: theme.colors.blue[9],
            backgroundColor: theme.colors.blue[9],
          },
        ],
      };

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Consumption</title>
        </Helmet>
        <Heading level={3} fontWeight={650} marginBottom={30} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Consumption</Heading>
        <Text mb={50}>Inspect and analyze real-time water consumption for your utility.</Text>

        <Divider ml={-10} mr={-10} />

        <Grid>
            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
            <Grid.Col mt={8} sm={12} lg={3} style={{height: (height - HEADER_HEIGHT - 150), borderRight: theme.colorScheme === "dark" ? `2px solid ${theme.colors.dark[6]}` : `2px solid ${theme.colors.gray[4]}`}}>
            <Heading fontWeight={500} marginBottom={20} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Filters</Heading>
            <Accordion chevronPosition="left">
                <Accordion.Item value="timeline" style={{borderBottom: 0}}>
                    <Accordion.Control>Timeline</Accordion.Control>
                    <Accordion.Panel>
                    <Select rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={[
                        {label: "Past 1 Day", value: "day"},
                        {label: "Past 1 Week", value: "week"},
                         {label: "Past 1 Month", value: "month"},
                         {label: "Past 1 Year", value: "year"},
                         {label: "Custom", value: "custom"}         
                    ]} value="year" />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Zone" style={{borderBottom: 0}}>
                    <Accordion.Control>District Metered Areas</Accordion.Control>
                    <Accordion.Panel>
                    <Select rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={dmas} value="all" />
                    </Accordion.Panel>
                </Accordion.Item>

            </Accordion>
            </Grid.Col>
            </MediaQuery>
            <Grid.Col sm={12} lg={9} style={{height: (height - HEADER_HEIGHT - 150)}}>      
                    {consumption.length === 0 ? (
                        <>
                        <Group position="center" mt="30%">
                        <FontAwesomeIcon icon={loading ? faSpinner : faTools} spin={loading} />
                        </Group>

                        <Text align="center" mt={30}>{loading ? "Loading your datasets..." : "No consumption data yet."}</Text>
                        </>
                    ) : (
                        <div style={{height: (height - HEADER_HEIGHT - 150), width: '100%'}}>
                            <Bar options={options} data={data} />   
                        </div>
                    )}
            </Grid.Col>
        </Grid>
        </>
    )
}