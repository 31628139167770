import { Button, NumberInput, Paper, } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Alert } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function CreateDate(){
    const [date, setDate] = useState(20);
    const [date2, setDate2] = useState(14);
    const [dates, setDates] = useState([]);
    const { state, dispatch } = useContext(AuthContext);
    const [success, setSuccess] = useState({success: false, message: ""});
    const [error, setError] = useState({error: false, message: ""})
    const [r, setR] = useState(false);
    const [loading, setLoading] = useState(false);
    const [review_window, setReviewWindow] = useState(2);

    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchDates = () => {
        axios.post("/dates/get-dates", {
            parent: state.userData._id
        }).then(function(res){
            if(res.status === 200){
                setDates(res.data.data);
            }
        }).catch(function(error){
            console.log(error);
        })
    } 

    useEffect(() => {
        fetchDates()
    }, [r]);

    const saveDate = (e) => {
        e.preventDefault();

        setLoading(true);

        axios.post("/dates/create", {
            starts: date,
            duration: date2,
            parent: state.userData._id,
            reviewWindow: review_window
        }).then(function(res){
            if(res.status === 200){
                setR(!r)
                setSuccess({success: true, message: `The date has been configured successfully.`});
                setDate(20);
                setDate2(27)
                setLoading(false);
                window.location.reload();
            }
        }).catch(function(err){
            setError({error: true, message: error.message});
            setLoading(false);
        })
    }
    return (
        <Paper p="md" >
        {success.success ? (
            <Alert marginTop={-10} marginLeft={-10} marginRight={-10} marginBottom={20} variation="success" hasIcon={true} isDismissible={false}>
                {success.message}
          </Alert>
        ) : null}

        {error.error ? (
            <Alert marginTop={-20} marginLeft={-20} marginRight={-10} marginBottom={20} variation="error" hasIcon={true} isDismissible={false}>
                {error.message}
          </Alert>
        ) : null}
        <form onSubmit={(e) => {saveDate(e)}}>
        <NumberInput value={date} onChange={(val) => {setDate(val)}} label="Starts in (Day of the month)" mt="md" description="The date of the month that this category starts to run." min={1} max={28} hideControls precision={0} step={1} />
        <NumberInput value={date2} onChange={(val) => {setDate2(val)}} label="Duration" description="Number of days that the meter reading cycle will run. Usually 14 days." mt="md" min={1} max={28} hideControls precision={0} step={1} />
        <NumberInput value={review_window} onChange={(val) => {setReviewWindow(val)}} label="Review window" description="Number of days to take before merging the meter readings. Usually 2 days." mt="md" min={1} max={7} hideControls precision={0} step={1} />
        <Button mb={50} disabled={loading} loading={loading} type="submit" mt={20} radius={28}>Configure Date</Button>
        </form>
        </Paper>
    )
}