import React, { useContext, useEffect, useState } from "react";
import { Avatar, Badge, Text, Paper, Checkbox, Table, Group, Button, Select, NumberInput, Pagination, 
Modal, Anchor, Center,Progress, Alert, useMantineTheme, Space, Divider, Drawer, Stack, TextInput, Textarea, 
ActionIcon, Menu as MantineMenu,
Title} from "@mantine/core";
import useStyles from "./customerList.style";
import {  Download, FileExport, FileImport, InfoCircle, Plus, Refresh, Upload, X } from "tabler-icons-react";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import ReactFileReader from 'react-file-reader';
import SharedUtils from "../../../utils/shared";
import swal from 'sweetalert';
import { nanoid } from "nanoid";
import { IconCheck, IconInfoCircle } from "@tabler/icons";
import { Heading, SearchField } from "@aws-amplify/ui-react";
import { CustomerContext } from "../customer-context/customer.context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import { FRONTEND_URL } from "../../../constants";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { useViewportSize } from "@mantine/hooks";
import { getDate } from "../../../utils/getMonth";
import { generateInvoiceNumber } from "../../../utils/generate_invoice_number";
import { Menu, MenuList, MenuButton, MenuItem,MenuLink, } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import WebWorker from "../../../WebWorker";
import customer_filter_worker from "../../../customers_filter.worker";
import { obfuscatePhoneNumber } from "../../../utils/obfuscation";
  
const Utils = new SharedUtils();

export default function CustomerList(params){
    const { state } = useContext(AuthContext);
    const { classes, cx } = useStyles();
    const [importm, setImport] = useState(false);
    const [exportm, setExport] = useState(false);
    const [selection, setSelection] = useState([]);
    const [data, setData] = useState([]);
    const [months, ] = useState([]);
    const [years, ] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [ready, setReady] = useState(false);
    const [size, setSize] = useState(10);
    const [rawdata, setRawData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [, setLoaded] = useState(false);
    const [scrolled, ] = useState(false);
    const [page, setPage] = useState(1);
    const [dmas, setDMAS] = useState([]);
    const [dmas2, setDMAS2] = useState([]);
    const [, setConfiguredTarrif] = useState([]);
    const [tarrifsLocal, setTarrifsLocal] = useState([]);
    const [name, setName] = useState("");
    const [r, setR] = useState(false);
    const [downloadall, setDownloadAll] = useState(false);
    const [bulk, setBulk] = useState("");
    const [refreshing, setRefreshing] = useState(false);
    const [max, setMax] = useState(0);
    const [filter, setFilter] = useState("all");
    const [update_meter, setUpdateMeter] = useState(false);
    const [new_meternumber, setNewMeterNumber] = useState("");
    const [meter_to_change, setMeterToChange] = useState("");
    const  [curr_val, setCurrVal] = useState(0);
    const [new_meter_reading, setNewMeterReading] = useState(0);
    const [c_loading, setCLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [customer_bal, setCustomerBal] = useState([]);
    const [payments_ready, setPaymentsReady] = useState(false);
    const [invoices_ready, setInvoicesReady] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [phonenumber, setPhoneNumber] = useState("");
    const [sms_drawer, setSMSDrawer] = useState(false);

    const [dma, setDMA] = useState("");
    const [bulk_disc, setBulkDisc] = useState("0"); // 0-false, 1-true, 2-both

    //disconnection
    const [disconnection_configs, setDisconnectionConfigs] = useState(null);
    const [sms_configs, setSMSConfigs] = useState([]);
    const [short_codes, setShortCodes] = useState([]);

    // message disconnection
    const [bulk_message, setBulkMessage] = useState("");
    const [message_opened, setMessageOpened] = useState("");
    const [batch_customers, setBatchCustomers] = useState([]);

    const [extra_charge, setExtraCharge] = useState("");
    const [extra_charge_opened, setExtraChargeOpened] = useState(false);

    const [services, setServices] = useState([]);

    const webWorker = new WebWorker(customer_filter_worker);

    const fetchServices = () => {
        try{
            axios.post("/services/get-service", {
                parent: state.userData._id
            }).then(function(res){
                if(res.status === 200){
                    let arr = res.data.data;

                    let arr2 = [];

                    for(let i=0; i<arr.length; i++){
                        let item = arr[i].categories;

                        for(let k=0; k<item.length; k++){
                            arr2.push({label: item[k].service, value: ""+item[k].cost})
                        }
                    }

                    setServices(arr2);
                }
            }).catch(function(error){
                console.log(error);
            })
        } catch(error){
            // handle error.
            console.log(error);
        }
    }

    useEffect(() => {
        fetchServices();
    }, []);

    const saveExtraCharge = () => {
        setCLoading(true);
        axios.post("/customers/update", {
            id: meter_to_change,
            update: {other_details: { meter_rent: parseInt(extra_charge)}}
        }).then(function(res){
            toast.success("The charges were added successfully.");
            setCLoading(false);
            setExtraChargeOpened(false);
        }).catch(function(err){
            console.debug("Could not add other extra charges...");
            setCLoading(false);
        })
    }

    const sendBulkMessage = () => {
        if(bulk_message.trim().length === 0){
            return;
        }

        setLoading(true);

        axios.post("/sms/bulk-sms", {
            message: bulk_message,
            filters: {
                dma: dma,
                disconnected: bulk_disc
            }
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                //
                toast.success("The messages were delivered sucessfully.");
                setLoading(false);
                setMessageOpened(false);
                setBulkMessage("");
            }
        }).catch(function(error){
            console.log(error);
            setLoading(false);
            toast.error("An issue occured while sending the bulk messages");
        }) 
    }


    const fetchShortCodes = () => {
        const body = {
          parent: state.userData._id
        }; 
  
        axios.post("/short-codes/get-short-codes", body).then(function (res) {
            if (res.status === 200) {
              let arr = res.data.data;
                setShortCodes(arr);
            }
          }).catch(function (error) {
            console.debug("An error occured while fetching paybill numbers")
          })
      }

      useEffect(() => {
        fetchShortCodes();
      }, [])

    useEffect(() => {
        axios.get("/disconnection-rates/getRate", {headers: {'Authorization': `Bearer ${state?.userToken}`}}).then(function(res){
            if(res.status === 200){
                if(res.data.data){
                    console.log(res.data.data)
                    setDisconnectionConfigs(res.data.data);
                }
            }
        }).catch(function(error){
            toast.error("Could not fetch your disconnection configurations")
        });
    }, []);

    useEffect(() => {
        axios.get("/sms-configs/get", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
            if(res.status === 200){
              let obj = res.data.data;
      
              if(obj){
                setSMSConfigs(obj?.configs);
              }
            }
          }).catch(function(error){
            console.log(error);
          })
    }, []);

    useEffect(() => {
        const body = {
            parent: state.userData._id
        };

        axios.post("/payments/all", body).then(function(res){
            if(res.status === 200){
                setPayments(res.data.data);
                setPaymentsReady(true);
            } else {
                toast.error("Payments not ready")
            }
        }).catch(function(error){
            //toast.error("Payments not ready")
            console.log(error);
        })
    }, [rawdata])

    useEffect(() => {
        const body = {
            parent: state.userData._id
        };

        axios.post("/master-invoices/all", body).then(function(res){
            if(res.status === 200){
                setInvoices(res.data.data);
                setInvoicesReady(true);
            } else {
                toast.error("Invoices not ready")
            }
        }).catch(function(error){
            //toast.error("Invoices not ready")
            console.log(error);
        })
    }, [rawdata])

    const getDefaultedBalance = (acc) => {
        let billed = invoices.reduce((sum, obj) => {
            if(obj.invoices.account_number === acc){
                return sum + parseFloat(obj.invoices.total)
            }
            return sum;
        }, 0);

        let paid = payments.reduce((sum, obj) => {
            if(obj.account.trim() === acc){
                return sum + parseFloat(obj.transaction_amount);
            }
            return sum;
        }, 0);

        let defaultedAmount = (billed - paid);

        return defaultedAmount;
    }

    useEffect(() => {
        if(payments_ready && invoices_ready){
            const t_id = toast.loading("Preparing customer data exports");
            axios.post("/customers/getCustomers", {parent: state.userData._id}).then(function(res){
                if(res.status === 200){
                    let arr = res.data.data;

                    setBatchCustomers(arr);
    
                    let arr2 = [];
    
                    for(let i=0; i<arr.length; i++){
                        let item = arr[i];
                        

                        let bal = getDefaultedBalance(item.account);
    
                        arr2.push({Name: item.name, Account: item.account, DMA: item.dma, Balance: bal, "Previous Reading": item.previous_reading, "Current Reading": item.last_reading, Status: item?.disconnected ? "Disconnected" : "Connected", Phonenumber: item.phonenumber});
                    }
    
                    setCustomerBal(arr2);
                    toast.dismiss(t_id)
                }
            }).catch(function(err){
                toast.error("An issue occured!");
                toast.dismiss(t_id)
            })
        }

    }, [payments_ready, invoices_ready])

    const updateMeter = (id, val) => {
        setMeterToChange(id);
        setUpdateMeter(true);
        setCurrVal(""+val);
    }

    const addCharge = (id) => {
        setMeterToChange(id);
        setExtraChargeOpened(true);
    }

    const saveNewMeter = () => {
        axios.post("/customers/update", {
            id: meter_to_change,
            update: { username: new_meternumber, last_reading: new_meter_reading, previous_reading: curr_val}
        }).then(function(res){
            toast.success("The meter was changed successfully.");
            setCLoading(false);
            setUpdateMeter(false);
            setR(!r);
        }).catch(function(err){
            console.debug("Meter could not be changed...");
            setCLoading(false);
        })
    }

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const searchCustomer = () => {
        if(name === ""){
            toast.error("Cannot search with an empty value", {
                position: "bottom-right"
            });

            return;
        }

        const toast_id = toast.loading("Searching for results...", {
            position: "bottom-right"
        });

        axios.get("/search/customers", {
            headers: {
                Authorization: `Bearer ${state.userToken}`
            },
            params: {
                search_key: name
            }
        }).then(function(res){
            if(res.status === 200){
                setData(res.data.result);
                setRawData(res.data.result);
                toast.dismiss(toast_id);
            } else {
                toast.error("Could not get the results because you don't have the privildges to perform this action.", {
                    id: toast_id
                })
            }
        })
        .catch(function(err){
            toast.error("An issue occured while performing the search.", {
                id: toast_id
            })
        })
    }

    React.useEffect(() => {
        const fetchdata = () => {
            setReady(true);
            setData([]);
            setRawData([]);
            const body = {
                parent: state.userData._id,
                paginate: true,
                size: size,
                page: page,
                filter: filter
            };
            axios.post("/customers/getCustomers", body)
                .then(function(res){
                    if(res.status === 200){
                        setReady(false);
                        setMax(res.data.count);
                        setPagination(Math.ceil(res.data.count / size));
                        setData(res.data.data);
                        setRawData(res.data.data);
                        setRefreshing(false);
                    }
                })
                .catch(function(error){
                    setReady(false);
                    toast.error(error.message)
                    setRefreshing(false);
                });
        }        

        fetchdata();

        return () => {
            webWorker.terminate();
        }

    }, [r, size, page, filter]);

    useEffect(() => {
        if(selection.length > 0){
            if(bulk !== ""){
                swal('Are you sure you really want to continue?',{
                    buttons:{
                        cancel: 'Cancel',
                        continue: {
                            text: 'Continue',
                            value: 'continue'
                        }
                    }
                  })
                  .then(async (value) => {
                    if (value === "continue") {
                        if(bulk === "connect"){
                            let loadId = toast.loading(`Connecting...`);

                            for(let i=0; i<selection.length; i++){
                                if(i === (selection.length - 1)){
                                    setTimeout(function(){
                                        setR(!r);
                                        setBulk("");
                                        setSelection([])
                                        toast.success("Done!", {
                                            id: loadId
                                        });
                                    }, 1000)
                                }

                                let id = selection[i];
                                let idx = data.findIndex((obj => obj.username === id));
                                if(idx !== -1){
                                    let obj = data[idx];

                                    if(!obj.disconnected){
                                        continue;
                                    } else {
                                        await ConnectCustomer(id, true);
                                    }
                                } 
                            }
                        } else if (bulk === "disconnect"){
                            let loadId = toast.loading(`Disconnecting...`);

                            for(let i=0; i<selection.length; i++){
                                if(i === (selection.length - 1)){
                                    setTimeout(function(){
                                        setR(!r);
                                        setBulk("");
                                        setSelection([])
                                        toast.success("Done!", {
                                            id: loadId
                                        });
                                    }, 1000)
                                }
                                let id = selection[i];
                                let idx = data.findIndex((obj => obj.username === id));
                                if(idx !== -1){
                                    let obj = data[idx];

                                    if(obj.disconnected){
                                        continue;
                                    } else {
                                        await disconnectCustomer(id, true);
                                    }
                                } 
                            }
                        } else {
                            let loadId = toast.loading(`Deleting...`);

                            for(let i=0; i<selection.length; i++){
                                if(i === (selection.length - 1)){
                                    setTimeout(function(){
                                        setR(!r);
                                        setBulk("");
                                        setSelection([])
                                        toast.success("Done!", {
                                            id: loadId
                                        });
                                    }, 1000)
                                }

                                let id = selection[i];
                                await deleteCustomer(id, true);
                            }
                        }
                    } else {
                        setBulk("");
                        setSelection([])
                    }
                  });
            }
        }
    }, [bulk]);


    useEffect(() => {
        if(progress === 100){
            toast.success("List uploaded successfully");
            setImport(false);
        }
    }, [progress])

    const fetchDMAs = () => {
        setDMAS([]);
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                var arr = [];
                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];
                    arr.push({label: item.name, value: item.name})
                    setDMAS(prevArr => ([...prevArr, item.name]));
                }

                setDMAS2(arr);
                
            }
        }).catch(function(error){
            toast.error("Could not fetch DMAs", {
                position: "bottom-right"
            });
        })
    }

    const fetchTarrifs = () => {
        setTarrifsLocal([]);
        
        try{
            const body = {
                parent: state.userData._id
            };

            axios.post("/tarrifs/gettarrifs", body).then(({data: {data: tarrifs}, }) => {
                let arr = [];

                for(let i=0; i<tarrifs.length; i++){
                    arr.push(tarrifs[i].tarrif)
                }
                
                setTarrifsLocal(arr);

            }).catch(function(error){
                toast.error("Could not fetch tarrifs", {
                    position: "bottom-right"
                });
            })
        } catch(error){
            toast.error("Could not fetch tarrifs", {
                position: "bottom-right"
            });
        }
    }


    React.useEffect(() => {
        fetchDMAs();
        fetchTarrifs();
    }, []);

    const localDelete = (username) => {
        let idx = data.findIndex((obj => obj.username === username));
        if(idx !== -1){
            data.splice(idx, 1);
            setData([...data]); // force rerender
        }
    }

    const deleteCustomer = (username, bulk=false) => {
        swal({
            text: "Are you sure you want to delete this customer? The customer's details will be removed permanently. Click anywhere else apart from the button below to cancel.",
            dangerMode: true,
            icon: "warning",
            title: "Delete Customer"
        }).then((x) => {
            if(x){
                axios.post("/customers/deleteCustomer", {
                    username: username
                }).then(function(res){
                    if(res.status === 200){
                        if(!bulk){
                            localDelete(username);
                        }
                    } else {
                        toast.error("The customer does not exist");
                    }
                }).catch(function(error){
                    toast.error("An issue occured while deleting the customer", {
                        position: "bottom-right"
                    });
                })
            }
        })
    }

    const localConnect = (username) => {
        let idx = data.findIndex((obj => obj.username === username));

        if(idx !== -1){
            data[idx].status = "Connected"
            setData([...data]);
            setR(!r);
        }
    }

    const localDisconnect = (username) => {
        let idx = data.findIndex((obj => obj.username === username));

        if(idx !== -1){
            data[idx].status = "Disconnected";
            setData([...data]);
            setR(!r)
        }
    }

    const ConnectCustomer = async (username, bulk=false) => {
        await axios.post("/customers/connectCustomer", {
            username: username
        }).then(function(res){
            if(res.status === 200){
                if(!bulk){
                    localConnect(username);
                    let Utils2 = new SharedUtils(state.userData.username, `A customer with meter number ${username} has been re-connected to the system. If this was not intentional, you can disconnect the customer or ask for support.`, `${username} has been re-connected`, "Important", "/app/customers/customers-list");
                    Utils2.createNotification();
                }
            } else {
                toast.error("The customer could not be found", {
                    position: "bottom-right"
                });
            }
        }).catch(function(error){
            toast.error("An error occured while connecting the customer", {
                position: "bottom-right"
            });
        })
    }


    const createDisconnectionInvoice = async(account) =>{
        await axios.post("/master-invoices/create", {
            isBulk: false,
            invoice: {
                parent: state.userData._id,
                period: getDate(),
                invoices: {total: ""+disconnection_configs?.fee, due_date: new Date(new Date().getTime()+(14 * 24 * 60 * 60 * 1000)).toLocaleDateString(), account_number: account, invoice_number: generateInvoiceNumber(data.findIndex((obj => obj.account === account)))},
                account: account,
                mode: "disconnection"
            }
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(async function(res){
            if(res.status === 200){
                // invoice created
                toast.success("A disconnection invoice was added to the customer", {
                    position: "bottom-right",
                });
            }
        }).catch(function(err){
            toast.error("An issue occured while generating invoice", {
                position: "bottom-right",
            });
        })
    }

    const matchPaybill = (acc) => {
        let dma = "";

        var idx2 = rawdata.findIndex((obj) => obj.account === acc);

        if(idx2 !== -1){
            dma = rawdata[idx2]?.dma
        } else {
            return "N/A"
        }

        let idx = short_codes.findIndex((obj) => obj.dma === dma);
  
        if(idx !== -1){
          let item = short_codes[idx];
          return item.short_code
        } else {
          if(short_codes.length > 0){
            let idx = short_codes.findIndex((obj) => obj?.dma === "*"); // all paybilss

            if(idx !== -1){
              let item = short_codes[idx];
              return item.short_code
            } else {
                return short_codes[0]; // the first short code
            }
          } else {
            return "N/A"
          }
        }
      }

      function processPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input phone number
        const cleanNumber = phoneNumber.replace(/\D/g, '');
    
        // Check if the cleaned number is at least nine digits long
        if (cleanNumber.length < 9) {
            // Handle the case where the phone number is too short
            return "";
        }
        // Take the last nine digits of the cleaned number
        const lastNineDigits = cleanNumber.slice(-9);
    
        // Add the country code "+254" to the last nine digits
        const formattedNumber = "254" + lastNineDigits;
    
        return formattedNumber;
    }

    const getName = (acc) => {
        let idx = rawdata.findIndex((obj) => obj.account === acc);

        if(idx !== -1){
            return rawdata[idx]?.name.split(" ")[0].toUpperCase();
        } else {
            return "N/A"
        }
    }

    const getMSISDN = (acc) => {
        let idx = rawdata.findIndex((obj) => obj.account === acc);

        if(idx !== -1){
            return rawdata[idx]?.phonenumber;
        } else {
            return "N/A"
        }
    }

    const disconnectCustomer = (username, bulk=false, acc) => {
        axios.post("/customers/disconnectCustomer", {
            username: username
        }).then(function(res){
            if(res.status === 200){
                if(!bulk){
                    localDisconnect(username);
                    // create an invoice
                    if(disconnection_configs){
                        if(disconnection_configs?.checked){
                            // invoice creation enabled.
                            createDisconnectionInvoice(acc);
                        }
                    }

                    // notify customer
                    if(sms_configs[1].checked){
                        // sms enabled
                        let phonenumber = getMSISDN(acc);
                        let name = getName(acc);
                        let balance = getDefaultedBalance(acc);
                        let paybill = matchPaybill(acc);

                        let message = `Hi ${name},your water service has been temporarily disconnected due to a balance of KES:${balance}.Reconnection fee of KSH:${disconnection_configs?.fee} has been added.Kindly pay at your earliest convenience.Paybill ${paybill} A/C ${acc}.Helpline ${state.userData?.phonenumber}/${state.userData?.alternative_phonenumber}`;

                        axios.post("/sms/mobitech-sms", {
                            to: processPhoneNumber(phonenumber),
                            sms: message,
                            parent: state.userData._id
                        }).then(function(res){
                            if(res.status === 200){
                                //
                            }
                        }).catch(function(error){
                            console.log(error);
                        })
                    }
                    let Utils2 = new SharedUtils(state.userData.username, `A customer with meter number ${username} has been disconnected from the system. If this was not intentional, you can re-connect the customer or ask for support.`, `${username} has been disconnected`, "Important", "/app/customers/customers-list");
                    Utils2.createNotification();
                }
            } else {
                toast.error("The customer could not be found", {
                    position: "bottom-right"
                });
            }
        }).catch(function(error){
            toast.error("An error occured while disconnecting the customer", {
                position: "bottom-right"
            });
        })
    }

    function convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)
      
        return array.map(it => {
          return Object.values(it).toString()
        }).join('\n')
      }

    const downloadCSV = () => {
        let csv = convertToCSV(rawdata);

        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = state.userData.waterServiceName.replace(/ /g,"-")+"-customers.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
  const toggleAll = () =>
    setSelection((current) => (current.length === data.length ? [] : data.map((item) => item.username)));

const handleFiles = (files) => {
    //setImport(false);
    var reader = new FileReader();
    reader.onload = function(e) {
    // Use reader.result
    var csv = reader.result;
    var lines = csv.split("\n");
    var result = [];
    var headers=lines[0].split(",");
    for(var i=1;i<lines.length;i++){
      var obj = {};
      var currentline=lines[i].split(",");
      for(var j=0;j<headers.length;j++){
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
      }  
      //return result; //JavaScript object
      //result= JSON.stringify(result); //JSON
    uploadToDb(result);
  }
  reader.readAsText(files[0]);
}

const checkExistence = (item) => {
    let idx = data.findIndex((obj => obj.username === item));

    if(idx === -1){
        return false;
    }

    return true;
}

let dmaLocals = [...dmas];

let tLocal = [...tarrifsLocal];

const createDMA = async (name) => {
    try {
        const body = {
            name: name,
            username: state.userData._id
        }

        await axios.post("/dmas/create", body).then(function(res){
            if(res.status === 200){
                toast.success(`Configuring ${name} DMA`, {
                    position: "bottom-right"
                });
                //setDMAS(prevArr => ([...prevArr, chunk]));
            }
        }).catch(function(error){
            toast.error("An error occured while creating the tarrif", {
                position: "bottom-right"
            });
        })
    } catch(error){
        //console.warn(error.message);
        toast.error("An error occured while creating the DMA", {
            position: "bottom-right"
        });
    }
}

const getTarrif = (t) => {
    switch(t){
        case "Domestic/Residential":
                return [
                    {"_comment": "This is a tarrif covering domestic or residential customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": true},
                    {"qty": "0-6", "flatRate": true, "cost": 204.00},
                    {"qty": "7-60", "cost": 53.00},
                    {"qty": ">60", "cost": 64.00}
                ]
                

        case "Commercial/Industrial":
           return [
                    {"_comment": "This is a tarrif covering Commercial/Industrial customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": true},
                    {"qty": "0-6", "flatRate": true, "cost": 204.00},
                    {"qty": "7-60", "cost": 53.00},
                    {"qty": ">60", "cost": 64.00}
                ]

        case "Government institutions":
            return [
                    {"_comment": "This is a tarrif covering Government institutions customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": true},
                    {"qty": "0-6", "flatRate": true, "cost": 204.00},
                    {"qty": "7-60", "cost": 53.00},
                    {"qty": ">60", "cost": 64.00}
                ]

        case "Government funded public schools":
         return [
                    {"_comment": "This is a tarrif covering Government funded public schools customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": true},
                    {"qty": "0-600", "flatRate": false, "cost": 48.00},
                    {"qty": "601-1200", "cost": 55.00},
                    {"qty": ">1200", "cost": 60.00}
                ]

        case "Water kiosks":
          return  [
                    {"_comment": "This is a tarrif covering Water kiosks customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": false},
                    {"cost": 20.00}
                ]

        case "Water at ATM water dispenser":
         return [
                    {"_comment": "This is a tarrif covering Water at ATM water dispenser customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": false},
                    {"cost": 25.00}
                ]

        case "Bulk water to residential flats/gated communities(from 25 households and above)":
            return [
                    {"_comment": "This is a tarrif covering Bulk water to residential flats/gated communities(from 25 households and above) customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": false},
                    {"cost": 53.00}
                ]

        case "Pre-paid meter customer":
          return [
                    {"_comment": "This is a tarrif covering Pre-paid meter  customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": false},
                    {"cost": 52.00}
                ]

        case "Bulk water to WSPs for resale":
          return [
                    {"_comment": "This is a tarrif covering Bulk water to WSPs for resale customers as derived from the Nairobi Water and Sewerage Company"},
                    {"with_blocks": false},
                    {"cost": 30.00}
                ]

        default:
            return [
                {"_comment": `This is a tarrif covering ${t}. The default values are as per the Domestic/Residential consupmtion blocks, derived from Nairobi Water and Sewerage Company`},
                {"with_blocks": true},
                {"qty": "0-6", "flat-rate": true, "cost": 204.00},
                {"qty": "7-60", "cost": 53.00},
                {"qty": ">60", "cost": 64.00}
            ]
    }
}
const createTarrif = async (tarrif) => {
    try{
        const body = {
            parent: state.userData._id,
            tarrif: tarrif,
            blocks: getTarrif(tarrif)
        };

        await axios.post("/tarrifs/create",body).then(({data: {data: tarrifs}, }) => {
            toast.success(`Configuring ${tarrif} as tarrif`, {
                position: "bottom-right"
            })
        }).catch(function(error){
            //console.error(error);
            toast.error("An error occured while creating the tarrif", {
                position: "bottom-right"
            });
        })
    } catch(error){
        //console.error(error);
        toast.error("An error occured while creating the tarrif", {
            position: "bottom-right"
        });
    }
}


const uploadToDb = async (result) => {
    const toastId = toast.loading("Parsing records...")

    for(let i=0; i<result.length; i++){
      let item = result[i];

      if(i === (result.length - 1)){
        setLoaded(true);
      }

      let num = Math.ceil((i / (result.length - 1)) * 100);
      if(num === 100){
        toast.dismiss(toastId);
      }
      setProgress(num);

      if(item.Tarrif === undefined){
        continue;
      }

      if(item.DMA === undefined){
        continue;
      }

      if(item.MeterNumber === undefined){
        continue;
      }

      if(checkExistence(item.MeterNumber)){
        toast.error(`A customer with a meter number ${item.MeterNumber} already exists. The record will be skipped.`, {
            position: "bottom-right",
            id: toastId
        });
        continue;
      }

      if(!dmaLocals.includes(item.DMA)){
        dmaLocals.push(item.DMA);
        createDMA(item.DMA);
      }

      if(!tLocal.includes(item.Tarrif.trim())){
        tLocal.push(item.Tarrif.trim());
        createTarrif(item.Tarrif.trim())
        setConfiguredTarrif(prevArr => ([...prevArr, {label: item.Tarrif.trim(), value: item.Tarrif.trim()}]));
      }

      let lastReading = 0;
      let previousReading = 0;

      // CurrentReading - the current reading of the meter as per the date of insertion.

      // PreviousReading - the last paid reading

      if(item.CurrentReading !== undefined){
        lastReading = parseFloat(item.CurrentReading);
        previousReading = lastReading;
      }

      let withReadings = true;
      
      if(item.WithReading !== undefined){
        withReadings = false;
      }

      try {
          let body = {
              username: item.MeterNumber,
              account: item.Account,
              parent: state.userData._id,
              name: item.FirstName + " " + item.LastName,
              phonenumber: item.Mobile,
              email: item.Email,
              address: item.Address + " " + item.HouseNumber,
              country: "Kenya",
              county: state.userData.county[0],
              zip: state.userData?.zipCode || "00100",
              serial: "",
              sewer: false,
              sewer_type: "",
              dma: item.DMA,
              coordinates: [parseFloat(item.Latitude), parseFloat(item.Longitude)],
              password: Utils.createDummyPasscode(),
              tarrif: item.Tarrif,
              meter_type: item.MeterType,
              disconnected: item.ConnectionStatus === "Connected" ? false : true,
              notes: item?.Notes || "",
              previous_reading: lastReading,
              last_reading: lastReading,
              withReadings: withReadings
          };

         await axios.post("/customers/create", body).then(async function(res){
              let it = res.data.data;
              let chunk = {id: it._id, dma: it.dma, readings: 0, last_reading: it.last_reading, previous_reading: it.previous_reading, name: it.name, username: it.username, tarrif: it.tarrif, disconnected: it.disconnected};
              setData(prevArr => ([...prevArr, chunk]))
              setPagination(Math.ceil(data.length / size));
              let num = Math.ceil((i / (result.length - 1)) * 100);

              setProgress(num);

              axios.post("/consumption/create", {
                prevReading: lastReading,
                currReading: lastReading,
                meter: item.MeterNumber,
                photo: "",
                parent: state.userData._id,
                year: item.Year,
                month: item.Month,
                day: item.Day
            }).then(function(res){
                if(res.status === 200){ 
                    //done successfully
                }
            }).catch(function(error){
                toast.error(error.message);
            })
        
          }).catch(function(error){
            //console.log("level 3:"+error.message);
          })
      } catch(error){
        //console.log("level 4:"+error.message);
      } 
    }
}

const resetPassword = async (username, email, name) => {
    let toastId = toast.loading("Sending request...", {
        position: "bottom-left"
    });

    let id = nanoid(64);

    await axios.post("/tokens/create", {
        username: username,
        token: id
    }).then(async function(res){
        if(res.status === 200){
            await axios.post("/verify/send", {
                fullname: name,
                username: email,
                link: `${FRONTEND_URL}/customer/verify?token=${id}&email=${email}&username=${username}`
            }).then(function(res){
                if(res.status == 200){
                    // do anything
                    toast.success("A password reset link has been send to the customer", {
                        id: toastId
                    })
                }
            })
        }
    })
}

const archiveCustomer = async (id, state) => {
    swal(`Are you sure you want to ${state ? "archive" : "remove from archive"} this customer?`, {
        buttons: {
            cancel: 'Cancel',
            continue: {
                text: state ? "Archive" : "Unarchive",
                value: "yes"
            }
        }
    })
    .then(async (value) => {
        if(value === "yes"){
            let toastId = toast.loading("Updating information...", {
                position: "bottom-right"
            });
        
            await axios.post("/customers/update", {
                id: id,
                update: {
                    archived: state
                }
            }).then(function(res){
                if(res.status === 200){
                    toast.success(`The customer details have been ${state ? "archived" : "removed from archive"}.`, {
                        id: toastId
                    });
                    setR(!r);
                }
            }).catch(function(error){
                toast.error(error.message, {
                    id: toastId
                })
            })
        }
    })
}

const smsDrawer = (num) => {
    setPhoneNumber(num);
    setSMSDrawer(true);
}


    const CustomerRows = React.memo(({data, page, size, name, selection}) => {
        
        const  paginate = (a, pageIndex, pageSize) => {
            pageIndex = pageIndex - 1;
            var endIndex = Math.min((pageIndex + 1) * pageSize, a.length);
            return a.slice(Math.max(endIndex - pageSize, 0), endIndex);   
        }   

        const rows = paginate(data, page, size).filter((item) => {
            if(item.name.toLowerCase().includes(name) || item.username.toLowerCase().includes(name) || item.account.toLowerCase().includes(name) || item.dma.toLowerCase().includes(name)){
                return item
            }

            }).map((item ,index) => {
                const selected = selection.includes(item._id);
                return (
                    <tr key={index} className={cx({ [classes.rowSelected]: selected })} >
                                <td>
                                <Checkbox
                                    checked={selection.includes(item.username)}
                                    onChange={() => toggleRow(item.username)}
                                    transitionDuration={0}
                                />
                                </td>
                                    <td>
                                        <Avatar color="blue" radius={40} size={40} >{item.name ? item.name.split("")[0].toUpperCase() : "U"}</Avatar>
                                    </td>
                                    <td>
                                        <Anchor component={"a"} target="_blank" href={`/app/customers/${encodeURIComponent(item.username)}`} >{item.name}</Anchor>
                                    </td>
                                    <td>
                                        {item.account}
                                    </td>
                                    <td>
                                        {obfuscatePhoneNumber(processPhoneNumber(item?.phonenumber))}
                                    </td>
                                    <td>
                                        {item.username}
                                    </td>
                                    <td>
                                        {item.dma}
                                    </td>
                                    <td>
                                        {item.meter_type}
                                    </td>
                                    <td>
                                        <Badge style={{textTransform: 'none'}} color={!item.disconnected ? "green" : "yellow"}>{item.disconnected ? "Disconnected" : "Connected"}</Badge>
                                    </td>

                                    <td>
                                        <Badge style={{textTransform: 'none'}} color={!item.archived ? "green" : "yellow"}>{item.archived ? "Yes" : "No"}</Badge>
                                    </td>

                                    <td>
                                        {item.previous_reading?.toFixed(2) || 0}
                                    </td>
                                    <td>
                                        {item.last_reading?.toFixed(2) || 0}
                                    </td>
                                    <td>
                                        {new Date(item.updatedAt).toLocaleDateString()}
                                    </td>
                                    <td>

                                                <Menu>
                                                    <MenuButton>
                                                    Actions <span aria-hidden>▾</span>
                                                    </MenuButton>
                                                    <MenuList style={{backgroundColor: theme.colorScheme === "light" ? "#ffffff" : "#000000", color: theme.colorScheme === "light" ? "#000000" : "#ffffff"}}>
                                                    <MenuItem onSelect={() => {updateMeter(item._id, item.last_reading)}}>Update/Change Customer Meter</MenuItem>
                                                    <MenuItem onSelect={() => {addCharge(item._id)}}>Add Extra Charge</MenuItem>
                                                    <MenuItem onSelect={() => {resetPassword(item.username, item.email, item.name)}}>
                                                        Send Password Reset Link
                                                    </MenuItem>
                                                    {item.disconnected ? (
                                                        <MenuItem onSelect={() => {ConnectCustomer(item.username)}}>Re-connect Customer</MenuItem>
                                                    ) : (
                                                        <MenuItem onSelect={() => {disconnectCustomer(item.username, false, item.account)}}>Disconnect Customer</MenuItem>
                                                    )}
                                                    
                                                    {item?.archived ? (
                                                        <MenuItem onSelect={() => {archiveCustomer(item._id, false)}}>Remove from Archive</MenuItem>
                                                    ) : (
                                                        <MenuItem onSelect={() => {archiveCustomer(item._id, true)}}>Archive Customer</MenuItem>
                                                    )}
                                                    <MenuLink as="a" target="_blank" href={`/app/customers/${encodeURIComponent(item.username)}`}>
                                                        Customer Details
                                                    </MenuLink>
                                                    <MenuItem onSelect={() => {deleteCustomer(item.username, false)}}>Delete Customer</MenuItem>
                                                    </MenuList>
                                                </Menu>
                                    </td>
                                </tr>
                )
            });

            return rows;
    });

    const { height, width } = useViewportSize();
    const theme = useMantineTheme();

    const downloadBalCSV = () => {
        let csv = convertToCSV(customer_bal);

        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = state.userData.waterServiceName.replace(/ /g,"-")+"-customers.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    function sendSMS(){
        setLoading(true);
        axios.post("/sms/mobitech-sms", {
            to: processPhoneNumber(phonenumber),
            sms: message,
            parent: state.userData?._id
        }).then(function(res){
            if(res.status === 200){
                toast.success("Your message was delivered");
                setLoading(false);
                setSMSDrawer(false);
            }
        }).catch(function(error){
            toast.error("SMS services are not available right now.")
            setLoading(false);
            setSMSDrawer(false)
        })  
    }

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{state.userData.waterServiceName} | Customers Management Console</title>
            </Helmet>
            <Alert mb={20} mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Customer management console allows you to keep track of your customers. Get to know how many customers you acquired this month, the trend in customer's connection status, manage specific customer details and much more.
        </Alert>

      <Drawer opened={message_opened} onClose={() => {setMessageOpened(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Bulk SMS</Heading>
            <Text>Send sms to all your customers with a single click.</Text>
            <Textarea mt="md" label="Message" value={bulk_message} onChange={(e) => {setBulkMessage(e.currentTarget.value)}} minRows={3} />
            <Title order={5} mt="md">Filters</Title>
            <Select value={bulk_disc} onChange={(val) => {setBulkDisc(val)}} data={[
                {label: "Disconnected", value: "1"},
                {label: "Connected", value: "0"},
                {label: "All", value: "2"}
            ]} mt="md" label="Connection Status" />

            <Select value={dma} onChange={(val) => {setDMA(val)}} label="DMA/Zone" data={dmas2} mt="md" />
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} variant="default" onClick={() => {setMessageOpened(false)}}>Cancel</Button>
            <Button size='sm' radius={28} onClick={() => {sendBulkMessage()}} loading={loading}>Send Bulk SMS</Button>
        </Group>
        </Stack>
      </Drawer>
            <Drawer opened={update_meter} onClose={() => {setUpdateMeter(false)}} position="right" title="Update/Change Meter" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
        <div>
        <NumberInput hideControls label="New Meter Reading" value={new_meter_reading} onChange={(val) => {setNewMeterReading(val)}} />
        <TextInput mt="md" value={new_meternumber} onChange={(e) => {setNewMeterNumber(e.currentTarget.value)}} label="Meter Number" />
        </div>
        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setUpdateMeter(false)}}>Cancel</Button>
            <Button size='sm' radius={28} onClick={() => {saveNewMeter()}} loading={c_loading}>Save Details</Button>
        </Group>
        </Stack>
      </Drawer>

      <Drawer opened={extra_charge_opened} onClose={() => {setExtraChargeOpened(false)}} position="right" title="Add Extra Charges" padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
        <div>
        <Select label="Charge Category" value={extra_charge} onChange={(val) => {setExtraCharge(val)}} data={services} />
        </div>
        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setExtraChargeOpened(false)}}>Cancel</Button>
            <Button size='sm' radius={28} onClick={() => {saveExtraCharge()}} loading={c_loading}>Save</Button>
        </Group>
        </Stack>
      </Drawer>

        <Paper p="md">
        {progress === 100 ? (
        <Alert mb={20} icon={<IconCheck />} title="Success" variant="filled">
                Customer records have successfully being imported into the system.
          </Alert>
        ) : null}
      <Group mb={10} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Customers</Heading>
      </Group>
      <Text mb={20}>You are currently viewing {data.length} customers of the {max} customers.</Text>
      <div>
     <Group grow mt={10} mb={20} noWrap>
            <Group position="left" spacing={"xs"} noWrap>
                <MantineMenu trigger="hover">
                    <MantineMenu.Target>
                        <Button>Export/Import</Button>
                    </MantineMenu.Target>

                    <MantineMenu.Dropdown>
                        <MantineMenu.Item disabled={customer_bal.length === 0} onClick={() => {downloadBalCSV()}} icon={<FileExport />}>Export Customers</MantineMenu.Item>
                        <MantineMenu.Divider />
                        <MantineMenu.Label color="red">Before importing customer data, please make sure that the file is in CSV <br /> and that all the columns are structured as per Emita's standards</MantineMenu.Label>
                        <MantineMenu.Item onClick={() => {setImport(true)}} icon={<FileImport />}>Import Customers</MantineMenu.Item>
                    </MantineMenu.Dropdown>
                </MantineMenu>
                    <Select value={filter} onChange={(val) => {setFilter(val)}} placeholder="Filter options" data={[
                    {label: "Connected Customers", value: "connected"},
                    {label: "Disconnected Customers", value: "disconnected"},
                    {label: "Archived Customers", value: "archived"},
                    {label: "All Customers", value: 'all'}
                ]} />

                <Button ml={10} onClick={() => {setMessageOpened(true)}}>
                   Bulk SMS
                </Button>
            </Group>

                <Group position="right" grow noWrap>
                <SearchField style={{width: "100%"}} placeholder="Search customers" value={name} onSubmit={() => {searchCustomer()}} onClear={() => {setName("")}} onChange={(e) => {setName(e.currentTarget.value.toLowerCase())}} />
                {/*<Button radius={28} variant="default" size="xs" onClick={() => {params.handleTab("add")}} leftIcon={<Plus size={13} />} >New Client</Button> */}
                <Select value={size.toString()} onChange={(val) => {setSize(parseInt(val))}} data={[
                    {label: "10", value: "10"},
                    {label: "25", value: "25"},
                    {label: "50", value: "50"},
                    {label: "100", value: "100"},
                    {label: "250", value: "250"},
                    {label: "500", value: "500"},
                    {label: "1000", value: "1000"},
                    {label: "5000", value: "5000"}
                ]} />
                </Group>
            </Group>
            <Divider ml={-10} mr={-10} mb={10} />
                        {!ready ? (
                                            <Table fontSize='xs' style={{borderBottom: '1px solid #E9ECEF'}} highlightOnHover >
                                            <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
                                                <tr>
                                                <th style={{ width: 40 }}>
                                                    <Checkbox
                                                        onChange={toggleAll}
                                                        checked={selection.length === data.length}
                                                        indeterminate={selection.length > 0 && selection.length !== data.length}
                                                        transitionDuration={0}
                                                    />
                                                    </th>
                                                    <th>
                                                        #
                                                    </th>

                                                    <th>
                                                        Name
                                                    </th>
                                                    <th>
                                                        Account
                                                    </th>
                                                    <th>
                                                        Phone
                                                    </th>
                                                    <th>
                                                        Meter
                                                    </th>
                                                    <th>
                                                        DMA
                                                    </th>
                                                    <th>
                                                        Type
                                                    </th>
                                                    <th>
                                                        Status
                                                    </th>
                                                    <th>
                                                        Archived
                                                    </th>
                                                    <th>
                                                        Last
                                                    </th>
                                                    <th>
                                                        Current
                                                    </th>
                                                    <th>
                                                        Modified
                                                    </th>
                                                    <th>
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                                <tbody>
                                                {rawdata.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={14}>
                                                            <Center mb={10}>No results were found.</Center>
                                                            <Center>
                                                            <Button onClick={() => {
                                                                setRefreshing(true);
                                                                setR(!r);
                                                                setName("");
                                                                }} radius={28}>
                                                            {refreshing ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: 10}} size="xs" />
                                                            ) : <Refresh style={{marginRight: 10}} size={15} />}
                                                            {refreshing ? "loading" : "Reset Filter"}
                                                            </Button>
                                                            </Center>
                                                        </td>
                                                    </tr>
                                                ) : <CustomerRows data={rawdata} page={page} size={size} name={name} selection={selection} />}
                                            </tbody>
                                        </Table>
                        ) : refreshing ? (
                            (
                                <Center>
                                <div
                        className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status">
                        <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >
                        Loading...
                        </span>
                        </div>
                            </Center>
                            )
                        ) : (
                            <Center>
                            <div
                    className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >
                    Loading...
                    </span>
                    </div>
                        </Center>
                            )}
                </div>
                <Group  mt={20} position="right">
                <Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
                </Group>
                <Modal closeOnClickOutside={false} centered opened={exportm} onClose={() => {setExport(false)}} title="Export Customer">
                    <Checkbox onChange={() => {setDownloadAll(!downloadall)}} label="Filter Dataset" />

                    {downloadall ? (
                    <>
                    <Select mt={20} radius={28} placeholder="Filter by year" data={years} />
                    <Select mt={10} radius={28} placeholder="Filter by month" data={months} />
                    </>
                    ) : null}

                    <Group position="right" mt={30} >
                        <Button onClick={() => {downloadCSV()}} >Download</Button>
                    </Group>
                </Modal>
                <Modal closeOnClickOutside={false} closeOnEscape={false} size="auto" centered opened={importm} onClose={() => {setImport(false)}} title="Import Customer">
                        <Text mb={20} size="xs">You can upload customers from an existing database. We currently support importing
                            data from CSV(comma-separated) and Excell data format.
                        </Text>
                        {progress !== 0 ? (
                            <Progress size="xl" radius="xl" mb={20} label={`${progress}%`} value={progress} />
                        ) : null}
                        <Group mb={10} grow >
                            <Group position="left">
                                <Text size="xs">Import from file</Text>
                            </Group>
                            <Group>
                            <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'} >
    
                            <Button style={{width: 200}}  leftIcon={<Upload size={14} />} >
                            {progress > 0 && progress < 100 ? "Uploading..." : progress === 100 ? "Uploaded" : "Upload CSV"}
                            </Button>
                            
                            </ReactFileReader>
                            </Group>
                        </Group>

                        <Group position="right">
                            <Button disabled={progress > 0 && progress < 100 ? true : false} onClick={() => {setImport(false)}} leftIcon={<X />} variant="outline" >{progress > 0 && progress < 100 ? "Please Wait..." : progress === 100 ? "Done" : "Cancel"}</Button>
                        </Group>
                </Modal>
            </Paper>
            </>
    )
}