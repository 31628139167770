import axios from "./axios";

class SharedUtils{
    constructor(username, content, title, category, link){
        this.length = 8;
        this.charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        this.retVal = "";

        //notification params
        this.username = username;
        this.content = content;
        this.title = title;
        this.category = category;
        this.link = link;
    }

    createDummyPasscode() {
        for (var i = 0, n = this.charset.length; i < this.length; ++i) {
            this.retVal += this.charset.charAt(Math.floor(Math.random() * n));
        }
        return this.retVal;
    }

    createNotification(){
        axios.post("/notifications/create", {
            username: this.username,
            content: this.content,
            title: this.title,
            category: this.category,
            link: this.link
        }).then(function(res){
            if(res.status === 200){
                return "Ok";
            }
        }).catch(function(error){
            return error.message;
        })
    }
}

export default SharedUtils;