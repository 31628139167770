const BACKEND_URL = "https://emita.herokuapp.com/" //"http://localhost:8080"
const FRONTEND_URL = "https://app.emitasuite.com"
const TOKEN_NAME = "SBJEU19256653nnsh1k2,1louj2323b"
const ACCOUNT_DATA = "Emita_Account_UUID"
const SESSION_DATA = "Emita_Session_UUID"
const COLORS = 'emita-color-scheme'
const DEFAULT_METER_READING_CYCLE = { starts: 20, duration: 14, reviewWindow: 2 };
const CLOUDINARY_URL = "cloudinary://916365672944813:n5xoci4HkCApnfybQXElXKsYdBc@ddu0rt9po"
const NODEMAILER_CONFIG= {"host": "smtp.gmail.com","port": "465","secure": "true","auth":{"user": "daviskitavi98@gmail.com", "pass": "fmzbotyygssqvwhy"}}
export const InfrastructureBucket = "emita-infrastructure-files";
export const InfrastructureRegion = "ap-northeast-1";
export const accessKeyId = "AKIAZHRAXGWVD4ORJW7P";
export const secretAccessKey = "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";

export {
  BACKEND_URL,
  TOKEN_NAME,
  ACCOUNT_DATA,
  SESSION_DATA,
  CLOUDINARY_URL,
  NODEMAILER_CONFIG,
  FRONTEND_URL,
  COLORS,
  DEFAULT_METER_READING_CYCLE
}
