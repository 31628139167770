import {
  TextInput,
  PasswordInput,
  Anchor,
  Group,
  Button,
  Center,
  Paper,
  Text,
  MediaQuery,
  BackgroundImage,
  Title,
  SimpleGrid,
  Space,
  Divider,
  Checkbox,
  Menu
} from '@mantine/core';
import {  useContext, useState, useMemo, useEffect} from 'react';
import toast from 'react-hot-toast';
import { useLocation, Link } from 'react-router-dom';
import axios from '../../utils/axios';
import { AuthContext } from '../../App';
import { SESSION_DATA } from '../../constants';
import { useForm } from "@mantine/form";
import { Helmet } from 'react-helmet';
import { useViewportSize } from '@mantine/hooks';
import Autumn2 from "../../assets/bg.jpg";
import { World } from 'tabler-icons-react';
import ReactGA from "react-ga4";
import { GuestCompanyLogo } from '../dashboard/layout/companyLogo';

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { height, width} = useViewportSize();
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(1);

  useEffect(() => {
    ReactGA.send({hitType: "pageView", page: window.location.href})
}, []);

  const form = useForm({
    initialValues: { email: "", password: ""},
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    window.history.replaceState(null, "Admin Login", "?r=emita saas root")
}, [])

  const onSubmit = async (obj) => {
    setLoading(true);
      try {
        const body = {
          username: obj.email,
          password: obj.password
        };
  
        axios.post("/auth/login", body).then(({
          data: {
            data: accountData,
            token: accessToken
          },
        }) => {
          localStorage.setItem(SESSION_DATA, accessToken);
          dispatch({type: "SIGN_IN", token: accessToken, data: accountData});
          setLoading(false);
        }).catch((error) => {
          toast.error(error?.response?.data?.message || error.message)
          setLoading(false);
        })
      } catch(error){
        toast.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
  }

  return (
    <>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Sign In</title>
      <meta name='description' content='Get access to your account.' />
      </Helmet>
      <SimpleGrid
      cols={2}
      spacing="lg"
      breakpoints={[
        { maxWidth: 980, cols: 1, spacing: 'md' }
      ]}
    >

<MediaQuery smallerThan="md" styles={{display: 'none'}}>
      <BackgroundImage
        src={Autumn2}
        h={height}
        style={{height: height, }}
      >
                <div style={{height: height}}>
                <Center mb={20} pt={height * 0.35}>
            <Title color='#ffffff'>Scalable, Modular & Flexible</Title>
        </Center>
        <Center>
        <Text color='#ffffff' size="xl" style={{textAlign: 'center'}}>Grow your water utility with Confidence. Experience efficiency,
speed and convenience in a unified platform.</Text>
        </Center>
                </div>
      </BackgroundImage>
            </MediaQuery>

            <div style={{height: height, overflowY: 'auto'}}>
<Paper p="lg">
<main className="w-full h-screen-2xl items-center justify-center py-10">
  <GuestCompanyLogo link={"/account/register"} />
  <Text mb={20} mt={20}>Welcome back to Emita. Explore <span style={{color: 'steelblue'}} >tools </span>& <span style={{color: 'steelblue'}} >consoles</span> that transition your utitlity into a smart utility.</Text>
  <Text inline>Don't have an Emita account yet? <Anchor component={Link} to='/account/register' color="blue">Sign Up</Anchor></Text>
    <Space h="sm" />
    <Divider />
    <Space h="sm" />
      <form onSubmit={form.onSubmit((values) => {onSubmit(values)})}>
          <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mb="sm" height={40} withAsterisk {...form.getInputProps("email")} placeholder="" label="Email Address/Username"/>
    <PasswordInput height={40} withAsterisk {...form.getInputProps("password")} label="Password" mt="md" />
    <Group position="apart" mt="md">
      <Checkbox label="Remember me in this device" />
      <Anchor component={Link} to="/account/forgot-password/" size="sm">
        Forgot password
      </Anchor>
    </Group>

          <Button height={45} loading={loading} disabled={loading} type='submit' fullWidth mt="lg">
                Sign In
              </Button>
      </form>
      <Group position='apart' mt={10}>
      <Group position='left'>
      <Text size="xs" component='a' href='#'>Privacy Terms</Text>
      <Text size="xs"  component='a' href='#'>Data Security</Text>
      <Text size="xs"  component='a' href='#'>Preference</Text>
      </Group>

      <Group position='right'>
        <Group spacing={2}>
          <World size={13} />
          <Menu>
              <Menu.Target>
              <Text size="xs">English(US)</Text>
              </Menu.Target>
              <Menu.Dropdown>
              <Menu.Item>
                <Text size="xs">English(UK)</Text>
                </Menu.Item>
                <Menu.Item>
                <Text size="xs">French</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
        </Group>
        <Text size="xs">Support & Resources</Text>
      </Group>
    </Group>
            </main>
    </Paper>
    </div>
</SimpleGrid>
</>
  );
};

export default Login;
