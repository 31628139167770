import { ActionIcon, Anchor, Box, Button, Center, Checkbox, Grid, Group, NumberInput, Paper, Select, SimpleGrid, TextInput, Title, useMantineTheme} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Plus, X } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Alert } from "@aws-amplify/ui-react";
import ReactGA from "react-ga4";

export default function CreateTarrif(){
    const { state, dispatch } = useContext(AuthContext);
    const [cost, setCost] = useState(0);
    const [withBlocks, setWithBlocks] = useState(false);
    const [blocks, setBlocks] = useState([]);
    const [customer, setCustomer] = useState("");
    const [saving, setSaving] = useState(false);
    const [tarrifs, setTarrifs] = useState([]);
    const [refresh, setRefresh] = useState([]);
    const [dataz, setDataz] = useState([
        {label: "Domestic/Residential", value: "1"},
        {label: "Commercial/Industrial", value: "2"},
        {label: "Government institutions", value: "3"},
        {label: "Government funded public schools", value: "4"},
        {label: "Water kiosks", value: "5"},
        {label: "Water at ATM water dispenser", value: "6"},
        {label: "Bulk water to residential flats/gated communities(from 25 households and above)", value: "7"},
        {label: "Pre-paid meter customer", value: "8"},
        {label: "Bulk water to WSPs for resale", value: "9"}
    ])
    const [success, setSuccess] = useState({success: false, message: ""});
    const [error, setError] = useState({error: false, message: ""});
    const [tarrif_name, setTarrifName] = useState("");

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchData = () => {
        setTarrifs([]);
        
        try{
            const body = {
                parent: state.userData._id
            };

            axios.post("/tarrifs/gettarrifs", body).then(({data: {data: tarrifs}, }) => {
                setTarrifs(tarrifs);

            })
        } catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
        setWithBlocks(false);
        setBlocks([]);
    }, [refresh])

    const parseCustomer = (tarrif) => {
        switch(tarrif){
            case "1":
                return "Domestic/Residential";
            case "2":
                return "Commercial/Industrial";
            case "3":
                return "Government institutions";
            case "4":
                return "Government funded public schools";
            case "5":
                return "Water kiosks";
            case "6":
                return "Water at ATM water dispenser";
            case "7":
                return "Bulk water to residential flats/gated communities";
            case "8":
                return "Pre-paid meter customer";
            case "9":
                return "Bulk water to WSPs for resale";
            default:
                return "";
        }
    }

    const addBlock = () => {
        setBlocks(prevBlocks => ([...prevBlocks, {lower: blocks.length > 0 ? blocks[blocks.length - 1].upper + 1 : 0,upper: 0, cost: 0, flatRate: false}]));
    }

    const removeBlock = (index) => {
        blocks.splice(index, 1);
        setBlocks([...blocks]);
    }

    const handleChange = (num, index, type) => {
        switch(type){
            case "lower":
                blocks[index].lower = num;
                break;
            case "upper":
                blocks[index].upper = num;
                break;
            case "cost":
                blocks[index].cost = num;
                break;
            case "flatrate":
                blocks[index].flatRate = num;
                break;
            default:
                // do nothing

        }

        setBlocks([...blocks]);
    }

    useEffect(() => {
        if(withBlocks){
            addBlock();
        } else {
            setBlocks([]);
        }
    }, [withBlocks]);

    const saveDetails = (e) => {
        e.preventDefault();
        setSaving(true);
        const body = {
            parent: state.userData._id,
            name: tarrif_name.trim() === "" ? parseCustomer(customer) : tarrif_name,
            tarrif: parseCustomer(customer),
            has_blocks: withBlocks,
            blocks: withBlocks ? blocks : [{flatRate: false, cost: cost}]
        };

        axios.post("/tarrifs/create",body).then(({data: {data: tarrifs}, }) => {
            setSuccess({success: true, message: "The tarrif has been configured successfully. Customers falling under this category will be charged based on the costs and units saved. You can always edit or delete this tarrif."})
            setSaving(false);
            setRefresh(!refresh);
            setCustomer("");
            setWithBlocks(false);
            setBlocks([]);

            window.location.reload();
            
        }).catch(function(error){
            setError({error: true, message: error.message})
            setSaving(false);
            setRefresh(!refresh);
        });
    }

    const theme = useMantineTheme();

    return (
        <Paper p="md">
        {success.success ? (
            <Alert marginTop={-10} marginLeft={-10} marginRight={-10} marginBottom={20} variation="success" hasIcon={true} isDismissible={false}>
                {success.message}
          </Alert>
        ) : null}

        {error.error ? (
            <Alert marginTop={-20} marginLeft={-20} marginRight={-10} marginBottom={20} variation="error" hasIcon={true} isDismissible={false}>
                {error.message}
          </Alert>
        ) : null}
        <Group position="apart" mb={20}>
            <Group position="left">
                <Title order={4} weight={300}>Create Water Tarrif</Title>
            </Group>
        </Group>
        <TextInput label="Tarrif Name" description="A catchy name that describes this tarrif" value={tarrif_name} onChange={(e) => {setTarrifName(e.currentTarget.value)}} mb="mb" />
        
        <Select mb={10} label="Customer Category" value={customer} onChange={(val) => {setCustomer(val)}} data={dataz.filter((el) => {
            return tarrifs.every((f) => {
                return f.tarrif != el.label;
            });
         })} 
        />
        <Checkbox checked={withBlocks} onChange={(e) => {setWithBlocks(e.currentTarget.checked)}} label="With consumption blocks?" mb={20} />
        {withBlocks ? (
            blocks.map((item, index) => {
                return (
                    <SimpleGrid
                    key={`block-${index}`}
                    mb={10}
                  cols={3}
                  spacing="lg"
                  breakpoints={[
                    { maxWidth: 980, cols: 3, spacing: 'md' },
                    { maxWidth: 755, cols: 1, spacing: 'sm' },
                    { maxWidth: 600, cols: 1, spacing: 'sm' },
                  ]}
                >
                  <NumberInput step={0.01} value={blocks[index].lower} onChange={(val) => {handleChange(val, index, "lower")}} precision={2} hideControls size="xs" variant={index !== blocks.length - 1 ? "filled" : "default"} label="Lower Unit(M³)" />
                  <NumberInput step={0.01} value={blocks[index].upper} onChange={(val) => {handleChange(val, index, "upper")}} precision={2} hideControls size="xs" variant={index !== blocks.length - 1 ? "filled" : "default"} label="Upper Unit(M³)" />
                    <Grid>
                        <Grid.Col span={6}>
                        <NumberInput step={0.01} value={blocks[index].cost} onChange={(val) => {handleChange(val, index, "cost")}} precision={2} hideControls size="xs" variant={index !== blocks.length - 1 ? "filled" : "default"} label="Rate(KSH)" />
                        </Grid.Col>
                        <Grid.Col span="auto">
                            <Group mt={30}>
                                <Checkbox checked={blocks[index].flatRate} onChange={(e) => {handleChange(e.currentTarget.checked, index, "flatrate")}} size="xs" label="FlatRate?" color="gray" />
                            </Group>
                        </Grid.Col>
                        <Grid.Col span="auto">
                            <Group mt={30} >
                            {index === blocks.length - 1 ? (
                            <ActionIcon size="sm" onClick={() => {addBlock()}} variant="light" radius={15} >
                            <Plus size={16} />
                        </ActionIcon>
                            ) : (
                                <Group>
                            <ActionIcon onClick={()=>{removeBlock(index)}} size="sm" variant="light" radius={15} >
                                <X size={16} />
                            </ActionIcon>
                                </Group>
                            )}
                            </Group>
                        </Grid.Col>
                    </Grid>
                </SimpleGrid>
                )
            })
        ) : (
            <NumberInput value={cost} onChange={(val) => {setCost(val)}} hideControls step={1} min={0} size="xs" mb={10} label="Rate(KSH)" />
        )}
        <Anchor mr={20} component={Link} to={`/app/configure/tarrifs#${state.userData._id}`}>
            <Center inline>
                <IconArrowLeft size={14} />
                <Box ml={5}>Back to Water Tarrifs Page</Box>
            </Center>
            </Anchor> 
        <Button disabled={saving || customer === ""} loading={saving} mt={10} radius={28} onClick={(e) => {saveDetails(e)}} >Create Water Tarrif</Button>
        </Paper>
    )
}