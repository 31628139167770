import { Accordion, ActionIcon, Button, Center, Divider, Drawer, Grid, Group, Menu, NumberInput, Paper, Stack, Table, Text, TextInput, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { ArrowLeft, Dots, FileExport, FileImport, Plus, RefreshAlert, X } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { Alert, Heading, SearchField } from "@aws-amplify/ui-react";
import { useContext, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import { Helmet } from "react-helmet";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faList } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import swal from "sweetalert";
import CreateDate from "./create";
import ReactGA from "react-ga4";

export default function Dates(){
    const theme = useMantineTheme();
    const { state, dispatch } = useContext(AuthContext);
    const [dates, setDates] = useState(null);
    const [r, setR] = useState(false);
    const [new_date, setNewDate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [opened, setOpened] = useState(false);

    const [id, setID] = useState("");
    const [starts, setStarts] = useState(20);
    const [duration, setDuration] = useState(14);
    const [review, setReview] = useState(2);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchDates = () => {
        setR(true)
        axios.post("/dates/get-dates", {
            parent: state.userData._id
        }).then(function(res){
            if(res.status === 200){
                setDates(res.data.data);
                setR(false);
            }
        }).catch(function(error){
            console.log(error);
            setR(false);
        })
    } 

    useEffect(() => {
        fetchDates()
    }, [refresh]);

    const deleteDate = (id) => {
        axios.post("/dates/delete-date", {
            id: id
        }).then(function(res){
            if(res.status === 200){
                toast.success("The date was has been dis-configured");
                setRefresh(!refresh);
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    const confirm_delete_date = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to continue?",
            icon: "warning",
            buttons: {
                cancel: 'No, Cancel Please!',
                continue: {
                    text: "Yes, I'm sure about this!",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                deleteDate(id)
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    const activateEditPanel = (id) => {
        setID(id);
        setStarts(dates?.starts);
        setDuration(dates?.duration);
        setReview(dates?.reviewWindow);

        setOpened(true);
    }

    const editDate = () => {
        axios.post("/dates/update", {
            id: id,
            update: {
                starts: starts,
                duration: duration,
                reviewWindow: review
            }
        }).then(function(res){
            if(res.status === 200){
                setRefresh(!refresh);
                setOpened(false);
            }
        }).catch(function(error){
            console.log(error);
            setOpened(false);
        })
    }

    return (
        <Paper p="md" >
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Configure Important Dates</title>
        </Helmet>
        <Drawer opened={opened} onClose={() => {setOpened(false)}} position="right" title="Edit date" padding="md" overlayProps={{ opacity: 0, blur: 0 }}>
        <NumberInput hideControls label="Starts" placeholder="Starts on" value={starts} min={1} max={28} onChange={(val) => {setStarts(val)}} />
        <NumberInput hideControls mt="md" label="Duration" placeholder="Duration" value={duration} min={1} max={28} onChange={(val) => {setDuration(val)}} />
        <NumberInput hideControls mt="md" label="Review Duration" placeholder="Review" value={review} min={1} max={28} onChange={(val) => {setReview(val)}} />

        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
            <Button size='sm' radius={28} onClick={() => {editDate()}}>Save Details</Button>
        </Group>
      </Drawer>
        <Heading level={3} fontWeight={650} marginBottom={30} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Dates</Heading>
        <Text mb={50}>Configure important dates in which various tasks will run. </Text>

                {dates === null && !r && !new_date? (
                    <>
                    <Group position="center" mt="10%">
                        <FontAwesomeIcon icon={faList} size="2xl" />
                        </Group>

                    <Text align="center" mt={30}>No dates have been configured. Default dates will be used.</Text>
                    <Center mt={30} mb="10%">
                        <Button onClick={() => {setNewDate(true)}} variant="default">Configure Date.</Button>
                    </Center>
                    </>
                ) : r && !new_date ? (
                    <>
                    <Group position="center" mt="10%">
                        <FontAwesomeIcon icon={faGear} spin />
                        </Group>

                    <Text mb="10%" align="center" mt={30}>Loading...</Text>
                    </>
                ) : !new_date ? (
                <Table size="xs" style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                        <tr>
                            <th>Starts On</th>
                            <th>Duration</th>
                            <th>Review window</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                                    <td>{dates?.starts}</td>
                                    <td>{dates?.duration}</td>
                                    <td>{dates?.reviewWindow}</td>
                                    <td>
                                        <Menu>
                                            <Menu.Target>
                                                <ActionIcon>
                                                    <Dots />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Item onClick={() => {activateEditPanel(dates?._id)}}>Edit</Menu.Item>
                                                <Menu.Item onClick={() => {confirm_delete_date(dates?._id)}}>Delete</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </td>
                                </tr>
                    </tbody>
                </Table>
                ) : (
                    <CreateDate />
                )}

        </Paper>
    )
}