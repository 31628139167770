import { Divider, Grid, Group, Text, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { SearchField } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

export default function Support(){
    const theme = useMantineTheme();
    const { height } = useViewportSize();
    return (
        <Grid>
            <Grid.Col sm={12} lg={12} style={{height: (height - HEADER_HEIGHT)}}>    
        <SearchField hasSearchButton={false} hasSearchIcon marginTop={10} placeholder="Search complains" style={{color: theme.colorScheme === "dark" ? "white" : "black"}} />   
        <Divider ml={-10} mr={-10} mt={10} mb={10} />      
            <Group position="center" mt="20%">
            <FontAwesomeIcon icon={faList} size="2xl" />
            </Group>

            <Text align="center" mt={30}>No complains yet. complains will be displayed here.</Text>
</Grid.Col>
        </Grid>
    )
}