import { useState, useContext, useEffect } from 'react';
import {
    Navbar,
    Group,
    NavLink,
    ScrollArea,
    Divider,
    useMantineTheme,
    ActionIcon
  } from '@mantine/core';
  import {useViewportSize } from '@mantine/hooks';
  import {
    IconUser,
    IconHelp,
    IconHome,
    IconSettings,
    IconUsers,
    IconAnalyze,
    IconRefreshAlert,
    IconGitFork,
    IconActivity,
    IconTool,
    IconAlertTriangle,
    IconDeviceAnalytics,
    IconFileReport,
    IconChartBar,
  } from '@tabler/icons';
import useStyles from "./navbar.style";
import { SidebarContext } from '../context/sidebar/sidebar.context';
import { Folders, Logout, StackPush, X } from 'tabler-icons-react';
import { Link } from "react-router-dom";
import { AuthContext } from '../../../../App';
import { CompanyLogo } from '../companyLogo';

  export function DrawerNavbar() {
    const { classes } = useStyles();
    const { state, dispatch } = useContext(SidebarContext);
    const { state: authState, dispatch: authDispatch} = useContext(AuthContext);
    const [opened, setOpened] = useState(false);

    const [sidebar, ] = useState("!default")

    const links = [
      { icon: IconHome, label: 'Home', href: `/app?utility_id=${authState.userData._id}&tab=basic&version=v1` },
      { icon: IconUsers, label: 'Customers', href: `/app/customers/customers-list`},
      { icon: IconAnalyze, label: 'Billing', href: `/app/payments`, },
      { icon: IconUser, label: 'Staff', href: `/app/staff`},
      { icon: IconRefreshAlert, label: 'Tasks', href: `/app/tasks`},
      { icon: IconDeviceAnalytics, label: 'Devices', href: `/app/devices`},
      { icon: IconFileReport, label: 'Reports', href: `/app/reports`},
      { icon: IconTool, label: 'NRW Flow Balance', href: `/app/${authState.userData._id}/production` },
      //{ icon: IconReportAnalytics, label: 'NRW Spectrum', href: `/app/spectral-analysis` },
      { icon: IconAlertTriangle, label: 'Incidents', href: `/app/incidents`, notifications: 0 },
      { icon: IconActivity, label: 'Logs', href: `/app/activity-log`, },
      { icon: IconGitFork, label: 'Configurations', href: '/app/configure/', description: {label: "Sync water sources, infrastructures and other key inventory "}, links: [
        {label: "Water Tarrifs", href: `/app/configure/tarrifs`},
        {label: "Sewer Tarrifs", href: `/app/configure/sewer-tarrifs`},
        {label: "Billing Method", href: `/app/configure/billing-methods`},
        {label: "Standing Charges", href: `/app/configure/standing-charges`},
        {label: "Recurring Tasks", href: `/app/configure/recurring-tasks`},
        {label: "Meter Reading Cycle", href: `/app/configure/dates`},
        {label: "District Metered Areas", href: `/app/configure/dma`},
        {label: "SMS Triggers", href: `/app/configure/sms`},
        {label: "Disconnection", href: "/app/configure/disconnection"},
        {label: "Invoice Defaulting", href: "/app/configure/invoice-defaulting"},
        //{label: "Infrastructure", href: `/app/configure/infrastructure#${authState.userData._id}`},
      ] },
      { icon: IconHelp, label: 'Support', href: `/app/support`, },
      { icon: IconSettings, label: 'Settings', href: `/app/settings/profile`, },
    ];

    useEffect(() => {
      if(window.location.href.includes("/app/configure")){
        setOpened(true);
      } else {
        setOpened(false);
      }
    }, [window.location.href])

    const theme2 = useMantineTheme();

    const itemLinksLight = links.map((link, idx) => {
      const hasLinks = Array.isArray(link.links);
      return(
        hasLinks ? (
          <NavLink opened={opened} onClick={() => {setOpened(!opened)}} active={window.location.href.includes("/app/configure")} component={Link} to={link.href} key={idx} label={link.label} icon={<link.icon strokeWidth={1} size={18}/>}  styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          >
            {link.links.map((itm) => {
              return (
                <NavLink
                component={Link}
                active={window.location.href.includes(itm.href)}
                to={itm.href}
                variant='subtle'
                key={itm.label}
                label={itm.label}
                />
              )
            })}
          </NavLink>
        ) : (
          <NavLink
          component={Link}
          styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          active={window.location.href.includes(link.href)}
          to={link.href}
          description={link.description?.label}
          label={link.label}
          icon={<link.icon strokeWidth={1} size={18} />}
          key={link.label}
          />
        )
      )
    })

  const { height, width } = useViewportSize();

    return (
      <Navbar hiddenBreakpoint="sm" hidden={state.opened} width={{ sm: state.width }} p="md" className={classes.navbar}>
        <Navbar.Section>
          <Group position='apart' ml={"xl"} mr="xl" mb="md">
            <CompanyLogo />
            <ActionIcon onClick={() => {dispatch({type: "TOGGLE"})}}>
              <X />
            </ActionIcon>
          </Group>
        </Navbar.Section>
        <Navbar.Section grow component={ScrollArea} sx={(theme) => ({height: '100%' })} className={classes.section}>
        <div className={classes.mainLinks}>{itemLinksLight}</div>
        </Navbar.Section>

        <Divider />
        <Navbar.Section style={{bottom: 10, marginBottom: 20}} className={classes.lastSection}>
        <Divider mb={10} mr={10} ml={10} />
        <NavLink
          component={Link}
          to={'/app/infrastructure'}
          label="Utility Assets"
          icon={<Folders size={18} />}
          />
          <NavLink
          component={Link}
          to={'/app/supply'}
          label="Supply History"
          active={window.location.href.includes("/app/supply")}
          icon={<IconChartBar size={18} />}
          />
        <NavLink
          component={Link}
          to={'/app/inventory-mapper'}
          label="Inventory Mapper"
          icon={<StackPush size={18} />}
          />
          <NavLink
          component={Link}
          to={'#'}
          label="Sign Out"
          onClick={() => {authDispatch({type:"SIGN_OUT"})}}
          icon={<Logout color='red' size={18} />}
          />
        </Navbar.Section>

      </Navbar>
    );
  }