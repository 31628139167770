import { ActionIcon, Button, Center, Group, Loader, Menu, NumberInput, Paper, Table, TextInput, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { Dots, FileExport, Plus } from "tabler-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { toast } from "react-hot-toast";
import { Alert } from "@aws-amplify/ui-react";
import swal from "sweetalert";
import { TaskContext } from "../../tasks/task-context/task.context";
export default function RecurringTasks(){
    const { state, dispatch } = useContext(AuthContext);
    const { state: navState, dispatch: navDispatch} = useContext(TaskContext);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.post("/tasks/user-recurring-tasks", {
            parent: state.userData._id
        }).then(function(res){
            if(res.status === 200){
                setData(res.data.data)
                setLoading(false);
            }
        }).catch(function(error){
            toast.error(error.message, {
                position: "bottom-right"
            });
            setLoading(false);
        })
    }, [refresh]);

    const terminateTask = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to terminate this task?",
            icon: "warning",
            buttons: {
                cancel: "No, Cancel Please!",
                continue: {
                    text: "Yes, I'm sure about this!",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                axios.post("/tasks/terminate-task", {
                    id: id
                }).then(function(res){
                    if(res.status === 200){
                        setRefresh(!refresh);
                        toast.success("The task has been terminated. It is no longer recurring.")
                    }
                }).catch(function(error){
                    toast.error(error.message);
                })
            }
        })
    }

    const theme = useMantineTheme();

    const createTask = () => {
        //navDispatch({type: "NAVIGATE", step: "2"});
        navigate(`/app/tasks#${state.userData._id}`);
    }

    return (
        <Paper p="md">
            <Title weight={300}>Recurring Tasks</Title>
            <Alert marginTop={20} marginLeft={-10} marginRight={-10} marginBottom={20} variation="info" hasIcon={true} isDismissible={false}>
                Some tasks such as customer meter readings are recursive. Configuring the days within the month when these tasks have to be handled is paramount to automating common roles. You can use this section to manage recurring tasks.
          </Alert>
            <Group mb={20} position="right">
                <Button variant="outline" onClick={() => {createTask()}} leftIcon={<Plus />}>Add New Task</Button>
            </Group>

            <div style={{overflowX: 'auto', marginTop: 20}}>
            {!loading ? (
                                <Table size="xs" style={{borderBottom: '1px solid #E9ECEF'}}>
                                <thead>
                                    <tr>
                                        <th>Task</th>
                                        <th>Recurs On</th>
                                        <th>Configured On</th>
                                        <th>Last Modified</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length === 0 ? (
                                        <tr>
                                        <td colSpan={5}>
                                            <Center>
                                                No data found
                                            </Center>
                                        </td>
                                    </tr>
                                    ) : (
                                        data.map((item, index) => {
                                            return (
                                                <tr key={`recurring-task-${index}`}>
                                                    <td>{item.title}</td>
                                                    <td>{new Date(item.startDate).getDate()}</td>
                                                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                                    <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                                                    <td>
                                                        <Menu>
                                                            <Menu.Target>
                                                                <ActionIcon>
                                                                    <Dots />
                                                                </ActionIcon>
                                                            </Menu.Target>
                                                            <Menu.Dropdown>
                                                                <Menu.Item onClick={() => {terminateTask(item._id)}}>Terminate</Menu.Item>
                                                            </Menu.Dropdown>
                                                        </Menu>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )}
                                </tbody>
                            </Table>
            ) : (
                <Center mt="10%" mb="10%">
                    <Loader />
                </Center>
            )}
            </div>
        </Paper>
    )
}