/**
 * This function has potential bug.
 * @param {*} num 
 * @returns 
 */
export function getDate(num){
    const today = new Date();

    let month = today.getMonth() - 1;

    let year = today.getFullYear();

    if (month < 0) {
      // just for clarity, but should typically be 11
      month = 12 + month; // Adjust for negative month
      year--; // Decrement year for previous year
    }

    let result = "";

    switch(month){
        case 0:
            result = `January ${year.toString()}`;
            break;
        case 1:
            result = `February ${year.toString()}`;
            break;
        case 2:
            result = `March ${year.toString()}`;
            break;
        case 3:
            result = `April ${year.toString()}`;
            break;
        case 4:
            result = `May ${year.toString()}`;
            break;
        case 5:
            result = `June ${year.toString()}`;
            break;
        case 6:
            result = `July ${year.toString()}`;
            break;
        case 7:
            result = `August ${year.toString()}`;
            break;
        case 8:
            result = `September ${year.toString()}`;
            break;
        case 9:
            result = `October ${year.toString()}`;
            break;
        case 10:
            result = `November ${year.toString()}`;
            break;
        case 11:
            result = `December ${year.toString()}`;
            break;

        default:
            result ="[Loading...]"
    }

    return result;
}

export function getDatefromMonth(num, year){
    let result = "";
    
    switch(num){
        case 0:
            result = `January ${year.toString()}`;
            break;
        case 1:
            result = `February ${year.toString()}`;
            break;
        case 2:
            result = `March ${year.toString()}`;
            break;
        case 3:
            result = `April ${year.toString()}`;
            break;
        case 4:
            result = `May ${year.toString()}`;
            break;
        case 5:
            result = `June ${year.toString()}`;
            break;
        case 6:
            result = `July ${year.toString()}`;
            break;
        case 7:
            result = `August ${year.toString()}`;
            break;
        case 8:
            result = `September ${year.toString()}`;
            break;
        case 9:
            result = `October ${year.toString()}`;
            break;
        case 10:
            result = `November ${year.toString()}`;
            break;
        case 11:
            result = `December ${year.toString()}`;
            break;

        default:
            result ="[Loading...]"
    }

    return result;
}


export function getMonth(num = 12){
    let today = new Date();
    today.setDate(1);

    let result = [];
    let results2 = [];
    for (let i=0; i<=num; i++) {
        result.push((today.getMonth() + 1) + '/' + today.getFullYear().toString().substr(-2));
        results2.push(today.getFullYear().toString().substr(-2))
        today.setMonth(today.getMonth() - 1);
    }

    return result.reverse();
}

export function getYear(num = 12){
    let today = new Date();
    today.setDate(1);

    let results2 = [];
    for (let i=0; i<=num; i++) {
        results2.push(today.getFullYear());
        today.setMonth(today.getMonth() - 1);
    }

    return results2.reverse();
}

export function getMonthAsNums(num = 12){
    let today = new Date();

    today.setDate(1);

    let result = [];

    for (let i=0; i<=num; i++) {
        result.push(today.getMonth() + 1);
        today.setMonth(today.getMonth() - 1);
    }

    return result.reverse();
}

export function getPeriodEnding(){
    const today = new Date();

    let month = today.getMonth() - 1;
    let year = today.getFullYear();

    let result = "";

    if(month < 0){
        month = 11;
        year -= year;
    }

    switch(month){
        case 0:
            result = `January ${year.toString()}`;
            break;
        case 1:
            result = `February ${year.toString()}`;
            break;
        case 2:
            result = `March ${year.toString()}`;
            break;
        case 3:
            result = `April ${year.toString()}`;
            break;
        case 4:
            result = `May ${year.toString()}`;
            break;
        case 5:
            result = `June ${year.toString()}`;
            break;
        case 6:
            result = `July ${year.toString()}`;
            break;
        case 7:
            result = `August ${year.toString()}`;
            break;
        case 8:
            result = `September ${year.toString()}`;
            break;
        case 9:
            result = `October ${year.toString()}`;
            break;
        case 10:
            result = `November ${year.toString()}`;
            break;
        case 11:
            result = `December ${year.toString()}`;
            break;

        default:
            result ="NaN"
    }

    return result;
}

export function getLastReadingMonth(){
    const today = new Date();

    let month = today.getMonth() - 1;

    let result = "";

    switch(month){
        case 0:
            result = `January`;
            break;
        case 1:
            result = `February`;
            break;
        case 2:
            result = `March`;
            break;
        case 3:
            result = `April`;
            break;
        case 4:
            result = `May`;
            break;
        case 5:
            result = `June`;
            break;
        case 6:
            result = `July`;
            break;
        case 7:
            result = `August`;
            break;
        case 8:
            result = `September`;
            break;
        case 9:
            result = `October`;
            break;
        case 10:
            result = `November`;
            break;
        case 11:
            result = `December`;
            break;

        default:
            result =""
    }

    return result;
}

export function getCurrentReadingMonth(){
    const today = new Date();

    let month = today.getMonth();

    let result = "";

    switch(month){
        case 0:
            result = `January`;
            break;
        case 1:
            result = `February`;
            break;
        case 2:
            result = `March`;
            break;
        case 3:
            result = `April`;
            break;
        case 4:
            result = `May`;
            break;
        case 5:
            result = `June`;
            break;
        case 6:
            result = `July`;
            break;
        case 7:
            result = `August`;
            break;
        case 8:
            result = `September`;
            break;
        case 9:
            result = `October`;
            break;
        case 10:
            result = `November`;
            break;
        case 11:
            result = `December`;
            break;

        default:
            result =""
    }

    return result;
}

export const monthDifference = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}