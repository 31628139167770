import { Text, Group, Badge } from '@mantine/core';
import { Bell } from 'tabler-icons-react';

export default function Comment({ postedAt, title, image, recent=false }) {
  return (
    <>
      <Group noWrap>
        <Bell size={14} />
        <Group position='apart'>
          <Group position='left'>
          <Text lineClamp={1} inline size="sm">{recent ? <Badge size='xs' style={{textTransform: 'none'}} >New</Badge> : null}{title}</Text>
          </Group>
          <Group style={{right: 0}} position='right'>
          <Text  size="xs" color="dimmed">
            {postedAt}
          </Text>
          </Group>
        </Group>
      </Group>
    </>
  );
}