import {
  TextInput,
  PasswordInput,
  Select,
  Button,
  Center,
  Text,
  Divider,
  Paper,
  SimpleGrid,
  Title,
  BackgroundImage,
  MediaQuery,
  Checkbox,
  FileInput,
  Space,
  NativeSelect,
  MultiSelect,
  Highlight,
  Group,
  Anchor,
  Menu
} from '@mantine/core';
import {  useMemo, useState, useContext, useEffect} from 'react';
import toast from 'react-hot-toast';
import { Link} from 'react-router-dom';
import { useViewportSize } from "@mantine/hooks";
import countryList from "react-select-country-list";
import { Upload, World } from 'tabler-icons-react';
import { Helmet } from 'react-helmet';
import axios from '../../utils/axios';
import { SESSION_DATA } from '../../constants';
import { AuthContext } from '../../App';
import parsedData from './dialCodes';
import parsedCounties from './counties';
import Autumn2 from "../../assets/bg.jpg";
import ReactGA from "react-ga4";
import { nanoid } from 'nanoid';
import { IP, OS, currentBrowser } from '../../utils/getOSAndBrowser';
import { GuestCompanyLogo } from '../dashboard/layout/companyLogo';

const Register = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { height, width } = useViewportSize();
  const [terms, setTerms] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("KE");
  const [county, setCounty] = useState([]);
  const [logo, setLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [token, setToken] = useState("");

  const options = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    ReactGA.send({hitType: "pageView", page: window.location.href})
  }, []);

useEffect(() => {
  window.history.replaceState(null, "Admin Login", "?r=emita saas root")
}, [])

const sendVerifyEmail = async () => {
  if(email.trim().length < 1){
    toast.error("You've not entered your organization's email", {
      position: "bottom-right"
    });
    return false;
  }

  if(!/^\S+@\S+$/.test(email)){
    toast.error("You've entered an invalid email", {
      position: "bottom-right"
    })
     return false;
  } 

  if(name.trim().length < 3){
    toast.error("Your utility name is invalid.", {
      position: "bottom-right"
    });
    return false;
  }

  setLoading(true);

  axios.post("/auth/code", {
    name: name,
    username: email,
    code: nanoid(8),
    browser: currentBrowser(window),
    os: OS(window),
    ip: await IP()
  }).then(function(res){
    if(res.status === 200){
      toast.success("A verification code has been send to "+email, {
        position: "bottom-right",
        duration: 5000
      });
      setLoading(false);
      setSteps(1)
    } else {
      toast.error("The email is already registered and in use!", {
        position: "bottom-right",
        duration: 5000
      })
      setLoading(false)
    }
  }).catch(function(err){
    toast.error("The token has already been send to you.", {
      position: "bottom-right",
      duration: 5000
    })
    setLoading(false)
  })

}

const verifyEmail = () => {
  if(token.length < 8){
    toast.error("The token length should be atleast 8 characters", {
      position: "bottom-right",
    });

    return false
  }

  setLoading(true);
  axios.post("/auth/verify", {
    username: email,
    token: token
  }).then(function(res){
    if(res.status === 200){
      toast.success("Verification succeeded!", {
        position: "bottom-right",
        duration: 5000
      });
      setLoading(false)
      setSteps(2);
    } else {
      toast.error("The verification token provided did not match", {
        position: "bottom-right",
        duration: 5000
      });
      setLoading(false)
    }
  }).catch(function(error){
    toast.error("Something wrong happened in the server!", {
      position: "bottom-right",
      duration: 5000
    });
    setLoading(false)
    setSteps(0);
  })
}

const submitAddress = () => {
  if(phone.trim().length < 9){
    toast.error("The phonenumber seems to be invalid", {
      position: "bottom-right"
    });
    return false;
  }

  if(address.trim().length < 2){
    toast.error("The address provided is invalid", {
      position: "bottom-right"
    });
    return false;
  }

  if(county.length === 0){
    toast.error("Seems like you have not picked your county of operation.", {
      position: "bottom-right"
    });
    return false;
  }

  setSteps(3);
}

const checkPasswords = () => {
  if(password.trim().length < 8){
    toast.error("Passwords should have a minimum of 8 characters", {
      position: "bottom-right"
    });
    return false;
  }

  if(password.trim() !== confirm_password.trim()){
    toast.error("The two passwords do not match", {
      position: "bottom-right"
    });
    return false
  }

  setSteps(4);
}
  
  const createAccount = async () => {
    if(!terms){
      toast.error("You must accept our terms of Use of Emita to continue.", {
        position: "bottom-right"
      });
      return false;
    }

    setLoading(true);
  
    try {
      const body = {
        username: email,
        password: password,
        country: country,
        phonenumber: phone,
        waterServiceName: name,
        address: address,
        county: county
      }

      axios
      .post('/auth/register', body)
      .then(({
        data: {
          data: accountData,
          token: accessToken,
        },
      }) => {
        toast.success("Your account has been created.");
        localStorage.setItem(SESSION_DATA, accessToken);
        dispatch({type: "SIGN_IN", token: accessToken, data: accountData});
        setLoading(false);
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        toast.error(error?.response?.data?.message || error.message)
      })
    } catch(error){
      toast.error(error, {
        position: "bottom-left"
      });
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Create Account</title>
      <meta name='description' content='Get started with Emita now.' />
      </Helmet>

<SimpleGrid
cols={2}
spacing={0}
breakpoints={[
{ maxWidth: 980, cols: 1}
]}
>
<MediaQuery smallerThan="md" styles={{display: 'none'}}>
      <BackgroundImage
        src={Autumn2}
        h={height}
        style={{height: height}}
      >
                <div style={{height: height}}>
                <Center mb={20} pt={height * 0.35}>
            <Title color='#ffffff'>Scalable, Modular & Flexible</Title>
        </Center>
        <Center>
        <Text color='#ffffff' size="xl" style={{textAlign: 'center'}}>Grow your water utility with Confidence. Experience efficiency,
speed and convenience in a unified platform.</Text>
        </Center>
                </div>
      </BackgroundImage>
            </MediaQuery>
<div style={{height: height, overflowY: 'auto'}}>
<Paper p="lg">
<main className="w-full h-screen-2xl items-center justify-center py-10">
  <GuestCompanyLogo link={"/account/register"} />
  <Text mb={20} mt={20}>It takes only a <span style={{color: 'steelblue'}} >few seconds</span> to create your <span style={{color: 'steelblue'}} >Emita</span> account.</Text>
  <Text inline>Already have an account? <Anchor component={Link} to='/' color="blue">Sign In</Anchor></Text>
    <Space h="sm" />
    <Divider />
    <Space h="sm" />
      {steps === 0 ? (
        <form>
        <TextInput value={name} onChange={(e) => {setName(e.currentTarget.value)}} label="Name of the Utility" placeholder="" required mb="md" styles={{input: {'::placeholder': {fontWeight: 300}}}} height={40} />
        <TextInput value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} label="Utility Email Address" type='email' required styles={{input: {'::placeholder': {fontWeight: 300}}}} height={40} mt="md" />

        <Button onClick={() => {sendVerifyEmail()}} fullWidth mt="lg" height={45} loading={loading} >Proceed</Button>
        </form>
      ) : steps === 1 ? (
        <form>
          <TextInput label="Verification Token" value={token} onChange={(e) => {setToken(e.currentTarget.value)}} placeholder="" required mb="md" styles={{input: {'::placeholder': {fontWeight: 300}}}} height={40} />
          <Button onClick={() => {verifyEmail()}} fullWidth mt="lg" loading={loading} >Verify Token</Button>
        </form>
      ) : steps === 2 ? (
        <form>
        <TextInput value={address} onChange={(e) => {setAddress(e.currentTarget.value)}} label="Address" withAsterisk mb="md" />
      <TextInput value={phone} onChange={(e) => {setPhone(e.currentTarget.value)}} label="Phonenumber" type='tel' placeholder="" withAsterisk mb="md"/> 
      <Select value={country} onChange={(val) => {setCountry(val)}} searchable label="Country" data={options} withAsterisk mb="md" /> 
      <MultiSelect searchable clearable value={county} onChange={setCounty} data={parsedCounties} label="Counties of operation" withAsterisk mb="md" />
      <Button onClick={() => {submitAddress()}} fullWidth mt="lg" loading={loading} >Proceed</Button>

        </form>
      ) : steps === 3 ? (
        <form>
        <PasswordInput value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} label="Password" withAsterisk mb="md" />
      <PasswordInput value={confirm_password} onChange={(e) => {setConfirmPassword(e.currentTarget.value)}} label="Confirm Password" withAsterisk />
      <Button onClick={() => {checkPasswords()}} fullWidth mt="lg" loading={loading} >Proceed</Button>
        </form>

      ) : (
        <form>
        <FileInput label="Company Logo(optional)" description="You can always change this later" placeholder="Upload logo" icon={<Upload />} mb={20} />
        <Checkbox checked={terms} onChange={(e) => {setTerms(e.currentTarget.checked)}} mb={20} size="sm" label={<Text size="sm" >I agree to E-mita's <span style={{color: "blue"}} >terms of service</span></Text>} />
  <Button onClick={() => {createAccount()}} fullWidth mt="lg" loading={loading} >Sign Up</Button>
  </form>
      )}

<Group mt={10} position='apart'>
      <Group position='left'>
      <Text size="xs" component='a' href='#'>Privacy Terms</Text>
      <Text size="xs"  component='a' href='#'>Data Security</Text>
      <Text size="xs"  component='a' href='#'>Preference</Text>
      </Group>

      <Group position='right'>
        <Group spacing={2}>
          <World size={13} />
            <Menu>
              <Menu.Target>
              <Text size="xs">English(US)</Text>
              </Menu.Target>
              <Menu.Dropdown>
              <Menu.Item>
                <Text size="xs">English(UK)</Text>
                </Menu.Item>
                <Menu.Item>
                <Text size="xs">French</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
        </Group>
        <Text size="xs">Support & Resources</Text>
      </Group>
    </Group>
    </main>
      </Paper>
        </div>
      </SimpleGrid>
</>
  );
};

export default Register;
