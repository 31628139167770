import { Anchor, Button, Center, Drawer, Alert, Group, NumberInput, Select, SimpleGrid, Stack, Table, TextInput, Textarea, useMantineTheme, AppShell, Header, MediaQuery, ActionIcon, Title, createStyles } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import { ArrowLeft, Map, X } from "tabler-icons-react";
import { toast } from "react-hot-toast";
import { nanoid } from "nanoid";
import SharedUtils from "../../utils/shared";
import { Heading } from "@aws-amplify/ui-react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { IconInfoCircle } from "@tabler/icons";

let Utils = new SharedUtils();

const useStyles = createStyles((theme) => ({
    header: {
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      backgroundColor: theme.colors.blue[9],
    },
  
    inner: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  
    links: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    search: {
      [theme.fn.smallerThan('xs')]: {
        display: 'none',
      },
    },
  
    link: {
      display: 'block',
      lineHeight: 1,
      padding: '8px 12px',
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      },
    },
  
    navbar: {
        paddingTop: 0,
      },
    
      section: {
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
        marginBottom: theme.spacing.md,
    
      },
    
      searchCode: {
        fontWeight: 700,
        fontSize: 10,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        border: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
        }`,
      },
    
      mainLinks: {
        paddingLeft: theme.spacing.md - theme.spacing.xs,
        paddingRight: theme.spacing.md - theme.spacing.xs,
        paddingBottom: theme.spacing.md,
      },
    
      mainLink: {
        display: 'flex',
        cursor: 'text',
        alignItems: 'center',
        width: '100%',
        fontSize: theme.fontSizes.xs,
        padding: `8px ${theme.spacing.xs}px`,
        borderRadius: theme.radius.sm,
        fontWeight: 500,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    
        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    
      mainLinkInner: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
    
      mainLinkIcon: {
        marginRight: theme.spacing.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      },
    
      mainLinkBadge: {
        padding: 0,
        width: 20,
        height: 20,
        pointerEvents: 'none',
      },
    
      collections: {
        paddingLeft: theme.spacing.md - 6,
        paddingRight: theme.spacing.md - 6,
        paddingBottom: theme.spacing.md,
      },
    
      collectionsHeader: {
        paddingLeft: theme.spacing.md + 2,
        paddingRight: theme.spacing.md,
        marginBottom: 5,
      },
  
      root: {
        position: 'relative',
        '& *': {
          cursor: 'pointer',
        },
      },
    
      collectionLink: {
        display: 'block',
        padding: `8px ${theme.spacing.xs}px`,
        textDecoration: 'none',
        cursor: 'text',
        borderRadius: theme.radius.sm,
        fontSize: theme.fontSizes.xs,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        lineHeight: 1,
        fontWeight: 500,
    
        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
  
      animalBody: {
        paddingLeft: 54,
        paddingTop: theme.spacing.sm,
      },
  }));

function InventoryTabularList(){
    const theme = useMantineTheme();

    const { state } = useContext(AuthContext);

    const { height } = useViewportSize();

    const [raw_points, setRawPoints] = useState([]);

    const [username, setUsername] = useState("");

    const [account, setAccount] = useState("");

    const [name, setName] = useState("");

    const [phonenumber, setPhoneNumber] = useState("");

    const [email, setEmail] = useState("");

    const [address1, setAddress1] = useState("");

    const [country, setCountry] = useState("");

    const [county, setCounty] = useState("");

    const [zip, setZip] = useState("");

    const [previous_reading, setPreviousReading] = useState(0);

    const [last_reading, setLastReading] = useState(0);

    const [serial, setSerial] = useState("");

    const [sewer, setSewer] = useState("");

    const [, setSewerType] = useState("");

    const [dma, setDMA] = useState("");

    const [coordinate, setCoordinate] = useState([]);

    const [tarrif, setTarrif] = useState("");

    const [meter, setMeter] = useState("");

    const [notes, setNotes] = useState("");

    const [status, setStatus] = useState("");

    const [tarrifs, setTarrifs] = useState([]);

    const [dmas, setDMAs] = useState([]);

    const [loading, setLoading] = useState(false);

    const [inventory_id, setInventoryID] = useState("");

    const [opened, setOpened] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [onsite_facilities, setOnsiteFacilities] = useState("")

    const mergeAndEdit = (id) => {

        setInventoryID(id);

        let idx = raw_points.findIndex((obj) => obj._id === id);

        if(idx !== -1){
            let item = raw_points[idx];
            setUsername(item?.meter_number);
            setAccount(item?.account);
            setName(item?.firstName + " " + item?.lastName);
            setPhoneNumber(item?.mobile);
            setEmail(item?.email);
            setAddress1(state.userData?.address);
            setCountry("Kenya");
            setCounty(state.userData?.county[0])
            //setCounty(state.userData?.county[0]);
            setZip(item?.postal_code);
            setPreviousReading(0);
            setLastReading(0);
            setSerial("");
            setSewer("onsite");
            setSewerType("");
            setOnsiteFacilities("Toilet");
            setCoordinate([item?.location?.coords?.latitude, item?.location?.coords?.longitude]);
            setTarrif("");
            setMeter("Mechanical");
            setNotes("");
            setStatus(item?.connection_status);

            setOpened(true);

        } else {
            toast.error("The record cannot be found...", {
                position: "bottom-right"
            })
        }
    }

    const mergeCustomer = () => {
        setLoading(true);
        const toastId = toast.loading('Creating new customer!');
                const id = nanoid(64);
                let body = {
                    username: username,
                    account: account,
                    parent: state.userData._id,
                    name: name,
                    phonenumber: phonenumber,
                    email: email,
                    address: address1,
                    country: country,
                    county: county,
                    zip: zip,
                    previous_reading: parseFloat(previous_reading),
                    last_reading: parseFloat(last_reading),
                    serial: serial,
                    sewer: sewer === "sewer" ? true : false,
                    sewer_type: sewer === "sewer" ? "Sewer" : onsite_facilities,
                    dma: dma,
                    password: Utils.createDummyPasscode(),
                    coordinates: coordinate,
                    tarrif: tarrif,
                    meter_type: meter,
                    notes: notes,
                    disconnected: status === "0" ? true : false
                };

                axios.post("/customers/create", body).then(function(res){
                    if(email !== ""){
                        axios.post("/tokens/create", {
                            username: email,
                            token: id
                        }).then(function(res){
                            if(res.status === 200){
                                toast.loading("Verifying the customer email...", {
                                    id: toastId
                                });
                                axios.post("/verify/send", {
                                    fullname: name,
                                    username: email,
                                    link: `http://app.emitasuite.com/customer/verify?token=${id}&email=${email}&username=${username}`
                                }).then(function(res){
                                    if(res.status === 200){
                                        
                                        // delete inventory record
                                        axios.post("/customers-meters/delete", {id: inventory_id}).then(function(res){
                                            if(res.status === 200){
                                                toast.success("Customer details saved", {
                                                    id: toastId
                                                });
                                                setOpened(false);
                                                setLoading(false);
                                                setRefresh(!refresh);
                                            }
                                        }).catch(function(error){
                                            toast.error("Something wrong happened", {
                                                id: toastId
                                            });
                                            setLoading(false);
                                        })

                                    }
                                }).catch(function(error){
                                    toast.error("Something wrong happened", {
                                        id: toastId
                                    });
                                })
                            }
                        }).catch(function(error){
                            toast.error("Something wrong happened", {
                                id: toastId
                            });
                        })
                    } else {
                        // delete inventory record
                        axios.post("/customers-meters/delete", {id: inventory_id}).then(function(res){
                            if(res.status === 200){
                                toast.success("Customer details saved", {
                                    id: toastId
                                });
                                setOpened(false);
                                setLoading(false);
                                setRefresh(!refresh);
                            }
                        }).catch(function(error){
                            toast.error("Something wrong happened", {
                                id: toastId
                            });
                            setLoading(false);
                        })
                    }
                }).catch(function(error){
                    console.log(error);
                    toast.error("A customer already exists with that meter number.", {
                        id: toastId
                    });
                    setLoading(false);
                })

    }

    const fetchData = () => {
        axios.post("/customers-meters/get-data", {
            parent: state?.userData?._id
        }).then(function(res){
            if(res.status === 200){
                setRawPoints(res.data.data);
            }
        }).catch(function(error){
            console.log(error);
        });
    }

    const fetchData2 = () => {
        setTarrifs([]);
        try{
            const body = {
                parent: state.userData._id
            };

            axios.post("/tarrifs/gettarrifs", body).then(({data: {data: t}, }) => {
                for(let i=0; i<t.length; i++){
                    let item = t[i];
                    let tt = item.tarrif;
                    let chunk = {label: item?.name, value: tt};

                    setTarrifs(prevArr => ([...prevArr, chunk]));
                }
            })
           
        } catch(error){
            console.log(error);
        }
    }

    const fetchData3 = () => {
        setDMAs([]);
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];

                    let chunk = {label: item.name, value: item.name};

                    setDMAs(prevArr => ([...prevArr, chunk]));
                }
                
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, [refresh])

    useEffect(() => {
        fetchData2();
        fetchData3();
    }, [])

    const deleteRecord = (id) => {
        axios.post("/customers-meters/delete", {id: id}).then(function(res){
            if(res.status === 200){
                setRefresh(!refresh)
            }
        }).catch(function(error){
            toast.error("Something wrong happened", {
                position: "bottom-right"
            });
        })
    }

    const askDelete = (id) => {
        swal("Are you sure you want to delete the record? This action cannot be reverted", {
            buttons: {
                cancel: 'Cancel',
                continue: {
                    text: "I'm sure, Delete",
                    value: 'continue'
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                deleteRecord(id);
            }
        })
    }

    const getColor = () => {
        return theme.colorScheme === "light" ? "#FFE3E3" : "#FA5252"
    }

    return (

       <>
            <Alert title="Adding New Customer" mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
                New customers captured by field technicians are never added automatically into the system. This section allows you to verify and add new customers into your system.
                You can always interchange between the tabular view of the inventory mapper with the map view. <Anchor component={Link} to="/app/inventory-mapper">Access Mapview here</Anchor>. Rows with a <span style={{color: "red"}}>red</span> dot have issues ranging from poor coordinate accuracy or lacking important attribute.
            </Alert>

        <Table fontSize={"xs"} highlightOnHover>
                <thead>
                  <tr>
                    <th style={{lineClamp:1}}>Firstname</th>
                    <th  style={{lineClamp:1}}>Lastname</th>
                    <th  style={{lineClamp:1}}>Mobile</th>
                    <th  style={{lineClamp:1}}>Account</th>
                    <th  style={{lineClamp:1}}>Status</th>
                    <th  style={{lineClamp:1}}>Latitude</th>
                    <th  style={{lineClamp:1}}>Longitude</th>
                    <th  style={{lineClamp:1}}>Accuracy</th>
                    <th  style={{lineClamp:1}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {raw_points.length === 0 ? (
                    <tr>
                      <td colSpan={17}>
                        <Center>No data was found</Center>
                      </td>
                    </tr>
                  ) : raw_points.map((item, idx) => {
                    return (
                        <tr key={`customer-draft-${idx}`}>
                            <td >{item.firstName}
                            {item?.location?.coords?.accuracy > 15 || item?.location?.coords === undefined || item?.location?.coords === null || item.account === "" ? (
                <span className="relative flex h-1 w-2">
                <span
                className="absolute inline-flex h-1 w-1 animate-ping rounded-full bg-red-400 opacity-75"
                ></span>
                <span
                className="relative inline-flex h-1 w-1 rounded-full bg-red-500"
                ></span>
            </span>
                ) : null}
                            </td>
                            <td >{item.lastName}</td>
                            <td >{item.mobile}</td>
                            <td >{item.account}</td>
                            <td >{item.connection_status}</td>
                            <td >{item?.location?.coords?.latitude}</td>
                            <td >{item?.location?.coords?.longitude}</td>
                            <td >{item?.location?.coords?.accuracy.toFixed(0)}</td>
                            <td>
                                <Group noWrap>
                                    <Button radius={28} size="xs" onClick={() => {mergeAndEdit(item._id)}}>Edit&Merge</Button>
                                    <Button radius={28} color="red" size="xs" onClick={() => {askDelete(item._id)}}>Delete</Button>
                                </Group>
                            </td>
                        </tr>
                    )
                  })}
                </tbody>
              </Table>
              <Drawer size={"xl"} opened={opened} onClose={() => {setOpened(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height, overflowY: "auto"}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Edit & Merge Customer</Heading>
            <SimpleGrid cols={2}>
            <TextInput mt="md" label="Meter Number" value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} />
            <TextInput mt="md" label="Account" value={account} onChange={(e) => {setAccount(e.currentTarget.value)}} />
            <TextInput mt="md" label="Full Name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} />
            <TextInput mt="md" label="Phonenumber" value={phonenumber} onChange={(e) => {setPhoneNumber(e.currentTarget.value)}} />
            <TextInput mt="md" label="Email" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} />
            <TextInput mt="md" label="Address" value={address1} onChange={(e) => {setAddress1(e.currentTarget.value)}} />
            <TextInput mt="md" label="Country" value={country} onChange={(e) => {setCountry(e.currentTarget.value)}} />
            <TextInput mt="md" label="County" value={county} onChange={(e) => {setCounty(e.currentTarget.value)}} />
            <TextInput mt="md" label="ZIP Code" value={zip} onChange={(e) => {setZip(e.currentTarget.value)}} />
            <TextInput mt="md" label="Location of the meter" value={JSON.stringify(coordinate)} />
            <NumberInput mt="md" label="Previous Reading" value={previous_reading} onChange={(val) => {setPreviousReading(val)}} hideControls min={0} />
            <NumberInput mt="md" label="Current Reading" value={last_reading} onChange={(val) => {setLastReading(val)}} hideControls min={0} />
            <TextInput mt="md" label="Serial Number" value={serial} onChange={(e) => {setSerial(e.currentTarget.value)}} />
            <Select label="DMA" mt={"md"} data={dmas} value={dma} onChange={(val) => {setDMA(val)}} />
            <Select label="Tarrif" mt="md" value={tarrif} onChange={(val => {setTarrif(val)})} data={tarrifs} />
            <Select label="Type of Meter" mt="md" value={meter} onChange={(val) => {setMeter(val)}} data={[{label: "Smart", value: "Smart"}, {label: "Mechanical", value: "Mechanical"}]} />
            <Select label="Type of sanitary facility" mt="md" value={sewer} onChange={(val) => {setSewer(val)}} data={[{label: "Sewer", value: 'sewer'}, {label: "Onsite", value: "onsite"}]} />
            {sewer === "onsite" ? (
                <TextInput mt="md" label="Onsite Facilities" placeholder="E.g toilets" value={onsite_facilities} onChange={(e) => {setOnsiteFacilities(e.currentTarget.value)}} />
            ) : null}
            <Select label="Connection status" mt="md" value={status} onChange={(val) => {setStatus(val)}} data={[{label: "Connected", value: "1"}, {label: "Disconnected", value: "0"}]}/>
            <Textarea label="Additional notes" mt="md" value={notes} onChange={(e) => {setNotes(e.currentTarget.value)}} />
            </SimpleGrid>
            </div>
        <Group mt={30} mb={50}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setOpened(false)}}>Close</Button>
            <Button size='sm' radius={28} onClick={() => {mergeCustomer()}} loading={loading}>Merge Customer</Button>
        </Group>
        </Stack>
      </Drawer>
       </>
    )
}

export default function InventoryTabular(){
    const { classes } = useStyles();

    const theme = useMantineTheme();

    const { state, dispatch } = useContext(AuthContext);

    const navigate = useNavigate();

    return (
        <AppShell styles={{
            main: {
                background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : "white",
                padding: 20,
                paddingTop: 60
              },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        header={
            <Header height={50} className={classes.header}>
            <div className={classes.inner}>
              <Group >
                <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
                <Group>
                <Anchor component={Link} to={`/app?utility_id=${state.userData._id}&tab=basic&version=v1`}>
                      <Center inline>
                      <ActionIcon sx={(theme) => ({":hover": {backgroundColor: "transparent"}})}>
                      <ArrowLeft size={14} color='white' />
                  </ActionIcon>
                  <Title color='white' order={4} style={{fontWeight: "normal"}}>{state.userData?.waterServiceName} </Title>
                      </Center>
              </Anchor>
                </Group>
                </MediaQuery>
                <MediaQuery largerThan={"md"} styles={{display: "none"}}>
                <Title color='white' order={4} style={{fontWeight: "normal"}}>{state.userData?.waterServiceName}</Title>
                </MediaQuery>
              </Group>
  
              <Group ml={50} spacing={5} className={classes.links}>
                
            </Group>
            
            <Group noWrap>

            <Button radius={28} color='teal' onClick={() => {navigate("/app/inventory-mapper")}}>Map View</Button>
              <Button radius={28} color='teal' onClick={() => {navigate("/app/service-area")}}>Update Service Area Centroid</Button>
            <Button radius={28} color='red' onClick={() => {
              swal({
                text: "Are you sure you want to logout?",
                buttons: {
                  cancel: "Cancel",
                  continue: {
                    text: "Logout",
                    value: "logout"
                  }
                }
              }).then(async (val) => {
                if(val === "logout"){
                  dispatch({type: "SIGN_OUT"})
                }
              })
            }}>Sign Out</Button>
            </Group>
            </div>
          </Header>
        }
        >
            <InventoryTabularList />
        </AppShell>
    )
}