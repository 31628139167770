import { Button, Text, Input, Paper, Select, Group, Anchor, TextInput, Center, ActionIcon, Divider, Progress, Loader, Alert, useMantineTheme, Container, Card, Title, Textarea, SimpleGrid, createStyles } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { ArrowLeft, ArrowRight, Check, Plus, X } from "tabler-icons-react";
import axios from "../../../utils/axios";
import { toast } from "react-hot-toast";
import SharedUtils from "../../../utils/shared";
import { AuthContext } from "../../../App";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import { IconAlertCircle, IconCheck,} from "@tabler/icons";
import { CustomerContext } from "../customer-context/customer.context";
import BlackGears from "../../../assets/gears.png";
import WhiteGears from "../../../assets/white-gears.png";
import { Heading } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";

let Utils = new SharedUtils();

const useStyles = createStyles((theme) => ({
    root: {
      position: 'relative',
    },
  
    input: {
      height: 54,
      paddingTop: 18,
      fontWeight: "bold"
      
    },
  
    label: {
      position: 'absolute',
      pointerEvents: 'none',
      fontSize: theme.fontSizes.xs,
      paddingLeft: theme.spacing.sm,
      paddingTop: `calc(${theme.spacing.sm} / 2)`,
      zIndex: 1,
    },
  }));

export default function CreateCustomer(){
    const { classes } = useStyles();
    const { state, dispatch } = useContext(AuthContext);
    const [current, setCurrent] = useState(0);
    const [username, setUsername] = useState("");
    const [parent, setParent] = useState(state.userData._id);
    const [phonenumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [country, setCountry] = useState("");
    const [county, setCounty] = useState("");
    const [zip, setZip] = useState("");
    const [meter, setMeter] = useState("");
    const [reading, setReading] = useState("");
    const [tarrif, setTarrif] = useState("");
    const [coordinate, setCoordinate] = useState([]);
    const [notes, setNotes] = useState("");
    const [name, setName] = useState("");
    const [dma, setDMA] = useState("");
    const [dmas, setDMAs] = useState([]);
    const [tarrifs, setTarrifs] = useState([]);
    const [ready1, setReady1] = useState(false);
    const [ready2, setReady2] = useState(false);
    const [success, setSuccess] = useState(false);
    const [status, setStatus] = useState("0");
    const [sewer, setSewer] = useState("");
    const [onsite_facilities, setOnsiteFacilities] = useState("")
    const [serial, setSerial] = useState("");
    const [s, setS] = useState({success: false, message: ""});
    const [error, setError] = useState({error: false, message: ""});
    const [step, setStep] = useState(0);
    const [account, setAccount] = useState("");

    const fetchData = () => {
        setDMAs([]);
        setReady1(false);
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            setReady1(true);
            if(res.status === 200){
                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];

                    let chunk = {label: item.name, value: item.name};

                    setDMAs(prevArr => ([...prevArr, chunk]));
                }
                
            }
        }).catch(function(error){
            toast.error(error.message);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition((position) => {
                    setCoordinate([position.coords.latitude, position.coords.longitude])
                })
            }
    }, [])


    const fetchData2 = () => {
        setTarrifs([]);
        setReady2(false);
        try{
            const body = {
                parent: state.userData._id
            };

            axios.post("/tarrifs/gettarrifs", body).then(({data: {data: t}, }) => {
                setReady2(true);
                for(let i=0; i<t.length; i++){
                    let item = t[i];
                    let tt = item.tarrif;
                    let chunk = {label: tt, value: tt};

                    setTarrifs(prevArr => ([...prevArr, chunk]));
                }
            })
           
        } catch(error){
            toast.error(error.message);
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData2();
    }, [])

    const createCustomer = () => {
        const toastId = toast.loading('Creating new customer!');
                const id = nanoid(64);
                let body = {
                    username: username,
                    account: account,
                    parent: parent,
                    name: name,
                    phonenumber: phonenumber,
                    email: email,
                    address: address1,
                    country: country,
                    county: county,
                    zip: zip,
                    previous_reading: parseFloat(reading),
                    last_reading: parseFloat(reading),
                    serial: serial,
                    sewer: sewer === "sewer" ? true : false,
                    sewer_type: sewer === "sewer" ? "Sewer" : onsite_facilities,
                    dma: dma,
                    password: Utils.createDummyPasscode(),
                    coordinates: coordinate,
                    tarrif: tarrif,
                    meter_type: meter,
                    notes: notes,
                    disconnected: status === "0" ? true : false
                };

                axios.post("/customers/create", body).then(function(res){
                    if(email !== ""){
                        axios.post("/tokens/create", {
                            username: email,
                            token: id
                        }).then(function(res){
                            if(res.status === 200){
                                toast.loading("Verifying the customer email...", {
                                    id: toastId
                                });
                                axios.post("/verify/send", {
                                    fullname: name,
                                    username: email,
                                    link: `http://app.emitasuite.com/customer/verify?token=${id}&email=${email}&username=${username}`
                                }).then(function(res){
                                    if(res.status === 200){
                                        toast.success("Customer details saved", {
                                            id: toastId
                                        });
                                        clearCanvas();
                                    }
                                }).catch(function(error){
                                    toast.error("Something wrong happened", {
                                        id: toastId
                                    });
                                })
                            }
                        }).catch(function(error){
                            toast.error("Something wrong happened", {
                                id: toastId
                            });
                        })
                    } else {
                        toast.success("Customer details saved", {
                            id: toastId
                        });
                        clearCanvas();
                    }
                }).catch(function(error){
                    setError({error: true, message: error.message});
                    toast.dismiss(toastId);
                })

    }

    const clearCanvas = () => {
        setSuccess(false);
        setCurrent(0);
        setUsername("");
        setAccount("");
        setPhoneNumber("");
        setEmail("");
        setAddress1("");
        setCountry("");
        setCounty("");
        setMeter("");
        setReading("");
        setTarrif("");
        setNotes("");
        setName("");
        setStatus("0");
        setStep(0);
    }
    
    const theme = useMantineTheme();

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Customers Management Dashboard </title>
        </Helmet>
        <Heading level={6} fontWeight={650} marginBottom={20} marginTop={10} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Create New Customer</Heading>
        <Text size="sm" mb={20} mt={20} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}><strong>Page refresh time:</strong>{` ${new Date().toString()}`}</Text>

        {s.success ? (
            <Alert mb={20} icon={<IconCheck />} title="Success" variant="filled">
                {s.message}
          </Alert>
        ) : null}

        {error.error ? (
            <Alert  mb={20} icon={<IconAlertCircle/>} title="Sorry!" color="red" variant="filled">
                {error.message}
          </Alert>
        ) : null}

        {dmas.length > 0 && tarrifs.length > 0 && ready1 && ready2 ? (
                    <>
                    {success ? (
                        <Paper mb={20} shadow="sm" p="md">
                            <Group position="apart">
                                <Group position="left">
                                    <ActionIcon radius={28} color="blue" variant="filled">
                                        <Check size={16} />
                                    </ActionIcon>
                                    <Text size="sm" >Success!</Text>
                                </Group>
                                <Group position="right">
                                    <X />
                                </Group>
                            </Group>
            
                        <Text mt={20} >Customer added successfully. <Anchor onClick={() => {clearCanvas()}} to="#" >Add new customers</Anchor></Text>
                        </Paper>
                    ) : (
                        <>
                                {step === 0 ? (
                                <Paper shadow="xs" p={0} withBorder>
                                <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                                    <Group position="apart">
                                        <div>
                                            <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                                                <Group>
                                                    Basic Details
                                                </Group>
                                            </Heading>
                                        </div>
                                    </Group>
                                </Paper>
                                <Divider />
                                <Paper p="md">
                                <TextInput label="Name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} placeholder="Name of the organization or individual" classNames={classes} styles={{input: {'::placeholder': {fontWeight: 300}}}} />
                                <TextInput mt="md" label="Email Address" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} placeholder="Business email address of the organization or individual" classNames={classes} styles={{input: {'::placeholder': {fontWeight: 300}}}} />
                                <TextInput mt="md" label="Phone Number" value={phonenumber} onChange={(e) => {setPhoneNumber(e.currentTarget.value)}} placeholder="Phone number of the organization or individual" classNames={classes} styles={{input: {'::placeholder': {fontWeight: 300}}}}/>
                                <TextInput mt="md" label="Account Number" value={account} onChange={(e) => {setAccount(e.currentTarget.value)}} placeholder="Unique account number of the organization or individual" classNames={classes} styles={{input: {'::placeholder': {fontWeight: 300}}}}/>
                                
                                <Group position="right">
                                <Button mt="md" disabled={name === "" || phonenumber === ""} variant="default" onClick={() => {setStep(1)}} rightIcon={<ArrowRight />}>Continue to Address Details</Button>
                                </Group>
                                </Paper>
                            </Paper>
                                ) : step === 1 ? (

                                    <Paper shadow="xs" p={0} withBorder>
                                    <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                                        <Group position="apart">
                                            <div>
                                                <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                                                    <Group>
                                                        Address Details
                                                    </Group>
                                                </Heading>
                                            </div>
                                        </Group>
                                    </Paper>
                                    <Divider />
                                    <Paper p="md">
                                        <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} classNames={classes} label="Country" placeholder="Defaults to the country where the utility is located" value={country} onChange={(e) => {setCountry(e.currentTarget.value)}} />
                                        <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes} label="County" placeholder="Defaults to county where the utility is located" value={county} onChange={(e) => {setCounty(e.currentTarget.value)}} />
                                        <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes} label="Zip Code" placeholder="e.g 00200" value={zip} onChange={(e) => {setZip(e.currentTarget.value)}} />
                                        <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes} withAsterisk label="Address" placeholder="Address of the customer" value={address1} onChange={(e) => {setAddress1(e.currentTarget.value)}} />
                                        <Group mt="md" position="right">
                                        <Button  variant="default" onClick={() => {setStep(0)}} leftIcon={<ArrowLeft />}>Go Back to Basic Details</Button>
                                        <Button disabled={country === "" || county === "" || zip === "" || address1 === ""} variant="default" onClick={() => {setStep(2)}} rightIcon={<ArrowRight />}>Continue to DMA & Tarrif Details</Button>
                                        </Group>
                                    </Paper>
                                </Paper>
                                ) : step === 2 ? (
                                    <Paper shadow="xs" p={0} withBorder>
                                    <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                                        <Group position="apart">
                                            <div>
                                                <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                                                    <Group>
                                                        DMA & Tarrif Details
                                                    </Group>
                                                </Heading>
                                            </div>
                                        </Group>
                                    </Paper>
                                    <Divider />
                                    <Paper p="md">
                                    <Select styles={{input: {'::placeholder': {fontWeight: 300}}}} classNames={classes}  label="DMA" placeholder="Customer's zone" data={dmas} value={dma} onChange={(val) => {setDMA(val)}} />
                                    <Select styles={{input: {'::placeholder': {fontWeight: 300}}}} classNames={classes}  label="Tarrif" placeholder="Customer's category" mt="md" value={tarrif} onChange={(val => {setTarrif(val)})} data={tarrifs} />
                                    <Group mt="md" position="right">
                                    <Button  variant="default" onClick={() => {setStep(1)}} leftIcon={<ArrowLeft />}>Go back to address details</Button>
                                    <Button disabled={dma === "" || tarrif === ""} variant="default" onClick={() => {setStep(3)}} rightIcon={<ArrowRight />}>Continue to meter details</Button>
                                    </Group>
                                    </Paper>
                                </Paper>

                                ) : step === 3 ? (
                                    <Paper shadow="xs" p={0} withBorder>
                                    <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                                        <Group position="apart">
                                            <div>
                                                <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                                                    <Group>
                                                        Meter Details
                                                    </Group>
                                                </Heading>
                                            </div>
                                        </Group>
                                    </Paper>
                                    <Divider />
                                    <Paper p="md">
                                    <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} classNames={classes}  label="Meter Number" placeholder="Unique meter number" value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} />
                                    <Select styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes}  label="Type of Meter" placeholder="Is it mechanical or smart?" value={meter} onChange={(val) => {setMeter(val)}} data={[{label: "Smart", value: "Smart"}, {label: "Mechanical", value: "Mechanical"}]} />
                                    <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes}  label="Last billed reading" placeholder="e.g 0.00" value={reading} onChange={(e) => {setReading(e.currentTarget.value)}} />
                                    <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes}  label="Location of the meter" placeholder="Allow GPS to capture location" value={JSON.stringify(coordinate)} />
                                        <Group mt="md" position="right">
                                        <Button  variant="default" onClick={() => {setStep(2)}} leftIcon={<ArrowLeft />}>Go back to DMA & Tarrif Details</Button>
                                        <Button disabled={username === "" || meter === "" || reading === "" || coordinate.length === 0} rightIcon={<ArrowRight />} variant="default" onClick={() => {setStep(4)}}>Continue to Sewer Details</Button>
                                        </Group>
                                    </Paper>
                                </Paper>

                                ) : step === 4 ? (
                                    <Paper shadow="xs" p={0} withBorder>
                                    <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                                        <Group position="apart">
                                            <div>
                                                <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                                                    <Group>
                                                        Sewer Details
                                                    </Group>
                                                </Heading>
                                            </div>
                                        </Group>
                                    </Paper>
                                    <Divider />
                                    <Paper p="md">
                                    <Select styles={{input: {'::placeholder': {fontWeight: 300}}}} classNames={classes} label="Type of sanitary facility" placeholder="Sewer or onsite facilities?" value={sewer} onChange={(val) => {setSewer(val)}} data={[{label: "Sewer", value: 'sewer'}, {label: "Onsite", value: "onsite"}]} />
                                    {sewer === "onsite" ? (
                                <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} mt="md" classNames={classes} label="Onsite Facilities" placeholder="E.g toilets" value={onsite_facilities} onChange={(e) => {setOnsiteFacilities(e.currentTarget.value)}} />
                                ) : null}
                                   <Group mt="md" position="right">
                                        <Button  variant="default" onClick={() => {setStep(3)}} leftIcon={<ArrowLeft />}>Go back to meter Details</Button>
                                        <Button  variant="default" onClick={() => {setStep(5)}}>Skip</Button>
                                        <Button disabled={sewer === ""} rightIcon={<ArrowRight />} variant="default" onClick={() => {setStep(5)}}>Save & continue to connection status Details</Button>
                                        </Group>
                                    </Paper>
                                </Paper>
                                ) : step === 5 ? (
                                    <Paper shadow="xs" p={0} withBorder>
                                    <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                                        <Group position="apart">
                                            <div>
                                                <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                                                    <Group>
                                                        Connection status & Submission
                                                    </Group>
                                                </Heading>
                                            </div>
                                        </Group>
                                    </Paper>
                                    <Divider />
                                    <Paper p="md">
                                    <Select styles={{input: {'::placeholder': {fontWeight: 300}}}} label="Connection status" placeholder="Is connection active?" classNames={classes} value={status} onChange={(val) => {setStatus(val)}} data={[{label: "Connected", value: "1"}, {label: "Disconnected", value: "0"}]}/>
                                    <Textarea styles={{input: {'::placeholder': {fontWeight: 300}}}} label="Additional notes" placeholder="optional" mt="md" classNames={classes} value={notes} onChange={(e) => {setNotes(e.currentTarget.value)}} />
                                     <Group mt="md" position="right">
                                        <Button  variant="default" onClick={() => {setStep(4)}} leftIcon={<ArrowLeft />}>Go back to sewer details</Button>
                                        <Button disabled={status === ""} rightIcon={<ArrowRight />} variant="default" onClick={() => {createCustomer()}}>Save Details and Create Customer</Button>
                                        </Group>
                                    </Paper>
                                </Paper>

                                ) : null}
                    </>
                    )}
            
                    </>
        ) : (dmas.length === 0 || tarrifs.length === 0) && ready1 && ready2 ? (
            <Paper p="lg" radius="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>

                {dmas.length === 0 ? (
                    <Text><strong>0</strong> DMAs have been configured</Text>
                ) :  tarrifs.length === 0 ? (
                    <Text ><strong>0</strong> Tarrifs have been configured</Text>
                ) : null}

                <Progress size="xs" value={100} mt={20} />

                {dmas.length === 0 ? (
                    <Text mt={20} ><strong>First thing first:</strong>Configure DMAs</Text>
                    
                ) : tarrifs.length === 0 ? (
                    <Text mt={20} ><strong>First thing first: </strong>Configure tarrifs</Text>
                ) : null}
                
                <Center mt={30} >
                    <img src={theme.colorScheme === "dark" ? WhiteGears : BlackGears} height={100} width={100} alt="Gears" />
                </Center>

                <Center mt={50} mb={50} >
                    {dmas.length === 0 ? (
                        <Button leftIcon={<Plus color="white" />} component={Link} to={`/app/configure/dma#${state.userData._id}`} styles={{label: {':hover': {color: "white"}, color: "white"}}}>Add Atleast One DMA</Button>
                    ) : tarrifs.length === 0 ? (
                        <Button leftIcon={<Plus color="white" />} component={Link} to={`/app/configure/tarrifs#${state.userData._id}`} styles={{label: {':hover': {color: "white"}, color: "white"}}}>Create Atleast One Tarrif</Button>
                    ) : null}
                </Center>
            </Paper>
        ) : (
            <Center mt="20%" mb="20%" >
                <Loader />
            </Center>
        )}
        </>
    )
}