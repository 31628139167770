import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import { Heading, SearchField } from "@aws-amplify/ui-react";
import { useMantineTheme } from "@mantine/styles";
import { Accordion, Divider, Grid, Group, MediaQuery, Select, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faList } from '@fortawesome/free-solid-svg-icons'
import { IconChevronDown } from "@tabler/icons";
import axios from "../../utils/axios";
import ReactGA from "react-ga";
import { toast } from "react-hot-toast";
import "./logs.css"

export default function SystemLogs(){
    const { state, dispatch} = useContext(AuthContext);
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    const [dmas, setDMAS] = useState([]);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        ReactGA.pageview("/app/logs");
    }, [])

    useEffect(() => {
        const fetchLogs = () => {
            const config = {
                headers: {
                    'Authorization': `Bearer ${state.userToken}`
                }
            };

            axios.get("/logs/get", config).then(function(res){
                if(res.status === 200){
                    setLogs(res.data.data);
                }
            }).catch(function(error){
                toast.error("Something wrong occured", {
                    position: "bottom-right"
                })
            })
        }

        const timer = setInterval(() => {
            fetchLogs();
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, []);

    const fetchDMAs = () => {
        setDMAS([]);
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                let arr = [{label:"All Zones", value: "all"}];

                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];
                    arr.push({label: item.name, value: item.name});
                    //setDMAS(prevArr => ([...prevArr, item.name]));
                }

                setDMAS(arr);
                
            }
        }).catch(function(error){
            console.warn(error.message);
        })
    }

    useEffect(() => {
        fetchDMAs();
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | System Logs</title>
        </Helmet>
        <Heading level={3} fontWeight={650} marginBottom={30} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Logs</Heading>
        <Text mb={50}>Search, inspect, and share the runtime logs from your E-mita account.</Text>

        <Divider ml={-10} mr={-10} />

        <Grid>
            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
            <Grid.Col mt={8} sm={12} lg={3} style={{height: (height - HEADER_HEIGHT - 150), borderRight: theme.colorScheme === "dark" ? `2px solid ${theme.colors.dark[6]}` : `2px solid ${theme.colors.gray[4]}`}}>
            <Heading fontWeight={500} marginBottom={20} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Filters</Heading>
            <Accordion chevronPosition="left">
                <Accordion.Item value="timeline" style={{borderBottom: 0}}>
                    <Accordion.Control>Timeline</Accordion.Control>
                    <Accordion.Panel>
                    <Select rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={[
                        {label: "Live⚡", value: "Live"},
                        {label: "Past 30 minutes", value: "30"},
                        {label: "Maximum 1 hour", value: "60"},
                         {label: "Custom", value: "custom"}   
                    ]} value="30" />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Zone" style={{borderBottom: 0}}>
                    <Accordion.Control>District Metered Areas</Accordion.Control>
                    <Accordion.Panel>
                    <Select rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={dmas} value="all" />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="portals" style={{borderBottom: 0}}>
                    <Accordion.Control>Platform</Accordion.Control>
                    <Accordion.Panel>
                    <Select rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={[
                        {label: "E-mita field", value: "emita-field"},
                        {label: "Customer Self Service", value: "customer"},
                        {label: "E-mita web app", value: "web"},
                    ]} value="emita-field" />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            </Grid.Col>
            </MediaQuery>
            <Grid.Col sm={12} lg={9} style={{height: (height - HEADER_HEIGHT - 150)}} p="sm">          
                        {logs.length === 0 ? (
                            <>
                    <SearchField hasSearchButton={false} hasSearchIcon marginTop={10} placeholder="Search logs" style={{color: theme.colorScheme === "dark" ? "white" : "black"}} />   
                    <Divider ml={-10} mr={-10} mt={10} mb={10} />
                        <Group position="center" mt="20%">
                        <FontAwesomeIcon icon={faList} size="2xl" />
                        </Group>

                        <Text align="center" mt={30}>No activity logs yet. Activity logs will be displayed here.</Text>
                            </>
                        ) : (
                            <div className="log-container">
                            <div className="log-content scroll" style={{maxHeight:(height - HEADER_HEIGHT - 170) }}>
                                {logs.map((item, index) => {
                                    return (
                                        <Text mb="md" size="xs" key={`log-${index}`}>{item.log}</Text>
                                    )
                                })}
                            </div>
                            </div>
                        )}
            </Grid.Col>
        </Grid>
        </>
    )
}