import { useLocalStorage } from "@mantine/hooks";

export default function Banner(){
    const [visible, setVisible] = useLocalStorage({
        key: "_emita_data_privacy_banner",
        defaultValue: "1",
        getInitialValueInEffect: true
    });
    return (
        visible === "1" ? (
            <div id="banner" tabindex="-1" className="sticky flex bg-zinc-900 border-b border-gray-200 dark:bg-white gap-8 justify-between items-start py-3 px-4 w-full sm:items-center lg:py-4">
            <p className="text-sm font-light text-white dark:text-gray-900">Here at Emita, we take your data security and privacy seriously. We are governed by the <a className="font-medium underline text-blue-600 hover:no-underline" href="https://www.odpc.go.ke/dpa-act/" target="_blank">Data Protection Act</a> under the Office Of The Data Protection Commissioner Kenya. <a className="font-medium underline text-blue-600 hover:no-underline" href="https://emitasuite.com" target="_blank">Learn about our data privacy </a></p>
            <button onClick={() => {setVisible("0")}} data-collapse-toggle="banner" type="button" className="flex items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
            </button>
        </div>
        ) : null
    )
}