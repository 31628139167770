import { Switch, Group } from '@mantine/core';
import classes from './CustomSwitch.module.css';
import { useColorScheme, useLocalStorage } from '@mantine/hooks';
import { THEME_KEY } from '../../App';

export function ToggleTheme() {
    const preferredColorschema = useColorScheme();

    const [colorScheme, setColorScheme] = useLocalStorage({
        key: THEME_KEY,
        defaultValue: preferredColorschema,
        getInitialValueInEffect: true,
    });


  return (
    <Group justify="center" p="md">
      <Switch label="Dark" classNames={classes} checked={colorScheme === "dark"} onChange={(e) => {e.currentTarget.checked ? setColorScheme("dark") : setColorScheme("light")}} />
    </Group>
  );
}