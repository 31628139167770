import { useLocalStorage } from "@mantine/hooks";
import React, { useReducer } from "react";
import { SystemContext } from "./system.context";

export const SystemProvider = ({ children }) => {
    const [defaultView, setDefaultView] = useLocalStorage({
        key: "e-mita-system-default-view",
        defaultValue: "general-system",
        getInitialValueInEffect: true
    });

    const reducer = (state, action) => {
        switch(action.type) {
            case 'DEFAULTVIEW':
                return {
                    ...state,
                    defaultView: action.defaultView
                };
            default:
                return state;
        }
    }

    const initialState = {
        defaultView: defaultView
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <SystemContext.Provider value={{state, dispatch}}>
            {children}
        </SystemContext.Provider>
    )

}