import { useEffect, useState, useMemo, useContext } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Center, Container, Group, List, Paper, PasswordInput, Text } from "@mantine/core";
import axios from "../../../utils/axios";
import toast, { Toaster } from "react-hot-toast";
import { AlertTriangle } from "tabler-icons-react";
import { AuthContext } from "../../../App";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function VerifyCustomerPassword() {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AuthContext);

    let query = useQuery();
    const [token, setToken] = useState(query.get("token") || "");
    const [ready, setReady] = useState(false);
    const [username, setUsername] = useState(query.get("email") || "");
    const [meterNumber, setMeterNumber] = useState(query.get("username") || "");
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    useEffect(() => {
        //dispatch({type: "SIGN_OUT"});
        if(token !== ""){
            axios.post("/tokens/gettoken", {
                username: meterNumber, // meter number is the default username for a customer
                token: token
            }).then(function(res){
                if(res.status === 200 && res.data.data){
                    setSuccess(true);
                    setReady(true);
                } else {
                    setSuccess(false);
                    setReady(true);
                }
            }).catch(function(error){
                setSuccess(false);
                setReady(true);
            })
        }
    }, [token, username]);

    const resetPassword = () => {
        if(password === ""){
            toast.error("Password cannot be empty");
            return false;
        }

        if(password.length < 8){
            toast.error("Password is too short");
            return false;
        }

        if(password !== confirm){
            toast.error("The two passwords do not match");
            return false;
        }

        try{
            const body = {
                role: 'customer',
                username: meterNumber,
                password: password
            };

            axios.post("/customers/reset", body).then(function(res){
                if(res.status === 200){
                    axios.post("/tokens/delete", {
                        username: username
                    }).then(function(res){
                        if(res.status === 200){
                            toast.success("Password has been updated successfully!")
                            navigate("/")
                        } else {
                            toast.error("Something wrong happened!");
                        }
                    }).catch(function(error){
                        toast.error(error.message);
                    })
                } else {
                    toast.error("Something wrong is happening!");
                }
            })
        } catch(error){
            toast.error(error.message);
        }
    }

    return (
        ready ? (
            success ? (
                <>
                <Paper>
                    <Container size={600}>
                        <Paper p="md">
                            <Center mt="20%" mb={30}>
                                <Text>Password Reset</Text>
                            </Center>

                            <PasswordInput value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} label="New Password" description="Atleast 8 characters long" />
                            <PasswordInput value={confirm} onChange={(e) => {setConfirm(e.currentTarget.value)}} label="Confirm Password" description="" mt={20} />
                            <Button onClick={() => {resetPassword()}} mt={20} fullWidth>Reset Password</Button>
                        </Paper>
                    </Container>
                </Paper>
                </>
            ) : (
                <Paper>
                    <Container size={600}>
                        <Paper mt="20%" p="md" radius="md" withBorder>
                            <Center>
                                <Text>Verification Error!</Text>
                            </Center>
                            <Center mt={20}>
                                <AlertTriangle color="red" />
                            </Center>
                            <Center mt={40}>
                                <Text>The token received did not match the token dispatched. <br /> <br /> Possible reasons for this include:</Text>
                            </Center>
                            <Center mt={20}>
                                <List center>
                                <List.Item>The token has already expired.</List.Item>
                                <List.Item>The token has been tampered with.</List.Item>
                                <List.Item>You have already reset your password.</List.Item>
                                </List>
                            </Center>
                            <Center mt={40}>
                        <Text>If this problem persists, try contacting your water utility company.</Text>
                    </Center>
                    <Group mt={40} grow>
                        <Button variant="outline" component={Link} to="/" >Go Home</Button>
                    </Group>
                            </Paper> 
                    </Container>
                </Paper>
            )
        ) : (
            <Text>Please wait...</Text>
        )
    )
}