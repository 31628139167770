import { useState, useContext, useEffect } from 'react';
import {
  AppShell,
  Header,
  useMantineTheme,
  Title,
  createStyles,
  Group,
  Container,
  Text,
  Button,
  Menu,
  MediaQuery,
  Anchor,
  Center,
  Paper,
  Drawer,
  Stack,
  FileInput,
  TextInput,
  Code,
  Tooltip,
  ColorInput,
  Slider,
  Checkbox,
  PasswordInput,
  ColorSwatch,
  ActionIcon,
  ThemeIcon,
} from '@mantine/core';
import { useHotkeys, useViewportSize } from '@mantine/hooks';
import { MapContainer, LayersControl, GeoJSON, TileLayer, FeatureGroup} from "react-leaflet";
import { Map, Check, Upload, Download, LayersLinked, LayersOff, ZoomInArea, ZoomOut, ZoomIn, Checklist, Send, Stack2, InfoCircle, Users, Line, ExternalLink, ArrowUpRight, ChevronUp, ChevronDown, ChevronsUp, ChevronsDown,} from 'tabler-icons-react';
import { AuthContext } from '../../../App.js';
import axios from '../../../utils/axios.js';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@aws-amplify/ui-react';
import ReactDOMServer from "react-dom/server";
import L from "leaflet"
import { isGeoJSONFile } from '../../../utils/isGeoJSON.js';
import { InfrastructureBucket, InfrastructureRegion, accessKeyId, secretAccessKey } from '../../../constants/index.js';
import EditControl from "../../../utils/EditControl";
import textClip from '../../../utils/textClip.js';
import { downloadJson } from '../../../utils/downloadCSV.js';
import { CompanyLogo, CompanyLogoIcon } from '../layout/companyLogo.js';

const AWS = require("aws-sdk");

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  navbar: {
      paddingTop: 0,
    },
  
    section: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      marginBottom: theme.spacing.md,
  
    },
  
    searchCode: {
      fontWeight: 700,
      fontSize: 10,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
      }`,
    },
  
    mainLinks: {
      paddingLeft: theme.spacing.md - theme.spacing.xs,
      paddingRight: theme.spacing.md - theme.spacing.xs,
      paddingBottom: theme.spacing.md,
    },
  
    mainLink: {
      display: 'flex',
      cursor: 'text',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.fontSizes.xs,
      padding: `8px ${theme.spacing.xs}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    mainLinkInner: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  
    mainLinkIcon: {
      marginRight: theme.spacing.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    },
  
    mainLinkBadge: {
      padding: 0,
      width: 20,
      height: 20,
      pointerEvents: 'none',
    },
  
    collections: {
      paddingLeft: theme.spacing.md - 6,
      paddingRight: theme.spacing.md - 6,
      paddingBottom: theme.spacing.md,
    },
  
    collectionsHeader: {
      paddingLeft: theme.spacing.md + 2,
      paddingRight: theme.spacing.md,
      marginBottom: 5,
    },

    root: {
      position: 'relative',
      '& *': {
        cursor: 'pointer',
      },
    },
  
    collectionLink: {
      display: 'block',
      padding: `8px ${theme.spacing.xs}px`,
      textDecoration: 'none',
      cursor: 'text',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.xs,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      lineHeight: 1,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },

    animalBody: {
      paddingLeft: 54,
      paddingTop: theme.spacing.sm,
    },
}));

export default function Dashboard() {
  const theme = useMantineTheme();
  const { state, dispatch } = useContext(AuthContext);
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const [basemap, setBasemap] = useState("ESRI");
  const [loading, setLoading] = useState(false);
  const [with_centroid, setWithCentroid] = useState(false);
  const [centroid, setCentroid] = useState([-1.2641141046016717, 36.82277094518902])
  const [data, setData] = useState([]);
  const [coords, setCoords] = useState(null);
  const [withBasemap, setWithBasemap] = useState(false);
  const [zoom, setZoom] = useState(15);
  const [pipeline, setPipeline] = useState([])
  const [service_area, setServiceArea] = useState([]);
  const [boreholes, setBoreholes] = useState([]);
  const [water_kiosks, setWaterKiosks] = useState([]);
  const [storage_tanks, setStorageTanks] = useState([]);
  const [sources, setSources] = useState([]);
  const [master_meters, setMasterMeters] = useState([]);
  const [category, setCategory] = useState("")
  const [file, setFile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [, setLocalDrawer] = useState(false);
  const [urlDrawer, setUrlDrawer] = useState(false);
  const [pipe_nodes, setPipeNodes] = useState([]);
  const [pipe_nodes_coords, setPipeNodesCoords] = useState([]);

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    try {
      const body = {
          parent: state.userData._id
      };

      axios.post("/tasks/gettasks", body).then(function(res){
          let arr = res.data.data;

          if(Array.isArray(arr) && arr.length > 0){
            let filtered = arr.filter((obj) => {
              return obj?.task === "3"
            });

            setTasks(filtered);
          }
      }).catch(function(error){
          toast.error(error.message, {
              position: "bottom-right"
          });
      })
  } catch(error){
      toast.error(error.message, {
          position: "bottom-right"
      })
  }
  }, []);

  useEffect(() => {
    const fetchPipeNodes = () => {
      setPipeNodes([]);

      axios.get("/pipe-nodes/pipe-nodes", {
        headers: {
          'Authorization': `Bearer ${state.userToken}`
        }
      }).then(function(res){
        if(res.status === 200){
          setPipeNodes(res.data.data);
        }
      }).catch(function(err){
        console.log(err);
      })
    }

    fetchPipeNodes();

  }, [])

  useEffect(() => {
    const fetchData = () => {
        setData([])
        let idT = toast.loading("fetching data...", {
            position: "bottom-right"
          })
        axios.post("/customers/getCustomers", {
            parent: state.userData?._id
        }).then(function(res){
            if(res.status === 200){
                setData(res.data.data);
                toast.dismiss(idT)
            }
        }).catch(function(error){
            console.log(error);
            toast.dismiss(idT)
        });
    }

fetchData();

}, []);

async function fetchData(path){
  return new Promise((resolve, reject) => {
    fetch(path).then(async function(res){
      res = await res.json();
      resolve(res);
    }).catch(function(err){
      resolve([]);
    })
  })
}

useEffect(() => {
  let idT = toast.loading("fetching your cloud assets...", {
    position: "bottom-right"
  })

  const config = {
    headers: {
      'Authorization': `Bearer ${state.userToken}`
    }
  };

  axios.get("/assets/get", config).then(async function(res){
    if(res.status === 200){
      let serviceArr = [];
      let pipeArr = [];
      let boreholeArr = [];
      let waterKioskArr = [];
      let storageTankArr = [];
      let masterArr = [];
      let sourcesArr = [];
      
      for(let i=0; i<res.data.data.length; i++){
        let item = res.data.data[i];
        switch(item.category){
          case "Service Area":
            // fetch service area
            var data = await fetchData(item.path);
            serviceArr.push(data)
            break;
          case "Pipeline Network":
            // fetch pipelines
            var data = await fetchData(item.path);
            pipeArr.push(data);
            break;
          case "Borehole":
            // fetch borehols
            var data = await fetchData(item.path);
            boreholeArr.push(data)
            break;
          case "Storage Tank":
            // fetch storage tanks
            var data = await fetchData(item.path);
            storageTankArr.push(data)
            break;
          case "Water Kiosk":
            // fetch water kiosk
            var data = await fetchData(item.path);
            waterKioskArr.push(data)
            break;
          case "Master Meters":
            // fetch master meters
            var data = await fetchData(item.path);
            masterArr.push(data)
            break;
          case "Other Water Sources":
            // fetch other sources
            var data = await fetchData(item.path);
            sourcesArr.push(data);
            break;
          default:
            // do nothing
        }
      }

      if(pipeArr.length > 0){
        setPipeline(pipeArr);
      }
      
      if(serviceArr.length > 0){
        setServiceArea(serviceArr);
      }
      
      if(boreholeArr.length > 0){
        setBoreholes(boreholeArr);
      }
      
      if(waterKioskArr.length > 0){
        setWaterKiosks(waterKioskArr);
      }
      
      if(storageTankArr.length > 0){
        setStorageTanks(storageTankArr);
      }
      
      if(masterArr.length > 0){
        setMasterMeters(masterArr);
      }

      if(sourcesArr.length > 0){
        setSources(sourcesArr);
      }

      toast.dismiss(idT)
    }
  }).catch(function(error){
    console.log(error);
    toast.error("An error occured while fetching your resources", {
      position: "bottom-right",
      id: idT
    })
  });
}, [refresh])

useEffect(() => {
  let arr = [];

  for(let i=0; i<pipe_nodes.length; i++){
    let item = pipe_nodes[i];

    let loc = [item.longitude, item.latitude];

    let x = { "type": "Feature", "properties": item, "geometry": { "type": "Point", "coordinates": loc } };

    arr.push(x);

  }

  let geoData = {
    "type": "FeatureCollection",
    "name": "Pipe Nodes",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": arr
  }

  setPipeNodesCoords(geoData);
}, [pipe_nodes])

useEffect(() => {
  let arr = [];
  for(let i=0; i<data.length; i++){
      let item = data[i];
      item.password = undefined;
      delete item.password;
      item.parent = undefined;
      delete item.parent;
      item._id = undefined;
      delete item._id;
      let loc = [item.coordinates[1], item.coordinates[0]];
      let x = { "type": "Feature", "properties": item, "geometry": { "type": "Point", "coordinates": loc } };

      arr.push(x)
  }


  let geoData = {
      "type": "FeatureCollection",
      "name": "Customers Meters",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": arr
  }

  setCoords(geoData);

}, [data]);

const uploadFile = async (file) => {
  const S3_BUCKET = InfrastructureBucket;
  const REGION = InfrastructureRegion;

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const key = state.userData.waterServiceName.toLowerCase().replace(/ /g, "-")+'-'+file.name;

  const params = {
    Bucket: S3_BUCKET,
    Key: key,
    Body: file,
  };

  try {
    const data = await s3.putObject(params).promise();
    return `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`;
  } catch (error) {
    toast.error("An error occured while uploading the file", {
      position: "bottom-right"
    })
  }
};

const uploadData = async () => {
  if(isGeoJSONFile(file)){
    setLoading(true);

    let path = await uploadFile(file);

    const body = {
      parent: state.userData._id,
      path: path,
      category: category
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${state.userToken}`
      }
    };

    axios.post("/assets/create", body, config).then(function(res){
      if(res.status === 200){
        toast.success("The data was uploaded successfully!");
        setCategory("");
        setFile(null);
        setLoading(false);
        setRefresh(!refresh);
      }
    }).catch(function(err){
      toast.error("Something wrong happened!");
      setLoading(false);
    })
    
  } else {
    toast.error("The file uploaded is not a valid GeoJSON file!", {
      position: "bottom-right"
    });
  }
}

const getDesc = (acc) => {

  let val = "View Customer Details";

  for(let i=0; i<tasks.length; i++){
    let itm = tasks[i];

    let incomplete = itm.building // arr;

    let complete = itm.completeBuildings; // arr

    if(incomplete.includes(acc) && itm.status === "Incomplete"){
      val = "Customer location is not yet updated. A field technician is in the process of updating this location."
      break;
    } else if(complete.includes(acc) && itm.status === "Incomplete"){
      val = "Customer location was updated successfully, though the task is still running."
      break;
    }

  }

  return val;
}

const PopupTable = ({ feature }) => {
  return (
      <Paper p="md">
            <Heading marginBottom={20}>{feature.properties.name}</Heading>
            <Anchor mb={20} href={`/app/customers/${encodeURIComponent(feature.properties.username)}`}>{getDesc(feature.properties.account)}</Anchor>
      </Paper>

  )
}

const EndPointPopup = ({ feature }) => {
  return (
      <Paper p="md">
            <Heading marginBottom={20}>Description</Heading>
            <Text>{feature?.properties?.comment || "No comment was given"}</Text>
      </Paper>

  )
}


  useEffect(() => {
    const config = {
      headers: {
        'Authorization': `Bearer ${state.userToken}`
      }
    };

    axios.get("/centroids/get", config).then(function(res){
      if(res.status === 200){
        if(res.data.data === null){
            setWithCentroid(false);
        } else {
            setCentroid([res.data.data.latitude, res.data.data.longitude])
            setWithCentroid(true);
        }
      }
    }).catch(function(error){
      toast.error("Could not fetch your service area centroid coordinates", {
        position: 'bottom-right'
      })
    });

  }, []);

 const navigate = useNavigate();

 useHotkeys([
  ['mod+Z', () => {
      let a = zoom;
      setZoom(a+1)
  }],
  ['mod+shift+Z', () => {
      let a = zoom;
      setZoom(a - 1);
  }],
  ['mod+O', () => {
      setLocalDrawer(true)
  }],
]);

 const MapPanel = () => {
  const [target, setTarget] = useState({lat:centroid[0], lng:centroid[1]});
  const [center, setCenter] = useState([centroid[0],centroid[1]]);
  const [layer_created, setLayerCreated] = useState(false);
  const [layer, setLayer] = useState(null);
  const [layer_color, setLayerColor] = useState("blue");
  const [layer_fill, setLayerFill] = useState("blue");
  const [fill_opacity, setFillOpacity] = useState(0.3);
  const [weight, setWeight] = useState(1);
  const [property_opened, setPropertyOpened] = useState(false);
  const [colorx, setColorx] = useState("#40C057"); // connected customers
  const [color1, setColor1] = useState("#ad1313") // disconnected customers
  const [color2, setColor2] = useState(theme.colorScheme === "light" ? "#0074D9" : "#4C8BF5"); // pipe networks
  const [color3, setColor3] = useState(theme.colorScheme === "dark" ? "#2ECC40" : "#48C774"); // service area
  const [color4, setColor4] = useState(theme.colorScheme === "light" ? "#FF851B" : "#FFB946"); // boreholes
  const [color5, setColor5] = useState(theme.colorScheme === "light" ? "#B10DC9" : "#D946EF"); // water kiosks
  const [color6, setColor6] = useState(theme.colorScheme === "light" ? "#1000ed" : "#1000ed"); // storage tanks
  const [color7, setColor7] = useState(theme.colorScheme === "light" ? "#38d6d6" : "#38d6d6"); // other water sources
  const [color8, setColor8] = useState(theme.colorScheme === "light" ? "#101113" : "#101113"); // master meters
  const [expanded, setExpanded] = useState(false);

  const [layers, setLayers] = useState([]);

  const HoverComponent = () => {
    return null
}

const PipeNodes = () => {
  return (
    <GeoJSON data={pipe_nodes_coords} onEachFeature={(f, l) => {
      l.bindPopup(ReactDOMServer.renderToString(<EndPointPopup feature={f} />))
    }} />
  )
}

const isBeingUpdated = (acc) => {
  var val = false;

  for(let i=0; i<tasks.length; i++){
    let itm = tasks[i];

    let incomplete = itm.building // arr;

    let complete = itm.completeBuildings; // arr

    if(incomplete.includes(acc) && itm.status === "Incomplete"){
      val = true;
      break;
    } else if(complete.includes(acc) && itm.status === "Incomplete"){
      val = true;
      break;
    }

  }

  return val

}

const getColor = (acc, disc=false) => {

  let color = disc ? color1 : colorx;

  for(let i=0; i<tasks.length; i++){
    let itm = tasks[i];

    let incomplete = itm.building // arr;

    let complete = itm.completeBuildings; // arr

    if(incomplete.includes(acc) && itm.status === "Incomplete"){
      color = color1;
      break;
    } else if(complete.includes(acc) && itm.status === "Incomplete"){
      color = colorx
      break;
    }

  }

  return color;
}

const CustomerMeters = () => {
  return (
      <GeoJSON data={coords} pointToLayer={(f, latLng) => {
          return new L.CircleMarker(latLng, {
            opacity: 1,
            weight: 2,
            fillOpacity: 1,
            color: getColor(f.properties.account, f.properties.disconnected),
            fillColor: getColor(f.properties.account, f.properties.disconnected),
            radius: isBeingUpdated(f.properties.account) ? 15 : 8,
            className: isBeingUpdated(f.properties.account) ? "marker-pulse" : ""
          })
        }} onEachFeature={(f, l) => {
          l.bindPopup(ReactDOMServer.renderToString(<PopupTable feature={f} />))
            //l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>First Name</strong></td><td>"+f.properties.FirstName+"</td></tr><tr scope='row'><td><strong>Last Name</strong></td><td>"+f.properties.LastName+"</td></tr><tr scope='row'><td><strong>Mobile Number</strong></td><td>"+f.properties.MobileNumber+"</td></tr><tr scope='row'><td><strong>Account Number</strong></td><td>"+f.properties.account+"</td></tr><tr scope='row'><td><strong>Meter Number</strong></td><td>"+f.properties.meter_number+"</td></tr></tbody><table>")
          }} />
  )
}

const PipeNetwork = () => {
  return (
   pipeline.map((item, idx) => {
          return (
            <GeoJSON key={`pipeline-${idx}`} data={item} style={(f, l) => {
              return {
                color: color2,
                fillColor: color2,
                strokeWidth: 10,
                weight: 7
              }
            }} />
          )
        })
  )
}

const ServiceArea = () => {
  return (
    service_area.map((item, idx) => {
          return (
            <GeoJSON key={`service-area-${idx}`} data={item} style={(f, l) => {
              return {
                color: color3,
                fillColor: "transparent",
                strokeWidth: 4,
                weight: 4,
              }
            }} />
          )
        })
  )
}

const Boreholes = () => {
  return (
    boreholes.map((item, idx) => {
        return (
          <GeoJSON key={`borehole-${idx}`} pointToLayer={(f, latLng) => {
            return new L.CircleMarker(latLng, {
              opacity: 1,
              weight: 2,
              fillOpacity: 1,
              color: color4,
              fillColor: color4,
              radius: 8,
            })
          }} data={item} />
        )
      })
  )
}

const WaterKiosks = () => {
  return (
   water_kiosks.map((item, idx) => {
        return (
          <GeoJSON key={`water-kiosk-${idx}`} data={item} pointToLayer={(f, latLng) => {
            return new L.CircleMarker(latLng, {
              opacity: 1,
              weight: 2,
              fillOpacity: 1,
              color: color5,
              fillColor: color5,
              radius: 8,
            })
          }} />
        )
      })
  )
}

const StorageTanks = () => {
  return (
    storage_tanks.map((item, idx) => {
        return (
          <GeoJSON key={`storage-${idx}`} data={item} pointToLayer={(f, latLng) => {
            return new L.CircleMarker(latLng, {
              opacity: 1,
              weight: 2,
              fillOpacity: 1,
              color: color6,
              fillColor: color6,
              radius: 8,
            })
          }}/>
        )
      })
  )
}

const OtherSources = () => {
  return (
    sources.map((item, idx) => {
      return (
        <GeoJSON key={`source-${idx}`} data={item} style={(f, l) => {
          return {
            color: color7,
            fillColor: color7,
            strokeWidth: 0.5,
            weight: withBasemap ? 2: 1
          }
        }} />
      )
    })
  )
}

const MasterMeters = () => {
  return (
   master_meters.map((item, idx) => {
        return (
          <GeoJSON key={`master-meter-${idx}`} data={item} pointToLayer={(f, latLng) => {
            return new L.CircleMarker(latLng, {
              opacity: 1,
              weight: 2,
              fillOpacity: 1,
              color: color8,
              fillColor: color8,
              radius: 8,
            })
          }}/>
        )
      })
  )
}


const onCreate = (e) => {
    setLayers(prevArr => ([...prevArr, e.layer]));
    setLayerCreated(true);
  }

  const onDeleted = (e) => {
    setLayerCreated(false);
  }


  const onEditStart = (e) => {
    console.log(e)
  }
  
  const sendBatchMessage = () => {
    console.log(layers);
  }
  return (
    <MapContainer zoomControl={false} center={with_centroid ? center : [-1.2641141046016717, 36.82277094518902]} style={{background: "transparent", zIndex: 1, height: "100%", width: '100%', padding: 0}} zoom={zoom}>
    <LayersControl position='topright'  >

  <LayersControl.BaseLayer checked={basemap === "CartoDB"} name='CartoDB'>
<TileLayer
  attribution='&copy; Emita, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
  url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
/>
</LayersControl.BaseLayer>
<LayersControl.BaseLayer checked={basemap === "ESRI"} name='Satellite'>
<TileLayer
  attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
/>
</LayersControl.BaseLayer>
<LayersControl.Overlay name="Service Area" checked>
<ServiceArea />
</LayersControl.Overlay>
<LayersControl.Overlay name='Pipeline Network' checked>
<PipeNetwork />
</LayersControl.Overlay>
<LayersControl.Overlay name='Pipe Endpoints' checked>
  <PipeNodes />
</LayersControl.Overlay>
<LayersControl.Overlay name='Customer Meters' checked>
<CustomerMeters />
</LayersControl.Overlay>
<LayersControl.Overlay name="Boreholes" checked>
<Boreholes />
</LayersControl.Overlay>
<LayersControl.Overlay name='Water Kiosks' checked>
<WaterKiosks />
</LayersControl.Overlay>
<LayersControl.Overlay name='Storage Tanks' checked>
<StorageTanks />
</LayersControl.Overlay>
<LayersControl.Overlay name='Master Meters' checked>
<MasterMeters />
</LayersControl.Overlay>
<LayersControl.Overlay name='Other Water Sources' checked>
<OtherSources />
</LayersControl.Overlay>
  </LayersControl>
  <HoverComponent />
        
          <FeatureGroup>
            <EditControl               
              position='topright'
              onEdited={onEditStart}
              onCreated={onCreate}
              onDeleted={onDeleted}
              
              draw={{
                rectangle: true,
                circle: true,
                polyline: true,
                polygon: true,
                marker: true,
                circlemarker: true,
              }} />
        </FeatureGroup>
          <Container  sx={(theme) => ({left: -250, bottom: 20, [theme.fn.smallerThan('md')]: {
            display: "none"
          },})} className='leaflet-bottom leaflet-right'>
                    <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8]  : "#ffff",
        [theme.fn.smallerThan('md')]: {
            display: "none"
          }
        })} p="xs">
            <Group noWrap>
                <Menu>
                    <Menu.Target>
                        <Button variant="default" size="xs" radius={28}>Files</Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Files</Menu.Label>
                        <Menu.Item onClick={() => {
                            setLocalDrawer(true);
                            setUrlDrawer(false);
                            }} rightSection={<Code ml={10}>Ctrl + O </Code>}  icon={<Upload size={11} />}><Text size="xs">Upload Local</Text></Menu.Item>
                        <Menu.Item onClick={() => {
                            setUrlDrawer(true);
                            setLocalDrawer(false);
                        }} icon={<ExternalLink size={11} />}><Text size="xs">Connect URL</Text></Menu.Item>
                        <Menu.Item icon={<Download size={11} />}><Text size="xs">Export Files</Text></Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                <Menu>
                    <Menu.Target>
                        <Button variant="default" size="xs" radius={28}>View</Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Canvas Appearance</Menu.Label>
                        <Menu.Item disabled={withBasemap} onClick={() => {setWithBasemap(true)}} icon={<LayersLinked size={11} />}><Text size="xs">With Basemap</Text></Menu.Item>
                        <Menu.Item disabled={!withBasemap} onClick={() => {setWithBasemap(false)}} icon={<LayersOff size={11} />}><Text size="xs">No Basemap</Text></Menu.Item>
                        <Menu.Divider />
                        <Menu.Label>Zoom Options</Menu.Label>
                        <Menu.Item onClick={() => {
                            setCenter([centroid[0],centroid[1]]);
                            setZoom(15);
                        }} icon={<ZoomInArea size={11} />}><Text size="xs">Zoom to Service Area</Text></Menu.Item>
                        <Menu.Item disabled={zoom === 1} onClick={() => {
                            let a = zoom;
                            if(a > 1){
                                setZoom(a - 1);
                            }
                        }} rightSection={<Code>Ctrl+Shift+Z </Code>} icon={<ZoomOut size={11} />}><Text size="xs">Zoom Out</Text></Menu.Item>
                        <Menu.Item disabled={zoom === 22} onClick={() => {
                            let a = zoom;
                            if(a < 22){
                                setZoom(a + 1);
                            }
                        }} rightSection={<Code>Ctrl+Z </Code>} icon={<ZoomIn size={11} />}><Text size="xs">Zoom In</Text></Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                    <Menu>
                        <Menu.Target>
                            <Button variant="default" size="xs" radius={28}>Layer Options</Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item icon={<Checklist size={13} />}><Text size="xs">Create Work Order</Text></Menu.Item>
                            <Menu.Item onClick={() => {sendBatchMessage()}} icon={<Send size={13} />}><Text size="xs">Send Batch Message</Text></Menu.Item>
                            <Menu.Item icon={<Stack2 size={13} />}><Text size="xs">Create Subzone</Text></Menu.Item>
                            <Menu.Item icon={<InfoCircle size={13} />}><Text size="xs">View Summary Details</Text></Menu.Item>
                            <Menu.Item icon={<Users size={13} />}><Text size="xs">Clip & Export Customers</Text></Menu.Item>
                            <Menu.Item icon={<Line size={13} />}><Text size="xs">Clip & Export Pipeline Networks</Text></Menu.Item>

                        </Menu.Dropdown>
                    </Menu>
                    <Menu opened={property_opened}>
                        <Menu.Target>
                            <Button variant="default" size="xs" onClick={() => {setPropertyOpened(!property_opened)}} radius={28}>Layer Properties</Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item>
                            <Group mb={30} grow noWrap>
                                <Group noWrap>
                                <div style={{width: 30,borderBottom: `2px solid ${layer_color}`}}></div>
                                <Text size='xs' >Color</Text>
                                </Group>
                                    <ColorInput size="xs" value={layer_color} onChange={(col) => {setLayerColor(col)}} variant="unstyled" />
                                </Group>
                            </Menu.Item>
                            <Menu.Item>
                            <Group mb={30} grow noWrap>
                            <Group noWrap>
                                <div style={{width: 30,borderBottom: `2px solid ${layer_fill}`}}></div>
                                <Text size='xs' >Fill</Text>
                                </Group>
                                    <ColorInput size="xs" value={layer_fill} onChange={(col) => {setLayerFill(col)}} variant="unstyled" />
                                </Group>
                            </Menu.Item>
                            <Menu.Item>
                            <Group mb={30} grow noWrap>
                            <Group noWrap>
                                <Text size='xs' >Weight</Text>
                                </Group>
                                    <Slider min={1} max={10} value={weight} onChangeEnd={setWeight} size="xs" />
                                </Group>
                            </Menu.Item>
                            <Menu.Item>
                            <Group mb={30} grow noWrap>
                            <Group noWrap>
                                <Text size='xs' >Fill Opacity</Text>
                                </Group>
                                    <Slider min={0.1} max={1.0} value={fill_opacity} onChangeEnd={setFillOpacity} size="xs" />
                                </Group>
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                    <Button variant="default" size="xs" radius={28}>Save Created Layers</Button>
            </Group>
                    </Container>
        </Container>

        <Container sx={(theme) => ({margin: 0, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
            display: "none"
          }})} className='leaflet-top leaflet-left'>
        <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8]  : "#ffff",
        width: 250,
        border: "none",
        [theme.fn.smallerThan('md')]: {
            display: "none"
          }
        })} p="md" >
              <Group mb={10} position='apart'>
              <Text size="xs" style={{fontWeight: 650}}>Assets Legend</Text>
              <ActionIcon onClick={() => {setExpanded(!expanded)}}>
                <ThemeIcon variant="light" radius={28}>
                  {expanded ? <ChevronsUp /> : <ChevronsDown />}
                </ThemeIcon>
              </ActionIcon>
              </Group>
    
            {expanded ? (
              <>
                            <Group mb={30} grow noWrap>
              <Group noWrap>
              <ColorSwatch color={colorx} size={10} />
                    <Text size='xs'>Active Meters</Text>
              </Group>
    
              <ColorInput size="xs" value={colorx} onChange={(col) => {setColorx(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
              <Group noWrap>
              <ColorSwatch color={color1} size={10} />
                    <Text size='xs'>Inactive Meters</Text>
              </Group>
    
              <ColorInput size="xs" value={color1} onChange={(col) => {setColor1(col)}} variant="unstyled" />
              </Group>
    
              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <div style={{width: 30,borderBottom: `2px solid ${color2}`}}></div>
                    <Text size='xs' >Pipes</Text>
                    </Group>
                        <ColorInput size="xs" value={color2} onChange={(col) => {setColor2(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <div style={{width: 30,borderBottom: `2px solid ${color3}`}}></div>
                    <Text size='xs' >Service Area</Text>
                    </Group>
                        <ColorInput size="xs" value={color3} onChange={(col) => {setColor3(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <ColorSwatch color={color4} size={10} />
                    <Text size='xs' >Borehole</Text>
                    </Group>
                        <ColorInput size="xs" value={color4} onChange={(col) => {setColor4(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <ColorSwatch color={color5} size={10} />
                    <Text size='xs' >Water Kiosk</Text>
                    </Group>
                        <ColorInput size="xs" value={color5} onChange={(col) => {setColor5(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <ColorSwatch color={color8} size={10} />
                    <Text size='xs' >Master Meters</Text>
                    </Group>
                        <ColorInput size="xs" value={color8} onChange={(col) => {setColor8(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <ColorSwatch color={color6} size={10} />
                    <Text size='xs' >Storage Tanks</Text>
                    </Group>
                        <ColorInput size="xs" value={color6} onChange={(col) => {setColor6(col)}} variant="unstyled" />
              </Group>

              <Group mb={30} grow noWrap>
                    <Group noWrap>
                    <ColorSwatch color={color7} size={10} />
                    <Text size='xs' >Other Water Sources</Text>
                    </Group>
                        <ColorInput size="xs" value={color7} onChange={(col) => {setColor7(col)}} variant="unstyled" />
              </Group>
              </>
            ) : null}

        </Container>
        </Container>
    
    </MapContainer>
  )
 }

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : "white",
          padding: 0,
          paddingTop: 50
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <Header height={50} className={classes.header}>
          <div className={classes.inner}>
            <Group >
              <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
              <Group ml={20}>
              <Anchor onClick={() => {navigate(-1)}} href='#'>
                    <Center inline>
                      <CompanyLogoIcon />
              <Title order={4}>ASSETS MANAGER</Title>
              </Center>
              </Anchor>
              </Group>
              </MediaQuery>
              <MediaQuery largerThan={"md"} styles={{display: "none"}}>
              <Title order={4}>ASSETS</Title>
              </MediaQuery>
            </Group>

            <Group ml={50} spacing={5} className={classes.links}>
              
          </Group>
          
          <Group noWrap>
            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Menu width={250}>
              <Menu.Target>
                <Button color='gray' leftIcon={<Upload size={16} />}>Upload Assets</Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Choose asset to upload</Menu.Label>
                <Menu.Item onClick={() => {setCategory("Pipeline Network")}}>Pipe Network</Menu.Item>
                <Menu.Item onClick={() => {setCategory("Service Area")}}>Service Area</Menu.Item>
                <Menu.Item onClick={() => {setCategory("Borehole")}}>Borehole</Menu.Item>
                <Menu.Item onClick={() => {setCategory("Storage Tank")}}>Storage Tank</Menu.Item>
                <Menu.Item onClick={() => {setCategory("Water Kiosk")}}>Water Kiosk</Menu.Item>
                <Menu.Item onClick={() => {setCategory("Master Meters")}}>Master Meter Location</Menu.Item>
                <Menu.Item onClick={() => {setCategory("Other Water Sources")}}>Other Sources</Menu.Item>
              </Menu.Dropdown>
            </Menu>
            </MediaQuery>
            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Button color='gray' onClick={() => {
              downloadJson("customers.geojson",coords);
            }}  leftIcon={<Download size={16} />}>Download Assets</Button>
            </MediaQuery>

            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Menu>
              <Menu.Target>
                <Button color='gray' leftIcon={<Map size={16} />}>Basemap Layer</Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => {setBasemap("CartoDB")}} icon={basemap === "CartoDB" ? <Check size={14} /> : null}>CartoDB</Menu.Item>
                <Menu.Item onClick={() => {setBasemap("ESRI")}} icon={basemap === "ESRI" ? <Check size={14} /> : null}>ESRI World Imagery</Menu.Item>
              </Menu.Dropdown>
            </Menu>
            </MediaQuery>

            <Button color='gray' rightIcon={<ArrowUpRight size={16} />} onClick={() => {navigate("/app/inventory-mapper")}}>Inventory Mapper</Button>
          <Button color='red' rightIcon={<ArrowUpRight size={16} />} onClick={() => {dispatch({type: "SIGN_OUT"})}}>Sign Out</Button>
          </Group>
          </div>
        </Header>
      }
    >
      <MapPanel />
      <Drawer opened={category !== ""} onClose={() => {setCategory("")}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Upload {category}</Heading>
            <FileInput value={file} onChange={setFile} label="File" mb="md" />
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} variant="default" onClick={() => {setCategory("")}}>Cancel</Button>
            <Button size='sm' radius={28} onClick={() => {uploadData()}} loading={loading}>Upload Data</Button>
        </Group>
        </Stack>
      </Drawer>

        <Drawer withOverlay={false} withCloseButton={false} opened={urlDrawer} onClose={() => {setUrlDrawer(false)}} position="right" padding="md">
            <Heading marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Connect URL</Heading>
            <Text mb={20}>Connect with your Postgres server and fetch your files to visualize here.</Text>

            <TextInput label="URL" description="URL to the server" mt="md" mb="md" />
            <Checkbox label="Protected?" checked mt="md" mb="md" />
            <PasswordInput label="Password" description="Password for your server" mt="md" mb="md" />

            <Group mt={30}>
                <Button radius={28} onClick={() => {setUrlDrawer(false)}} variant="default">Discard</Button>
                <Button radius={28}>Connect</Button>
            </Group>
        </Drawer>
    </AppShell>
  );
}