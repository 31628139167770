import { ActionIcon, Group, NumberInput, Button, Paper, Table, TextInput, Title, Tooltip, Center, Text, Menu, Loader, Alert } from "@mantine/core";
import { IconAlertCircle, IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Dots, FileExport, FileImport, Plus } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";

export default function OtherServices(){
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const { state, dispatch } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [sucess, setSuccess] = useState({sucess: false, message: ""});
    const [error, setError] = useState({error: false, message: ""});

    const fetchData = () => {
        setLoading(true);
        setData([]);
        setSuccess(false);
        setError(false);
        try{
            axios.post("/services/get-service", {
                parent: state.userData._id
            }).then(function(res){
                if(res.status === 200){
                    setData(res.data.data);
                    setLoading(false);
                }
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                });
                setLoading(false);
            })
        } catch(error){
            // handle error.
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchData();
    }, [refresh]);

    const deleteService = (id, service) => {
        axios.post("/services/delete-service", {
            id: id
        }).then(function(res){
            if(res.status === 200){
                setRefresh(!refresh);
            }
        }).catch(function(err){
            setError({error: true, message: err.message})
        })
    }
    return (
        <>
        {sucess.sucess ? (
            <Alert icon={<IconCheck />} title="Success" variant="filled">
                {sucess.message}
          </Alert>
        ) : null}
        
        {error.error ? (
            <Alert icon={<IconAlertCircle/>} title="Sorry!" color="red" variant="filled">
                {error.message}
          </Alert>
        ) : null}
        <Paper p="md">
            <Title weight={300}>Other Services</Title>
            <Group mb={20} position='right'>
                <Tooltip label="Import other services">
                    <ActionIcon>
                        <FileImport />
                    </ActionIcon>
                </Tooltip>
                <Tooltip label="Export other services">
                    <ActionIcon>
                        <FileExport />
                    </ActionIcon>
                </Tooltip>
                <Button variant="outline" component={Link} to="/app/configure/services/create" leftIcon={<Plus />}>Add New Service</Button>
            </Group>

            <Group position="apart">
                <Group position="left">
                    <TextInput placeholder="search" radius={28} size="sm" />
                </Group>
                <Group position="right">
                    <NumberInput step={10} label="Items Per Page" size="xs" />
                </Group>
            </Group>

            <div style={{overflowX: 'auto', marginTop: 20}}>
                {!loading ? (
                    <Table size="xs" style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                        <tr>
                            <th>Service Name</th>
                            <th>Created On</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.length === 0 ? (
                        <tr>
                            <td colSpan={3}>
                                <Center>
                                    <Text>No data found</Text>
                                </Center>
                            </td>
                        </tr>
                    ) : (
                        data.map((item, index) => {
                            return (
                                <tr key={`service-row-${index}`}>
                                    <td>{item.serviceTitle}</td>
                                    <td>{new Date(item.createdAt).toDateString()}</td>
                                    <td>
                                        <Menu>
                                            <Menu.Target>
                                                <ActionIcon>
                                                    <Dots />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Item>Edit</Menu.Item>
                                                <Menu.Item onClick={() => {deleteService(item._id, item.serviceTitle)}}>Delete</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </td>
                                </tr>
                            )
                        })
                    )}
                    </tbody>
                </Table>
                ) : (
                    <Center mt="10%" mb="10%" >
                        <Loader />
                    </Center>
                )}
            </div>
        </Paper>
        </>
    )
}