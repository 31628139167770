import { Paper, Group, Button, Table, TextInput, useMantineTheme, Center, Text, Drawer, Stack } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { Heading } from "@aws-amplify/ui-react";
import swal from "sweetalert";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useViewportSize } from "@mantine/hooks";
import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
  } from "@reach/menu-button";
import "@reach/menu-button/styles.css";

export default function TarrifList(){
    const [tarrifs, setTarrifs] = useState([]);
    const { state, dispatch } = useContext(AuthContext);
    const [refresh, setRefresh] = useState(false);

    const [id, setID] = useState("");
    const [opened, setOpened] = useState(false);

    const [name, setName] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const askUpdateTarrif = (id, name) => {
        setID(id);
        setName(name);
        setOpened(true);
    }

    const updateTarrif = () => {
        setLoading(true);

        let toastId = toast.loading("Updating the tarrif... Please wait")
        axios.patch("/tarrifs/update", {
            id: id, 
            update: {
                name: name
            }
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                toast.success("The tarrif was updated successfully", {
                    id: toastId
                });

                setLoading(false);

                setRefresh(!refresh)

                setOpened(false);
            }
        }).catch(function(err){
            toast.error("The tarrif was not updated. An issue occured", {
                id: toastId
            });

            setLoading(false);

            console.log(err);
        })
    }

    const deleteTarrif = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to continue with this action?",
            icon: "warning",
            buttons: {
                cancel: 'No, Cancel Please!',
                continue: {
                    text: "Yes, I'm sure about this!",
                    value: 'continue'
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                const body = {
                    id: id
                };
    
                axios.post("/tarrifs/deletetarrif", body).then(function(res){
                    toast.success("The tarrif has been deleted permanently.")
                    setRefresh(!refresh)
                }).catch(function(error){
                    toast.error(error.message);
                })
            }
        })
    }

    const fetchData = () => {
        setTarrifs([]);
        
        try{
            const body = {
                parent: state.userData._id
            };

            axios.post("/tarrifs/gettarrifs", body).then(({data: {data: tarrifs}, }) => {
                setTarrifs(tarrifs);

            })
        } catch(error){
            console.error(error);
        }
    }

    React.useEffect(() => {
        fetchData();
    }, [refresh])

    const TarrifsList = ({tarrifs}) => {
        return (
                tarrifs.map((item, index) => {
                    return (
                        <tr key={`row-${index}`} >
                            <td>
                                {item?.name.trim() === "" ? item.tarrif : item?.name.trim()}
                            </td>
                            <td>
                                {item.has_blocks ? (
                                    <Table fontSize="xs" withColumnBorders striped>
                                        <thead>
                                            <tr>
                                                <th>Lower</th>
                                                <th>Upper</th>
                                                <th>Cost</th>
                                                <th>Flat Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.blocks.map((item, index) => {
                                                return (
                                                    <tr key={`water-block-${index}`}>
                                                        <td>{item.lower}</td>
                                                        <td>{item.upper}</td>
                                                        <td>{item.cost}</td>
                                                        <td>{item.flatRate ? "Yes" : "No"}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <Table fontSize="xs" withColumnBorders striped>
                                        <thead>
                                            <tr>
                                                <th>Cost/Unit</th>
                                                <th>Flat Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{item.blocks[0].cost}</td>
                                                <td>Yes</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                            <td>
                                <Menu >
                                <MenuButton>
                                    Actions <span aria-hidden>▾</span>
                                </MenuButton>
                                <MenuList style={{backgroundColor: theme.colorScheme === "light" ? "#ffffff" : "#000000", color: theme.colorScheme === "light" ? "#000000" : "#ffffff"}}>
                                        <MenuItem onSelect={() => {askUpdateTarrif(item._id, item?.name)}}>Edit</MenuItem>
                                        <MenuItem onSelect={() => {deleteTarrif(item._id)}}>Delete</MenuItem>
                                    </MenuList>
                                </Menu>
                            </td>
                        </tr>
                    )
                })
    
        )
    }

    const theme = useMantineTheme();

    const { height } = useViewportSize();

    return(
        <Paper p="md">
        <Helmet>
            <meta charSet='utf-8' />
            <title>Tarrifs | {state.userData.waterServiceName}</title>
        </Helmet>
        <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Tarrifs</Heading>
        <Drawer opened={opened} onClose={() => {setOpened(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.95}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Update Tarrif</Heading>
            
            <TextInput mt="md" label="Name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} />
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
            <Button size='sm' radius={28} onClick={() => {updateTarrif()}} loading={loading}>Update Tarrif</Button>
        </Group>
        </Stack>
      </Drawer>
        <div style={{overflowX: 'auto'}} >
        <Table highlightOnHover size='xs' style={{borderBottom: '1px solid #E9ECEF'}} >
        <thead>
            <tr>
                <th>Name</th>
                <th><Center>Consumption Blocks</Center></th>
                <th>Configured on</th>
                <th>Last Modified</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
        {tarrifs.length === 0 ? (
            <tr>
                <td colSpan={5}>
                    <Center>
                        <Text>No data found</Text>
                    </Center>
                </td>
            </tr>
        ) : <TarrifsList tarrifs={tarrifs} />}
        </tbody>
    </Table>
    </div>

        </Paper>
    )
}
