import { Alert, Center, Group, Stack, Text, useMantineColorScheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react"
import { ClockOff } from "tabler-icons-react";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import { IconInfoCircle } from "@tabler/icons";
import { Heading } from "@aws-amplify/ui-react";
const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);


export default function History(){
    const { state } = useContext(AuthContext);

    const [history, setHistory] = useState([]);

    const [loading, setLoading] = useState(false);

    const { height } = useViewportSize();

    useEffect(() => {
        setLoading(true);

        axios.get("/bulk-history/histories", {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                if(Array.isArray(res.data.data)){
                    setHistory(res.data.data.reverse());
                }
                setLoading(false)
            }
        }).catch(function(err){
            setLoading(false);
            toast.error(err?.message || "An issue occured while fetching your data");
        })
    }, [])

    const theme = useMantineColorScheme();

    return (

        <>
        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Emita end-to-end pipeline is sequential. That means that meter readings have to be taken and bills delivered to have a list of payments. Although some payments may be received in-sequentially, they will not be displayed.
        </Alert>

        <Group mt={20} mb={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Bulk Histories</Heading>
      </Group>

        <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">

        {history.length > 0 && history.slice(0, 10).map((itm, idx) => {
            return (
                <div key={`billing-history-${idx}`} className="relative">
                <div className="md:flex items-center md:space-x-4 mb-3">
                    <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
           
                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1">
                            <svg className={itm.title === "Reminders" ? "fill-emerald-500" : "fill-pink-400"} xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                <path d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
                            </svg>
                        </div>
        
                        <time className="font-caveat font-light md:w-28">{new Date(itm.createdAt).toLocaleDateString()}</time>
                    </div>
          
                    <div className="ml-14"><span className="font-bold">{itm.title}</span> - {dayjs(itm.createdAt).fromNow()}</div>
                </div>
        
                <div className="p-4 rounded border border-slate-200 shadow ml-14 md:ml-44">
                    {itm.description}
                </div>
            </div>
            )
        })}
<Stack justify="center" h={height * 0.6}>
        {history.length === 0 && !loading ? (
            <>
            <Center>
                <ClockOff />
            </Center>
            <Text align="center" mt={20}>History for bulk operations involving bills such as reminders will appear here.</Text>
            </>
        ) : null}

        {history.length === 0 && loading ? (
            <Center>
                <div
  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
  role="status">
  <span
    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >
        Loading...
    </span>
</div>
            </Center>
        ) : null}

</Stack>
</div>
</>
    )
}