import { Button, NumberInput, Paper, Select, TextInput, } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Alert } from "@aws-amplify/ui-react";
import ReactGA from "react-ga4";

export default function CreateDate(){
    const [category, setCategory] = useState("");
    const { state, dispatch } = useContext(AuthContext);
    const [success, setSuccess] = useState({success: false, message: ""});
    const [error, setError] = useState({error: false, message: ""})
    const [r, setR] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dynamicDate, setDynamicDate] = useState(14);


    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);


    const saveDate = (e) => {
        e.preventDefault();

        setLoading(true);

        axios.post("/due-dates/create", {
            parent: state.userData._id,
            category: category,
            date: ""+dynamicDate
        }, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
            if(res.status === 200){
                setR(!r)
                setSuccess({success: true, message: `The date has been configured successfully.`});
                setLoading(false);
                window.location.reload();
            }
        }).catch(function(err){
            setError({error: true, message: error.message});
            setLoading(false);
        })
    }
    return (
        < >
        {success.success ? (
            <Alert marginTop={-10} marginLeft={-10} marginRight={-10} marginBottom={20} variation="success" hasIcon={true} isDismissible={false}>
                {success.message}
          </Alert>
        ) : null}

        {error.error ? (
            <Alert marginTop={-20} marginLeft={-20} marginRight={-10} marginBottom={20} variation="error" hasIcon={true} isDismissible={false}>
                {error.message}
          </Alert>
        ) : null}
        <form onSubmit={(e) => {saveDate(e)}}>
        <Select mb={"md"} label="Category" data={[{label: "Fixed", value: "Fixed"}, {label: "Dynamic", value: "Dynamic"}]} value={category} onChange={(val) => {setCategory(val)}} />

        <NumberInput label={category === "Fixed" ? "Date" : "Number of Days"} hideControls min={1} max={28} value={dynamicDate} onChange={(val) => {setDynamicDate(val)}} />

        <Button mb={50} disabled={loading} loading={loading} type="submit" mt={20} radius={28}>Configure Default Invoice Date</Button>
        </form>
        </>
    )
}