import { useState } from "react";
import { Anchor, Button, Checkbox, TransferList, Group, Select, TextInput, Alert, Center, Loader, ActionIcon, Input, useMantineTheme, Textarea } from "@mantine/core";
import { DateRangePicker, TimeInput } from '@mantine/dates';
import { Avatar, Text } from '@mantine/core';
import { useEffect } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import { useContext } from "react";
import { IconAlertCircle } from "@tabler/icons";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { nanoid } from "nanoid";
import { useQuery } from "../../login/login";
import { Helmet } from "react-helmet";
import { Heading } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { DEFAULT_METER_READING_CYCLE } from "../../../constants";
import { ArrowLeft } from "tabler-icons-react";

export default function CreateTask(){
    const query = useQuery();
    const [r, ] = useState(query.get("recurring") || "")
    const [dates, setDates] = useState(DEFAULT_METER_READING_CYCLE);
    const date = new Date();
    date.setDate(dates?.starts)
    const days = dates?.duration;
    const [value, setValue] = useState([
        date,
        new Date(date.getTime()+(days * 24 * 60 * 60 * 1000)),
      ]);
    const { state } = useContext(AuthContext);
    const [staff, setStaff] = useState([]);
    const [ready, setReady] = useState(false);
    const now = dayjs().hour(0).minute(0).second(0).toDate();
    const then = dayjs(now).add(23, 'hours').add(59, "minute").add(59, "second").toDate();
    const [val1, setVal1] = useState(now);
    const [val2, setVal2] = useState(then);
    const [time, ] = useState([val1, val2]);
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [dmas, setDMAs] = useState([]);
    const [meters, setMeters] = useState([]);
    const [rawmeters, setRawMeters] = useState([]);
    const [task, setTask] = useState("");
    const [dma, setDMA] = useState("");
    const [building, ] = useState([]);
    const [category, setCategory] = useState("");
    const [step, setStep] = useState(0);
    const [done, setDone] = useState(false);
    const [y, setY] = useState([[], []])
    const [z, setZ] = useState([[], []])
    const [recurring, setRecurring] = useState(r === "yes" ? true : false);
    const [startsIn, setStartsIn] = useState(null);
    const [endsIn, setEndsIn] = useState(null);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    useEffect(() => {
        setReady(false);

        const body = {
            parent: state.userData._id
        }; 

        axios.post("/dates/get-dates", body).then(function (res) {
            if (res.status === 200) {
              setDates(res.data.data)

              let dates = res.data.data;

            if(dates !== null){
                setDates(dates);
            } else {
                return;
            }

            if(dates && dates !== null){
                let today = new Date();
                let startsOn = dates?.starts;
                let duration = dates?.duration;
            
                today.setDate(startsOn);
                today.setHours(0);
                today.setMinutes(0);
    
                setStartsIn(today);
            
                let endsIn = new Date(today.getTime() + (duration * 24 * 60 * 60 * 1000));
    
                setEndsIn(endsIn);
            }

              setReady(false);
            }
          }).catch(function (error) {
            console.log(error);
            setReady(false);
          })
    }, [])

    useEffect(() => {
        if(dma !== ""){
            if(dma === "all"){
                setZ([rawmeters, []]);
            } else {
                let arr = [];
                for(let i=0; i<rawmeters.length; i++){
                    if(rawmeters[i].dma === dma){
                        arr.push(rawmeters[i]);
                    }
                }
                setZ([arr, []]);
            }
        } else {
            setZ([meters, []]);
        }
    }, [dma])

    useEffect(() => {
        if(category !== ""){
            let arr2 = [];

            for(let i=0; i<staff.length; i++){
                if(staff[i].group === category){
                    arr2.push(staff[i])
                }
            }

            setY([arr2, []]);
        } else {
            setY([staff, []]);
        }
    }, [category]);

    useEffect(() => {
        setReady(false);
        try {
            axios.post("/dmas/getdmas", {
                username: state.userData._id
            }).then(function(res){
                if(res.status === 200){
                    let arr = [];
                    for(let i=0; i<res.data.data.length; i++){
                        let item = res.data.data[i];
                        arr.push({label: item.name, value: item.name});
                    }

                    setDMAs(arr);
                }
                setReady(true)
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                });
                setReady(true)
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            })
            setReady(true)
        }
    }, []);

    useEffect(() => {
        setReady(false)
        try {
            axios.post("/customers/getCustomers", {
                parent: state.userData._id
            }).then(function(res){
                let arr = [];
                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];
                    arr.push({label: item.account, value: item.account, dma: item.dma, group: item.dma})
                }
                setZ([arr, []]);
                setRawMeters(arr);
                setReady(true)
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                });
                setReady(true)
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            })
            setReady(true)
        }
    }, []);

    const createTask = () => {
        if(title === ""){
            toast.error("Title cannot be blank", {
                position: "bottom-right"
            });
            return false;
        }

        if(task === ""){
            toast.error("Task category cannot be blank", {
                position: "bottom-right"
            });
            return false;
        }

        if(dma === "" && task !== "3"){
            toast.error("Select a dma for task", {
                position: "bottom-right"
            });
            return false
        }

        if(building === "" && (task !== "3" || task !== "2")){
            toast.error("Buildings cannot be empty", {
                position: "bottom-right"
            });
            return false
        }

        let arr = [];

        if(task !== "2" || task !== "3"){
            for(let i=0; i<y[1].length; i++){
                arr.push(y[1][i].value);
            }
        }

        let arr2 = [];

        if(task !== "2" || task !== "3"){
            for(let i=0; i<z[1].length; i++){
                arr2.push(z[1][i].value);
            }
        }

        try {
            const body = {
                title: title,
                description: desc,
                startDate: value[0],
                endDate: value[1],
                startTime: time[0],
                endTime: time[1],
                dma: dma,
                task: task,
                building: arr2,
                uuid: nanoid(64),
                staff: arr,
                completeBuildings: [],
                staffCategory: category,
                parent:state.userData._id,
                recurring: recurring
            };

            axios.post("/tasks/create", body).then(function(res){
                //setDone(true);
                setTitle("");
                setDesc("");
                setStep(0);
                setTask("");
                setCategory("");
                setDMA("");

                toast.success("The task was dispatched successfully", {
                    position: "bottom-right"
                })
            }).catch(function(error){
                setDone(true);
                toast.error(error.message, {
                    position: "bottom-right"
                })
            })
        } catch(error){
            setDone(true);
            toast.error(error.message, {
                position: "bottom-right"
            })
        }
    }

    useEffect(() => {

        const fetchStaff = () => {
            setReady(false)

            const loading = toast.loading("Loading configurations...", {
                position: "bottom-right",
            })
            try {
                
                axios.post("/staff/getstaffs", {
                    parent: state.userData._id
                }).then(function(res){
                    if(res.data.data.length > 0){
                        let arr = [];

                        for(let i=0; i<res.data.data.length; i++){
                            let item = res.data.data[i];

                            let chunk =   {
                                image: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
                                label: item.firstname + " " + item.lastname,
                                value: item.username,
                                description: item.username,
                                title: item.title,
                                group: item.title
                              };

                              arr.push(chunk);
                        }
                        setY([arr, []]);
                        setStaff(arr);
                        setReady(true);
                        toast.dismiss(loading);
                    } else {
                        toast.error('OOPS! Seems like you have not created any staff', {
                            id: loading,
                          });
                          setReady(true);
                    }
                }).catch(function(error){
                    toast.error('oops! Something terrible happened', {
                        id: loading,
                      });
                    console.log(error);
                    setReady(true)
                })
            } catch(error){
                toast.error('oops! Something terrible happened', {
                    id: loading,
                  });
                console.log(error.message);
                setReady(true)
            }
        }

        fetchStaff();
    }, []);

    useEffect(() => {
        if(dma === ""){
            setMeters(rawmeters)
        } else if(dma === "all") {
            setMeters(rawmeters);
        } else {
            let arr = [];
            for(let i=0; i<rawmeters.length; i++){
                let obj = rawmeters[i];

                if(obj.dma.includes(dma)){
                    arr.push(obj);
                }
            }

            setMeters(arr);
        }

        if(task === "0"){
            setTitle(`Customer meter reading - ${dma}`);
            setDesc(`Perform customer meter reading for the attached customers. Verify that the data is accurate before submitting`);
        } else if(task === "2"){
            setTitle(`Master meter reading for zone - ${dma}`)
            setDesc('Perform master meter reading. Ensure that the data you are submitting is accurate.');
        } else {
            setTitle(`Customer location update at zone - ${dma}`);
            setDesc('Update customer locations for the attached accounts. Ensure that the GPS coordinate captured is okay before submitting.');
        }
    }, [dma]);

    const ItemComponent = ({
        data,
        selected,
      }) => (
        <Group noWrap>
          <Avatar src={data.image} radius="xl" size="lg" />
          <div style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {data.label}
            </Text>
            <Text size="xs" color="dimmed" weight={400}>
              {data.description}
            </Text>
          </div>
          <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
        </Group>
      );

    const theme = useMantineTheme();

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Schedule New Task</title>
        </Helmet>

        <Heading fontWeight={500} level={5} marginBottom={10} marginTop={10} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Create and schedule new task</Heading>

        {!ready ? (
            <Center mt={"10%"} >
                <Loader size="xs" variant="bars" />
            </Center>
        ): (
            staff.length > 0 && dates !== null ? (
                done ? (
                    <>
                    <Center mt={"10%"} mb={20}>
                        <FontAwesomeIcon icon={faCheckCircle} size="xl" color="green" />
                    </Center>
                    <Text align="center">{`Task deployed successfully.`}</Text>
                    </>
                ) : (
                    <>
                    {step === 0 ? (
                        <>
                    <Select searchable value={task} onChange={(val) => {setTask(val)}} label="Task category" description="Select one that fits the scheduled task." data={[
                        {label: "Customer Meter Reading", value: "0"},
                        {label: "Master Meter Reading", value: "2"},
                        {label: "Customer Location Updates", value: "3"},
                    ]} />
                    <Checkbox checked={recurring} disabled={task === "3"} onChange={(e) => {setRecurring(e.currentTarget.checked)}} mt={10} label="Recurring task?" />
                    <Group mt={20} position="right">
                        <Button disabled={task === "" ? true : false} onClick={() => {setStep(1)}} >Next</Button>
                    </Group>
                        </>
                    ) : step === 1 ? (
                        <>
                        <Select searchable withAsterisk mt="md" value={dma} onChange={(val) => {setDMA(val)}} label="DMA/Zone" description="Select the zone which the task will take place." data={dmas} />
                        {dma !== "" && task !== "2" ? (
                            <TransferList
                            styles={{transferListTitle: {weight: 400, fontSize: 14}}}
                            titles={["Meters", "Assigned Meters"]}
                            value={z}
                            mt={10}
                            onChange={setZ}
                            listHeight={300}
                            searchPlaceholder="Search..."
                            nothingFound="No account found"
                            filter={(query, item) =>
                                item.label.toLowerCase().includes(query.toLowerCase().trim()) ||
                                item.dma.toLowerCase().includes(query.toLowerCase().trim())
                            }
                            />
                        ) : null}

                    <Group mt={20} position="right">
                    <ActionIcon onClick={() => {setStep(0)}} >
                            <ArrowLeft />
                        </ActionIcon>
                        <Button disabled={dma === "" ? true : false} onClick={() => {setStep(2)}} >Next</Button>
                    </Group>
                        </>
                    ) : step === 2 ? (
                        task === "0" ? (
                            <>
                            <DateRangePicker
                              label="DateRange"
                              description="Dates since and upto the time the task is scheduled to complete. Uses the configured dates in the system."
                              placeholder=""
                              value={[startsIn, endsIn]}
                              mt={10}
                              mb={10}
                              withAsterisk
                            />
        
                            <TimeInput mb={10} disabled withAsterisk label="Starts At" description="Daily time at which the task is supposed to start." format="12" value={startsIn} onChange={setVal1} />
                            <TimeInput disabled withAsterisk label="Ends At" description="Daily time at which the task is supposed to end" format="12" value={endsIn} onChange={setVal2} />
                            <Group mt={20} position="right">
                            <ActionIcon onClick={() => {setStep(task === "3" ? 0 : 1)}} >
                                    <ArrowLeft />
                                </ActionIcon>
                                <Button onClick={() => {setStep(3)}} >Next</Button>
                            </Group>
                                </>
                        ) : (
                            <>
                            <DateRangePicker
                              label="DateRange"
                              description="Dates since and upto the time the task is scheduled to complete."
                              placeholder=""
                              value={value}
                              onChange={setValue}
                              mt={10}
                              withAsterisk
                            />
        
                            <TimeInput withAsterisk label="Starts At" description="Daily time at which the task is supposed to start." format="12" value={val1} onChange={setVal1} />
                            <TimeInput withAsterisk label="Ends At" description="Daily time at which the task is supposed to end" format="12" value={val2} onChange={setVal2} />
                            <Group mt={20} position="right">
                            <ActionIcon onClick={() => {setStep(task === "3" ? 0 : 1)}} >
                                    <ArrowLeft />
                                </ActionIcon>
                                <Button onClick={() => {setStep(3)}} >Next</Button>
                            </Group>
                                </>
                        )
                    ) : step === 3 ?  (
                        <>
                    <Select value={category} onChange={(val) => {setCategory(val)}} mt={10} label="Staff Category" description="Select the best fit of category for this task" withAsterisk data={[
                        {label: "Technician", value: "technician"}
                    ]} />

                    {category !== "" ? (
                            <TransferList
                            value={y}
                            mt={10}
                            onChange={setY}
                            titles={['All staff', 'Staff to assign task']}
                            listHeight={250}
                            searchPlaceholder="Search..."
                            nothingFound="No staff found"
                            itemComponent={ItemComponent}
                            filter={(query, item) =>
                              item.label.toLowerCase().includes(query.toLowerCase().trim()) ||
                              item.description.toLowerCase().includes(query.toLowerCase().trim())
                            }
                          />
                    ) : null}
                
                    <Group position="right" mt={20}>
                    <ActionIcon onClick={() => {setStep(2)}} >
                            <ArrowLeft />
                        </ActionIcon>
                        <Button disabled={y[1].length === 0 ? true : false} onClick={() => {setStep(4)}} >Next</Button>
                    </Group>
                        </>
                    ) : (
                        <>
                        <TextInput mt="md" value={title} onChange={(e) => {setTitle(e.currentTarget.value)}} label="Task Name" description="Brief title about the task e.g Meter Reading, Leakage Fixation etc" placeholder="" withAsterisk />
                        <Input.Wrapper label="Description" description="Long details about the task" withAsterisk mt={10}>
                        <Textarea minRows={4} autosize value={desc} onChange={(e) => {setDesc(e.currentTarget.value)}} />
                        </Input.Wrapper>
                    <Group mt={20} position="right">
                        <ActionIcon onClick={() => {setStep(3)}} >
                            <ArrowLeft />
                        </ActionIcon>
                        <Button disabled={desc === "" || title === "" ? true : false} onClick={() => {createTask()}} >Create Task</Button>
                    </Group>
                        </>
                    )}
                        </>
                )
                
            ) : (
                <>
                {staff.length === 0 ? (
                    <Alert mt={20} icon={<IconAlertCircle/>} title="Warning!" color="red" variant="outline">
                    Your account does not seem to have any onboarded staff. You need to onboard staff and configure their roles before assigning them tasks.<br /> <Anchor component={Link} to={`/app/staff#${state.userData._id}`}>Onboard new staff here</Anchor>
                  </Alert>
                ) : null}

                </>
            )
        )}
        </>
    )
}