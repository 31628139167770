import { jsPDF } from "jspdf-invoice-template";

/**
 * 
 * @props {{
 * water_service_name: string,
 * company_phone: string,
 * company_email: string,
 * customer_name: string,
 * invoice_date: string,
 * customer_address: string,
 * bill_invoice_date: string,
 * customer_phone: string,
 * invoice_number: string,
 * account_number: string,
 * meter_number: string,
 * due_date: string,
 * previous_bill_date: string,
 * defaulted_balance: string,
 * tables_date: [[previous_reading: string, current_reading: string, consumption: string]],
 * current_bill_date: string,
 * water_blocks: [[units: string, rate: string, cost: string]],
 * total_water_charges: string,
 * 
 * has_standing_charges: boolean,
 * standing_charges: string,
 * 
 * 
 * sub_total_amount: string,
 * total: string,
 * vat_percentage: string,
 * vat_amount: string,
 * total_charges: string,
 * 
 * has_sewer_charges: boolean,
 * sewer_blocks = [[units: string, rate: string, cost: string]]
 * }}
 * 
 */
function appendZero(phoneNumber) {
    // Get the last 9 digits of the phone number.
    const phoneNumberDigits = phoneNumber.slice(-9);

    return "0"+phoneNumberDigits;
}  
  
export function generateInvoice(props){
    const splitTextAndGetHeight = (text, size) => {
        var lines = doc.splitTextToSize(text, size);

        return {text: lines, height: doc.getTextDimensions(lines).h + 3}
    }

    const options = {
        orientation: "",
        compress: true
    };

    var doc = new jsPDF(options);

    var docWidth = doc.internal.pageSize.width;

    var black_color = "#000000";
    var current_height = 5;

    var pdf_config = {
        logo: 20,
        headerTextSize: 15,
        labelTextSize: 10,
        fieldTextSize:9,
        lineHeight: 6,
        subLineHeight: 4,
        tableHeight: 2
    };

    doc.setFontSize(pdf_config.fieldTextSize);
    doc.setTextColor(black_color);
    doc.setFont(undefined, "bold");
    // letter head
    doc.text(docWidth * 0.5, current_height, props.water_service_name, {align: 'center'});
    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "normal");
    doc.text(docWidth * 0.5, current_height, props.company_phone, {align: 'center'});
    current_height += pdf_config.subLineHeight;
    doc.text(docWidth * 0.5, current_height, props.company_address, {align: 'center'});
    current_height += pdf_config.subLineHeight;
    doc.text(docWidth * 0.5, current_height, props.company_email, {align: 'center'});

    // line break
    current_height += pdf_config.subLineHeight;
    doc.line(10, current_height, docWidth-10, current_height);

    // contact
    current_height += pdf_config.lineHeight;
    doc.text(10, current_height, props.customer_name);
    doc.text(docWidth - 70, current_height, "Invoice Date:", "left");
    doc.text(docWidth - 30, current_height, new Date().toLocaleDateString(), "left");
    current_height += pdf_config.subLineHeight;

    doc.text(10, current_height, props.customer_address.replace("undefined", ""));
    doc.text(docWidth - 70, current_height, "Bill Invoice For:", "left");
    doc.text(docWidth - 30, current_height, props.bill_month, "left");
    current_height += pdf_config.subLineHeight;
    doc.text(10, current_height, appendZero(props.customer_phone));
    doc.text(docWidth - 70, current_height, "Invoice #:", "left");
    doc.text(docWidth - 30, current_height, props.invoice_number, "left")
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth - 70, current_height, "Account #:", "left");
    doc.text(docWidth - 30, current_height, props.account_number, "left");
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth - 70, current_height, "Meter #:", "left");
    doc.text(docWidth - 30, current_height, props.meter_number, "left");
    current_height += pdf_config.subLineHeight;

    doc.text(docWidth - 70, current_height, "Account Status:", "left");
    doc.text(docWidth - 30, current_height, props.account_status, "left");
    current_height += pdf_config.subLineHeight;

    doc.setFont(undefined, "bold");
    doc.text(docWidth - 70, current_height, "Due Date:", "left");
    doc.text(docWidth - 30, current_height, props.due_date, "left");
    doc.setFont(undefined, "normal");
    current_height += pdf_config.subLineHeight;

    // line breaker
    current_height += pdf_config.subLineHeight;
    doc.line(10, current_height, docWidth - 10, current_height);

    // balance brought forward
    current_height += pdf_config.lineHeight;
    doc.setFont(undefined, "bold");
    doc.text(10, current_height, `Cumulative balance brought forward from the previous bills:`);
    doc.text(docWidth - 30, current_height, "KSH."+props.defaulted_balance);
    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "normal");

    // table
    var td_width = (doc.getPageWidth() - 20) / 4;
    var headers = ["Previous Reading", "Present Reading", "Consumption Billed"];

    if(headers.length > 2){
        // add style for 2 or more columns
        const customColumnNo = headers.map(x => x?.style?.width || 0).filter(x => x > 0);
        let customWidthOfAllColumns = customColumnNo.reduce((a, b) => a+b, 0);
        td_width = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (headers.length - customColumnNo.length);
    }

    // table border header
    var addTableHeaderBorder = () => {
        current_height += 2;
        const line_height = 7;
        let start_width = 0;

        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width ||  td_width;
            if(i === 0){
                doc.rect(10, current_height, current_td_width, line_height);
            } else {
                const previous_td_width = headers[i-1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, line_height);
            }
        }

        current_height -= 2;
    };

    // table body border
    var addTableBodyBorder = (lineHeight) => {
        let start_width = 0;
        for(let i=0; i<headers.length; i++){
            const current_td_width = headers[i]?.style?.width || td_width;
            if(i === 0) {
                doc.rect(10, current_height, current_td_width, lineHeight);
            } else {
                const previous_td_width = headers[i - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.rect(start_width + 10, current_height, current_td_width, lineHeight);
            }
        }
    };

    // table header
    var addTableHeader = () => {
        addTableHeaderBorder();

        current_height += pdf_config.subLineHeight;
        doc.setFontSize(pdf_config.fieldTextSize);
        doc.setDrawColor(black_color);
        current_height += 2;

        let start_width = 0;
        headers.forEach(function (row, index) {
            if(index === 0){
                doc.text(row, 11, current_height);
            } else {
                const current_td_width = row?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(row, start_width + 11, current_height);
            }
        });

        current_height += pdf_config.subLineHeight - 1;
    }

    addTableHeader();

    const tables_data = props.tables_data;

    tables_data.forEach(function(row, index) {
        doc.line(10, current_height, docWidth - 10, current_height);

        // get max height for the current row
        var getRowsHeight = function(){
            let rowsHeight = [];

            row.forEach(function(rr, index){
                const width_to_use = headers[index]?.style?.width || td_width;

                let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix the padding issues between borders
                rowsHeight.push(item.height)
            });

            return rowsHeight;
        }

        var maxHeight = Math.max(...getRowsHeight());

        // body borders
        addTableBodyBorder(maxHeight + 1);

        let start_width = 0;

        row.forEach(function(rr, index) {
            const width_to_use = headers[index]?.style?.width || td_width;
            let item = splitTextAndGetHeight(rr, width_to_use - 1); // minus 1 to fix padding issues between borders
            
            if(index === 0){
                doc.text(item.text, 11, current_height + 4);
            } else {
                const current_td_width = rr?.style?.width || td_width;
                const previous_td_width = headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(item.text, 11+start_width, current_height + 4);
            }
        });

        current_height += maxHeight - 4;

        // td border height
        current_height += 5;
    })

    current_height += pdf_config.lineHeight;

    doc.text(10, current_height, `Current bill of ${props.bill_month}`);
    current_height += pdf_config.subLineHeight + 3;
    doc.setFont(undefined, "bold");
    doc.text(10, current_height, "Part A:")
    doc.setFont(undefined, "normal");
    current_height += pdf_config.subLineHeight;

    const water_headers = ["Units Consumed(M³)", "Rate(KES/M³)", "Cost(KES)"];
    if(water_headers.length > 2){
        const customColumnNo = water_headers.map(x => x?.style?.width || 0).filter(x => x > 0);
        let customWidthOfAllColumns = customColumnNo.reduce((a, b) => a+b, 0);
        td_width = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (water_headers.length - customColumnNo.length);
    }


    var addWaterTableHeader = () => {
        current_height += pdf_config.tableHeight;
        let start_width = 0;

        water_headers.forEach(function(row, index) {
            if(index === 0){
                doc.text(row, 11, current_height);
            } else {
                const current_td_width = row?.style?.width || td_width;
                const previous_td_width = water_headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(row, start_width + 11, current_height);
            }
        });

        current_height += pdf_config.subLineHeight - 1;
    };

    addWaterTableHeader();

    var water_consumption = props.water_blocks;

    water_consumption.forEach(function(row, index){
        //get max height for the current row
        var getRowsHeight = function(){
            let rowsHeight = [];
            row.forEach(function(rr, index){
                const width_to_use = water_headers[index]?.style?.width || td_width;
                let item = splitTextAndGetHeight(rr, width_to_use - 1);
                rowsHeight.push(item.height);
            });

            return rowsHeight;
        };

        var maxHeight = Math.max(...getRowsHeight());
        //addWaterTableBodyBorder(maxHeight + 1);

        let start_width = 0;
        row.forEach(function(rr, index){
            const width_to_use = water_headers[index]?.style?.width || td_width;
            let item = splitTextAndGetHeight(rr, width_to_use - 1);

            if(index === 0){
                doc.text(item.text, 11, current_height + 4);
            } else {
                const current_td_width = rr?.style?.width || td_width;
                const previous_td_width = water_headers[index - 1]?.style?.width || td_width;
                const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                start_width += width_to_use;
                doc.text(item.text, 11 + start_width, current_height + 4);
            }
        });

        current_height += maxHeight - 1;
    });
    current_height += pdf_config.subLineHeight + 5;
    doc.text(10, current_height, `Total water charges for the period of ${props.bill_month}`);
    doc.text(docWidth - 30, current_height, "KSH."+props.total_water_charges);
    current_height += pdf_config.subLineHeight;

    if(props.has_standing_charges){
        current_height += pdf_config.subLineHeight + 3;
        doc.setFont(undefined, "bold")
        doc.text(10, current_height, "Part B:")
        doc.setFont(undefined, "normal")
        current_height += pdf_config.subLineHeight + 2;
        doc.text(10, current_height, `Monthly standing charges for the period of ${props.bill_month}`);
        doc.text(docWidth - 30, current_height, "KSH."+props.standing_charges);
    }

    if(props.has_sewer_charges){
        current_height += pdf_config.subLineHeight + 3;
        doc.setFont(undefined, "bold");
        doc.text(10, current_height, props.has_standing_charges ? "Part C:" : "Part B:");
        doc.setFont(undefined, "normal");
        current_height += pdf_config.subLineHeight;

        const sewer_headers = ["Units Consumed(M³)", "Rate(KSH)", "Cost"];
        if(sewer_headers.length > 2){
            const customColumnNo = sewer_headers.map(x => x?.style?.width || 0).filter(x => x > 0);
            let customWidthOfAllColumns = customColumnNo.reduce((a, b) => a + b, 0);
            td_width = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (sewer_headers.length - customColumnNo.length);
        }

        var addSewerTableHeader = () => {
            //addSewerTableHeaderBorder();

            current_height += pdf_config.tableHeight;
            let start_width = 0;

            sewer_headers.forEach(function(row, index) {
                if(index === 0){
                    doc.text(row, 11, current_height);
                } else {
                    const current_td_width = row?.style?.width || td_width;
                    const previous_td_width = sewer_headers[index - 1]?.style?.width || td_width;
                    const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                    start_width += width_to_use;
                    doc.text(row, start_width + 11, current_height);
                }
            });

            current_height += pdf_config.subLineHeight - 1;
        }

        addSewerTableHeader();

        var sewer_blocks = props.sewer_blocks;

        sewer_blocks.forEach(function(row, index) {
            // get maximum height for the current row
            var getRowsHeight = function(){
                let rowsHeight = [];
                row.forEach(function(rr, index) {
                    const width_to_use = sewer_headers[index]?.style?.width || td_width;
                    let item = splitTextAndGetHeight(rr, width_to_use - 1);
                    rowsHeight.push(item.height);
                });

                return rowsHeight;
            };

            var maxHeight = Math.max(...getRowsHeight());
            //addSewerTableBodyBorder(maxHeight + 1);

            let start_width = 0;
            row.forEach(function(rr, index) {
                const width_to_use = sewer_headers[index]?.style?.width || td_width;
                let item = splitTextAndGetHeight(rr, width_to_use - 1);

                if(index === 0){
                    doc.text(item.text, 11, current_height + 4);
                } else {
                    const current_td_width = rr?.style?.width || td_width;
                    const previous_td_width = sewer_headers[index - 1]?.style?.width || td_width;
                    const width_to_use = current_td_width === previous_td_width ? current_td_width : previous_td_width;
                    start_width += width_to_use;
                    doc.text(item.text, 11 + start_width, current_height + 4);
                }
            });

            current_height += maxHeight - 1;
        });

        current_height += pdf_config.subLineHeight + 5;
        doc.text(10, current_height, `Total sewer charges for the period of ${props.bill_month}`);
        doc.text(docWidth - 30, current_height, "KSH."+props.total_sewer_charges);
        current_height += pdf_config.subLineHeight;
    }

    current_height += pdf_config.lineHeight + 10;
    doc.text(docWidth * 0.5, current_height, "Sub-total Amount:");
    doc.text(docWidth - 30, current_height, "KSH."+props.sub_total_amount);
    current_height += pdf_config.subLineHeight;

    doc.setFont(undefined, "bold");
    doc.setFontSize(pdf_config.headerTextSize - 2);
    current_height += pdf_config.lineHeight;
    doc.text(docWidth * 0.5, current_height, "Total Due:");
    doc.setFontSize(pdf_config.fieldTextSize);
    doc.text(docWidth - 30, current_height, "KSH."+(parseFloat(props.total) + parseFloat(props.defaulted_balance)).toFixed(2));
    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "normal");
    doc.setFontSize(pdf_config.fieldTextSize);

    current_height += pdf_config.subLineHeight;
    doc.setFont(undefined, "bold");
    doc.text(docWidth * 0.5, current_height, `Summary`);
    doc.setFont(undefined, "normal");
    current_height += pdf_config.subLineHeight;
    doc.line(docWidth * 0.5, current_height, docWidth - 10, current_height);

    current_height += pdf_config.lineHeight;
    doc.text(docWidth * 0.5, current_height, "Sub-total in KES:");
    doc.text(docWidth - 30, current_height, "KSH."+props.sub_total_amount);
    current_height += pdf_config.subLineHeight + 2;

    doc.text(docWidth * 0.5, current_height, `Previous Balance${parseFloat(props.defaulted_balance) > 0 ? "(Defaulted)" : parseFloat(props.defaulted_balance) < 0 ? "(Overpayment)" : ""}:`);
    doc.text(docWidth - 30, current_height, "KSH."+props.defaulted_balance);
    current_height += pdf_config.subLineHeight + 2;

    doc.text(docWidth * 0.5, current_height, `VAT(${props.vat}%):`);
    doc.text(docWidth - 30, current_height, "KSH."+props.vat_amount);
    current_height += pdf_config.subLineHeight + 2;

    doc.text(docWidth * 0.5, current_height, "Total:");
    doc.text(docWidth - 30, current_height, "KSH."+(parseFloat(props.total) + parseFloat(props.defaulted_balance)).toFixed(2));
    current_height += pdf_config.subLineHeight + 10;

    doc.text(10, doc.internal.pageSize.height - (doc.internal.pageSize.height * 0.15), `This invoice outlines your water consumption for the period of ${props.last_reading_month} ${props.current_reading_month === "January" ? (parseFloat(props.reading_date) - 1).toString() : props.reading_date} to ${props.current_reading_month} ${props.reading_date} for the user ${props.customer_name} with account number ${props.account_number}. All payments should be made on or before ${props.due_date}. For cheque payments, kindly attach the customer account number at the back of the cheque. For payments through the bank, forward the deposit slips/credit advices to our office for receipting. You can access your consumption, invoicing and payments history via your customer self service portal at https://emita.vercel.app/customer/login`, {maxWidth: docWidth * 0.75})
    current_height += pdf_config.subLineHeight;

    // add page number
    doc.text(10, doc.internal.pageSize.height - 6, "Invoiced by Emita");
    doc.text("1/1", docWidth - 20, doc.internal.pageSize.height - 6);


    let return_obj = {
        pagesNumber: doc.getNumberOfPages(),
    };

    if(props.return_jspdfdoc_object){
        return_obj = {
            ...return_obj,
            jsPDFDocObject: doc
        };
    }

    switch(props.output_type){
        case "save":
            doc.save(props.filename);
            break;
        case "blob":
            const blob_output = doc.output("blob");
            return_obj = {
                ...return_obj,
                blob: blob_output
            };
            break;
        case "datauristring":
            return_obj = {
                ...return_obj,
                dataUriString: doc.output("datauristring", {
                    filename: props.filename
                }),
            };
            break;
        case "arraybuffer":
            return_obj = {
                ...return_obj,
                arrayBuffer: doc.output("arraybuffer"),
            };
            break;
        default:
            doc.output(props.output_type, {
                filename: props.filename
            });
    }

    return return_obj;

}