import { ActionIcon, Button, Center, Group, Loader, Menu, Paper, Table, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Dots, Plus } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Alert } from "@aws-amplify/ui-react";
import dayjs from "dayjs";
import swal from "sweetalert";
import ReactGA from "react-ga4";

export default function StandingCharges(){
    const { state, dispatch } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    useEffect(() => {
        setLoading(true);
        axios.post("/standing-charges/get-standing-charge", {
            parent: state.userData._id
        }).then(function(res){
            if(res.status === 200){
                setData(res.data.data);
                setLoading(false);
            }
        }).catch(function(error){
            toast.error(error.message, {
                position: "bottom-right",
            });
            setLoading(false);
        })
    }, [refresh])

    const deleteStandingCharge = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to continue with this action?",
            icon: "warning",
            buttons: {
                cancel: "No, Cancel Please!",
                continue: {
                    text: "Yes, I'm sure about this!",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                axios.post("/standing-charges/delete-standing-charge", {
                    id: id
                }).then(function(res){
                    if(res.status === 200){
                        toast.success("The standing charge has been deleted permanently.")
                        setRefresh(!refresh);
                    }
                }).catch(function(error){
                    toast.error(error.message);
                })
            }
        })
    }

    const theme = useMantineTheme();

    return (
        <Paper p="md" >
            <Title weight={300}>Standing Charges</Title>
            <Alert marginTop={20} marginLeft={-10} marginRight={-10} marginBottom={20} variation="info" hasIcon={true} isDismissible={false}>
                Standing charges are fixed amount that customers have to pay for water, no matter the units they consume. Any amount configured will be added to the monthly water charges for each customer.
          </Alert>
            {data.length === 0 ? (
                            <Group mb={20} position="right">
                            <Button variant="outline" component={Link} to="/app/configure/standing-charges/create" leftIcon={<Plus />}>Create Standing Charge</Button>
                        </Group>
            ) : null}

            <div style={{overflowX: 'auto', marginTop: 20}}>
            {!loading ? (
                                <Table style={{borderBottom: '1px solid #E9ECEF'}}>
                                <thead>
                                    <tr>
                                        <th>Standing Charge(KES)</th>
                                        <th>Configured On</th>
                                        <th>Last Modified</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>
                                        <Center>
                                            <Text>No data found.</Text>
                                        </Center>
                                    </td>
                                </tr>
                                ) : (
                                    data.map((item, index) => {
                                        return (
                                            <tr key={`charge-${index}`}>
                                                <td>{item.cost}</td>
                                                <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                                <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                                                <td>
                                                    <Menu>
                                                        <Menu.Target>
                                                            <ActionIcon>
                                                                <Dots />
                                                            </ActionIcon>
                                                        </Menu.Target>
                                                        <Menu.Dropdown>
                                                            <Menu.Item>Edit</Menu.Item>
                                                            <Menu.Item onClick={() => {deleteStandingCharge(item._id)}} >Delete</Menu.Item>
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                                </tbody>
                            </Table>
            ) : (
                <Center mt="10%" mb="10%" >
                    <Loader />
                </Center>
            )}
            </div>
        </Paper>
    )
}