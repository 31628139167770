import { ActionIcon, Alert, Anchor, Box, Button, Center, Checkbox, Grid, Group, NumberInput, Paper, SimpleGrid, TextInput, Title } from "@mantine/core";
import { IconAlertCircle, IconArrowLeft, IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Plus, X } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";

export default function CreateService(){
    const { state, dispatch } = useContext(AuthContext);
    const [ withBlocks, setWithBlocks ] = useState(false);
    const [ blocks, setBlocks ] = useState([]);
    const [cost, setCost] = useState(0);
    const [vat_cost, setVATCost] = useState(0);
    const [vat, setVAT] = useState(false);
    const [saving, setSaving] = useState(false);
    const [title, setTitle] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const addBlock = () => {
        setBlocks(prevBlocks => ([...prevBlocks, {service: "", cost: 0, vat: true, vat_cost: 0}]));
    }

    const removeBlock = (index) => {
        blocks.splice(index, 1);
        setBlocks([...blocks]);
    }

    const handleChange = (val, index, type) => {
        switch(type){
            case "service":
                blocks[index].service = val;
                break;
            case "cost":
                blocks[index].cost = val;
                break;
            case "vat":
                blocks[index].vat = val;
                break;
            case "vat_cost":
                blocks[index].vat_cost = val;
                break;
            default:
                // do something
        }

        setBlocks([...blocks]);
    }

    useEffect(() => {
        if(withBlocks){
            addBlock();
        } else {
            setBlocks([]);
        }
    }, [withBlocks])

    const saveDetails = (e) => {
        e.preventDefault();

        setSaving(true);

        setSuccess(false);

        setError(false);

        try{
            const body = {
                parent: state.userData._id,
                title: title,
                categories: withBlocks ? blocks : [{service: title, cost: cost, vat: vat, vat_cost: vat_cost}]
            };

            axios.post("/services/create", body).then(function(res){
                if(res.status === 200){
                    toast.success("Service category sucessfully created", {
                        position: "bottom-right",
                        duration: 60000
                    });

                    setSuccess(true);
                    setSaving(false);
                }
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right",
                    duration: 60000
                });

                setError(true);

                setSaving(false);
            })
        } catch(error){

        }
    }

    return (
        <>
        {success ? (
            <Alert  mb={20} icon={<IconCheck />} title="Success" variant="filled">
                {`Service title, ${title}, was added successfully.`}
          </Alert>
        ) : null}

        {error ? (
            <Alert  mb={20} icon={<IconAlertCircle/>} title="Sorry!" color="red" variant="filled">
                {`Your service, ${title}, was not saved. Something wrong happened while saving.`}
          </Alert>
        ) : null}

        <Paper p="md">
        <Group position="apart" mb={20}>
            <Group position="left">
                <Title order={4} weight={300}>Create New Service</Title>
            </Group>
        </Group>

        <TextInput value={title} onChange={(e) => {setTitle(e.currentTarget.value)}} size="xs" label="Service Title" mb={10} />
        <Checkbox size="xs" value={withBlocks} onChange={(e) => {setWithBlocks(e.currentTarget.checked)}} label="With services?" mb={20} />

        {withBlocks ? (
            blocks.map((item, index) => {
                return (
                    <SimpleGrid key={`other-services-${index}`} mb={10} cols={3} spacing="lg" breakpoints={[
                        {maxWidth: 755, cols: 1, spacing: "sm"}
                    ]}>
                        <TextInput value={item.service} onChange={(e) => {handleChange(e.currentTarget.value, index, "service")}} size="xs" variant={index !== blocks.length - 1 ? "filled" : "default" } label="Service Name" />
                        <NumberInput step={0.01} value={item.cost} onChange={(val) => {handleChange(val, index, "cost")}} precision={2} hideControls size="xs" variant={index !== blocks.length - 1 ? "filled" : "default"} label="Cost(KSH)" />

                        <Grid>
                            <Grid.Col span="auto">
                                <Checkbox mt={30} label="VAT?" size="xs" color="gray" checked={blocks[index].vat} onChange={(e) => {handleChange(e.currentTarget.checked, index, "vat")}} />
                            </Grid.Col>
                            {item.vat ? (
                                <Grid.Col span={8}>
                                    <NumberInput step={0.01} value={item.vat_cost} onChange={(val) => {handleChange(val, index, "vat_cost")}} precision={2} hideControls size="xs" variant={index !== blocks.length - 1 ? "filled" : "default" } label="VAT(%)" min={1} max={100} />
                                </Grid.Col>
                            ) : null}
                            <Grid.Col span="auto">
                                <Group mt={30}>
                                    {index === blocks.length - 1 ? (
                                        <ActionIcon size="sm" onClick={() => {addBlock()}} variant="light" radius={15}>
                                            <Plus size={16} />
                                        </ActionIcon>
                                    ) : (
                                        <ActionIcon onClick={() => {removeBlock(index)}} size="sm" variant="light" radius={15}>
                                            <X size={16} />
                                        </ActionIcon>
                                    )}
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </SimpleGrid>
                )
            })
        ) : (
            <>
            <NumberInput value={cost} onChange={(val) => {setCost(val)}} hideControls step={1} min={0} size="xs" mb={10} label="Cost(KSH)" />
            <Checkbox checked={vat} onChange={(e) => {setVAT(e.currentTarget.checked)}} label="VAT?" mb={10} size="xs" />
            {vat ? (
                <NumberInput value={vat_cost} onChange={(val) => {setVATCost(val)}} step={0.01} precision={2} min={1} max={100} hideControls size='xs' mb={10} label="VAT(%)" />
            ) : null}
            </>
        )}

        <Anchor mr={20} component={Link} to="/app/configure/services">
            <Center inline>
                <IconArrowLeft size={14} />
                <Box ml={5}>Back to Services Page</Box>
            </Center>
            </Anchor> 
        <Button disabled={saving || title === ""} onClick={(e) => {saveDetails(e)}} loading={saving} mt={10} radius={28}>Add Service</Button>
        </Paper>
        </>
    )
}