import * as React from "react";
import { Box, Container, Divider, Group, Text, useMantineColorScheme } from "@mantine/core";
import LogoBlue from "../../assets/logo/logo-blue.jpg";
import LogoWhite from "../../assets/logo/logo-white.jpg";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Confirm(){
    let query = useQuery();
    const theme = useMantineColorScheme();
    return (
        <Container size={700} my={30}>
            <Group sx={(theme) => ({ [theme.fn.smallerThan('sm')]: { display: 'none' } })} >
            <img src={theme.colorScheme === "dark" ? LogoWhite : LogoBlue} />
            </Group>

            <Divider mt={10} sx={(theme) => ({ [theme.fn.smallerThan('sm')]: { display: 'none' } })} />

        <Text size="sm" mt={20}>
            Dear {query.get("firstname") + " " + query.get("lastname")},<br /> <br />

            We are reviewing your application.It&apos;ll take upto 3 business days. Once approved, we&apos;ll send you an Email
            and you can start using our service.<br />
            If you have questions, contact us.<br />
            Your customer ID is {query.get("customer_id")}.<br /> <br />
            Thank you, the e-mita team.
            </Text>
        </Container>
    )
}