import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import { Heading} from "@aws-amplify/ui-react";
import { useMantineTheme } from "@mantine/styles";
import { Accordion, Alert, Checkbox, Divider, Grid, Group, MediaQuery, Radio, Select, Switch, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faSpinner, faTools } from '@fortawesome/free-solid-svg-icons'
import { IconChevronDown, IconInfoCircle } from "@tabler/icons";
import axios from "../../utils/axios";
import { getMonth, getMonthAsNums, getYear } from "../../utils/getMonth";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
  } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import ReactGA from "react-ga";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  );
  
  const options = {
    responsive: true,
    maintainAspectRation: false,
    borderWidth: 2,
    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Non-revenue water trend',
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        },
      },
      y: {
        grid: {
          display: false
        },
      }
    }
  };
export default function Production(){
    const { state, dispatch} = useContext(AuthContext);
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    const [dmas, setDMAS] = useState([]);
    const [jan1, setJan1] = useState(0);
    const [feb1, setFeb1] = useState(0);
    const [mar1, setMar1] = useState(0);
    const [apr1, setApr1] = useState(0);
    const [may1, setMay1] = useState(0);
    const [jun1, setJun1] = useState(0);
    const [jul1, setJul1] = useState(0);
    const [aug1, setAug1] = useState(0);
    const [sep1, setSep1] = useState(0);
    const [oct1, setOct1] = useState(0);
    const [nov1, setNov1] = useState(0);
    const [dec1, setDec1] = useState(0);
    const [jann1, setJann1] = useState(0);
    const [loading, setLoading] = useState(false);
    const [production, setProduction] = useState([]);

    const [consumption, setConsumption] = useState([]);
    const [jan, setJan] = useState(0);
    const [feb, setFeb] = useState(0);
    const [mar, setMar] = useState(0);
    const [apr, setApr] = useState(0);
    const [may, setMay] = useState(0);
    const [jun, setJun] = useState(0);
    const [jul, setJul] = useState(0);
    const [aug, setAug] = useState(0);
    const [sep, setSep] = useState(0);
    const [oct, setOct] = useState(0);
    const [nov, setNov] = useState(0);
    const [dec, setDec] = useState(0);
    const [jann, setJann] = useState(0);
    const [dates, setDates] = useState(null);

    const [chart, setChart] = useState("line");

    const labels = getMonth(12);
    const months = getMonthAsNums();
    let years = getYear();

    useEffect(() => {
      ReactGA.pageview("/app/nrw");
    }, [])

    useEffect(() => {
        try {
          setLoading(true);
          axios.post("/productions/get", {
            parent: state.userData._id
          }).then(function(res){
            if(res.status === 200){
              setProduction(res.data.data);
              setLoading(false)
            }
          })
        } catch(error){
          console.log(error.message);
          setLoading(false);
        }
    }, [])
    
    useEffect(() => {
      axios.post("/dates/get-dates", {
        parent: state.userData._id
    }).then(function(res){
        if(res.status === 200){
            setDates(res.data.data);
        }
    }).catch(function(error){
        console.log(error);
    })
    }, []);

    useEffect(() => {
      setLoading(true);

      const config = {
        headers: {
          'Authorization': `Bearer ${state.userToken}`
        },
        params: {
          parent: state.userData._id
        }
      }
      axios.get("/consumption/get", config).then(function(res){
        if(res.status === 200){
          setConsumption(res.data.data)
        }
      }).catch(function(error){
        //toast.error("Something wrong is happening!");
        console.log(error);
        setLoading(false);
      })
  }, []);

    useEffect(() => {
        setJan1(0);
        setFeb1(0);
        setMar1(0);
        setApr1(0);
        setMay1(0);
        setJun1(0);
        setJul1(0);
        setAug1(0);
        setSep1(0);
        setOct1(0);
        setNov1(0);
        setDec1(0);
        setJann1(0);
      
        const funcB = () => {
          for(let i=0; i<months.length; i++){
            for(let k=0; k<years.length; k++){
              if(i === k){
                let month = months[i];
                let year = years[k];
        
                let filteredData = production.filter((obj) => ((obj.month === month) && (obj.year === year)));
        
                if(filteredData.length > 0){
        
                  let p = filteredData.reduce((sum, obj) => sum + obj.production, 0);
        
                  switch(i){
                    case 0:
                      setJan1(p);
                      break;
                    case 1:
                      setFeb1(p);
                      break;
                    case 2:
                      setMar1(p);
                      break;
                    case 3:
                      setApr1(p);
                      break;
                    case 4:
                      setMay1(p);
                      break;
                    case 5:
                      setJun1(p);
                      break;
                    case 6:
                      setJul1(p);
                      break;
                    case 7:
                      setAug1(p);
                      break;
                    case 8:
                      setSep1(p);
                      break;
                    case 9:
                      setOct1(p);
                      break;
                    case 10:
                      setNov1(p);
                      break;
                    case 11:
                      setDec1(p);
                      break;
                    case 12:
                      setJann1(p);
                      break;
                    default:
                      // handle default
                  }
                }
              }
            }
          }

          setLoading(false);
        }
      
        if(production.length > 0){
          funcB();
        } 
      
      }, [production]);

      useEffect(() => {
        setJan(0);
        setFeb(0);
        setMar(0);
        setApr(0);
        setMay(0);
        setJun(0);
        setJul(0);
        setAug(0);
        setSep(0);
        setOct(0);
        setNov(0);
        setDec(0);
        setJann(0);
    
        let startsOn = dates?.starts;
        let duration = dates?.duration;
        let today = new Date();
    
      const funcA = async() => {
        for(let i=0; i<months.length; i++){
          for(let k=0; k<years.length; k++){
            if(i === k){
              let month = months[i] - 1;
              let year = years[k];
    
              today.setFullYear(year);
              today.setMonth(month);
              today.setDate(startsOn);
              today.setHours(0);
              today.setMinutes(0);
              today.setSeconds(0); // 0000hrs
    
              let endsIn = new Date(today.getTime() + (duration * 24 * 60 *60 * 1000));
      
              let filteredData = consumption.filter((obj) => (new Date(obj.createdAt) >= today) && (new Date(obj.createdAt) <= endsIn));
      
              if(filteredData.length > 0){
      
                let cons = filteredData.reduce((sum, obj) => sum + (obj.currReading - obj.prevReading), 0);
      
                switch(i){
                  case 0:
                    setJan(cons);
                    break;
                  case 1:
                    setFeb(cons);
                    break;
                  case 2:
                    setMar(cons);
                    break;
                  case 3:
                    setApr(cons);
                    break;
                  case 4:
                    setMay(cons);
                    break;
                  case 5:
                    setJun(cons);
                    break;
                  case 6:
                    setJul(cons);
                    break;
                  case 7:
                    setAug(cons);
                    break;
                  case 8:
                    setSep(cons);
                    break;
                  case 9:
                    setOct(cons);
                    break;
                  case 10:
                    setNov(cons);
                    break;
                  case 11:
                    setDec(cons);
                    break;
                  case 12:
                    setJann(cons); 
                    break;
                  default:
                    // handle default
                }
              }
            }
          }
        }
    
        setLoading(false);
      
      }
      
      if(consumption.length > 0){
        funcA();
      } 
      
      }, [consumption]);

    const fetchDMAs = () => {
        setDMAS([]);
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                let arr = [{label:"All Zones", value: "all"}];

                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];
                    arr.push({label: item.name, value: item.name});
                    //setDMAS(prevArr => ([...prevArr, item.name]));
                }

                setDMAS(arr);
                
            }
        }).catch(function(error){
            console.warn(error.message);
        })
    }

    useEffect(() => {
        fetchDMAs();
    }, []);

    const data = {
        labels,
        datasets: [
          {
            fill: chart === "area",
            lineTension: 0.4,
            label: 'Supply',
            data: [jan1, feb1,mar1, apr1, may1, jun1, jul1,aug1, sep1, oct1, nov1, dec1, jann1],
            borderColor: theme.colors.red[9],
            backgroundColor: theme.colors.red[9],
          },
          {
            fill: chart === "area",
            lineTension: 0.4,
            label: 'Consumption',
            data: [jan, feb,mar, apr, may, jun, jul,aug, sep, oct, nov, dec, jann],
            borderColor: theme.colors.green[9],
            backgroundColor: theme.colors.green[9],
          },
        ],
      };

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Production</title>
        </Helmet>
        <Alert icon={<IconInfoCircle />} mt={-20} ml={-20} mr={-15}>
          Lost water is lost money. This console gives you a chance to focus on reducing non-revenue water and close the gap between the volume of water
          you supply and the water you bill your customers every month.
        </Alert>

        <Grid>
            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
            <Grid.Col mt={8} sm={12} lg={3} style={{borderRight: theme.colorScheme === "dark" ? `2px solid ${theme.colors.dark[6]}` : `2px solid ${theme.colors.gray[4]}`}}>
            <Accordion defaultValue={"timeline"} chevronPosition="right">
            <Accordion.Item value="timeline" style={{borderBottom: 0}}>
                    <Accordion.Control>Timeline</Accordion.Control>
                    <Accordion.Panel>
                    <Select label rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={[
                         {label: "Past 1 Month", value: "month"},
                         {label: "Past 3 Months", value: "3months"},
                         {label: "Past 6 Months", value: "6months"},
                         {label: "Past 1 Year", value: "year"},
                         {label: "Custom", value: "custom"}         
                    ]} value="year" />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultValue={"source"} chevronPosition="right">
                <Accordion.Item value="source" style={{borderBottom: 0}}>
                  <Accordion.Control>Sources</Accordion.Control>
                  <Accordion.Panel>
                    <Checkbox.Group>
                      <Checkbox label="Main leaks and storage tank overflows" value="1" />
                      <Checkbox label="Unauthorized use(theft and tampering)" value="2" />
                      <Checkbox label="Unbilled consumption and Meter innacuracy" value="3" />
                      <Checkbox label="Unmetered consumption" value={"4"} />
                      <Checkbox label="Leak adjustments and bad debts" value={"5"} />
                    </Checkbox.Group>
                  </Accordion.Panel>
                </Accordion.Item>

            </Accordion>

            <Accordion defaultValue={"zone"} chevronPosition="right">
            <Accordion.Item value="Zone" style={{borderBottom: 0}}>
                    <Accordion.Control>District Metered Areas</Accordion.Control>
                    <Accordion.Panel>
                    <Select rightSection={<IconChevronDown size="1rem" />} rightSectionWidth={30} data={dmas} value="all" />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            </Grid.Col>
            </MediaQuery>
            <Grid.Col sm={12} lg={9}>         
            {production.length === 0 ? (
                        <>
                        <Group position="center" mt="30%">
                        <FontAwesomeIcon icon={loading ? faSpinner : faTools} spin={loading} />
                        </Group>

                        <Text align="center" mt={30}>{loading ? "Loading your supply" : "No supply data yet."}</Text>
                        </>
                    )  : consumption.length === 0 ? (
                      <>
                      <Group position="center" mt="30%">
                      <FontAwesomeIcon icon={loading ? faSpinner : faTools} spin={loading} />
                      </Group>

                      <Text align="center" mt={30}>{loading ? "Loading customer's consumption" : "No consumption data yet."}</Text>
                      </>
                    ) : (
                      <>
                      <Group mt={20} mb={20} position="apart">
                      <Group position="left">
                        <Text>Supply vs Consumption Trends</Text>
                      </Group>
                      <Group position="right">
                        <Select value={chart} onChange={(val) => {setChart(val)}} data={[{label: "Line", value: "line"}, {label: "Bar", value: "bar"}, {label: "Area", value: "area"}]} />
                      </Group>
                      </Group>
                        <div style={{height: (height - HEADER_HEIGHT) * 0.7, width: '100%'}}>
                            {chart === "line" || chart === "area" ? (
                              <Line options={options} data={data} /> 
                            ) : <Bar options={options} data={data} /> }  
                        </div>
                        </>
                    )}
            </Grid.Col>
        </Grid>
        </>
    )
}