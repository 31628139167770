import TasksList from "./TasksList";
import CreateTask from "../create-task";
import { TaskProvider } from "../task-context/task.provider";
import WorkOrder from "../../WorkOrder";
import { Center, Stack, Tabs, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import { Settings } from "tabler-icons-react";
import { useState } from "react";

function TasksWrapper(){
    const { height } = useViewportSize();
    const [activeTab, setActiveTab] = useState("list");

    const handleTab = (val) => {
      setActiveTab(val);
    }
    return (
        <Tabs value={activeTab} onTabChange={(val) => {setActiveTab(val)}}>
            <Tabs.List>
        <Tabs.Tab value="list">
          Scheduled Tasks
        </Tabs.Tab>
        <Tabs.Tab value="new">
          New Schedule
        </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="list">
        <TasksList handleTab={handleTab} />
      </Tabs.Panel>

      <Tabs.Panel value="new">
        <CreateTask handleTab={handleTab} />
      </Tabs.Panel>

      <Tabs.Panel value="settings">
        <Stack justify="center" h={height - HEADER_HEIGHT}>
            <Center>
                <Settings />
            </Center>
            <Text align="center" mt={20}>Configuration options for different tasks will appear here.</Text>
        </Stack>
      </Tabs.Panel>
        </Tabs>
    )
}

export default function Tasks(){
    return (
        <TaskProvider>
            <TasksWrapper />
        </TaskProvider>
    )
}