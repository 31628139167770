import React, { useReducer } from "react";
import { StaffContext } from "./staff.context";

export const StaffProvider = ({ children }) => {
    const reducer = (state, action) => {
        switch(action.type){
            case "NAVIGATE":
                return {
                    ...state,
                    step: action.step
                };

                default:
                    return state;
        }
    }

    const initialState = {
        step: "0"
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StaffContext.Provider value={{state, dispatch}}>
            {children}
        </StaffContext.Provider>
    )
}