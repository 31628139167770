export function obfuscateEmail(email) {
    // Split the email into the local part and domain part
    const [localPart, domainPart] = email.split('@');
    
    // Determine the visible part of the local part (first two characters)
    const visiblePart = localPart.slice(0, 2);
    
    // Generate the obfuscated part
    const obfuscatedPart = '***';
    
    // Combine the visible part, obfuscated part, and domain part
    return `${visiblePart}${obfuscatedPart}@${domainPart}`;
}

function processPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the input phone number
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    // Check if the cleaned number is at least nine digits long
    if (cleanNumber.length < 10) {
        // Handle the case where the phone number is too short
        return "";
    }
    // Take the last nine digits of the cleaned number
    const lastNineDigits = cleanNumber.slice(-9);

    // Add the country code "+254" to the last nine digits
    const formattedNumber = "254" + lastNineDigits;

    return formattedNumber;
}

export function obfuscatePhoneNumber(phoneNumber) {
    // Ensure the phone number is a string
    phoneNumber = phoneNumber.toString();
    
    let mainNumber = processPhoneNumber(phoneNumber);

    if(mainNumber === ""){
        return "INVALID"
    }
    // Determine the visible parts of the main number
    const visibleStart = mainNumber.slice(0, 4);

    const visibleEnd = mainNumber.slice(-3);
    
    // Generate the obfuscated part
    const obfuscatedPart = '*****';
    
    // Combine the country code, visible parts and obfuscated part
    return `${visibleStart}${obfuscatedPart}${visibleEnd}`;
}
