import { createContext, useEffect, useReducer, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate,} from "react-router-dom";
import LoginPage from './Page-Components/Auth/Login';
import SignUp from './Page-Components/Auth/Register';
import ConfirmPage from './Page-Components/Auth/Confirm';
import { AppLayout } from './components/dashboard/layout/layout';
import { Layout } from './components/layout/layout';
import Dashboard from './components/dashboard/home';
import CreateCustomer from './components/customers/create-customers';
import { Center, Loader } from '@mantine/core';
import ConfigureTarrif from './components/configure/tarrifs';
import axios from './utils/axios';
import { SESSION_DATA } from './constants';
import { MantineProvider,ColorSchemeProvider,createEmotionCache,} from '@mantine/core';
import { useLocalStorage, useHotkeys, } from '@mantine/hooks';
import ForgotPasswordPage from './Page-Components/Auth/forgot-password';
import ConfigureDMA from './components/configure/dma';
import Analytics from './components/Analytics/home';
import TasksList from './components/tasks/task-list';
import VerifyPassword from './components/staff/verify';
import NotFound from './components/notfound';
import CustomerView from './components/customers/customer';
import Payments from './components/payments';
import UploadAssets from './components/upload';
import CustomerLogin from './components/customers/login/login';
import VerifyCustomerPassword from './components/customers/verify';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, DarkTheme, BaseProvider} from 'baseui';
import CreateTarrif from './components/configure/tarrifs/create';
import SewerTarrif from './components/configure/sewer';
import CreateSewerTarrif from './components/configure/sewer/create';
import OtherServices from './components/configure/services';
import CreateService from './components/configure/services/create';
import Dates from './components/configure/dates';
import RecurringTasks from './components/configure/recurring-tasks';
import ConfigureInfrastructure from './components/configure/infrastructure';
import ConfigureUploadAssets from './components/configure/infrastructure/upload';
import CreateDMA from './components/configure/dma/create';
import CreateDate from './components/configure/dates/create';
import CustomerPaymentsHistory from './components/payments/customer-payment';
import StandingCharges from './components/configure/standing-charges';
import CreateStandingCharge from './components/configure/standing-charges/create';
import { ServerError } from './components/Error-500';
import Customers from './components/customers';
import SystemLogs from './components/logs';
import BillingMethod from './components/configure/billing-method';
import Production from './components/production';
import Consumption from './components/consumption';
import AppSettings from './components/settings';
import Complains from './components/complains';
import Support from './components/support';
import Task from './components/tasks/task';
import Staff from './components/staff/staff-list';
import InventoryMapperDashboard from './components/inventory-mapper/Dashboard';
import ServiceAreaDashboard from './components/service-area/MapDashboard';
import { Toaster } from 'react-hot-toast';
import EmitaBilling from './components/emita-billing';
import EmitaAccess from './components/access';
import ConnectionRequests from './components/connection-requests';
import SpectralAnalysis from './components/spectral-analysis';
import Reports from './components/reports';
import InventoryTabular from './components/inventory-mapper/Tabular';
import WorkOrder from './components/WorkOrder';
import Devices from './components/Devices';
import { ConfigureSMS } from './components/configure/sms';
import SMS from './components/sms';
import { ConfigureDisconnection } from './components/configure/disconnection';
import Supply from './components/supply';
import InvoiceDefaulting from './components/configure/invoice-defaulting';
import MFA from "./components/mfa";
import ConfigureInvoices from './components/configure/invoices';
import CustomersPayments from './components/payments/customer-payments';
import Bills from './components/payments/bills';
import PaymentsSummary from './components/payments/payments-summary';
import History from './components/payments/history';
import CustomerList from './components/customers/customer-list';
import CustomersSummary from './components/customers/customers-summary';

const engine = new Styletron();

export const THEME_KEY = 'e-mita-color-scheme';

const initialState = {
  isLoading: true,
  isSignout: false,
  userToken: null,
  userData: null,
};

export const AuthContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AuthReducer(state, action) {
  switch(action.type){
    case "RESTORE_TOKEN":
      return {
        ...state,
        isLoading: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_IN":
      return {
        ...state,
        isSignout: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_UP":
      return {
        ...state,
        isSignout: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_OUT":
      localStorage.removeItem(SESSION_DATA);
      return {
        ...state,
        isSignout: true,
        userToken: null,
        userData: null
      };

    default:
      return state;
  }
}
export default function App() {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [session, ] = useState(localStorage.getItem(SESSION_DATA));
  
  useEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      let data;

      try{
        userToken = session;
        // login with token
        if(userToken !== null){
          try {
            const {
              data: {
                data: accountData,
                token: accessToken
              },
            } = await axios.get("/auth/account", {
              headers: {
                authorization: `Bearer ${userToken}`,
              },
            });
  
            userToken = accessToken;
            data = accountData;
  
          } catch(error){
            userToken = null;
            data = null;
          }
        } 
      } catch(error){
        userToken = null
        data = null;
      }

      dispatch({type: 'RESTORE_TOKEN', token: userToken, data: data});
    }


    bootstrapAsync();
  }, [session]);

const LoaderPage = () => {
    return (
      <Center my={"20%"}>
      <div
className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
role="status">
<span
className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>
Loading...
</span>
</div>
  </Center>
    )
  }

  const AuthPage = () => {
    return (
      <Navigate to={`/app/account-summary`} />
    )
  }

  const UnauthPage = () => {
    return (
      <Navigate to={"/"} />
    )
  }

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: THEME_KEY,
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const [dir,] = useState('ltr');

  const toggleColorScheme = (value) => {
      setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  }

  useHotkeys([
    ['mod+J', () => toggleColorScheme()],
  ]);

  const myCache = createEmotionCache({key: "emita-app"})

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={colorScheme === "light" ? LightTheme : DarkTheme}>
    <AuthContext.Provider value={{state, dispatch}}> 
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          theme={{ 
            colorScheme, 
            dir, 
            primaryColor: "indigo",
            primaryShade: 9 ,
            white: 'white', 
            black: 'black',
            cursorType: "pointer",
          }}
          withGlobalStyles
          withNormalizeCSS
          emotionCache={myCache}

        >
    {state.isLoading ? (
      <LoaderPage />
    ) : (
      <BrowserRouter>
        <Routes>
          
          <Route exact path='/' element={state.userToken === null ? <Layout noFooter /> : <AuthPage />} >
            <Route index element={<LoginPage />} />
            <Route path="/account/login" element={<LoginPage />} />
            <Route path='/account/register' element={<SignUp />} />
            <Route path='/account/forgot-password' element={<ForgotPasswordPage /> } />
            <Route path='/account/confirm' element={<ConfirmPage />} />
            <Route path='/account/customer/login' element={<CustomerLogin />} />
            <Route path='*' element={<NotFound link="/" />} />
          </Route>
          <Route path='/account/forgot-password/:token' element={<ConfirmPage />} />
          <Route path='/customer/verify' element={<VerifyCustomerPassword />} />
          <Route path='/staff/verify' element={<VerifyPassword />} />
          <Route path='/app/infrastructure' element={state.userToken === null ? <UnauthPage /> : <Dashboard />} />
          <Route path='/app/inventory-mapper' element={state.userToken === null ? <UnauthPage /> : <InventoryMapperDashboard />} />
          <Route path='/app/inventory-mapper/table' element={state.userToken === null ? <UnauthPage /> : <InventoryTabular />} />
          <Route path='/app/service-area' element={state.userToken === null ? <UnauthPage /> : <ServiceAreaDashboard />} />
          <Route path='/app/' element={state.userToken === null ? <UnauthPage /> : <AppLayout img={state?.userData?.avatar} />}>
            <Route index element={<Analytics />} />
            <Route path='/app/account-summary' element={<Analytics />} />
            <Route path='/app/support' element={<Support />} />
            <Route path='/app/incidents' element={<Complains />} />
            <Route path="/app/customers/create-customer" element={<CreateCustomer />} />
            <Route path='/app/customers/customers-list' element={<CustomerList />} />
            <Route path='/app/customers/summary' element={<CustomersSummary />} />
            <Route path='/app/customers/requests' element={<ConnectionRequests />} />
            <Route path='/app/customers/:id' element={<CustomerView />} />
            <Route path='/app/settings/profile' element={<AppSettings />} />
            <Route path='/app/configure/tarrifs' element={<ConfigureTarrif />} />
            <Route path='/app/configure/standing-charges' element={<StandingCharges />} />
            <Route path='/app/configure/standing-charges/create' element={<CreateStandingCharge />} />
            <Route path='/app/configure/sewer-tarrifs' element={<SewerTarrif />} />
            <Route path='/app/configure/tarrifs/create' element={<CreateTarrif />} />
            <Route path='/app/configure/sewer-tarrifs/create-sewer-tarrif' element={<CreateSewerTarrif />} />
            <Route path='/app/configure/dma' element={<ConfigureDMA />} />
            <Route path='/app/configure/invoices' element={<ConfigureInvoices />} />
            <Route path='/app/configure/dma/create' element={<CreateDMA />} />
            <Route path='/app/configure/services' element={<OtherServices />} />
            <Route path='/app/configure/services/create' element={<CreateService />} />
            <Route path="/app/configure/dates" element={<Dates />} />
            <Route path="/app/configure/invoice-defaulting" element={<InvoiceDefaulting />} />
            <Route path="/app/configure/billing-methods" element={<BillingMethod />} />
            <Route path="/app/configure/dates/create-date" element={<CreateDate />} />
            <Route path="/app/configure/recurring-tasks" element={<RecurringTasks />} />
            <Route path='/app/configure/infrastructure' element={<ConfigureInfrastructure />} />
            <Route path='/app/configure/infrastructure/upload' element={<ConfigureUploadAssets />} />
            <Route path='/app/configure/disconnection' element={<ConfigureDisconnection />} />
            <Route path='/app/staff' element={<Staff />} />
            <Route path='/app/tasks' element={<TasksList />} />
            <Route path='/app/tasks/:id' element={<Task />} />
            <Route path='/app/activity-log' element={<SystemLogs />} />
            <Route path='/app/billing/payments' element={<CustomersPayments />} />
            <Route path="/app/billing/invoices" element={<Bills />} />
            <Route path="/app/billing/mtd" element={<PaymentsSummary />} />
            <Route path="/app/billing/histories" element={<History />} />
            <Route path='/app/:id/production' element={<Production />} />
            <Route path='/app/:id/consumption' element={<Consumption />} />
            <Route path='/app/payments/:id' element={<CustomerPaymentsHistory />} />
            <Route path='/app/onboarding' element={<UploadAssets />} />
            <Route path='*' element={<NotFound link="/app/" />} />
            <Route path="/app/server-error" element={<ServerError />} />
            <Route path='/app/emita-billing' element={<EmitaBilling />} />
            <Route path='/app/account-access' element={<EmitaAccess />} />
            <Route path='/app/mfa' element={<MFA />} />
            <Route path='/app/connections' element={<ConnectionRequests />} />
            <Route path="/app/spectral-analysis" element={<SpectralAnalysis />} />
            <Route path='/app/reports' element={<Reports />} />
            <Route path='/app/work-orders' element={<WorkOrder />} />
            <Route path='/app/devices' element={<Devices />} />
            <Route path='/app/configure/sms' element={<ConfigureSMS />} />
            <Route path="/app/sms" element={<SMS />} />
            <Route path="/app/supply" element={<Supply />} />
          </Route>

        </Routes>
      </BrowserRouter>
    )}
        <Toaster />
            </MantineProvider>
      </ColorSchemeProvider>
    </AuthContext.Provider>
    </BaseProvider>
    </StyletronProvider>
  )
}

