import { Heading, SearchField } from "@aws-amplify/ui-react";
import { Alert, Anchor, Avatar, Badge, Button, Center, Divider, Drawer, Group, Menu, Pagination, Paper, Select, Space, Table, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react"
import { getDate} from "../../utils/getMonth";
import { Link } from "react-router-dom";
import { AuthContext } from "../../App";
import axios from "../../utils/axios";
import { ArrowUpRight, ChevronDown, Download, Edit, FileExport, X } from "tabler-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { generateInvoice } from "../../utils/invoice2";
import { toast } from "react-hot-toast";
import diffInMonths from "../../utils/monthsToNow";
import getCycles from "../../utils/getCycles";
import ReactGA from "react-ga4";
import { downloadCSV } from "../../utils/downloadCSV";
import { useSessionStorage } from "@mantine/hooks";
import OTPInput from "otp-input-react";
import { IconInfoCircle } from "@tabler/icons";

export default function Bills(){
    const { state } = useContext(AuthContext);
    const [bills, setBills] = useState([]);
    const [name, setName] = useState("");
    const [size, setSize] = useState(25);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(0);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const [id, setId] = useState("");
    const [amount, setAmount] = useState("");
    const [refresh, setRefresh] = useState(false);

    const [payments, setPayments] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [payments_ready, setPaymentsReady] = useState(false);
    const [invoices_ready, setInvoicesReady] = useState(false);

    const [opened, setOpened] = useState(false);

    const [fa_step, setFAStep] = useState(0);

    const [otp, setOTP] = useState("");

    const [otp_loading, setOTPLoading] = useState(false);

    const [otp_active, setOTPActive] = useState(false);

    const [mfa_session, setMFASession] = useSessionStorage({
        defaultValue: "0",
        getInitialValueInEffect: true,
        key: "emita-2fa-session"
    });

    useEffect(() => {
        if(otp.length === 6){
            setOTPActive(true);
        } else {
            setOTPActive(false);
        }
    }, [otp])

    const verifyOTP = () => {
        setOTPLoading(true);
        // verify
        axios.post("/mfa/verify", {
            otp_token: otp
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                setOTPLoading(false);
                setMFASession("1");
                setFAStep(1);
                setOTP("");
            }
        }).catch(function(err){
            toast.error("The passed token was invalid, or it might have expired already.");
            setOTP("");
            setOTPLoading(false);
        })
    }

    let diff_in_months = diffInMonths(new Date(state.userData.createdAt));

    let [c, setC] = useState(getCycles(diff_in_months)[0].value);

    let cycles = getCycles(diff_in_months);

    const theme = useMantineTheme();

    useEffect(() => {
        const body = {
            parent: state.userData._id
        };

        axios.post("/payments/all", body).then(function(res){
            if(res.status === 200){
                setPayments(res.data.data);
                setPaymentsReady(true);
            }
        }).catch(function(error){
            console.log(error);
        })
    }, [])

    useEffect(() => {
        const body = {
            parent: state.userData._id
        };

        axios.post("/master-invoices/all", body).then(function(res){
            if(res.status === 200){
                setInvoices(res.data.data);
                setInvoicesReady(true);
            }
        }).catch(function(error){
            console.log(error);
        })
    }, [])

    const saveInvoice = async (pdf) => {
        pdf.output_type = "save"
        generateInvoice(pdf);
    }

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const downloadBills = () => {
        var t_id = toast.loading(`Preparing invoices for ${c}...`);
        axios.post("/master-invoices/invoices", {
            period: c,
            parent: state.userData._id,
            size: count,
            page: 1
        })
        .then(function(res) {
            if (res.status === 200) {
                if (res.data.data.length > 0) {
                    var arr = res.data.data;
    
                    if (Array.isArray(arr)) {
                        // Pre-process the data to create a new array with desired columns
                        const processedData = arr.map(item => ({
                            Name: item.invoices.customer_name,
                            Account: item.invoices.account_number,
                            Amount: item.invoices.total,
                            Status: item.invoices.account_status,
                            Category: getModeDescription(item.mode), // Use a function to determine mode
                            InvoicedAt: new Date(item.createdAt).toLocaleDateString(),
                            DueDate: item.invoices.due_date,
                        }));
    
                        downloadCSV(processedData, `${c}-invoices.csv`);
                        toast.dismiss(t_id);
                    } else {
                        toast.dismiss(t_id);
                    }
                } else { 
                    toast.dismiss(t_id);
                }
            }
        })
        .catch(function(error) {
            console.log(error);
            // setLoading(false);
            toast.error("An issue occurred while preparing invoices for download, please check your internet connectivity", {
                id: t_id
            });
        });
    };
    
    // Function to determine the mode description
    function getModeDescription(mode) {
        switch (mode) {
            case "disconnection":
                return "Suspension/Reconnection";
            case "invoice defaulting":
                return "Late Payment";
            case "repairs":
                return "Repairs";
            case "illegal connection":
                return "Illegal Connection";
            case "registration":
                return "Registration";
            case "meter deposit":
                return "Meter Deposit";
            case "sms":
            case "batch-download":
            case "email":
                return "Monthly Bill";
            default:
                return "Others";
        }
    }
    

    useEffect(() => {
        setLoading(true);
        setCount(0);
        axios.post("/master-invoices/invoices", {
            period: c,
            parent: state.userData._id,
            size: size,
            page: page
        }).then(function(res){
            if(res.status === 200){
                if(res.data.data.length > 0){
                    let filteredData = res.data.data.filter((obj) => obj.mode === "batch-download" || obj.mode === "sms" || obj.mode === "email")
                    setBills(filteredData)
                    setPagination(Math.ceil(res.data.count / size));
                    setCount(res.data.count);
                    setLoading(false);
                }
            }
        }).catch(function(error){
            console.log(error);
            setLoading(false);
        });
    }, [size, page, refresh, c]);

    const editInvoice = (id, amount) => {
        setId(id);
        setAmount(amount);
        setOpened(true);
    }

    const saveEdits = () => {
        let idx = bills.findIndex((obj) => obj._id === id);
        if(idx === -1){
            toast.error("The invoice does not seem to be existing.")
            return false;
        }


        axios.post("/master-invoices/update", {
            id: id,
            update: {
                invoices: {...bills[idx].invoices, total: amount}
            }
        }).then(function(res){
            axios.post("/bills/update", {
                period: getDate(),
                account: bills[idx].account,
                update: {
                    total_cost: parseFloat(amount)
                }
            }).then(function(res){
                if(res.status === 200){
                    setId("");
                    setOpened(false);
                    setRefresh(!refresh);
                }
            }).catch(function(error){
                console.log(error);
            })
        }).catch(function(error){
            toast.error("Internal issues occured.", {
                position: "bottom-right",
                duration: 5000
            })
        })
    }

    const getDefaultedBalance = (acc) => {
        let billed = invoices.reduce((sum, obj) => {
            if(obj.invoices.account_number === acc){
                return sum + parseFloat(obj.invoices.total)
            }
            return sum;
        }, 0);

        let paid = payments.reduce((sum, obj) => {
            if(obj.account.trim() === acc){
                return sum + parseFloat(obj.transaction_amount);
            }
            return sum;
        }, 0);

        let defaultedAmount = (billed - paid);

        return defaultedAmount;
    }

    const FormatBadge = ({item}) => {
        const real_time_defaulted = getDefaultedBalance(item.invoices.account_number); 

        const invoiced = parseFloat(item.invoices.total) + parseFloat(item.invoices.defaulted_balance);


        const color = (real_time_defaulted === invoiced && real_time_defaulted > 0) ? "red" : (real_time_defaulted < invoiced && real_time_defaulted > 0) ? "yellow"  : real_time_defaulted === 0 ? "green" : real_time_defaulted < 0 ? "blue" : "Loading";

        const text = (real_time_defaulted === invoiced && real_time_defaulted > 0) ? "Pending" : (real_time_defaulted < invoiced && real_time_defaulted > 0) ? "Partial"  : real_time_defaulted === 0  ? "Complete" : real_time_defaulted < 0 ? "Overpaid" : "Loading";

        return (
            <Badge size="xs" style={{textTransform: "none"}} color={color}>{text}</Badge>
        )
    }

    const rows = bills.filter((item) => {
        if(item.invoices?.customer_name?.toLowerCase().includes(name) || item.invoices?.account_number?.toLowerCase().includes(name) || item.invoices?.dma?.toLowerCase().includes(name)){
            return item
           }
    }).map((item, index) => {
        return (
            <tr key={`bill-${index}`}>
                <td style={{textAlign: "center"}}>
                    <Avatar color="blue" radius={40} size={40} >{item.invoices.customer_name ? item.invoices.customer_name.split("")[0].toUpperCase() : "U"}</Avatar>
                </td>
                <td style={{textAlign: "center"}}>
                <Anchor size="xs" target="_blank" component={"a"} href={`/app/customers/${encodeURIComponent(item.invoices.meter_number)}`} >{item.invoices.customer_name.split(" ")[0]}</Anchor>
                </td>
                <td style={{textAlign: "center"}}>
                    {item.invoices.account_number}
                </td>
                <td style={{textAlign: "center"}}>
                {item.invoices.invoice_number}
                </td>
                <td style={{textAlign: "center"}}>
                {item.invoices.total}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                    {item.invoices.defaulted_balance}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                    {parseFloat(item.invoices.total) + parseFloat(item.invoices.defaulted_balance)}
                </td>
                {getCycles(diff_in_months)[0].value === c ? (
                                    <td style={{alignItems: "center", textAlign: "center"}}>
                                    <FormatBadge item={item} />
                                </td>
                ) : null}
                <td style={{textAlign: "center"}}>
                {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td style={{textAlign: "center"}}>
                {item.invoices.due_date}
                </td>
                <td style={{textAlign: "center"}}>
                    <Group noWrap>
                        <Button radius={28} leftIcon={<Edit size={13} />} variant="default" size="xs" onClick={() => {editInvoice(item._id, item.invoices.total)}}>Edit</Button>
                        <Button radius={28} leftIcon={<Download size={13} />} variant="default" size="xs" onClick={() => {saveInvoice(item.invoices)}}>Save</Button>
                        <Button radius={28} leftIcon={<ArrowUpRight size={13} />} variant="default" size="xs" component={"a"} target="_blank" href={`/app/customers/${item.invoices.meter_number}?tab=payments&tk=${state.userData._id}`} >More</Button>
                    </Group>
                </td>
            </tr>
        )
    })


    return (
        <Paper p="md">
         <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Emita end-to-end pipeline is sequential. That means that meter readings have to be taken and bills delivered to have a list of payments. Although some payments may be received in-sequentially, they will not be displayed.
        </Alert>
        <Drawer size={"xl"} opened={opened} onClose={() => {setOpened(false)}} position="right" title="Edit customer invoice" padding="md" withOverlay={false} overlayProps={{ opacity: 0.1, blur: 0, color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2] }}>
        {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <TextInput label="Invoiced Amount" placeholder="" value={amount} onChange={(e) => {setAmount(e.currentTarget.value)}} />
                )
            ) : (
                <TextInput label="Invoiced Amount" placeholder="" value={amount} onChange={(e) => {setAmount(e.currentTarget.value)}} />
            )}


        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setOpened(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} loading={loading} onClick={() => {saveEdits()}}>Save Details</Button>
        </Group>
      </Drawer>
      <Group mt={20} mb={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Invoices</Heading>
      </Group>
      <Text mb={20}>You are currently viewing the most recent cycle's invoices. You can always review and manage your billing cycle configurations <Anchor component={Link} to="/app/configure/dates">here</Anchor>. For single customer's invoice updates, manage the invoice on the customer's profile.</Text>
      <Divider ml={-10} mr={-10} />
                <Group position="apart" noWrap style={{overflowX: "auto"}} mt={20}>
                <Group noWrap>
                <Text># Invoices in this cycle: <strong><span style={{color: "green"}}>{count}</span></strong></Text>
                <Button disabled={count < 1} onClick={() => {downloadBills()}} leftIcon={<FileExport />}>Export All</Button>
                </Group>
                <Group position="right" noWrap>
                <Text>Invoicing Cycle:</Text>
                <Select placeholder="Filter invoices by cycles" data={cycles} value={c} onChange={(val) => {setC(val)}} />
                <Text>Items to show:</Text>
                <Select value={size.toString()} onChange={(val) => {setSize(parseInt(val))}} data={[
                    {label: "25", value: "25"},
                    {label: "50", value: "50"},
                    {label: "100", value: "100"},
                    {label: "250", value: "250"},
                    {label: "500", value: "500"},
                    {label: "1000", value: "1000"},
                    {label: "5000", value: "5000"}
                ]} />
                </Group>
            </Group>
            <Space h="xs" />
            <Divider />
            <Space h="xs" />
            <SearchField hasSearchButton={false} hasSearchIcon inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' placeholder="Find customers by name" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}} />
            <Space h="xs" />
            <div style={{overflowX: 'auto'}} >
                    <Table fontSize="xs"  style={{borderBottom: '1px solid #E9ECEF'}}>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Account
                                </th>
                                <th>
                                    Invoice#
                                </th>
                                <th>
                                    Cost
                                </th>
                                <th>
                                    Arrears
                                </th>
                                <th>
                                    Total
                                </th>
                                {getCycles(diff_in_months)[0].value === c ?(
                                    <th>
                                        Status
                                    </th>
                                ) : null}
                                <th>
                                    Invoice Date
                                </th>
                                <th>
                                    Due Date
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{height: bills.length > 0 ? 200 : '100%'}}>
                            {bills.length === 0 && !loading ? (
                                <tr>
                                    <td colSpan={11}>
                                        <Center>
                                            <Text>No invoices were found</Text>
                                        </Center>
                                    </td>
                                </tr>
                            ) : bills.length > 0 && !loading && payments_ready && invoices_ready ? rows : (
                                <tr>
                                    <td colSpan={11}>
                                    <Center>
                                            <div
                                    className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                                    role="status">
                                    <span
                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                    >
                                    Loading...
                                    </span>
                                    </div>
                                        </Center>
                                    </td>
                                    </tr>
                            )}
                        </tbody>
                    </Table>
            </div>

            <Group mt={20} position="right">
                <Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>
        </Paper>
    )
}