import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css';
import {AuthProvider} from './contexts/AuthContext'
import CssBaseline from '@mui/material/CssBaseline'
import '@fontsource/roboto'
import './styles/index.css'
import ReactGA from "react-ga4";
import { ChakraProvider } from '@chakra-ui/react'

ReactGA.initialize("G-7TSKLYLLH6");

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <CssBaseline />
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
