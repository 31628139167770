import React, { useState } from "react";
import { Text, Checkbox, Table, Group, Button, Avatar, NumberInput, Anchor, Center, Badge, Progress, useMantineTheme, Space, Divider, Drawer, TextInput, Stack, MultiSelect } from "@mantine/core";
import { Plus } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { useContext } from "react";
import axios from "../../../utils/axios";
import { useEffect } from "react";
import useStyles from "./taskList.style";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { Heading, SearchField } from "@aws-amplify/ui-react";
import { TaskContext } from "../task-context/task.context";
import { DateRangePicker } from "@mantine/dates";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import { COLORS } from "../../../constants";
import ReactGA from "react-ga4";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";

export default function TasksList(params){
    const { state } = useContext(AuthContext);
    const [selection, setSelection] = useState([]);
    const [data, setData] = useState([]);
    const [, setPagination] = useState(0);
    const [, setRawData] = useState([]);
    const { classes, cx } = useStyles();
    const [refresh, setRefresh] = useState(false);
    const [size, setSize] = useState(10);
    const [name, setName] = useState("");
    const [task_id, setTaskID] = useState("");
    const [drawer_opened, setDrawerOpened] = useState(false);

    const [task_title, setTaskTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [staff, setStaff] = useState([]);
    const [all_staff, setAllStaff] = useState([]);
    const [, setStartsIn] = useState(null);
    const [, setEndsIn] = useState(null);
    const [value, setValue] = useState([]);
    const [complete_accounts, setCompleteAccounts] = useState([]);
    const [incomplete_accounts, setIncompleteAccounts] = useState([]);

    const [complete_to_incomplete, setCTI] = useState([]);
    const [incomplete_to_complete, setITC] = useState([]);
    const [updating, setUpdating] = useState(false);

    const { height} = useViewportSize();

    const theme = useMantineTheme();

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const deleteTaskAction = (id) => {
        try {
            axios.post("/tasks/delete", {
                id: id
            }).then(function(res){
                toast.success("Task deleted!");
                setRefresh(!refresh);

            }).catch(function(error){
                toast.error(error.message);
            })
        } catch(error){
            toast.error(error.message);
        }
    }

    const deleteTask = (id) => {
        swal({
            title: "Warning",
            text: "Are you sure you want to delete this task?",
        buttons: {
            cancel: "Cancel",
            continue: {
                text: "Delete",
                value: "continue"
            }
        }
        }).then(async (value) => {
            if(value === "continue"){
                deleteTaskAction(id);
            }
        })
    }

    const fetchData = () => {
        try {
            const body = {
                parent: state.userData._id
            };

            axios.post("/tasks/gettasks", body).then(function(res){
                setRawData(res.data.data);
                setData(res.data.data);
                setPagination(Math.ceil(res.data.data.length / size));
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                });
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const fetchStaff = () => {
            try {
                axios.post("/staff/getstaffs", {
                    parent: state.userData._id
                }).then(function(res){
                    if(res.data.data.length > 0){
                        let arr = [];

                        for(let i=0; i<res.data.data.length; i++){
                            let item = res.data.data[i];

                            let chunk =   {
                                image: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
                                label: item.firstname + " " + item.lastname,
                                value: item.username,
                                description: item.username,
                                title: item.title,
                                group: item.title
                              };

                              arr.push(chunk);
                        }
                        setAllStaff(arr);
                    }
                }).catch(function(error){
                    console.log(error);
                })
            } catch(error){
                console.log(error.message);
            }
        }

        fetchStaff();
    }, []);

    const updateTask = (id) => {
        let idx = data.findIndex((obj) => obj._id === id);
        if(idx !== -1){
            setTaskID(id);

            let obj = data[idx];

            setTaskTitle(obj?.title);
            setDesc(obj?.description);
            setStaff(obj?.staff);
            setCompleteAccounts(obj?.completeBuildings);
            setIncompleteAccounts(obj?.building);
            setStartsIn(obj?.startDate);
            setEndsIn(obj?.endDate);
            setValue([new Date(obj?.startDate), new Date(obj?.endDate)])

            setDrawerOpened(true);
        }
    }

    const saveTaskUpdates = () => {
        setUpdating(true);

        let readArr = complete_accounts.filter(x => !complete_to_incomplete.includes(x)) // complete accounts not moved to incomplete

        let unreadArr = incomplete_accounts.filter(x => !incomplete_to_complete.includes(x)) // incomplete accounts not moved to complete
        
        axios.post("/tasks/update", {
            id: task_id,
            update: {
                title: task_title,
                description: desc,
                staff: staff,
                startDate: value[0],
                endDate: value[1],
                building: unreadArr.concat(complete_to_incomplete),
                completeBuildings:readArr.concat(incomplete_to_complete)
            }
        }).then(function(res){
            if(res.status === 200){
                toast.success("Your updates were saved successfully!", {
                    position: "bottom-right"
                });
                setDrawerOpened(false);
                setRefresh(!refresh);
                setUpdating(false);
            }
        }).catch(function(error){
            toast.error("Your updates could not be saved.", {
                position: "bottom-right"
            });
            setUpdating(false);
        })
    }

    useEffect(() => {
        if(data.length > 0){
            let i=0; 
            while(i < data.length){
                let item = data[i];

                if(item.building.length === 0 && item.status !== "Complete" && (item.task === "0" || item.task === "1")){
                    axios.post("/tasks/update", {
                        id: item._id,
                        update: {
                            status: "Complete"
                        }
                    }).then(function(res){
                        if(res.status === 200){
                            // do nothing
                        } else {
                            console.log(res);
                        }
                    })
                } 

               if((new Date(item.endDate) < new Date()) && item.status !== "Overdue" && item.status !== "Complete"  ){
                    axios.post("/tasks/update", {
                        id: item._id,
                        update: {
                            status: "Overdue"
                        }
                    }).then(function(res){
                        if(res.status === 200){
                            // do nothing again
                        } else {
                            console.log(res);
                        }
                    })
                }
                if(i === (data.length - 1)){
                    //setRefresh(!refresh);
                }
                i++;
            }
                
        }
    }, [data]);

const markTask = (id, variant) => {
        try {
            axios.post("/tasks/marktask", {
                id: id,
                variant: variant
            }).then(function(res){
                toast.success("Status updated successfully");
                setRefresh(!refresh);
            }).catch(function(err){
                console.log(err);
            })
        } catch(error){
            toast.error(error.message);
        }
}


const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
  const toggleAll = () =>
    setSelection((current) => (current.length === data.length ? [] : data.map((item) => item._id)));


const recursiveTask = (id) => {
    axios.post("/tasks/recursive-task", {
        id: id
    }).then(function(res){
        if(res.status === 200){
            setRefresh(!refresh);
            toast.success("The task has been configured as recursive.")
        }
    }).catch(function(error){
        toast.error(error.message);
    })
}

const terminateTask = (id) => {
    swal({
        title: "Warning!",
        text: "Are you sure you want to terminate this task?",
        buttons: {
            cancel: "Cancel",
            continue: {
                text: "Terminate",
                value: "continue"
            }
        }
    }).then(async (value) => {
        if(value === "continue"){
            axios.post("/tasks/terminate-task", {
                id: id
            }).then(function(res){
                if(res.status === 200){
                    setRefresh(!refresh);
                    toast.success("The task has been terminated. It is no longer recurring.")
                }
            }).catch(function(error){
                toast.error(error.message);
            })
        }
    })
}

const [color, setColor] = useLocalStorage({
    key: COLORS,
    defaultValue: "blue",
    getInitialValueInEffect: true
})

const rows = data.filter((item) => {
    if(name !== ""){
        if(item.title.toLowerCase().includes(name) || item.description.toLowerCase().includes(name)){
            return item;
        }
    } else {
        return item;
    }
}).map((item ,index) => {
    const selected = selection.includes(item._id);
    return (
        <tr key={index} className={cx({ [classes.rowSelected]: selected })}>
                    <td>
                    <Checkbox
                        checked={selection.includes(item._id)}
                        onChange={() => toggleRow(item._id)}
                        transitionDuration={0}
                    />
                    </td>
                        <td>
                            <Avatar title={item.title} color={color} radius={40} size={40} >{item.description?.split("")[0].toUpperCase() || "~"}</Avatar>
                        </td>
                        <td>
                            {item.task === "0" ? (
                                <Anchor component={Link} to={`/app/tasks/${item.uuid}?utility=${state.userData._id}`}>{item.title}</Anchor>
                            ) : (
                                <Anchor href="#">{item.title}</Anchor>
                            )}
                            
                        </td>
                        <td>
                           <Avatar.Group spacing="sm">
                            {item.staff.length > 4 ? (
                            item.staff.slice(0, 4).map((it, index) => {
                                return (
                                    index < 3 ? (
                                        <Avatar title={it} color={color} variant="filled" key={`avatar-${index}`} src="#" radius="xl">{it.split("")[0].toUpperCase()}</Avatar>
                                    ) : (
                                        <Avatar title={item.staff.slice(3)} color={color} variant="filled" key={`avatar-${index}`} src="#" radius="xl">{`+${item.staff.length - 4}`}</Avatar>
                                    )
                                )
                            })
                            ) : (
                                item.staff.map((item, index) => {
                                    return (
                                        <Avatar title={item} color={color} variant="filled" key={`avatar-${index}`} src="#" radius="xl">{item.split("")[0].toUpperCase()}</Avatar>
                                    )
                                })
                            )}
                           </Avatar.Group>
                           
                        </td>
                        <td>
                           {new Date(item.startDate).toLocaleDateString()} 
                        </td>
                        <td>
                           {new Date(item.endDate).toLocaleDateString()} 
                        </td>
                        <td>
                        <Group position="apart">
                            <Text size="xs" color="teal" weight={700}>
                            {item.task === "2" && item.status === "Incomplete" ? 0 : item.task === "2" && item.status === "Complete" ? 100  : ((item.completeBuildings.length / (item.completeBuildings.length + item.building.length)) * 100).toFixed(0)}%
                            </Text>
                            <Text size="xs" color="red" weight={700}>
                            {item.task === "2" && item.status === "Incomplete" ? 100 : item.task === "2" && item.status === "Complete" ? 0  : ((item.building.length / (item.completeBuildings.length + item.building.length)) * 100).toFixed(0) }%
                            </Text>
                        </Group>
                            <Progress size="xs" classNames={{ bar: classes.progressBar }} sections={[{value: item.task === "2" && item.status === "Incomplete" ? 0 : item.task === "2" && item.status === "Complete" ? 100 : (item.completeBuildings.length / (item.completeBuildings.length + item.building.length)) * 100, color: 'green'}, {value: item.task === "2" && item.status === "Incomplete" ? 100 : item.task === "2" && item.status === "Complete" ? 0 : (item.building.length / (item.completeBuildings.length + item.building.length)) * 100, color: 'red'}]} />
                        </td>
                        <td>
                            <Badge style={{textTransform: "none"}} color={item.status === "Incomplete" ? "yellow" : item.status === "Overdue" ? "red" : "green"} >{item.status}</Badge>   
                        </td>
                        <td>
                            <Menu>
                                <MenuButton>
                                Actions <span aria-hidden>▾</span>
                                </MenuButton>

                                <MenuList style={{backgroundColor: theme.colorScheme === "light" ? "#ffffff" : "#000000", color: theme.colorScheme === "light" ? "#000000" : "#ffffff"}}>
                                    {item.status === "Complete" ? (
                                        <>
                                    <MenuItem  onSelect={() => {markTask(item._id, "Incomplete")}}>Mark Pending/Incomplete</MenuItem>                                     <MenuItem></MenuItem> 
                                    <MenuItem onSelect={() => {markTask(item._id, "Overdue")}}>Mark Overdue</MenuItem> 
                                        </>
                                    ) : item.status === "Incomplete" ? (
                                        <>
                                    <MenuItem onSelect={() => {markTask(item._id, "Complete")}}>Mark Complete</MenuItem> 
                                    <MenuItem onSelect={() => {markTask(item._id, "Overdue")}}>Mark Overdue</MenuItem> 
                                        </>
                                    ) : (
                                        <>
                                        <MenuItem onSelect={() => {markTask(item._id, "Incomplete")}}>MarK Pending/Incomplete</MenuItem> 
                                        <MenuItem onSelect={() => {markTask(item._id, "Complete")}}>Mark Complete</MenuItem> 
                                        </>
                                    )}
                                    <MenuItem onSelect={() => {updateTask(item._id)}}>Edit Task</MenuItem> 
                                    {!item.recurring ? (
                                        <MenuItem onSelect={() => {recursiveTask(item._id)}}>Recur</MenuItem> 
                                    ) : (
                                        <MenuItem onSelect={() => {terminateTask(item._id)}}>Terminate</MenuItem> 
                                    )}
                                    <MenuItem onSelect={() => {deleteTask(item._id)}}>Delete Task</MenuItem> 
                                </MenuList>
                            </Menu>
                        </td>
                    </tr>
    )
})

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Tasks</title>
        </Helmet>
        <Group mt={20} mb={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Tasks</Heading>
      </Group>
      <Text mb={20}>This list displays your task dispatched to various Emita platforms. See the progress of various tasks and and improve your worker's efficiency. Importantly, explore the most and least tasked staff.</Text>
      <Divider ml={-10} mr={-10} />
        <Drawer opened={drawer_opened} onClose={() => {setDrawerOpened(false)}} withCloseButton={false} padding={"md"} position="right" withOverlay={false} size={"xl"}>
        <Stack justify="space-between" style={{height: height * 0.9}}>
            <div style={{height: "100%", overflowY: "auto"}}>
            <Heading marginBottom={10} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Edit Task.</Heading>
        
        <TextInput label="Task Name" value={task_title} onChange={(e) => {setTaskTitle(e.currentTarget.value)}} mb="md" />

        <TextInput label="Task Description" value={desc} onChange={(e) => {setDesc(e.currentTarget.value)}} mb="md" />

        <MultiSelect searchable limit={10} label="Staff" value={staff} data={all_staff} mb="md" onChange={setStaff} />  

        <DateRangePicker
            label="DateRange"
            description="Dates since and upto the time the task is scheduled to complete. Uses the configured dates in the system."
            placeholder=""
            value={value}
            onChange={setValue}
            mb="md"
            withAsterisk
        />

        <MultiSelect searchable limit={10} label="Transfer to Unread" description="Transfer an account whose reading has been captured. Make sure to delete the consumption history as well." value={complete_to_incomplete} data={complete_accounts} mb="md" onChange={setCTI} />  

        <MultiSelect searchable limit={10} label="Transfer to Read" description="Transfer an unread account into a read account" value={incomplete_to_complete} data={incomplete_accounts} onChange={setITC} />  
        </div>

            <Group mt={30}>
            <Button radius={28} variant="default" onClick={() => {setDrawerOpened(false)}}>Cancel</Button>
            <Button radius={28} onClick={() => {saveTaskUpdates()}} loading={updating}>Save Details</Button>
        </Group>
        </Stack>

        </Drawer>
        <Group grow mt={20}>
                <Group position="left" style={{width: '100%'}}>
                <SearchField inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' size="small" placeholder="Type to search tasks" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}} />
                </Group>
                <Group position="right">
                    <Button onClick={() => {params.handleTab("new")}} leftIcon={<Plus />}>Schedule New Task</Button>
                        <NumberInput value={size} onChange={(val) => {setSize(val)}} min={5} step={5} />
                </Group>
            </Group>
            <Space h="md" />
            <Divider />
            <Space h="md" />
                <div style={{overflowX: 'auto'}} >
                <Table fontSize='xs' style={{borderBottom: '1px solid #E9ECEF'}} >
                    <thead>
                        <tr>
                        <th style={{ width: 40 }}>
                            <Checkbox
                                onChange={toggleAll}
                                checked={selection.length === data.length}
                                indeterminate={selection.length > 0 && selection.length !== data.length}
                                transitionDuration={0}
                            />
                            </th>
                            <th>
                                #
                            </th>
                            <th>
                                Task
                            </th>
                            <th>
                                Assignees
                            </th>
                            <th>
                                Starts On
                            </th>
                            <th>
                                Ends On
                            </th>
                            <th>
                                Task Progress
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </thead>
                        <tbody>
                            {data.length === 0 ?                             
                            <tr>
                                <td colSpan={8} >
                                    <Center>
                                        <Text>No data available</Text>
                                    </Center>
                                </td>
                            </tr> : (
                                rows
                            )}
                            </tbody>
                </Table>
                </div>
        </>
    )
}