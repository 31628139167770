export function downloadText(data, name){
    var filename = `${name}.txt`;
    var text = data;
    text.unshift(`Total Items: ${data.length}`);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text.join("\n")));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    return true;
}