import { Toaster } from 'react-hot-toast';
import { FooterPage } from './footer/footer';
import { Outlet } from "react-router-dom";
import Banner from '../login/banner';

export function Layout({ noFooter=false}) {
// location.pathname !== "/account/login" && location.pathname !== "/account/register" ? HEADER_HEIGHT :
  return (
  <>
    <main style={{ paddingTop:  0}}>
      <Banner />
      <Outlet />
      </main>
    {!noFooter && <FooterPage />}
    <Toaster />
    </>
  );
}