import { Button, Center, Group, Table, Text, Title, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faList } from "@fortawesome/free-solid-svg-icons";
import CreateDate from "./create";
import ReactGA from "react-ga4";

export default function InvoiceDefaulting(){
    const theme = useMantineTheme();
    const { state, dispatch } = useContext(AuthContext);
    const [dates, setDates] = useState(null);
    const [r, setR] = useState(false);
    const [new_date, setNewDate] = useState(false);
    const [refresh, ] = useState(false);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchDates = () => {
        setR(true)
        axios.get("/due-dates/due-dates", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
            if(res.status === 200){
                setDates(res.data.data);
                setR(false);
                console.log(res.data.data)
            }
        }).catch(function(error){
            console.log(error);
            setR(false);
        })
    } 

    useEffect(() => {
        fetchDates()
    }, [refresh]);


    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Configure Invoice Defaulting Dates</title>
        </Helmet>
                {dates === null && !r && !new_date? (
                    <>
                    <Group position="center" mt="10%">
                        <FontAwesomeIcon icon={faList} size="2xl" />
                        </Group>

                    <Text align="center" mt={30}>No dates have been configured. Default dates will be used.</Text>
                    <Center mt={30} mb="10%">
                        <Button onClick={() => {setNewDate(true)}} variant="default">Configure Invoice Defaulting Date.</Button>
                    </Center>
                    </>
                ) : r && !new_date ? (
                    <>
                    <Group position="center" mt="10%">
                        <FontAwesomeIcon icon={faGear} spin />
                        </Group>

                    <Text mb="10%" align="center" mt={30}>Loading...</Text>
                    </>
                ) : !new_date ? (
                <>
                <Title weight={300}>Invoice Defaulting Dates</Title>
                <Table size="xs" style={{borderBottom: '1px solid #E9ECEF', marginTop: 20}}>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Days</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                                    <td>{dates?.category}</td>
                                    <td>{dates?.date}</td>
                                </tr>
                    </tbody>
                </Table>
                </>
                ) : (
                    <CreateDate />
                )}

        </>
    )
}