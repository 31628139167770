import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import {  Anchor, Button, Center, Container, Grid, Group, MediaQuery, Menu, Paper, SimpleGrid, Stack, Text, TextInput, useMantineTheme } from "@mantine/core";
import { List, Menu2, Search } from "tabler-icons-react";
import { IconChevronDown, IconLayoutDashboard, IconList } from "@tabler/icons";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
  

export default function EmitaAccess(){
    const { state, dispatch } = useContext(AuthContext);
    const theme = useMantineTheme();
    const [display, setDisplay] = useState("layout");
    const [accounts, setAccounts] = useState([]);

    const { height } = useViewportSize();

    return (
        <>
        <Helmet>
            <title>Account Access | {state.userData.waterServiceName}</title>
        </Helmet>
        <Grid>
            <Grid.Col lg={10} md={8}>
                <TextInput placeholder="Search..." icon={<Search size={15} />} />
            </Grid.Col>
            <Grid.Col lg={2} md={4}>

            <Button rightIcon={<IconChevronDown size="1.05rem" stroke={1.5} />} pr={12}>
              Add New...
            </Button>
            </Grid.Col>
        </Grid>

        <Stack justify="center" align="center" h={(height - HEADER_HEIGHT) * 0.7}>
            <Center>
                <Menu2 />
            </Center>
            <Text align="center" mb={20}>
            This section will allow you to set up custom endpoints to enhance <br /> data collaboration with your preferred stakeholders.
            <Anchor href="#">Learn more</Anchor>
            </Text>


        </Stack>
        </>

    )
}