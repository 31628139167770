const end = new Date();

/**
 * 
 * @param {date} start - the date to get months difference from e.g utility creation date.
 * @returns number - the number of months between now and the date provided.
 */

export default function diffInMonths(start){
    var timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365.25));
}