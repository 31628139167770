import { useState, useEffect, useContext } from 'react';
import {
  AppShell,
  Header,
  useMantineTheme,
  Title,
  createStyles,
  Group,
  ActionIcon,
  Container,
  Anchor,
  Avatar,
  Text,
  Button,
  Center,
  Table as Table2,
  Menu,
  TextInput,
  MediaQuery,
  Switch,
  Checkbox,
  ScrollArea,
  Divider,
  Pagination
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { MapContainer, GeoJSON, LayersControl, TileLayer, Marker, useMap, ZoomControl, FeatureGroup, Popup} from "react-leaflet";
import L from "leaflet"
import {Menu2, Map, Stack, Database, InfoCircle, Clock, Plus, Minus, ZoomPan, Search, ListDetails, Table, ListCheck, Filter, Check, Trash, Home, ArrowLeft } from 'tabler-icons-react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { downloadCSV } from '../../utils/downloadCSV.js';
import EditControl from "../../utils/EditControl";
import { AuthContext } from '../../App.js';
import { IconFolderPlus, IconFolderX } from '@tabler/icons';
import axios from '../../utils/axios.js';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    backgroundColor: theme.colors.blue[9],
  },

  inner: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  navbar: {
      paddingTop: 0,
    },
  
    section: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      marginBottom: theme.spacing.md,
  
    },
  
    searchCode: {
      fontWeight: 700,
      fontSize: 10,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
      }`,
    },
  
    mainLinks: {
      paddingLeft: theme.spacing.md - theme.spacing.xs,
      paddingRight: theme.spacing.md - theme.spacing.xs,
      paddingBottom: theme.spacing.md,
    },
  
    mainLink: {
      display: 'flex',
      cursor: 'text',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.fontSizes.xs,
      padding: `8px ${theme.spacing.xs}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    mainLinkInner: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  
    mainLinkIcon: {
      marginRight: theme.spacing.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    },
  
    mainLinkBadge: {
      padding: 0,
      width: 20,
      height: 20,
      pointerEvents: 'none',
    },
  
    collections: {
      paddingLeft: theme.spacing.md - 6,
      paddingRight: theme.spacing.md - 6,
      paddingBottom: theme.spacing.md,
    },
  
    collectionsHeader: {
      paddingLeft: theme.spacing.md + 2,
      paddingRight: theme.spacing.md,
      marginBottom: 5,
    },

    root: {
      position: 'relative',
      '& *': {
        cursor: 'pointer',
      },
    },
  
    collectionLink: {
      display: 'block',
      padding: `8px ${theme.spacing.xs}px`,
      textDecoration: 'none',
      cursor: 'text',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.xs,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      lineHeight: 1,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },

    animalBody: {
      paddingLeft: 54,
      paddingTop: theme.spacing.sm,
    },
}));

export default function InventoryMapperDashboard() {
  const theme = useMantineTheme();
  const { state, dispatch } = useContext(AuthContext);
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const [basemap, setBasemap] = useState("ESRI");
  const [records, setRecords] = useState(true);
  const [points, setPoints] = useState([]);
  const [raw_points, setRawPoints] = useState([]);
   const [raw_points2, setRawPoints2] = useState([]);
  const [zoom, setZoom] = useState(3);
  const [show_table, setShowTable] = useState(false);
  const [query, setQuery] = useState(false);
  const [query_text, setQueryText] = useState("");
  const [issues, setIssues] = useState(0);
  const [coords, setCoords] = useState([-1.2641141046016717, 36.82277094518902])
  const [with_centroid, setWithCentroid] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        'Authorization': `Bearer ${state.userToken}`
      }
    };

    axios.get("/centroids/get", config).then(function(res){
      if(res.status === 200){
        if(res.data.data === null){
            setWithCentroid(false);
        } else {
            setCoords([res.data.data.latitude, res.data.data.longitude])
            setWithCentroid(true);
        }
      }
    }).catch(function(error){
      toast.error("Could not fetch your service area centroid coordinates", {
        position: 'bottom-right'
      })
    });

  }, []);

  useEffect(() => {
    const config = {
      headers: {
        'Authorization': `Bearer ${state.userToken}`
      }
    };

    axios.get("/centroids/get", config).then(function(res){
      if(res.status === 200){
        if(res.data.data !== null){
            setCoords([res.data.data.latitude, res.data.data.longitude]);
        } 
      }
    }).catch(function(error){
      toast.error("Could not fetch your service area centroid coordinates", {
        position: 'bottom-right'
      })
    });

  }, [])

  const fetchData = () => {
    axios.post("/customers-meters/get-data", {
        parent: state?.userData?._id
    }).then(function(res){
        if(res.status === 200){
            setRawPoints(res.data.data);
        }
    }).catch(function(error){
        console.log(error);
    });
}

useEffect(() => {
    fetchData();
}, [])

useEffect(() => {
    if(raw_points.length > 0){

        let arr = [];
        let arr2 = [];
        let iss = 0;

        for(let i=0; i<raw_points.length; i++){
            let item = raw_points[i];

            var new_data = {latitude: item?.location?.coords?.latitude, longitude: item?.location?.coords?.longitude}
            arr2.push({...item, ...new_data});

            if(item?.location?.coords?.latitude !== null && item?.location?.coords?.latitude !== undefined && typeof(item?.location?.coords?.latitude) === "number"){
                if(item?.location?.coords?.accuracy > 20){
                    iss += 1;
                }

                arr.push(item);
            } else {
                iss += 1;
            }
        }

        setRawPoints2(arr2);

        setPoints(arr);
        setIssues(iss);
    }
}, [raw_points])
const MarkerLayer = () => {
  return (
    points.map((item, index) => {
      return (
        <Marker key={`marker-${index}`} position={[item?.location?.coords?.latitude, item?.location?.coords?.longitude]}>
          <Popup>
            <Table2>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Account</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Connection Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item?.firstName}</td>
                  <td>{item?.lastName}</td>
                  <td>{item?.account}</td>
                  <td>{item?.mobile}</td>
                  <td>{item?.email}</td>
                  <td>{item?.connection_status}</td>
                </tr>
              </tbody>
            </Table2>
          </Popup>
        </Marker>
      )
    })
  )
}

function createAndDownloadZip(arrayOfObjects, geojsonFiles) {
  const zip = new JSZip();

  const jsonContent = JSON.stringify(arrayOfObjects);
  zip.file('customers.json', jsonContent);

  geojsonFiles.forEach((geojson, index) => {
    zip.file(`geojson_${index + 1}.geojson`, geojson);
  });

  zip.generateAsync({ type: 'blob' })
    .then(function (content) {

      const url = URL.createObjectURL(content);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'dataset.zip';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(function (error) {
      console.error('Error while creating the zipped file:', error);
    });
}

const geojsonFiles = null;

const parseWUDA = () => {
  let arr = [];
  for(let i=0; i<points.length; i++){
    arr.push(points[i].wuda)
  }

  return arr;
}

const margeClean = () => {
  for(let i=0; i<points.length; i++){
    let item = points[i];
  }
}

const navigate = useNavigate();

const MapPanel = () => {
    return (
        <MapContainer zoomControl={false} center={coords} style={{background: "transparent", zIndex: 1, height: "100%", width: '100%', padding: 0}} zoom={with_centroid ? 15 : zoom}>
        <LayersControl position='topright' vis >

      <LayersControl.BaseLayer checked={basemap === "CartoDB"} name='CartoDB'>
    <TileLayer
      attribution='&copy; Emita, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
      url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
    />
    </LayersControl.BaseLayer>
    <LayersControl.BaseLayer checked={basemap === "ESRI"} name='Satellite'>
    <TileLayer
      attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    />
    </LayersControl.BaseLayer>


      </LayersControl>

          {records && <MarkerLayer />}

              <Container sx={(theme) => ({marginTop: -12, marginLeft: -12, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-top leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ffff",
            width: width * 0.3,
            border: "none",
            height: height,
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >
              <Text size={"lg"} mb={20}>
              The Inventory Mapper is a vital tool that allows you to visualize draft field data, including customer details, pipeline, and water source information. 
              It empowers your utility to analyze data collected by your staff for accuracy and patterns before integrating it with the main dataset.
              </Text>
    
              <Button onClick={() => {navigate("/app/inventory-mapper/table")}} fullWidth mb="sm">Merge Dataset</Button>
              <Button mb={30} onClick={() => {downloadCSV(raw_points2, "inventory-mapper")}} fullWidth variant='outline'>Download Data</Button>
    
              <Title mb={15} order={5} fw={600}>Details</Title>

              <Group mb="md">
                <IconFolderPlus strokeWidth={1} />
                <div>
                <Text>Total Points</Text>
                <Text>
                  {raw_points.length}
                  </Text>
                </div>
              </Group>

              <Group mb="md">
                <IconFolderPlus strokeWidth={1} />
                <div>
                <Text color='green'>Okay</Text>
                <Text color='green'>
                  {points.length}
                  </Text>
                </div>
              </Group>
              <Group mb="md">
                <IconFolderX strokeWidth={1} />
                <div>
                <Text color='red'>With Issues</Text>
                <Text color='red'>
                  {issues}
                  </Text>
                </div>
              </Group>
              <Group mb="md">
                <Clock  strokeWidth={1} />
                <div>
                <Text>Last Added</Text>
                <Text>
                {raw_points.length > 0 ? new Date(raw_points[raw_points.length - 1].createdAt).toLocaleDateString() : "N/A"}
                  </Text>
                </div>
              </Group>
            </Container>
      </Container>

        {query ? (
                <Container sx={(theme) => ({border: "none", marginRight: 60, padding: 0, [theme.fn.smallerThan('md')]: {
                  display: "none"
                }})} className='leaflet-top leaflet-right'>
                  <TextInput className="leaflet-control leaflet-bar" placeholder='Search record...' value={query_text} onChange={(e) => {setQueryText(e.currentTarget.value)}} width={width * 0.5} />
                </Container>
        ) : null}

        <ZoomControl position='bottomright' />
        <FeatureGroup>
            <EditControl               
              position='bottomright'
              onEdited={() => {console.log("feature edited")}}
              onCreated={() => {console.log("feature created")}}
              onDeleted={() => {console.log("feature deleted")}}
              draw={{
                rectangle: true,
                circle: true,
                polyline: true,
                polygon: true,
              }} />
        </FeatureGroup>
        {!show_table ? (
            <Container sx={(theme) => ({border: "none", right: 0, marginTop: height * 0.1,  alignItems: "center", padding: 0, [theme.fn.smallerThan('md')]: {
              display: "none"
            }})} className='leaflet-top leaflet-right'>
              <div className="leaflet-control leaflet-bar" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ffff",}} >
              <Center mb={10} mt={10}>
              <ActionIcon  sx={(theme) => ({":hover": {backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ffff",}})}  onClick={() => {setQuery(!query)}} title='Search Records'>
                <Search size={30} strokeWidth={1} />
              </ActionIcon>
              </Center>
            <Divider />

            <Center mb={10} mt={10}>
              <ActionIcon component={Link} to="/app/inventory-mapper/table" sx={(theme) => ({":hover": {backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ffff",}})} title='Display Table'>
                <ListCheck size={30} strokeWidth={1} />
              </ActionIcon>
            </Center>
            </div>
          </Container>
        ) : null}
        </MapContainer>
    )
}

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : "white",
          padding: 0,
          paddingTop: 50
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <Header height={50} className={classes.header}>
          <div className={classes.inner}>
            <Group >
              <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
              <Group>
              <Anchor component={Link} to={`/app/account-summary`}>
                    <Center inline>
                    <ActionIcon sx={(theme) => ({":hover": {backgroundColor: "transparent"}})}>
                    <ArrowLeft size={14} color='white' />
                </ActionIcon>
                <Title color='white' order={4} style={{fontWeight: "normal"}}>{state.userData?.waterServiceName} </Title>
                    </Center>
            </Anchor>
              </Group>
              </MediaQuery>
              <MediaQuery largerThan={"md"} styles={{display: "none"}}>
              <Title color='white' order={4} style={{fontWeight: "normal"}}>{state.userData?.waterServiceName}</Title>
              </MediaQuery>
            </Group>

            <Group ml={50} spacing={5} className={classes.links}>
              
          </Group>
          
          <Group noWrap>
            <Menu>
              <Menu.Target>
              <ActionIcon sx={(theme) => ({":hover": {backgroundColor: "transparent"}})}>
                <Map color="#ffffff" />
              </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => {setBasemap("CartoDB")}} icon={basemap === "CartoDB" ? <Check size={14} /> : null}>CartoDB</Menu.Item>
                <Menu.Item onClick={() => {setBasemap("ESRI")}} icon={basemap === "ESRI" ? <Check size={14} /> : null}>ESRI World Imagery</Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <Button radius={28} color='teal' onClick={() => {navigate("/app/service-area")}}>Update Service Area Centroid</Button>
          <Button radius={28} color='red' onClick={() => {
            swal({
              text: "Are you sure you want to logout?",
              buttons: {
                cancel: "Cancel",
                continue: {
                  text: "Logout",
                  value: "logout"
                }
              }
            }).then(async (val) => {
              if(val === "logout"){
                dispatch({type: "SIGN_OUT"})
              }
            })
          }}>Sign Out</Button>
          </Group>
          </div>
        </Header>
      }
    >
        <MapPanel />
    </AppShell>
  );
}