export function isGeoJSONFile(file) {
    // Check file extension
    const allowedExtensions = ['geojson', 'json'];
    const fileExtension = getFileExtension(file.name);
  
    if (!allowedExtensions.includes(fileExtension)) {
        return false;
    }

    return true;

  }
  
  function getFileExtension(filename) {
    return filename.split('.').pop();
  }