import { Button, Group, MultiSelect, Paper, Select, Text, TextInput } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Send } from "tabler-icons-react";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import textClip from "../../utils/textClip";

export default function SMS(){
    const { state, dispatch } = useContext(AuthContext);
    const [customers, setCustomers] = useState([]);
    const [recipients, setRecipients] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    function processPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input phone number
        const cleanNumber = phoneNumber.replace(/\D/g, '');

        // Check if the cleaned number is at least nine digits long
        if (cleanNumber.length < 9) {
            // Handle the case where the phone number is too short
            return ;
        }
        // Take the last nine digits of the cleaned number
        const lastNineDigits = cleanNumber.slice(-9);

        // Add the country code "+254" to the last nine digits
        const formattedNumber = "+254" + lastNineDigits;

        return formattedNumber;
    }
      

    useEffect(() => {
        const body = {
            parent: state.userData._id
        };   

        axios.post("/customers/getCustomers",body).then(({ data: { data: customers }, }) => {
           let arr = [];
            for(let i=0; i<customers.length; i++){
                let c= customers[i];

                if(c?.phonenumber.length > 8){
                    arr.push({label: c?.name + "-" + c?.account, value: processPhoneNumber(c?.phonenumber)})
                }
            }

            setCustomers(arr);

          }).catch(function (error) {
            toast.error(error.message);
          })
    }, [])

    const sendSMS = () => {
        if(recipients === ""){
            return;
        }

        if(message.trim().length === 0){
            return;
        }

        setLoading(true);

        axios.post("/sms/mobitech-sms", {
            to: recipients,
            sms: message
        }).then(function(res){
            if(res.status === 200){
                toast.success("Your message was delivered");
                setLoading(false);
            }
        }).catch(function(error){
            console.log(error);
            setLoading(false);
        })  
    }
    return (
        <Paper p="xl" radius="md">
            <Select value={recipients} onChange={(val) => {setRecipients(val)}} searchable clearable label="Recipient" data={customers} mb="md" />
            <TextInput value={message} onChange={(e) => {setMessage(e.currentTarget.value)}} label="Message" />
            <Text size={"xs"} color="dimmed">Max of 120 characters.</Text>
            <Group mt="lg" position="right">
                <Button loading={loading} onClick={() => {sendSMS()}} leftIcon={<Send />}>Send Message</Button>
            </Group>
        </Paper>
    )
}