import React, { useReducer } from "react";
import { TaskContext } from "./task.context";

export const TaskProvider = ({ children }) => {
    const reducer = (state, action) => {
        switch(action.type){
            case "NAVIGATE":
                return {
                    ...state,
                    step: action.step
                };

                default:
                    return state;
        }
    }

    const initialState = {
        step: "0"
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <TaskContext.Provider value={{state, dispatch}}>
            {children}
        </TaskContext.Provider>
    )
}