import { Center, Stack, Tabs, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import TarrifList from "./tarrif-list";
import CreateTarrif from "./create";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import { Settings } from "tabler-icons-react";

export default function ConfigureTarrif(){
    const { height } = useViewportSize();

    return (
        <>
             <Tabs defaultValue={"list"}>
                <Tabs.List>
                    <Tabs.Tab value="list">
                        Tarrif List
                    </Tabs.Tab>

                    <Tabs.Tab value="new">
                        New
                    </Tabs.Tab>

                    <Tabs.Tab value="settings">
                        Settings
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="list">
                    <TarrifList />
                </Tabs.Panel>

                <Tabs.Panel value="new">
                    <CreateTarrif />
                </Tabs.Panel>

                <Tabs.Panel value="settings">
                    <Stack justify="center" h={height - HEADER_HEIGHT}>
                        <Center>
                            <Settings />
                        </Center>
                        <Text align="center" mt={20}>Configuration options for various tarrifs will appear here.</Text>
                    </Stack>
                </Tabs.Panel>
             </Tabs>
        </>
    )
}