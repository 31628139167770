import { StaffProvider } from "../staff-context/staff.provider";
import { Alert, Center, Stack, Tabs, Text } from "@mantine/core";
import StaffList from "./StaffList";
import CreateStaff from "../create-staff";
import { IconInfoCircle } from "@tabler/icons";
import { Settings, TrendingUp2 } from "tabler-icons-react";
import { HEADER_HEIGHT } from "../../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { useState } from "react";

function StaffWrapper(){
    const { height } = useViewportSize();
    const [activeTab, setActiveTab] = useState("list");

    const handleTab = (val) => {
      setActiveTab(val);
    }

    return (
        <>
        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
                🚀Onboard your staff into your account and assign them tasks. Get to understand who is doing what, at what time, and at which rate.
                Note that Emita currently supports technicians. Once the account is created, a password reset link will be sent to the link provided.
        </Alert>

        <Tabs value={activeTab} onTabChange={(val) => {setActiveTab(val)}}>
            <Tabs.List>
        <Tabs.Tab value="list">
          Staff
        </Tabs.Tab>

        <Tabs.Tab value="new">
          New
        </Tabs.Tab>

        <Tabs.Tab value="performance">
          Staff Performance
        </Tabs.Tab>

        <Tabs.Tab value="settings">
          Settings
        </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="list">
        <StaffList handleTab={handleTab} />
      </Tabs.Panel>

      <Tabs.Panel value="new">
        <CreateStaff handleTab={handleTab} />
      </Tabs.Panel>

      <Tabs.Panel value="performance">
      <Stack justify="center" h={height - HEADER_HEIGHT}>
            <Center>
                <TrendingUp2 />
            </Center>
            <Text align="center" mt={20}>Staff performance will be analyzed here.</Text>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="settings">
      <Stack justify="center" h={height - HEADER_HEIGHT}>
            <Center>
                <Settings />
            </Center>
            <Text align="center" mt={20}>Configuration options for staff will appear here.</Text>
        </Stack>
      </Tabs.Panel>
        </Tabs>
        </>
    )
}

export default function Staff(){
    return (
        <StaffProvider>
            <StaffWrapper />
        </StaffProvider>
    )
}