import { Button, Group, NumberInput, Switch, Tabs, Text, TextInput } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";

export function ConfigureDisconnection(){
    const { state, dispatch } = useContext(AuthContext);
    const [data, setData] = useState([
        {title: "Fee", description: "Charge the customer the prescribed fee on disconnection", checked: false, fee: 0}
    ]);

    const [configs, setConfigs] = useState([]);
    const [r, setR] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setID] = useState("");

    useEffect(() => {
        axios.get("/disconnection-rates/getRate", {headers: {'Authorization': `Bearer ${state?.userToken}`}}).then(function(res){
            if(res.status === 200){
                if(res.data.data){
                    setConfigs([res.data.data]);
                }
            }
        }).catch(function(error){
            toast.error("Could not fetch your disconnection configurations")
        });
    }, [r])

    const handleChange = (val, idx) => {
        data[idx].checked = val;
        setData([...data]);
    }

    const handleChange2 = (val, idx) => {
        configs[idx].checked = val;
        setConfigs([...configs]);
    }

    const handleInput = (val, idx) => {
        data[idx].fee = val;
        setData([...data]);
    }

    const handleInput2 = (val, idx) => {
        configs[idx].fee = val;
        setConfigs([...configs]);
    }

    const createConfigs = () => {
        const loadId = toast.loading("Creating a disconnection configuration");
        setLoading(true);
        axios.post("/disconnection-rates/create", {
            title: data[0].title,
            description: data[0].description,
            checked: data[0].checked,
            parent: state.userData._id,
            fee: data[0].fee
        }, {headers: {'Authorization': `Bearer ${state.userToken}`}})
        .then(function(res){
            if(res.status === 200){
                toast.success("Your disconnection configuration was created successfully", {
                    id: loadId
                });
                setR(!r);
                setLoading(false);
            }
        })
        .catch(function(error){
            toast.error("Could not create your disconnection configurations", {
                id: loadId
            });
            setLoading(false);
        })
    }

    const saveChanges = () => {
        setLoading(true);
    
        axios.post("/disconnection-rates/update", {update: {checked: configs[0].checked, fee: configs[0].fee}, id: configs[0]._id}, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
          if(res.status === 200){
            setR(!r);
            setLoading(false);
            toast.success("The changes were saved successfully");
          }
        }).catch(function(error){
          toast.error("An issue occured while updating the configs");
          setLoading(false);
        })
      }
    return (
        <Tabs defaultValue={"configs"}>
            <Tabs.List>
                <Tabs.Tab value="configs">Disconnection Configurations</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel pt="xl" value="configs">
                {configs.length > 0 ? (
                    configs.map((item, idx) => {
                        return (
                            <>
                            <Group mb={20} position="apart">
                                <div>
                                <Text style={{fontWeight: "bold"}}>{item.title}</Text>
                                <Text size={"sm"}>{item.description}</Text>
                                </div>
                            <Switch checked={item.checked} onChange={(e) => {handleChange2(e.currentTarget.checked, idx)}} onLabel="ON" offLabel="OFF" />
                        </Group>

                        <NumberInput variant="unstyled" value={item.fee} onChange={(val) => {handleInput2(val, idx)}} label="Disconnection Fee" hideControls min={1} />
                        </>
                        )
                    })
                ) : (
                    data.map((item, idx) => {
                        return (
                        <>
                            <Group mb={20} position="apart">
                                <div>
                                <Text style={{fontWeight: "bold"}}>{item.title}</Text>
                                <Text size={"sm"}>{item.description}</Text>
                                </div>
                            <Switch checked={item.checked} onChange={(e) => {handleChange(e.currentTarget.checked, idx)}} onLabel="ON" offLabel="OFF" />
                        </Group>
                        <NumberInput value={item.fee} onChange={(val) => {handleInput(val, idx)}} label="Disconnection Fee" hideControls min={1} />
                        </>
                        )
                    })
                )}

                <Group position="right" mt={40}>
                    {configs.length > 0 ? (
                        <Button onClick={() => {saveChanges()}}>Save Changes</Button>
                    ) : (
                        <Button onClick={() => {createConfigs()}} loading={loading}>Create Configurations</Button>
                    )}
                </Group>
            </Tabs.Panel>
        </Tabs>
    )
}