import { useContext, useCallback } from 'react';
import {AppShell,Drawer,MediaQuery,useMantineTheme,} from '@mantine/core';
import { Header } from './header/header';
import { NavbarSmall } from './nav/navbar';
import { SidebarContext } from './context/sidebar/sidebar.context';
import { DrawerNavbar } from './nav/drawernav';
import { useLocation} from 'react-router-dom';
import Footer from './footer/footer';
import { NavbarContext } from './context/navbar/navbar.context';

export default function AppShellLayoutLayout({children, img}) {
    const { state, dispatch } = useContext(SidebarContext);
    
    const { state: navState, } = useContext(NavbarContext);

    const location = useLocation();

    const toggleHandler = useCallback(() => {
      dispatch({
        type: 'TOGGLE',
      });
    }, [dispatch]);

    const theme = useMantineTheme();

    return (
      <AppShell
        styles={{
          main: {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
            padding: location.pathname === "/app/infrastructure" ? 0 : null
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
            <NavbarSmall />
          </MediaQuery>
        }
        header={
           <Header img={img} />
        }
      >
        <Drawer onClose={toggleHandler} opened={!state.opened}>
          <DrawerNavbar img={img} />
        </Drawer>
        {children}
      </AppShell>
    );
  }