import { ActionIcon, Button, Center, Group, Menu, Paper, Table, Text, Title, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { CirclePlus, Dots, Edit, Plus, Trash } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Link } from "react-router-dom";
import { Alert, Heading } from "@aws-amplify/ui-react";
import swal from "sweetalert";
import ReactGA from "react-ga4";

export default function ConfigureDMA(){
    const { state, dispatch } = useContext(AuthContext);
    const [refresh, setRefresh] = useState(false);
    const [dmas, setDMAs] = useState([]);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchData = () => {
        const body = {
            username: state.userData._id
        };

        axios.post("/dmas/getdmas", body).then(function(res){
            if(res.status === 200){
                setDMAs(res.data.data);
            }
        }).catch(function(error){
            toast.error(error.message);
        })
    }

    useEffect(() => {
        fetchData();
    }, [refresh]);

    const deleteDMA = (id) => {
        swal({
            title: "Warning!",
            text: "Are you sure you want to delete the DMA?",
            icon: "warning",
            buttons: {
                cancel: "No, Cancel Please!",
                continue: {
                    text: "Yes, I'm sure about this!",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                const body = {
                    id: id
                }
        
                axios.post("/dmas/delete", body).then(function(res){
                    if(res.status === 200){
                        setRefresh(!refresh);
                        toast.success("The DMA has been deleted permanently!");
                    }
                }).catch(function(error){
                    toast.error(error.message);
                })
            }
        })
    }

    const theme = useMantineTheme();

    return (
        <Paper p="md" >
        <Group mb={30} position="apart">
        <Heading level={3} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>DMAs</Heading>
            <Button variant="default" component={Link} to="/app/configure/dma/create" leftIcon={<CirclePlus />}>New DMA</Button>
        </Group>
        <Text mb={50}>Configure DMAs in your service area.</Text>

        <div style={{overflowX: 'auto', marginTop: 20}}>
            <Table style={{borderBottom: '1px solid #E9ECEF'}}>
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Configured On
                        </th>

                        <th>Last Modified</th>

                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                        {dmas.length === 0 ? (
                        <tr>
                        <td colSpan={4}>
                            <Center>
                            <Text>No data available</Text>
                            </Center>
                        </td>
                        </tr>
                        ) : (
                            dmas.map((item, index) => {
                                return (
                                    <tr key={`dma-${index}`}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {new Date(item.createdAt).toLocaleDateString()}
                                        </td>
                                        <td>
                                        {new Date(item.updatedAt).toLocaleDateString()}
                                        </td>
                                        <td>
                                            <Menu>
                                                <Menu.Target>
                                                    <ActionIcon>
                                                        <Dots size={13} />
                                                    </ActionIcon>
                                                </Menu.Target>
                                                <Menu.Dropdown>
                                                    <Menu.Item icon={<Edit size={13} />}>Edit</Menu.Item>
                                                    <Menu.Item onClick={() => {deleteDMA(item._id)}} icon={<Trash size={13} />}>Delete</Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                </tbody>
            </Table>
        </div>
        </Paper>
    )
}