import { Alert, Anchor, createStyles, Group, Paper, SimpleGrid, Text, useMantineTheme } from '@mantine/core';
import {
  IconUserPlus,
  IconDiscount2,
  IconArrowUpRight,
  IconArrowDownRight,
  IconTool,
  IconChartArea,
  IconCoin,
  IconAlertTriangle,
  IconInfoCircle
} from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../App';
import axios from '../../../utils/axios';
import { nFormatter } from '../../../utils/formatMoney';

const useStyles = createStyles((theme) => ({
  root: {
    padding: 0,
    marginBottom: 20,
  },

  value: {
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    color: theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

export function Statistics() {
  const { classes } = useStyles();
  const { state,} = useContext(AuthContext);
  const [consumption, setConsumption] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [prod, setProd] = useState(0);
  const [customers2, setCustomers2] = useState([]);
  const [payments, setPayments] = useState(0);
  const [prev_payments, setPrevPayments] = useState(0);
  const [prevConsumption, setPreviousConsumption] = useState(0);
  const [prevProduction, setPrevProduction] = useState(0);
  const [issues, setIssues] = useState(0);

  useEffect(() => {
    axios.post("/payments/monthly", {
      parent: state.userData._id
    }).then(function(res){
      if(res.status === 200){
        if(res.data.data.current.length > 0){
          setPayments(res.data.data?.current[0]?.totalAmount);
        }

        if(res.data.data.previous.length > 0){
          // THERE IS A BUG ORIGINATING FROM THE BACKEND.
          // NOT RETURNING PAYMENTS FOR DECEMBER WHEN CURRENT IS JANUARY
          setPrevPayments(res.data.data?.previous[0]?.totalAmount);
        }
      }
    }).catch(function(err){
      console.log(err);
    })

}, [])

useEffect(() => {
    const config = {
      headers: {
        'Authorization': `Bearer ${state.userToken}`
      },
      params: {
        parent: state.userData._id
      }
    }

    Promise.all([
      axios.get("/consumption/get", config),
      axios.post("/productions/get", { parent: state.userData._id })
    ])
    .then(([consumptionRes, productionRes]) => {
      const today = new Date();

      const month = today.getMonth();
  
      const year = today.getFullYear();
  
      var prevMonth, prevYear;

      if(month === 0){
          prevMonth = 11;
          prevYear = year - 1;
      } else {
        prevMonth = month - 1;
        prevYear = year;
      }

      if (consumptionRes.status === 200) {
        const data = consumptionRes.data.data;

        const consumptionSum = data.reduce((sum, item) => {
          if (new Date(item.createdAt).getMonth() === month && new Date(item.createdAt).getFullYear() === year) {
            return sum + (item.currReading - item.prevReading);
          }
          return sum;
        }, 0);

        const prevConsumptionSum = data.reduce((sum, item) => {
          if (new Date(item.createdAt).getMonth() === prevMonth && new Date(item.createdAt).getFullYear() === prevYear) {
            return sum + (item.currReading - item.prevReading);
          }
          return sum;
        }, 0);
        setConsumption(consumptionSum);
        setPreviousConsumption(prevConsumptionSum);
      }

      if (productionRes.status === 200) {
        const data1 = productionRes.data.data;

        //console.log(data);

        const productionSum = data1.reduce((sum, obj) => {
          if (new Date(obj.createdAt).getMonth() === month  && new Date(obj.createdAt).getFullYear() === year) {
            return sum + obj.production;
          }
          return sum;
        }, 0);

        const prevProductionSum = data1.reduce((sum, obj) => {
          if (new Date(obj.createdAt).getMonth === prevMonth  && new Date(obj.createdAt).getFullYear() === prevYear) {
            return sum + obj.production;
          }
          return sum;
        }, 0);

        setProd(productionSum);
        setPrevProduction(prevProductionSum);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }, [state]);
  

  useEffect(() => {
    axios.post("/customers/getCustomers", {
      parent: state.userData._id
    })
    .then(function(res){
      if(res.status === 200){
        const date = new Date();

        const year = date.getFullYear();
        const month = date.getMonth();

        var prevMonth, prevYear;

        if(month === 0){
          prevMonth = 11;
          prevYear = year - 1;
      } else {
        prevMonth = month - 1;
        prevYear = year;
      }

        const filteredData = res.data.data.filter(item => {
          const itemDate = new Date(item.createdAt);
          return (itemDate.getMonth() === month && itemDate.getFullYear() === year) ||
                 (itemDate.getMonth() === prevMonth && itemDate.getFullYear() === prevYear);
        });
  
        const customers = filteredData.reduce((sum, item) => {
          const itemDate = new Date(item.createdAt);
          if (itemDate.getMonth() === month && itemDate.getFullYear() === year) {
            return sum + 1;
          }
          return sum;
        }, 0);
  
        const customers2 = filteredData.reduce((sum, item) => {
          const itemDate = new Date(item.createdAt);
          if (itemDate.getMonth() === prevMonth && itemDate.getFullYear() === prevYear) {
            return sum + 1;
          }
          return sum;
        }, 0);
  
        setCustomers(customers);
        setCustomers2(customers2);
      }
    })
    .catch(function(error){
      console.log(error);
    })
  }, [state])
  
  const fetchWithIssues = () => {
    const config = {
        headers: {
            'Authorization': `Bearer ${state.userToken}`
        },
        params:{
            size: 10,
            page: 1,
        }
    };

    axios.get('/payments/with-issues', config).then(function(res){
        if(res.status === 200){
            setIssues(res.data.data.total[0]?.totalIssues);
        }
    }).catch(function(error){
      console.log(error);
    })
}

useEffect(() => {
    fetchWithIssues();
}, [])

  const data = [
    {
      "title": "Supplied Quantity",
      "icon": IconTool,
      "color": "blue",
      "value": prod,
      "diff": prevProduction === 0 && prod > 0 ? 100 : prevProduction === 0 && prod === 0 ? 0 : ((prod - prevProduction) / prevProduction) * 100,
    },
    {
      "title": "Consumed Quantity",
      "icon": IconDiscount2,
      "color": "cyan",
      "value": consumption,
      "diff": prevConsumption === 0 && consumption > 0 ? 100 : prevConsumption === 0 && consumption === 0 ? 0 : ((consumption - prevConsumption) / prevConsumption) * 100,
    },
    {
      "title": `NRW(Flow Balance)`,
      "icon": IconChartArea,
      "color": "red",
      "value": (prod - consumption),
      "diff": prod === 0 && consumption === 0 ? 0 : prod === 0 && consumption !== 0 ? 0 : prod !== 0 && consumption === 0 ? 100 : prevProduction === 0 && prod !== 0 && prevConsumption === 0 && consumption !== 0 ? 100 : (((prod - consumption) - (prevProduction - prevConsumption)) / (prevProduction - prevConsumption)) * 100,
    },
    {
      "title": "MTD Revenue(Ksh)",
      "icon": IconCoin,
      "color": "green",
      "value": nFormatter(payments, 1),
      "diff": prev_payments === 0 ? 0 : ((((payments - prev_payments) / prev_payments) * 100))
    },
    {
      "title": "New Connections",
      "icon": IconUserPlus,
      "color": "yellow",
      "value": customers,
      "diff": customers === 0 ? 0 : ((customers - customers2) / customers) * 100
    }
  ]

  const theme = useMantineTheme()
  const stats = data.map((stat) => {
  const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Paper withBorder p="sm" style={{borderLeft: `2px solid ${stat.color}`}} key={stat.title} >
        <Group position="apart">
          <Text size="xs" className={classes.title}>
            {stat.title}
          </Text>
          <stat.icon className={classes.icon} size={18} stroke={1} />
        </Group>

        <Group align="flex-end" spacing="xs" mt={25}>
          <Text className={classes.value}>{stat.value}</Text>
          <Text
            color={stat.diff > 0 && stat.title !== "NRW(M³)" ? theme.colors.blue[9] : stat.diff < 0 && stat.title === "NRW(M³)" ? theme.colors.blue[9] : stat.diff > 0 && stat.title === "NRW(M³)" ? "red" : 'red'}
            size="sm"
            weight={500}
            className={classes.diff}
          >
            <span>{stat.diff.toFixed(1)}%</span>
            <DiffIcon size={16} stroke={1.5} />
          </Text>
        </Group>

        <Text size="xs" mt={7}>
          Compared to previous month
        </Text>
      </Paper>
    );
  });

  const nrw = ((prod - consumption) / prod) * 100;

  return (
    <div className={classes.root} style={{overflowX: "auto"}}>
      <SimpleGrid
        cols={6}
        spacing="xs"
        breakpoints={[
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'xs', cols: 1 },
        ]}
        mb="md"
      >
        {stats}
        <Paper withBorder p="sm" >
        <Group position="apart">
          <Text size="xs" className={classes.title}>
            Payments with Issues
          </Text>
          <IconAlertTriangle className={classes.icon} size={18} stroke={1} />
        </Group>

        <Group align="flex-end" spacing="xs" mt={25}>
          <Text className={classes.value}>{issues}</Text>
          <Text
            size="sm"
            weight={500}
            className={classes.diff}
            color='red'
          >
            <span>{issues}%</span>
            <IconArrowUpRight size={16} stroke={1.5} />
          </Text>
        </Group>

        <Text size="xs" mt={7}>
        Since onboarding on {new Date(state.userData.createdAt).toLocaleDateString()}
        </Text>
      </Paper>
      </SimpleGrid>

        {nrw >= 0 ? (
                <Alert color={nrw < 25 ? "green" : nrw < 50 ? "yellow" : "red"} icon={<IconInfoCircle />} title="Non Revenue Water">
                Your recent non-revenue water was {prod -consumption}M³ which was approximately {nrw.toFixed(0)}% of the total water produced. Learn how you can use Emita to reduce your non-revenue water <Anchor href='#'>here</Anchor>
              </Alert>
        ) : (
          <Alert color={"red"} icon={<IconInfoCircle />} title="Non Revenue Water">
          Your supply dataset is not structured well to provide a report about NRW water. Learn about how to structure your supply data as well as how you can use Emita
          to reduce your non-revenue water <Anchor href='#'>here</Anchor>
        </Alert>
        )}
    </div>
  );
}