import { useContext } from 'react';
import { createStyles, } from '@mantine/core';
import { AuthContext } from '../../../App';
import { Link } from 'react-router-dom';
import { HEADER_HEIGHT } from './header/header.style';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.gray[4],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colors.gray[4]
  },
}));

export function CompanyLogo() {
  const { state, dispatch } = useContext(AuthContext);
  const { classes, theme } = useStyles();
  return (
    <div>
            <Link to={`/app#${state.userData._id}`} className={`flex ${theme.colorScheme === "dark" ? "text-white" : "text-gray-800"} -ml-5 font-bold text-lg justify-center items-center`}>
            <img
               src={"/emita-email-logo.png"}
                alt="Emita logo"
                width={30}
                className='mr-2'
            />
            Emita
        </Link>
    </div>
  );
}

export function CompanyLogoIcon() {
  const { state, dispatch } = useContext(AuthContext);
  const { classes, theme } = useStyles();
  return (
    <div>
            <Link to={`/app#${state.userData._id}`} className={`flex ${theme.colorScheme === "dark" ? "text-white" : "text-gray-800"} -ml-5 font-bold text-lg justify-center items-center`}>
            <img
               src={"/emita-email-logo.png"}
                alt="Emita logo"
                width={30}
                className='mr-2'
            />
        </Link>
    </div>
  );
}

export function DrawerCompanyLogo() {
  const { state, dispatch } = useContext(AuthContext);
  return (
    <div>
            <Link to={`/app#${state.userData._id}`} className={`flex text-white -ml-5 font-bold text-lg justify-center items-center`}>
            <img
                src={"/emita-email-logo.png"}
                width={70} 
                height={40}
                alt="Emita logo"
                className="flex-1 -mr-3"
            />
            Emita
        </Link>
    </div>
  );
}

export function GuestCompanyLogo({link}){
  return (
    <div>
    <Link to={link} className={`flex font-bold text-lg items-center`}>
    <img
        src={"/android-chrome-192x192.png"}
        width={50} 
        alt="Emita logo"
        className='mr-2'
    />
    Emita
</Link>
</div>
  )
}