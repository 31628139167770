import { createStyles } from '@mantine/styles';
import { useContext } from 'react';

import { SidebarContext } from '../context/sidebar/sidebar.context';

export const HEADER_HEIGHT = 50;

export default createStyles((theme) => {
  const { state, dispatch } = useContext(SidebarContext);
  return ({
    header: {
      position: 'fixed',
      zIndex: 10,
      top: 0,
      [theme.fn.largerThan('md')]: {
        left: 0,
      },
      [theme.fn.smallerThan('md')]: {
        left: 0,
        overflowX: 'auto'
      },
      right: 0,
      height: HEADER_HEIGHT,
      backgroundColor:theme.colorScheme === "light" ? theme.colors.gray[0] : theme.colors.dark[9],
      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    },
  
    search: {
      marginTop: -15,
      [theme.fn.smallerThan('md')]: { display: 'none' },
    },

    user: {
      display: 'block',
      padding: theme.spacing.md,
      '&:hover': {
        backgroundColor: "transparent"
      },
      border: '1px solid gray'
    },
  
    inner: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%'
    },

    subLink: {
      width: '100%',
      padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
      borderRadius: theme.radius.md,
  
      ...theme.fn.hover({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      }),
  
      '&:active': theme.activeStyles,
    },
})
});