import { Alert, Center, Grid, Text, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { Heading } from "@aws-amplify/ui-react";
import { IconInfoCircle } from "@tabler/icons";

export default function ConnectionRequests(){
    const theme = useMantineTheme();
    const { height, width } = useViewportSize();

    return (
        <Grid>
            <Grid.Col sm={12} lg={12}> 
            <Alert mb={20} mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Customer management console allows you to keep track of your customers. Get to know how many customers you acquired this month, the trend in customer's connection status, manage specific customer details and much more.
        </Alert> 
                            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Connection Requests</Heading>          
                <Center mt={"15%"}>
                    <img src={require("../../assets/waiting_hourglass.png")} />
                </Center>

            <Text align="center" mt={30}>New connection requests will appear here</Text>
</Grid.Col>
        </Grid>
    )
}