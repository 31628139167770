import { Heading, SearchField } from "@aws-amplify/ui-react";
import { Button, Center, Group, Menu, Paper, Space, Table, Divider, Text, Pagination, Select, Anchor, Drawer, TextInput, Stack, Alert } from "@mantine/core";
import { useMantineTheme } from "@mantine/styles";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import axios from "../../utils/axios";
import { ArrowUpRight, ChevronDown, CircuitSwitchOpen, Download, Edit, EyeOff, FileExport, Trash, X } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import diffInMonths from "../../utils/monthsToNow";
import { getPaymentsAndInvoiceCycles, parseCycle } from "../../utils/getCycles";
import { useSessionStorage, useViewportSize } from "@mantine/hooks";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { toast } from "react-hot-toast";
import { DEFAULT_METER_READING_CYCLE } from "../../constants";
import swal from "sweetalert";
import OTPInput from "otp-input-react";
import { IconCircuitSwitchClosed, IconInfoCircle } from "@tabler/icons";

export default function CustomersPayments(){
    const { state } = useContext(AuthContext);
    const [payments, setPayments] = useState([]);
    const [name, setName] = useState("");
    const [size, setSize] = useState(25);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(0);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    const [drawer_opened, setDrawerOpened] = useState(false);
    const [id, setID] = useState("");
    const [amount, setAmount] = useState(0);
    const [account, setAccount] = useState("");

    const [dates, setDates] = useState(null);
    const [, setStartsIn] = useState(null);
    const [, setEndsIn] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [customers, setCustomers] = useState([]);

    const [withIssues, setWithIssues] = useState([]);
    const [issues, setIssues] = useState(0);
    const [show_with_issues, setShowWithIssues] = useState(false);

    const [, setTotalBills] = useState(0);
    const [, setTotalPaid] = useState(0);

    const [mode, setMode] = useState("batch-download");

    const [short_codes, setShortCodes] = useState([]);

    const [fa_step, setFAStep] = useState(0);

    const [otp, setOTP] = useState("");

    const [otp_loading, setOTPLoading] = useState(false);

    const [otp_active, setOTPActive] = useState(false);

    const [mfa_session, setMFASession] = useSessionStorage({
        defaultValue: "0",
        getInitialValueInEffect: true,
        key: "emita-2fa-session"
    });

    useEffect(() => {
        if(otp.length === 6){
            setOTPActive(true);
        } else {
            setOTPActive(false);
        }
    }, [otp])

    const verifyOTP = () => {
        setOTPLoading(true);
        // verify
        axios.post("/mfa/verify", {
            otp_token: otp
        }, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }).then(function(res){
            if(res.status === 200){
                setOTPLoading(false);
                setMFASession("1");
                setFAStep(1);
                setOTP("");
            }
        }).catch(function(err){
            toast.error("The passed token was invalid, or it might have already expired.");
            setOTP("");
            setOTPLoading(false);
        })
    }


    let diff_in_months = diffInMonths(new Date(state.userData.createdAt));
    let [c, setC] = useState(getPaymentsAndInvoiceCycles(diff_in_months)[0].value);

    let cycles = getPaymentsAndInvoiceCycles(diff_in_months);


    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const fetchCustomerCumulativeBills = () => {
        axios.get("/master-invoices/customer-cumulative-bills", {
          headers: {"Authorization": `Bearer ${state.userToken}`},
          params: {
            parent: state.userData._id,
            account: account
          }
        }).then(function(res){
          if(res.status === 200){
            let arr = res.data.data;
            if(arr.length > 0){
              setTotalBills(arr[0].total)
            }
          }
        }).catch(function(err){
          console.debug("fetching master invoices failed...")
        })
    }

    const promisifiedCumulativeBills = async () => {
        return new Promise((resolve, reject) => {
            axios.get("/master-invoices/customer-cumulative-bills", {
                headers: {"Authorization": `Bearer ${state.userToken}`},
                params: {
                  parent: state.userData._id,
                  account: account
                }
              }).then(function(res){
                if(res.status === 200){
                  let arr = res.data.data;
                  let y=0;
                  if(arr.length > 0){
                    y = arr[0].total
                  }

                  resolve(y);
                }
              }).catch(function(err){
                reject(err);
              })
        })
    }

    const promisifiedCumulativePayments = async () => {
        return new Promise((resolve, reject) => {
            axios.get("/payments/customer-cumulative-payments", {
                headers: {"Authorization": `Bearer ${state.userToken}`},
                params: {
                  parent: state.userData._id,
                  account: account
                }
              }).then(function(res){
                if(res.status === 200){
                  let arr = res.data.data;
                  let x = 0;
                  if(arr.length > 0){
                    x = arr[0].totalAmount
                  }

                  resolve(x);
                }
              }).catch(function(err){
                reject(err);
              })
        })
    }

    const fetchCustomerCumulativePayments = () => {
        axios.get("/payments/customer-cumulative-payments", {
          headers: {"Authorization": `Bearer ${state.userToken}`},
          params: {
            parent: state.userData._id,
            account: account
          }
        }).then(function(res){
          if(res.status === 200){
            let arr = res.data.data;
            if(arr.length > 0){
              setTotalPaid(arr[0].totalAmount)
            }
          }
        }).catch(function(err){
          console.debug("fetching payments failed...")
        })
      }

      const fetchShortCodes = () => {
        const body = {
          parent: state.userData._id
        }; 
  
        axios.post("/short-codes/get-short-codes", body).then(function (res) {
            if (res.status === 200) {
              let arr = res.data.data;
                setShortCodes(arr);
            }
          }).catch(function (error) {
            console.debug("An error occured while fetching paybill numbers")
          })
      }

    useEffect(() => {
        if(account !== ""){
            fetchCustomerCumulativeBills();
            fetchCustomerCumulativePayments();
            fetchShortCodes();
        }
    }, [account]);

    const fetchCustomers = () => {
        setLoading(true);

        const body = {
            parent: state.userData._id,
            paginate: false
        };
        axios.post("/customers/getCustomers", body)
            .then(function(res){
                if(res.status === 200){
                    setCustomers(res.data.data)
                }
            })
            .catch(function(error){
                toast.error(error.message);
                setLoading(false);
            });
    } 

    const fetchWithIssues = () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
            params:{
                size: size,
                page: page,
            }
        };

        axios.get('/payments/with-issues', config).then(function(res){
            if(res.status === 200){
                setIssues(res.data.data.total[0]?.totalIssues);
                setWithIssues(res.data.data.data);
            }
        }).catch(function(error){
            console.log(error)
            toast.error("Something wrong happened", {
                position: "bottom-right"
            });
        })
    }

    useEffect(() => {
        fetchWithIssues();
    }, [size, page, refresh])

    useEffect(() => {
        fetchCustomers();
    }, [])

    const matchAccountWithCustomer = (acc) => {
        let result = "#";

        let idx = customers.findIndex((obj) => obj.account === acc);

        if(idx !== -1){
            result = customers[idx].username
        }

        return result;
    }

    useEffect(() => {
        setPagination(0);
        setPage(1);
        setSize(10);
    }, [c]);

    useEffect(() => {
        const body = {
          parent: state.userData._id
        }; 
      
        axios.post("/dates/get-dates", body)
          .then(function (res) {
            if (res.status === 200) {
              let dates = res.data.data;
              if(dates === null){
                dates = DEFAULT_METER_READING_CYCLE;
              }
              setDates(dates);
              let today = new Date();
              let startsOn = dates?.starts;
              let duration = dates?.duration;
              let review_window = dates?.reviewWindow;
      
              let currentMonth = today.getMonth() + 1;
              let currentYear = today.getFullYear();
      
              // Calculate the start and end dates of the current meter reading cycle
              let cycleStart;
              let cycleEnd;
              if (startsOn > today.getDate()) {
                // Cycle spans across two months
                cycleStart = new Date(currentYear, currentMonth - 2, startsOn);
                cycleEnd = new Date(currentYear, currentMonth - 2, startsOn + duration + review_window);
              } else {
                // Cycle starts and ends within the same month
                cycleStart = new Date(currentYear, currentMonth - 1, startsOn);
                cycleEnd = new Date(currentYear, currentMonth - 1, startsOn + duration + review_window);
              }
    
              //console.log(cycleEnd.getDate());

              setStartsIn(cycleStart);
              setEndsIn(cycleEnd.getDate());
    
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }, []);

    useEffect(() => {
        
        if(dates !== null && customers.length > 0){
            setLoading(true);
            setPayments([]);
            axios.post("/payments/utility-payments", {
                parent: state.userData._id,
                period: c,
                size: size,
                page: page,
                date: dates?.starts,
                month: parseCycle(c).month,
                year: parseInt(c.split(" ")[1])

            }).then(function(res){
                if(res.status === 200){
                    setLoading(false);
                    let dt = res.data.data;
                    setPayments(dt)
                    setPagination(Math.ceil(res.data.count / size));
                    setCount(res.data.count);
                }
            }).catch(function(err){
                setLoading(false);
            })
        } else if(dates !== null && customers.length === 0) {
            setLoading(false);
        }
    }, [size, page, dates, c, customers, refresh])

    useEffect(() => {
        if(show_with_issues){
            setPagination(Math.ceil(issues / size))
        } else {
            setPagination(Math.ceil(count / size))
        }
    }, [show_with_issues])

    const openUpdateDrawer= (id) => {
        setID(id);

        let idx = payments.findIndex((obj) => obj._id == id);

        if(idx !== -1){
            setAmount(payments[idx].transaction_amount);
            setAccount(payments[idx].account);

            setDrawerOpened(true);
        } else {
            let idx2 = withIssues.findIndex((obj) => obj._id === id);

            if(idx2 !== -1){
                setAmount(withIssues[idx2].transaction_amount);
                setAccount(withIssues[idx2].account);
    
                setDrawerOpened(true);
            } else {
                toast.error("The payment cannot be found. Maybe it has been deleted!", {
                    position: "bottom-right"
                });
            }
        }
    }
    
    const saveUpdates = () => {
        setLoading(true);

        axios.post("/payments/update", {
            id: id,
            update: {
                account: account
            }
        }).then(function(res){
            if(res.status === 200){
                toast.success("Payment updated successfully!", {
                    position: "bottom-right",
                    duration: 5000
                });
                sendSMSAboutUpdates(account);
                setRefresh(!refresh);
                setLoading(false);
                setDrawerOpened(false);
            }
        }).catch(function(error){
            toast.error("Something wrong happened!", {
                position: "bottom-right",
                duration: 5000
            });
            setLoading(false);
        })
    }

    const askDelete = (id) => {
        swal("Are you sure you want to delete the payment? This action cannot be reverted. It is advisable to download all the payments with issues before deleting them.", {
            buttons: {
                cancel: 'Cancel',
                continue: {
                    text: "I'm sure, Delete",
                    value: 'continue'
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                deletePayment(id);
            }
        })
    }

    const deletePayment = (id) => {
        axios.post("/payments/delete", {
            id: id
        }).then(function(res){
            if(res.status === 200){
                toast.success("Payment deleted successfully!", {
                    position: "bottom-right",
                    duration: 5000
                });
                setRefresh(!refresh);
            }
        }).catch(function(error){
            toast.error("Something wrong happened!", {
                position: "bottom-right",
                duration: 5000
            });
        })
    }

    const getName = (acc) => {
        let idx = customers.findIndex((obj) => obj.account === acc);

        if(idx !== -1){
            return customers[idx]?.name.split(" ")[0].toUpperCase();
        } else {
            return "N/A"
        }
    }

    const getMSISDN = (acc) => {
        let idx = customers.findIndex((obj) => obj.account === acc);

        if(idx !== -1){
            return customers[idx]?.phonenumber;
        } else {
            return "N/A"
        }
    }

    const matchPaybill = (acc) => {
        let dma = "";

        var idx2 = customers.findIndex((obj) => obj.account === acc);

        if(idx2 !== -1){
            dma = customers[idx2]?.dma
        } else {
            return "N/A"
        }

        let idx = short_codes.findIndex((obj) => obj.dma === dma);
  
        if(idx !== -1){
          let item = short_codes[idx];
          return item.short_code
        } else {
          if(short_codes.length > 0){
            let idx = short_codes.findIndex((obj) => obj?.dma === "*"); // all paybilss

            if(idx !== -1){
              let item = short_codes[idx];
              return item.short_code
            } else {
                return short_codes[0]; // the first short code
            }
          } else {
            return "N/A"
          }
        }
      }

      function processPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input phone number
        const cleanNumber = phoneNumber.replace(/\D/g, '');

        // Check if the cleaned number is at least nine digits long
        if (cleanNumber.length < 9) {
            // Handle the case where the phone number is too short
            return ;
        }
        // Take the last nine digits of the cleaned number
        const lastNineDigits = cleanNumber.slice(-9);

        // Add the country code "+254" to the last nine digits
        const formattedNumber = "254" + lastNineDigits;

        return formattedNumber;
    }

    const sendSMSAboutUpdates = async (acc) => {
        let phonenumber = getMSISDN(acc);

        if(phonenumber !== "N/A"){
            let y = 0;

            y = await promisifiedCumulativeBills()

            let x = 0;

            x = await promisifiedCumulativePayments()

            let message = `Hi ${getName(acc)}.KES:${amount} was well received.Balance Due KES:${y - x}.Paybill ${matchPaybill(acc)} A/C ${acc}.Thank you for choosing ${state.userData?.waterServiceName}.Helpline ${state.userData?.phonenumber}`;

            axios.post("/sms/mobitech-sms", {
                to: processPhoneNumber(phonenumber),
                sms: message,
                parent: state.userData._id,
                saveable: true,
                account: acc
            }).then(function(res){
                if(res.status === 200){
                    toast.success("An sms was send to the customer")
                }
            }).catch(function(err){
                toast.error("An issue occured while sending the sms");
            })
        }
    }

    function convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)
      
        return array.map(it => {
          return Object.values(it).toString()
        }).join('\n')
      }

    const downloadCSV = (arr, filename="payments-with-issues.csv") => {
        let csv = convertToCSV(arr);

        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = filename;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const downloadAllIssues = () => {
        downloadCSV(withIssues);
    }

    const downloadIssuesAndDelete = () => {
        swal("Are you sure you want to download and delete payment records with issues? This action cannot be reverted",{
            buttons: {
                cancel: 'Cancel',
                continue: {
                    text: "Continue",
                    value: "1"
                }
            }
        }).then(async (val) => {
            if(val === "1"){
                var arr = [...withIssues];

                for(var i=0; i<arr.length; i++){
                    axios.post("/payments/delete", {id: arr[i]._id}, {
                        headers: {
                            Authorization: `Bearer ${state.userToken}`
                        }
                    }).then(function(res){
                        if(res.status === 200){
                            // continue
                        }
                    }).catch(function(err){
                        console.log(err);
                        toast.error("An issue occured while deleting a record");
                    })
                }

                downloadCSV(arr);

                setRefresh(!refresh);

            }
        })
    }
    

    const theme = useMantineTheme();

    const rows = payments.filter((item) => {
        if((item?.first_name && item?.first_name !== "") || (item?.account && item?.account !== "")){
            if( item?.first_name.toLowerCase().includes(name) || item?.account.toLowerCase().includes(name)){
                return item
            }
        }
    }).map((item, index) => {
        var param = matchAccountWithCustomer(item.account.trim());
        var encodedParam = encodeURIComponent(param);
        return (
            <tr key={`payment-${index}`}>
                <td>
                    {index + 1}
                </td>
                <td>
                <Anchor target="_blank" component={"a"} href={`/app/customers/${encodedParam}`}>{item.first_name === "" ? getName(item.account.trim()).trim() : item.first_name.toUpperCase()}
                {matchAccountWithCustomer(item.account.trim()) === "#" ? (
                <span className="relative flex h-1 w-2">
                <span
                className="absolute inline-flex h-1 w-1 animate-ping rounded-full bg-red-400 opacity-75"
                ></span>
                <span
                className="relative inline-flex h-1 w-1 rounded-full bg-red-500"
                ></span>
            </span>
                ) : null}
                </Anchor>
                </td>
                <td>
                {item.account}
                </td>
                <td>
                {item.transaction_id}
                </td>
                <td>
                    {item.business_short_code === "" ? item.transaction_type : item.business_short_code}
                </td>
                <td>
                    {item.transaction_amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </td>
                <td>
                    {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td>
                    <Group noWrap>
                        <Button radius={28} size="xs" variant="default" leftIcon={<Edit size={13} />} onClick={() => {openUpdateDrawer(item._id)}}>Update</Button>
                        <Button radius={28} size="xs" variant="default" leftIcon={<ArrowUpRight size={13} />} component={"a"} target="_blank" href={`/app/customers/${matchAccountWithCustomer(item.account)}?tab=payments&tk=${state.userData._id}`}>More</Button>
                    </Group>
                </td>
            </tr>
        )
    })

    const rows_with_issues = withIssues.map((item, index) => {
        return (
            <tr key={`payment-${index}`}>
                <td>
                    {index + 1}
                </td>
                <td>
                <Anchor className="mr-1 flex" target="_blank" component={"a"} href={`${encodeURIComponent(matchAccountWithCustomer(item.account))}?tab=payments&tk=${state.userData._id}`}>{item.first_name === "" ? getName(item.account) : item.first_name.toUpperCase()}
                {matchAccountWithCustomer(item.account) === "#" ? (
                <span className="relative flex h-1 w-2">
                <span
                className="absolute inline-flex h-1 w-1 animate-ping rounded-full bg-red-400 opacity-75"
                ></span>
                <span
                className="relative inline-flex h-1 w-1 rounded-full bg-red-500"
                ></span>
            </span>
                ) : null}
                </Anchor>
                </td>
                <td>
                {item.account}
                </td>
                <td>
                {item.transaction_id}
                </td>
                <td>
                    {item.business_short_code === "" ? item.transaction_type : item.business_short_code}
                </td>
                <td>
                    {item.transaction_amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </td>
                <td>
                    {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td>
                <Group noWrap>
                        <Button radius={28} size="xs" variant="default" leftIcon={<Edit size={13} />} onClick={() => {openUpdateDrawer(item._id)}}>Update</Button>
                        <Button radius={28} size="xs" variant="default" leftIcon={<Download size={13} />} onClick={() => {askDelete(item._id)}}>Delete</Button>
                    </Group>
                </td>
            </tr>
        )
    });

        // Function to determine the mode description
        function getModeDescription(mode) {
            switch (mode) {
                case "disconnection":
                    return "Suspension/Reconnection";
                case "invoice defaulting":
                    return "Late Payment";
                case "repairs":
                    return "Repairs";
                case "illegal connection":
                    return "Illegal Connection";
                case "registration":
                    return "Registration";
                case "meter deposit":
                    return "Meter Deposit";
                case "sms":
                case "batch-download":
                case "email":
                    return "Monthly Bill";
                default:
                    return "Others";
            }
        }

    const downloadPayments = () => {
        var t_id = toast.loading(`Preparing payments for ${c}...`);
        
        axios.post("/payments/utility-payments", {
            period: c,
            parent: state.userData._id,
            size: count,
            page: 1,
            date: dates?.starts,
            month: parseCycle(c).month,
            year: parseInt(c.split(" ")[1])
        })
        .then(function(res) {
            if (res.status === 200) {
                if (res.data.data.length > 0) {
                    var arr = res.data.data;
    
                    if (Array.isArray(arr)) {
                        // Pre-process the data to create a new array with desired columns
                        const processedData = arr.map(item => ({
                            Name: item.first_name,
                            Account: item.account,
                            Amount: item.transaction_amount,
                            Till: item.business_short_code,
                            ID: item.transaction_id,
                            Type: item.transaction_type === "Settlement" ? item.transaction_type +"-"+ item.settlement_description : item.transaction_type,
                            Category: getModeDescription(item.mode), // Use a function to determine mode
                            Date: new Date(item.createdAt).toLocaleDateString(),
                        }));
    
                        downloadCSV(processedData, `${c}-payments.csv`);
                        toast.dismiss(t_id);
                    } else {
                        toast.dismiss(t_id);
                    }
                } else { 
                    toast.dismiss(t_id);
                }
            }
        })
        .catch(function(error) {
            console.log(error);
            // setLoading(false);
            toast.error("An issue occurred while preparing payments for download, please check your internet connectivity", {
                id: t_id
            });
        });
    }

    const { height } = useViewportSize();

    return (
        <Paper p="md">
        
        <Helmet>
            <meta charSet='utf-8' />
            <title>{state.userData.waterServiceName} - Payments Management Console</title>
        </Helmet>
        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Emita end-to-end pipeline is sequential. That means that meter readings have to be taken and bills delivered to have a list of payments. Although some payments may be received in-sequentially, they will not be displayed.
        </Alert>
        <Group mt={20} mb={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Payments</Heading>
      </Group>
      <Drawer size={"xl"} opened={drawer_opened} onClose={() => {setDrawerOpened(false)}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Update Payment</Heading>
            {state.userData?.mfa?.enabled ? (
                fa_step === 0 || mfa_session === "0" ? (
                    <div>
                    <Text mb={10}>
                        This account is protected by 2FA. Enter the six-digit code generated by the authenticator app to verify yourself.
                    </Text>
                    <Group mb={30}>
                    <OTPInput inputStyles={{width: 37, color: "#000000"}} value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={otp_loading} />
                    <Button disabled={!otp_active} loading={otp_loading} onClick={() => {verifyOTP()}}>Verify Token</Button>
                    </Group>

                    </div>
                ) : (
                    <>
                    <Text mb={30}>Disclaimer: Make sure that you update customer payment information that you really intend to update. Updating this information may have consequences on other items.</Text>
                    <TextInput mt="md" label="Account" value={account} onChange={(e) => {setAccount(e.currentTarget.value)}} />
                    <Select required mt={"lg"} label="Payment Category" onChange={(val) => {setMode(val)}} value={mode} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />
                </>
                )
            ) : (
                <>
                <Text mb={30}>Disclaimer: Make sure that you update customer payment information that you really intend to update. Updating this information may have consequences on other items.</Text>
                <TextInput mt="md" label="Account" value={account} onChange={(e) => {setAccount(e.currentTarget.value)}} />
                <Select required mt={"lg"} label="Payment Category" onChange={(val) => {setMode(val)}} value={mode} data={[
                        {label: "Disconnection/Suspension/Reconnection Fee", value: "disconnection"},
                        {label: "Illegal Connection", value: "illegal connection"},
                        {label: "Repairs", value: "repairs"},
                        {label: "Monthly Water Charges/bill", value: "batch-download"},
                        {label: "Late Payment", value: "invoice defaulting"},
                        {label: "Registration", value: "registration"},
                        {label: "Meter Deposit", value: "meter deposit"},
                         {label: "Others", value: "others"},
                    ]} />
            </>
            )}
            
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} leftIcon={<X size={13} />} variant="default" onClick={() => {setDrawerOpened(false)}}>Cancel</Button>
            <Button size='sm' disabled={state.userData?.mfa?.enabled && (fa_step === 0 || mfa_session === "0") ? true : false} radius={28} onClick={() => {saveUpdates()}} loading={loading}>Update Payment</Button>
        </Group>
        </Stack>
      </Drawer>
      <Text mb={20}>You are currently viewing the most recent cycle's payments. You can always review and manage your billing cycle configurations <Anchor component={Link} to="/app/configure/dates">here</Anchor>. For single customer's payments updates, manage the invoice on the customer's profile.</Text>
      <Divider ml={-10} mr={-10} />
                <Group position="apart" noWrap style={{overflowX: "auto"}} mt={20}>
                <Group noWrap position="left" spacing={"xs"}>
                <Text size={"xs"} color="dimmed">All: <strong><span style={{color: "green"}}>{count}</span></strong></Text>
                <Text size={"xs"} color="dimmed">Issues: <strong><span style={{color: "red"}}>{issues}</span></strong></Text>
                <Menu width={220}>
                    <Menu.Target>
                        <Button>Select Action...</Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item icon={show_with_issues ? <IconCircuitSwitchClosed /> : <CircuitSwitchOpen />}  disabled={withIssues.length === 0} onClick={() => {setShowWithIssues(!show_with_issues)}}>{show_with_issues ? "Show all payments" : "Show payments with issues"}</Menu.Item>
                        <Menu.Item disabled={withIssues.length === 0} icon={<Download size={13} />} onClick={() => {downloadAllIssues()}}>Download all with issues</Menu.Item>
                        <Menu.Item disabled={withIssues.length === 0} icon={<Trash color="red" size={13} />} onClick={() => {downloadIssuesAndDelete()}}>Download all with issues & Delete</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                <Button disabled={count < 1} onClick={() => {downloadPayments()}} leftIcon={<FileExport />}>Export All</Button>
                </Group>
                <Group position="right" spacing={"xs"} noWrap>
                {/*<Button leftIcon={<Plus />} onClick={() => {setOpened(true)}}>Insert Payment</Button>*/}
                <Select placeholder="Filter payments by cycles" value={c} onChange={(val) => {setC(val)}} data={cycles} />
                <Select value={size.toString()} onChange={(val) => {setSize(parseInt(val))}} data={[
                    {label: "10", value: "10"},
                    {label: "25", value: "25"},
                    {label: "50", value: "50"},
                    {label: "100", value: "100"},
                    {label: "250", value: "250"},
                    {label: "500", value: "500"},
                    {label: "1000", value: "1000"},
                    {label: "5000", value: "5000"}
                ]} />
                </Group>
            </Group>
            <Space h="xs" />
            <Divider />
            <Space h="xs" />
            <SearchField hasSearchButton={false} hasSearchIcon inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' placeholder="Find customers by name" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}} />
            <Space h="xs" />

                <div style={{overflowX: 'auto'}}>
                    <Table fontSize="xs"  style={{borderBottom: '1px solid #E9ECEF'}}>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Account
                                </th>
                                <th>
                                    Transaction ID
                                </th>
                                <th>
                                    Short Code
                                </th>
                                <th>
                                    Amount Paid
                                </th>
                                <th>
                                    Date Paid
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{height: payments.length > 0 ? 200 : '100%'}}>
                            {payments.length === 0 && !loading ? (
                                <tr>
                                    <td colSpan={9}>
                                        <Center>
                                            <Text>No payments were found</Text>
                                        </Center>
                                    </td>
                                </tr>
                            ) : payments.length > 0 && !loading && show_with_issues ?  rows_with_issues : payments.length > 0 && !loading && !show_with_issues ? rows : (
                                <tr>
                                    <td colSpan={9}>
                                    <Center mt="10%" mb="10%">
                                            <div
                                    className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                                    role="status">
                                    <span
                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                    >
                                    Loading...
                                    </span>
                                    </div>
                                        </Center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <Group mt={20} position="right">
                <Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>
        </Paper>
    )
}