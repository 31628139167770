import { useState, useContext, useCallback, useEffect } from 'react';
import {
  AppShell,
  Header,
  useMantineTheme,
  Title,
  createStyles,
  Group,
  ActionIcon,
  Container,
  Text,
  Button,
  Menu,
  MediaQuery,
  Anchor,
  Center,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { MapContainer, LayersControl, TileLayer, useMapEvents, useMapEvent, Marker} from "react-leaflet";
import { Map, Check, ArrowLeft,} from 'tabler-icons-react';
import { AuthContext } from '../../App.js';
import axios from '../../utils/axios.js';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    backgroundColor: theme.colors.blue[9],
  },

  inner: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  navbar: {
      paddingTop: 0,
    },
  
    section: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      marginBottom: theme.spacing.md,
  
    },
  
    searchCode: {
      fontWeight: 700,
      fontSize: 10,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
      }`,
    },
  
    mainLinks: {
      paddingLeft: theme.spacing.md - theme.spacing.xs,
      paddingRight: theme.spacing.md - theme.spacing.xs,
      paddingBottom: theme.spacing.md,
    },
  
    mainLink: {
      display: 'flex',
      cursor: 'text',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.fontSizes.xs,
      padding: `8px ${theme.spacing.xs}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    mainLinkInner: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  
    mainLinkIcon: {
      marginRight: theme.spacing.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    },
  
    mainLinkBadge: {
      padding: 0,
      width: 20,
      height: 20,
      pointerEvents: 'none',
    },
  
    collections: {
      paddingLeft: theme.spacing.md - 6,
      paddingRight: theme.spacing.md - 6,
      paddingBottom: theme.spacing.md,
    },
  
    collectionsHeader: {
      paddingLeft: theme.spacing.md + 2,
      paddingRight: theme.spacing.md,
      marginBottom: 5,
    },

    root: {
      position: 'relative',
      '& *': {
        cursor: 'pointer',
      },
    },
  
    collectionLink: {
      display: 'block',
      padding: `8px ${theme.spacing.xs}px`,
      textDecoration: 'none',
      cursor: 'text',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.xs,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      lineHeight: 1,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },

    animalBody: {
      paddingLeft: 54,
      paddingTop: theme.spacing.sm,
    },
}));

export default function ServiceAreaDashboard() {
  const theme = useMantineTheme();
  const { state, dispatch } = useContext(AuthContext);
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const [basemap, setBasemap] = useState("CartoDB");
  const [zoom, setZoom] = useState(3);
  const [latlng, setLatLng] = useState(null);
  const [loading, setLoading] = useState(false);
  const [with_centroid, setWithCentroid] = useState(false);
  const [centroid, setCentroid] = useState([-1.2641141046016717, 36.82277094518902])

  useEffect(() => {
    const config = {
      headers: {
        'Authorization': `Bearer ${state.userToken}`
      }
    };

    axios.get("/centroids/get", config).then(function(res){
      if(res.status === 200){
        if(res.data.data === null){
            setWithCentroid(false);
        } else {
            setCentroid([res.data.data.latitude, res.data.data.longitude])
            setWithCentroid(true);
        }
      }
    }).catch(function(error){
      toast.error("Could not fetch your service area centroid coordinates", {
        position: 'bottom-right'
      })
    });

  }, []);

  const updateCentroid = () => {
    setLoading(true);

    const config = {
        headers: {
          'Authorization': `Bearer ${state.userToken}`
        }
      };

    const body = {
        id: state.userData._id,
        update: {
            longitude: latlng?.lng,
            latitude: latlng?.lat
        }
    };

    axios.patch("/centroids/update", body, config).then(function(res){
        if(res.status === 200){
            setLoading(false);
            setLatLng(null)
            navigate(`/app?utility_id=${state.userData._id}&tab=basic&version=v1`);
        }
    }).catch(function(error){
        toast.error("Something wrong happened while saving your updates!");
        setLoading(false);
    })
  }

 const ClickHandler = () => {
    const onClick = useCallback(
        (e) => {
          setLatLng(e.latlng);
        },
        [],
      )
      useMapEvent('click', onClick);

      return null;
 }

 const navigate = useNavigate();

 const createCentroid = () => {
    setLoading(true);

    const body = {
        parent: state.userData._id,
        longitude: latlng?.lng,
        latitude: latlng?.lat
    };

    const config = {
        headers: {
            'Authorization': `Bearer ${state.userToken}`
        }
    };

    axios.post("/centroids/create", body, config).then(function(res){
        if(res.status === 200){
            setLoading(false);
            setLatLng(null)
            navigate(`/app?utility_id=${state.userData._id}&tab=basic&version=v1`);
        }
    }).catch(function(error){
        toast.error("Something wrong happened while saving the coordinates");
        setLoading(false);
    })
 }

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : "white",
          padding: 0,
          paddingTop: 50
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <Header height={50} className={classes.header}>
          <div className={classes.inner}>
            <Group >
              <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
              <Group>
              <Anchor component={Link} to={`/app?utility_id=${state.userData._id}&tab=basic&version=v1`}>
                    <Center inline>
                    <ActionIcon sx={(theme) => ({":hover": {backgroundColor: "transparent"}})}>
                    <ArrowLeft size={14} color='white' />
                </ActionIcon>
              <Title color='white' order={4} style={{fontWeight: "normal"}}>{state.userData?.waterServiceName + " Service Area Centroid"} </Title>
              </Center>
              </Anchor>
              </Group>
              </MediaQuery>
              <MediaQuery largerThan={"md"} styles={{display: "none"}}>
              <Title color='white' order={4} style={{fontWeight: "normal"}}>{state.userData?.waterServiceName}</Title>
              </MediaQuery>
            </Group>

            <Group ml={50} spacing={5} className={classes.links}>
              
          </Group>
          
          <Group noWrap>
            <Menu>
              <Menu.Target>
              <ActionIcon sx={(theme) => ({":hover": {backgroundColor: "transparent"}})}>
                <Map color="#ffffff" />
              </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => {setBasemap("CartoDB")}} icon={basemap === "CartoDB" ? <Check size={14} /> : null}>CartoDB</Menu.Item>
                <Menu.Item onClick={() => {setBasemap("ESRI")}} icon={basemap === "ESRI" ? <Check size={14} /> : null}>ESRI World Imagery</Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <Button onClick={() => {navigate("/app/inventory-maper")}}>Inventory Mapper</Button>
          <Button onClick={() => {dispatch({type: "SIGN_OUT"})}}>Sign Out</Button>
          </Group>
          </div>
        </Header>
      }
    >
        <MapContainer zoomControl={false} center={with_centroid ? centroid : [-1.2641141046016717, 36.82277094518902]} style={{background: "transparent", zIndex: 1, height: "100%", width: '100%', padding: 0}} zoom={zoom}>
        <LayersControl position='topright' vis >

      <LayersControl.BaseLayer checked={basemap === "CartoDB"} name='CartoDB'>
    <TileLayer
      attribution='&copy; Emita, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
      url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
    />
    </LayersControl.BaseLayer>
    <LayersControl.BaseLayer checked={basemap === "ESRI"} name='Satellite'>
    <TileLayer
      attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    />
    </LayersControl.BaseLayer>
      </LayersControl>
        {latlng && (
            <Marker position={latlng} />
        )}
        <ClickHandler />
              <Container sx={(theme) => ({marginTop: -12, marginLeft: -12, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-top leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ffff",
            width: width * 0.3,
            height: height,
            border: "none",
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >

            {latlng === null ? (
                <>
                <Title order={5} fw={600}>Usage</Title>

                <Text size={"lg"} mb={20}>
                Update the centroid of your service area. Zoom in and out of the map to find the perfect centroid of your service area, then right click your device to save the coordinates.
                </Text>
                </>
            ) : (
                <>
            <Title order={5} fw={600}>Success!</Title>

            <Text size={"lg"} mb={20}>
            The coordinates, {`[${latlng.lat}, ${latlng.lng}]`}, have been captured.
            </Text>

            <Button mb={10} loading={loading} fullWidth onClick={() => {with_centroid ? updateCentroid() : createCentroid()}}>{with_centroid ? "Update Coordinates" : "Save Coordinates"}</Button>
            <Button mb={20} variant='outline' onClick={() => {setLatLng(null)}} fullWidth>Cancel</Button>
                </>
            )}
    
            </Container>
      </Container>


        </MapContainer>
    </AppShell>
  );
}