import { Text, Group, Alert, Paper, Dialog, SimpleGrid, Anchor, useMantineTheme, Center, Divider, Grid, Table, Box, createStyles, MediaQuery, Button, Title as Title2, HoverCard, TextInput, ActionIcon, Select} from '@mantine/core';
import { AuthContext } from '../../../App';
import { useContext, useEffect, useState } from 'react';
import { Statistics } from "./Statistics";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  Interaction
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import axios from '../../../utils/axios';
import { Helmet } from 'react-helmet';
import { SelectField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ChevronDown, Download, ExternalLink, InfoCircle, MoonStars, Plus, Search, SunHigh, Sunrise,} from 'tabler-icons-react';
import { getDate, getMonth, getMonthAsNums, getYear } from '../../../utils/getMonth';
import { useDisclosure, useLocalStorage, useViewportSize } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowTrendDown, faArrowTrendUp, faChartLine, faCoins, faTriangleExclamation, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom';
import { createSewerCost, createWaterCost } from '../../../utils/createWaterCost';
import billEfficiencyTrend, { billEfficiency } from '../../../utils/billEfficiency';
import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
import { IconArrowDownLeft, IconArrowUpRight, IconInfoCircle } from '@tabler/icons';
import {  DEFAULT_METER_READING_CYCLE } from '../../../constants';
import ReactGA from "react-ga4";
import { greetUser } from '../../../utils/greetUser';
import { nFormatter } from '../../../utils/formatMoney';
import { HEADER_HEIGHT } from '../../dashboard/layout/header/header.style';
import diffInMonths from '../../../utils/monthsToNow';
import getCycles from '../../../utils/getCycles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  CrosshairPlugin
);

Interaction.modes.interpolate = Interpolate

const options = {
  borderWidth: 2,
  pointRadius: 1,
  plugins: {
    tooltip: {
      mode: 'interpolate',
      intersect: false
    },
    crosshair: {
      line: {
          color: '#F66',  // crosshair line color
          width: 1        // crosshair line width
        },
      sync: {
          enabled: false,            // enable trace line syncing with other charts
          group: 1,                 // chart group
          suppressTooltips: false   // suppress tooltips when showing a synced tracer
        },
        zoom: {
          enabled: true,                                      // enable zooming
          zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
          zoomboxBorderColor: '#48F',                         // border color of zoom box
          zoomButtonText: 'Reset Zoom',                       // reset zoom button text
          zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
    },
    legend: {
      display: false,
      position: 'top',
    },

    title: {
      display: false,
      text: 'E-mita system summary',
    },
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      ticks: {
        display: false
      },
      grid: {
        display: false
      }
    }
  }
};

const useStyles = createStyles((theme) => ({
  root: {
    padding: 0,
    marginBottom: 20,
  },

  value: {
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    color: theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase"
  },
}));


function Analytics() {
  const { classes } = useStyles();
  const { width } = useViewportSize();
  const { state } = useContext(AuthContext);
  const [dmas, setDMAs] = useState([]);
  const [opened, { close }] = useDisclosure(true);
  const now = new Date();
  now.setDate(1);
  const [date, setDate] = useState(new Date());
  const [production, setProduction] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [jan, setJan] = useState(0);
  const [feb, setFeb] = useState(0);
  const [mar, setMar] = useState(0);
  const [apr, setApr] = useState(0);
  const [may, setMay] = useState(0);
  const [jun, setJun] = useState(0);
  const [jul, setJul] = useState(0);
  const [aug, setAug] = useState(0);
  const [sep, setSep] = useState(0);
  const [oct, setOct] = useState(0);
  const [nov, setNov] = useState(0);
  const [dec, setDec] = useState(0);
  const [jann, setJann] = useState(0);

  const [jan1, setJan1] = useState(0);
  const [feb1, setFeb1] = useState(0);
  const [mar1, setMar1] = useState(0);
  const [apr1, setApr1] = useState(0);
  const [may1, setMay1] = useState(0);
  const [jun1, setJun1] = useState(0);
  const [jul1, setJul1] = useState(0);
  const [aug1, setAug1] = useState(0);
  const [sep1, setSep1] = useState(0);
  const [oct1, setOct1] = useState(0);
  const [nov1, setNov1] = useState(0);
  const [dec1, setDec1] = useState(0);
  const [jann1, setJann1] = useState(0);

  // individual consumption
  const [jan2, setJan2] = useState(0);
  const [feb2, setFeb2] = useState(0);
  const [mar2, setMar2] = useState(0);
  const [apr2, setApr2] = useState(0);
  const [may2, setMay2] = useState(0);
  const [jun2, setJun2] = useState(0);
  const [jul2, setJul2] = useState(0);
  const [aug2, setAug2] = useState(0);
  const [sep2, setSep2] = useState(0);
  const [oct2, setOct2] = useState(0);
  const [nov2, setNov2] = useState(0);
  const [dec2, setDec2] = useState(0);
  const [jann2, setJann2] = useState(0);

  // public consumption
  const [jan3, setJan3] = useState(0);
  const [feb3, setFeb3] = useState(0);
  const [mar3, setMar3] = useState(0);
  const [apr3, setApr3] = useState(0);
  const [may3, setMay3] = useState(0);
  const [jun3, setJun3] = useState(0);
  const [jul3, setJul3] = useState(0);
  const [aug3, setAug3] = useState(0);
  const [sep3, setSep3] = useState(0);
  const [oct3, setOct3] = useState(0);
  const [nov3, setNov3] = useState(0);
  const [dec3, setDec3] = useState(0);
  const [jann3, setJann3] = useState(0);

  // water kiosks
  const [jan4, setJan4] = useState(0);
  const [feb4, setFeb4] = useState(0);
  const [mar4, setMar4] = useState(0);
  const [apr4, setApr4] = useState(0);
  const [may4, setMay4] = useState(0);
  const [jun4, setJun4] = useState(0);
  const [jul4, setJul4] = useState(0);
  const [aug4, setAug4] = useState(0);
  const [sep4, setSep4] = useState(0);
  const [oct4, setOct4] = useState(0);
  const [nov4, setNov4] = useState(0);
  const [dec4, setDec4] = useState(0);
  const [jann4, setJann4] = useState(0);

  const [defaultView, setDefaultView] = useState("system");

  const [, setStaff] = useState(0);

  const [, setW] = useState(600);

  const [customer_details, setCustomerDetails] = useState([]);
  const [customers, setCustomers] = useState(0);

  const [, setCurrentProd] = useState(0);
  const [, setPreviousProd] = useState(0);

  const [, setCurrentCons] = useState(0);
  const [, setPreviousCons] = useState(0);

  const [dates, setDates] = useState(null);

  const [zones_data, setZonesData] = useState([]);
  const [zones_data2, setZonesData2] = useState([]);

  // highest NRW zone
  const [zone, setZone] = useState("N/A");
  const [total, setTotal] = useState(0);
  const [prior_trend, setPriorTrend] = useState(0);
  const [zone_prod, setZoneProd] = useState(0);
  const [zone_prod_trend, setZoneProdTrend] = useState(0);
  const [zone_cons, setZoneCons] = useState(0);
  const [zone_cons_trend, setZoneConsTrend] = useState(0);

  const [tarrifs, setTarrifs] = useState([]);
  const [standing_charges, setStandingCharges] = useState(0);
  const [sewer_tarrifs, setSewerTarrifs] = useState([]);
  // payments
  const [, setForecastedAmount] = useState(0);
  const [billing_efficiency_summary_trend, setBillingEfficiencySummaryTrend] = useState(0);

  // cumulative payments
  const [cumulativePayments, setCumulativePayments] = useState(0);

  // cumulative bills
  const [cumulativeBills, setCumulativeBills] = useState(0);

  // revenue - net
  const [jan5, setJan5] = useState(0);
  const [feb5, setFeb5] = useState(0);
  const [mar5, setMar5] = useState(0);
  const [apr5, setApr5] = useState(0);
  const [may5, setMay5] = useState(0);
  const [jun5, setJun5] = useState(0);
  const [jul5, setJul5] = useState(0);
  const [aug5, setAug5] = useState(0);
  const [sep5, setSep5] = useState(0);
  const [oct5, setOct5] = useState(0);
  const [nov5, setNov5] = useState(0);
  const [dec5, setDec5] = useState(0);
  const [jann5, setJann5] = useState(0);

  // revenue - gross
  const [jan6, setJan6] = useState(0);
  const [feb6, setFeb6] = useState(0);
  const [mar6, setMar6] = useState(0);
  const [apr6, setApr6] = useState(0);
  const [may6, setMay6] = useState(0);
  const [jun6, setJun6] = useState(0);
  const [jul6, setJul6] = useState(0);
  const [aug6, setAug6] = useState(0);
  const [sep6, setSep6] = useState(0);
  const [oct6, setOct6] = useState(0);
  const [nov6, setNov6] = useState(0);
  const [dec6, setDec6] = useState(0);
  const [jann6, setJann6] = useState(0);

  const [bills, setBills] = useState([]);
  const [payments, setPayments] = useState([]);
  const [revenue_by_zones, setRevenueByZones] = useState([]);
  const [, setRawCustomers] = useState([]);
  const [expected, setExpected] = useState(0);
  const [received, setReceived] = useState(0);

  let diff_in_months = diffInMonths(new Date(state.userData.createdAt));

  let [c, setC] = useState(getCycles(diff_in_months)[0].value);

  const [months_num, setMonthsNum] = useLocalStorage({
    key: "emita-analytics-range",
    defaultValue: "3",
    getInitialValueInEffect: true
  });

  let cycles = getCycles(diff_in_months);

  const months = getMonthAsNums();
  let years = getYear();

  // charts options
  const [chart1, setChart1] = useLocalStorage({
    key: "emita-chart-type",
    defaultValue: "line",
    getInitialValueInEffect: true
  });
  const [, setNewCustomers] = useState(0);

  function getCustomers(){
    axios.get("/customers/export", {
        headers: {
            'Authorization': `Bearer ${state.userToken}`
        }
    }).then(function(res){
        if(res.status === 200){
            setRawCustomers(res.data.data);
        }
    }).catch(function(error){
        console.log(error);
    })
}

useEffect(() => {
  getCustomers()
}, [])

  useEffect(() => {
    axios.post("/payments/cumulative-payments", {
      parent: state.userData._id
    }).then(function(res){
      if(res.status === 200){
        if(res.data.data.length > 0){
          setCumulativePayments(res.data?.data[0]?.totalAmount)
        }
      } else{
        console.log(res)
      }
    }).catch(function(error){
      console.log(error);
    })
  }, []);

  useEffect(() => {
    axios.post("/master-invoices/cumulative", {
      parent: state.userData._id
    }).then(function(res){
      if(res.status === 200){
        if(res.data.data.length > 0){
          setCumulativeBills(res.data?.data[0]?.total);
        }
      }
    }).catch(function(error){
      console.log(error);
    })
  }, [])

  const fetchDates = () => {
    axios.post("/dates/get-dates", {
        parent: state.userData._id
    }).then(function(res){
        if(res.status === 200){
            let d = res.data.data;
            if(d !== null){
              setDates(d);
            } else {
              setDates(DEFAULT_METER_READING_CYCLE);
            }
        }
    }).catch(function(error){
        console.log(error);
    })
} 

useEffect(() => {
    fetchDates()
}, []);

  useEffect(() => {
    axios.post("/customers/getCustomers", {
      parent: state.userData._id
    }).then(function(res){
      if(res.status === 200){
        setCustomerDetails(res.data.data);
        setCustomers(res.data.count);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        let sum = 0;

        for(let i=0; i<res.data.data.length; i++){
          let item = res.data.data[i];

          if(new Date(item.createdAt).getMonth() === month && (new Date(item.createdAt).getFullYear() === year)){
            sum += 1;
            continue;
          }
          
        }
        setNewCustomers(sum);
      }
    }).catch(function(error){
      console.log(error);
    })
  }, []);

  useEffect(() => {
    axios.post("/tarrifs/gettarrifs", {parent: state.userData._id}).then(({ data: { data: x }, }) => {
      setTarrifs(x);
  }).catch(function(error){
    console.log(error);
  });

  axios.post("/standing-charges/get-standing-charge", {parent: state.userData._id}).then(function (res) {
    if (res.status === 200) {
      let arr = res.data.data;
      let total_cost = 0;
      for (let i = 0; i < arr.length; i++) {
        total_cost += arr[i].cost
      }
      setStandingCharges(parseFloat(total_cost.toFixed(2)));
    }
  }).catch(function (error) {
    console.log(error);
  });

  axios.post("/sewer-tarrifs/gettarrifs", {parent: state.userData._id}).then(({ data: { data: x }, }) => {
    setSewerTarrifs(x);
  }).catch(function (error) {
    console.log(error);
  })

  axios.post("/bills/all", {parent: state.userData._id}).then(function(res){
    if(res.status === 200){
      setBills(res.data.data);
    }
  }).catch(function (error) {
    console.log(error);
  })

  axios.post("/payments/all", {parent: state.userData._id}).then(function(res){
    if(res.status === 200){
      setPayments(res.data.data);
    }
  }).catch(function (error) {
    console.log(error);
  })

  }, [])


  const createCost = (qty, t) => {
    if(tarrifs.length === 0){
        return 0
    }

    if(qty <= 0){
        return 0;
    }

    let idx = tarrifs.findIndex((obj => obj.tarrif == t));
    if(idx === -1){
        return 0
    }
    let item = tarrifs[idx];
    let blocks = item.blocks;

    return createWaterCost(parseFloat(qty), blocks, standing_charges);
}

const createSwrCost = (qty, t) => {
  if(sewer_tarrifs.length === 0){
      return 0;
  }

  if(qty <= 0){
      return 0;
  }

  let idx = sewer_tarrifs.findIndex((obj => obj.tarrif == t));
  if(idx === -1){
      return 0;
  }

  let item = sewer_tarrifs[idx];
  let blocks = item.blocks;

  return createSewerCost((0.7 * parseFloat(qty)), blocks);
}

useEffect(() => {
  if(customer_details.length > 0 && tarrifs.length > 0){
    let sum = 0;
    for(let i=0; i<customer_details.length; i++){
      let cust = customer_details[i];

      let water_cost = createCost((cust.last_reading - cust.previous_reading), cust.tarrif);

      let sewer_cost = cust.sewer ? createSwrCost((cust.last_reading - cust.previous_reading), cust.tarrif) : 0;

      let total = water_cost + sewer_cost + standing_charges;

      sum += total;
    }

    setForecastedAmount(sum);
  }
}, [customer_details, tarrifs])

  useEffect(() => {
    setW(width);
  }, [width])

  useEffect(() => {
    setDate(new Date());
  }, [])

const fetchStaff = () => {
  const body = {
    parent: state.userData._id
  };

  axios.post("/staff/getstaffs", body).then(function(res){
      setStaff(res.data.data.length)
  }).catch(function(error){
      console.error(error.message);
  })
}

  useEffect(() => {
    fetchStaff();
  }, [])

  /**
   * TODO:: implement charts
   */
useEffect(() => {
  setConsumption(0);
  const config = {
    headers: {
      'Authorization': `Bearer ${state.userToken}`
    },
    params: {
      parent: state.userData._id
    }
  }
  axios.get("/consumption/get",config).then(function(res){
    if(res.status === 200){
      setConsumption(res.data.data)
    }
  }).catch(function(error){
    //toast.error("Something wrong is happening!");
    console.log(error);
  })
}, []);

useEffect(() => {
  try {
    axios.post("/productions/get", {
      parent: state.userData._id
    }).then(function(res){
      if(res.status === 200){
        setProduction(res.data.data);
      }
    })
  } catch(error){
    console.log(error.message);
  }
}, []);

useEffect(() => {
     const fetchDMAs = () => {
      try{
        axios.post("/dmas/getdmas", {
          username: state.userData._id
        }).then(function(res){
          if(res.status === 200){
            let arr = [{label: "System", value: "system"}];
            for(let i=0; i<res.data.data.length; i++){
              let item = res.data.data[i];
              arr.push({label: item.name, value: item.name})
            }

            setDMAs(arr);
          }
        })
      } catch(error){
        console.log(error);
      }
    }

    fetchDMAs();
}, [])

useEffect(() => {

const funcA = async() => {
  setJan(0);
  setFeb(0);
  setMar(0);
  setApr(0);
  setMay(0);
  setJun(0);
  setJul(0);
  setAug(0);
  setSep(0);
  setOct(0);
  setNov(0);
  setDec(0);
  setJann(0);


  let today = new Date();

  const legacy_month = today.getMonth() - 1;
  const legacy_year = today.getFullYear();

  let consumption2 = null;

  if(defaultView !== "system"){
    let customers = customer_details.filter((obj) => (obj.dma === defaultView));

    consumption2 = consumption.filter((data) => {
      const customer = customers.find(cust => cust.account === data.meter);
      return customer !== undefined
    })


  } else {
    consumption2 = consumption
  }

  for(let i=0; i<months.length; i++){
    for(let k=0; k<years.length; k++){
      if(i === k){
        let month = months[i] - 1;
        let year = years[k];
      
        let filteredData = consumption2.filter((obj) => (new Date(obj.createdAt).getMonth() === month) && (new Date(obj.createdAt).getFullYear() === year));
        
        if(filteredData.length > 0){

          let cons = filteredData.reduce((sum, obj) => sum + (obj.currReading - obj.prevReading), 0);

          if(month === legacy_month && year === legacy_year && defaultView ==="system"){
            setCurrentCons(cons);
          }

          switch(i){
            case 0:
              setJan(cons);
              break;
            case 1:
              setFeb(cons);
              break;
            case 2:
              setMar(cons);
              break;
            case 3:
              setApr(cons);
              break;
            case 4:
              setMay(cons);
              break;
            case 5:
              setJun(cons);
              break;
            case 6:
              setJul(cons);
              break;
            case 7:
              setAug(cons);
              break;
            case 8:
              setSep(cons);
              break;
            case 9:
              setOct(cons);
              break;
            case 10:
              setNov(cons);
              break;
            case 11:
              setDec(cons);
              // always the second last(previous month)
              setPreviousCons(cons);
              break;
            case 12:
              setJann(cons); 
              // always the current month
              setCurrentCons(cons)
              break;
            default:
              // handle default
          }
        }
      }
    }
  }

}

if(consumption.length > 0){
  funcA();
}

}, [consumption, defaultView, customer_details]);

useEffect(() => {
  const funcD = () => {
    let arr = [];
    let booster = 0;

    if(dates?.duration < 14){
      booster += 14
    }
    let today = new Date();
    let startsOn = dates?.starts;
    let duration = dates?.duration + booster;
    let review_window = dates?.reviewWindow;

    let currentMonth = today.getMonth() + 1;
    let currentYear = today.getFullYear();

    // Calculate the start and end dates of the current meter reading cycle
    let cycleStart;
    let cycleEnd;
    if (startsOn > today.getDate()) {
      // Cycle spans across two months
      cycleStart = new Date(currentYear, currentMonth - 2, startsOn);
      cycleEnd = new Date(currentYear, currentMonth - 2, startsOn + duration + review_window);
    } else {
      // Cycle starts and ends within the same month
      cycleStart = new Date(currentYear, currentMonth - 1, startsOn);
      cycleEnd = new Date(currentYear, currentMonth - 1, startsOn + duration + review_window);
    }

    let prev_today = new Date(cycleStart.getFullYear(), cycleStart.getMonth() - 1, startsOn);
    let prev_endsIn = new Date(cycleEnd.getFullYear(), cycleEnd.getMonth() - 1, startsOn + duration + review_window );

    for(let i=1; i<dmas.length; i++){
      let instance_dma = dmas[i].value;

      let filteredData1 = production.filter((obj) => (obj.dma === instance_dma));
      const currentProductionSum = filteredData1.reduce((sum, obj) => {
        if (new Date(obj.createdAt) >= cycleStart  && new Date(obj.createdAt) <= cycleEnd) {
          return sum + obj.production;
        }
        return sum;
      }, 0);
      const prevProductionSum = filteredData1.reduce((sum, obj) => {
        if (new Date(obj.createdAt) >= prev_today  && new Date(obj.createdAt) <= prev_endsIn) {
          return sum + obj.production;
        }
        return sum;
      }, 0);

      // consumption
      let customers = customer_details.filter((obj) => (obj.dma === instance_dma));
      const filteredData2 = consumption.filter(obj => {
        const customer = customers.find(cust => cust.account === obj.meter);
        return customer !== undefined;
      });
      
      const currentConsumptionSum = filteredData2.reduce((sum, obj) => {
        if (new Date(obj.createdAt) >= cycleStart  && new Date(obj.createdAt) <= cycleEnd) {
          return sum + (obj.currReading - obj.prevReading);
        }
        return sum;
      }, 0);

      const previousConsumptionSum = filteredData2.reduce((sum, obj) => {
        if (new Date(obj.createdAt) >= prev_today  && new Date(obj.createdAt) <= prev_endsIn) {
          return sum + (obj.currReading - obj.prevReading);
        }
        return sum;
      }, 0);

      let trend = (currentProductionSum - currentConsumptionSum) / (prevProductionSum - previousConsumptionSum)

      arr.push({dma: instance_dma, production: currentProductionSum, prevProduction: prevProductionSum, previousConsumption: previousConsumptionSum, consumption: currentConsumptionSum, loss: (currentProductionSum - currentConsumptionSum), trend: (prevProductionSum - previousConsumptionSum) < 1 ? "0.0%" : (prevProductionSum - previousConsumptionSum) < 1 && (currentProductionSum - currentConsumptionSum) > 0 ? "100%" : (trend * 100).toFixed(1)+"%"})
    }

    setZonesData(arr);
  }

  if(consumption.length > 0 && customer_details.length > 0 && dmas.length > 0){
    funcD();
  }

}, [production, consumption, customer_details, dmas]);


useEffect(() => {
  const funcE = () => {
    let arr = [];

    let today = new Date();

    const month = today.getMonth();

    const year = today.getFullYear();


    for(let i=1; i<dmas.length; i++){
      let instance_dma = dmas[i].value;

      //individuals category
      let individualCustomers = customer_details.filter((obj) => (obj.tarrif === "Domestic/Residential") && (obj.dma === instance_dma));

      let filteredData1 = consumption.filter(data => {
        let customer = individualCustomers.find((cust) => cust.account === data.meter);
        return customer !== undefined;
      });

      let sum1 = filteredData1.reduce((sum, obj) => {
        if (new Date(obj.createdAt).getMonth() === month  && new Date(obj.createdAt).getFullYear() === year) {
          return sum + (obj.currReading - obj.prevReading);
        }
        return sum;
      }, 0);

      // commercial category
      let publicCustomers = customer_details.filter((obj) => (obj.tarrif === "Government institutions" || obj.tarrif === "Government funded public schools") && (obj.dma === instance_dma));
      let filteredData2 = consumption.filter(data => {
        let customer = publicCustomers.find((cust) => cust.account === data.meter);
        return customer !== undefined
      });

      let sum2 = filteredData2.reduce((sum, obj) => {
        if (new Date(obj.createdAt).getMonth() === month  && new Date(obj.createdAt).getFullYear() === year) {
          return sum + (obj.currReading - obj.prevReading);
        }
        return sum;
      }, 0);

      // water kiosks

      let kioskCustomers = customer_details.filter((obj) => (obj.tarrif === "Water Kiosks") && (obj.dma === instance_dma));
      let filteredData3 = consumption.filter(data => {
        let customer = kioskCustomers.find((cust) => cust.account === data.meter);
        return customer !== undefined
      });

      let sum3 = filteredData3.reduce((sum, obj) => {
        if (new Date(obj.createdAt).getMonth() === month  && new Date(obj.createdAt).getFullYear() === year) {
          return sum + (obj.currReading - obj.prevReading);
        }
        return sum;
      }, 0);

      arr.push({dma: instance_dma, tarrif1: sum1, tarrif2: sum2, tarrif3: sum3});
    }

    setZonesData2(arr);
  }

  if(consumption.length > 0 && dmas.length > 0 && customer_details.length > 0){
    funcE();
  }
}, [consumption, dmas, customer_details])

useEffect(() => {
  if(zones_data.length > 0 && defaultView === "system"){
    // sort to find the highest lost
   let arr = zones_data.sort((a,b) => a.loss > b.loss ? -1 : 1);

   setZone(arr[0].dma);
   setTotal(arr[0].loss);
   setPriorTrend(arr[0].trend);
   setZoneProd(arr[0].production);

   let t = (arr[0].production - arr[0].prevProduction) / (arr[0].prevProduction);
   setZoneProdTrend(arr[0].prevProduction === 0 ? 0 : (t * 100))

   let t2 = (arr[0].consumption - arr[0].previousConsumption) / (arr[0].previousConsumption);
   setZoneConsTrend(arr[0].previousConsumption === 0 ? 0 : (t2 * 100));
   setZoneCons(arr[0].consumption);
  }
}, [zones_data, defaultView])

useEffect(() => {
  const funcC = async() => {
    setJan2(0);
    setFeb2(0);
    setMar2(0);
    setApr2(0);
    setMay2(0);
    setJun2(0);
    setJul2(0);
    setAug2(0);
    setSep2(0);
    setOct2(0);
    setNov2(0);
    setDec2(0);
    setJann2(0);

    let individualTarrif = customer_details.filter((obj) => obj.tarrif === "Domestic/Residential");

    let kioskTarrif = customer_details.filter((obj) => obj.tarrif === "Water kiosks");

    let publicTarrif = customer_details.filter((obj) => obj.tarrif === "Government institutions" || obj.tarrif === "Government funded public schools");

    let consumption2 = null;

    if(defaultView !== "system"){
    let customers = customer_details.filter((obj) => (obj.dma === defaultView));

    consumption2 = consumption.filter((data) => {
      const customer = customers.find(cust => cust.account === data.meter);
      return customer !== undefined
    })
    } else {
      consumption2 = consumption
    }

    let filteredIndividualData = consumption2.filter(obj => {
      const customer = individualTarrif.find(cust => cust.account === obj.meter)
      return customer !== undefined;
    });

    let filteredKioskData = consumption2.filter(obj => {
      const customer = kioskTarrif.find(cust => cust.account === obj.meter)
      return customer !== undefined;
    });

    let filteredPublicData = consumption2.filter(obj => {
      const customer = publicTarrif.find(cust => cust.account === obj.meter)
      return customer !== undefined;
    });
  
    for(let i=0; i<months.length; i++){
      for(let k=0; k<years.length; k++){
        if(i === k){
          let month = months[i] - 1;
          let year = years[k];
  
          let filteredData1 = filteredIndividualData.filter((obj) => (new Date(obj.createdAt).getMonth() === month) && (new Date(obj.createdAt).getFullYear() === year));
          let filteredData2 = filteredPublicData.filter((obj) => ((new Date(obj.createdAt).getMonth()) === month) && (new Date(obj.createdAt).getFullYear() === year));
          let filteredData3 = filteredKioskData.filter((obj) => ((new Date(obj.createdAt).getMonth() ) === month) && (new Date(obj.createdAt).getFullYear() === year));

          if(filteredData1.length > 0){
  
            let cons1 = filteredData1.reduce((sum, obj) => sum + (obj.currReading - obj.prevReading), 0);
            let cons2 = filteredData2.reduce((sum, obj) => sum + (obj.currReading - obj.prevReading), 0);
            let cons3 = filteredData3.reduce((sum, obj) => sum + (obj.currReading - obj.prevReading), 0);

            switch(i){
              case 0:
                setJan2(cons1);
                setJan3(cons2);
                setJan4(cons3);
                break;
              case 1:
                setFeb2(cons1);
                setFeb3(cons2);
                setFeb4(cons3);
                break;
              case 2:
                setMar2(cons1);
                setMar3(cons2);
                setMar4(cons3);
                break;
              case 3:
                setApr2(cons1);
                setApr3(cons2);
                setApr4(cons3);
                break;
              case 4:
                setMay2(cons1);
                setMay3(cons2);
                setMay4(cons3);
                break;
              case 5:
                setJun2(cons1);
                setJun3(cons2);
                setJun4(cons3);
                break;
              case 6:
                setJul2(cons1);
                setJul3(cons2);
                setJul4(cons3);
                break;
              case 7:
                setAug2(cons1);
                setAug3(cons2);
                setAug4(cons3);
                break;
              case 8:
                setSep2(cons1);
                setSep3(cons2);
                setSep4(cons3);
                break;
              case 9:
                setOct2(cons1);
                setOct3(cons2);
                setOct4(cons3);
                break;
              case 10:
                setNov2(cons1);
                setNov3(cons2);
                setNov4(cons3);
                break;
              case 11:
                setDec2(cons1);
                setDec3(cons2);
                setDec4(cons3);
                break;
              case 12:
                setJann2(cons1);
                setJann3(cons2);
                setJann4(cons3);
                break;
              default:
                // handle default
            }
          }
        }
      }
    }
  
  }

  if(consumption.length > 0 && customer_details.length > 0){
    funcC();
  }
}, [consumption, customer_details, defaultView])

useEffect(() => {
  setJan1(0);
  setFeb1(0);
  setMar1(0);
  setApr1(0);
  setMay1(0);
  setJun1(0);
  setJul1(0);
  setAug1(0);
  setSep1(0);
  setOct1(0);
  setNov1(0);
  setDec1(0);
  setJann1(0);

  const funcB = () => {
    let today = new Date();

    const legacy_month = today.getMonth() - 1;
    const legacy_year = today.getFullYear();

    let production2 = null;

    if(defaultView !== "system"){
      production2 = production.filter((data) => (data.dma === defaultView))
    } else {
      production2 = production
    }

    for(let i=0; i<months.length; i++){
      for(let k=0; k<years.length; k++){
        if(i === k){
          let month = months[i] - 1;
          let year = years[k];
  
          let filteredData = production2.filter((obj) => (new Date(obj.createdAt).getMonth() === month && new Date(obj.createdAt).getFullYear() === year ));
  
          if(filteredData.length > 0){
  
            let p = filteredData.reduce((sum, obj) => sum + obj.production, 0);

            if(month === legacy_month && year === legacy_year && defaultView === "system"){
              setCurrentProd(p);
            }

            switch(i){
              case 0:
                setJan1(p);
                break;
              case 1:
                setFeb1(p);
                break;
              case 2:
                setMar1(p);
                break;
              case 3:
                setApr1(p);
                break;
              case 4:
                setMay1(p);
                break;
              case 5:
                setJun1(p);
                break;
              case 6:
                setJul1(p);
                break;
              case 7:
                setAug1(p);
                break;
              case 8:
                setSep1(p);
                break;
              case 9:
                setOct1(p);
                break;
              case 10:
                setNov1(p);
                break;
              case 11:
                setDec1(p);
                setPreviousProd(p);
                break;
              case 12:
                setJann1(p);
                setCurrentProd(p);
                break;
              default:
                // handle default
            }
          }
        }
      }
    }
  }

  if(production.length > 0){
    funcB();
  }

}, [production, defaultView, customer_details]);

function getCurrentMonthAndYear(month, year) {
  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ];
  
  const currentMonth = months[month];


  return `${currentMonth} ${year}`;
}

useEffect(() => {
  const today = new Date();

  var year = today.getFullYear();
  var month = today.getMonth();

  var prevMonth, prevYear;

  if(month === 0){
    month = 11;
    year = year - 1;

    prevMonth = 10;
    prevYear = year - 1;
  } else if(month === 1){
    month = 0;

    prevMonth = 11;
    prevYear = year - 1;
  } else {
    prevMonth = month - 2;
    prevYear = year;
  }

  const filteredData1 = bills.filter(item => {
    return (item.period === getCurrentMonthAndYear(month, year)) ||
           (item.period === getCurrentMonthAndYear(prevMonth, prevYear));
  });

  const filteredData2 = payments.filter(item => {
    const itemDate = new Date(item.createdAt);
    return (itemDate.getMonth() === today.getMonth() && itemDate.getFullYear() === today.getFullYear()) ||
           (itemDate.getMonth() === today.getMonth() === 0 ? 11 : today.getMonth()-1 && itemDate.getFullYear() === today.getMonth() === 0 ? today.getFullYear()-1 : today.getFullYear());
  });

  const bill1 = filteredData1.reduce((sum, item) => {
    if (item.period === getCurrentMonthAndYear(month, year)) {
      return sum + parseFloat(item.total_cost) + parseFloat(item.previous_balance);
    }
    return sum;
  }, 0);

  const bill2 = filteredData1.reduce((sum, item) => {
    if (item.period === getCurrentMonthAndYear(prevMonth, prevYear)) {
      return sum + parseFloat(item.total_cost) + parseFloat(item.previous_balance);
    }
    return sum;
  }, 0);

  setExpected(bill1);

  const pay1 = filteredData2.reduce((sum, item) => {
    const itemDate = new Date(item.createdAt);
    if (itemDate.getMonth() === today.getMonth() && itemDate.getFullYear() === today.getFullYear()) {
      return sum + parseFloat(item.transaction_amount);
    }
    return sum;
  }, 0);

  const pay2 = filteredData2.reduce((sum, item) => {
    const itemDate = new Date(item.createdAt);
    if (itemDate.getMonth() === today.getMonth() === 0 ? 11 : today.getMonth()-1 && itemDate.getFullYear() === today.getMonth() === 0 ? today.getFullYear()-1 : today.getFullYear()) {
      return sum + parseFloat(item.transaction_amount);
    }
    return sum;
  }, 0);

  setReceived(pay1);

  const eff = billEfficiencyTrend(bill1, bill2, pay1, pay2);

  setBillingEfficiencySummaryTrend(eff);

}, [bills, payments])

useEffect(() => {
  const funcY = () => {
    setJan5(0);
    setFeb5(0);
    setMar5(0);
    setApr5(0);
    setMay5(0);
    setJun5(0);
    setJul5(0);
    setAug5(0);
    setSep5(0);
    setOct5(0);
    setNov5(0);
    setDec5(0);
    setJann5(0);

    for(let i=0; i<months.length; i++){
      for(let k=0; k<years.length; k++){
        if(i === k){
          let month = months[i];
          let year = years[k];

          let filteredData = bills.filter((obj) => (obj.period === getCurrentMonthAndYear(month-1, year)));

          if(defaultView !== "system" && filteredData.length > 0){
            filteredData = filteredData.filter((obj) => obj.dma === defaultView)
          }

          if(filteredData.length > 0){
            let b = filteredData.reduce((sum, obj) => sum + parseFloat(obj.total_cost), 0);

            switch(i){
              case 0:
                setJan5(b);
                break;
              case 1:
                setFeb5(b);
                break;
              case 2:
                setMar5(b);
                break;
              case 3:
                setApr5(b);
                break;
              case 4:
                setMay5(b);
                break;
              case 5:
                setJun5(b);
                break;
              case 6:
                setJul5(b);
                break;
              case 7:
                setAug5(b);
                break;
              case 8:
                setSep5(b);
                break;
              case 9:
                setOct5(b);
                break;
              case 10:
                setNov5(b);
                break;
              case 11:
                setDec5(b);
                break;
              case 12:
                setJann5(b);
                break;
              default:
                // handle default
            }
          }
        }
      }
    }
  }

  if(bills.length > 0){
    funcY();
  }
}, [bills, defaultView]);

useEffect(() => {
  const funcZ = () => {
    setJan6(0);
    setFeb6(0);
    setMar6(0);
    setApr6(0);
    setMay6(0);
    setJun6(0);
    setJul6(0);
    setAug6(0);
    setSep6(0);
    setOct6(0);
    setNov6(0);
    setDec6(0);
    setJann6(0);

    for(let i=0; i<months.length; i++){
      for(let k=0; k<years.length; k++){
        if(i === k){
          var month = months[i];
          var year = years[k];

          let filteredData = payments.filter((obj) => (new Date(obj.createdAt).getMonth() === (month-1)) && (new Date(obj.createdAt).getFullYear() === year));

          if(defaultView !== "system" && filteredData.length > 0){
            filteredData = filteredData.filter(obj => {
              const customer = customer_details.find(cust => (cust.account === obj.account) && (cust.dma === defaultView));
              return customer !== undefined
            });
          }

          if(filteredData.length > 0){
            let p = filteredData.reduce((sum, obj) => sum + parseFloat(obj.transaction_amount), 0);

            switch(i){
              case 0:
                setJan6(p);
                break;
              case 1:
                setFeb6(p);
                break;
              case 2:
                setMar6(p);
                break;
              case 3:
                setApr6(p);
                break;
              case 4:
                setMay6(p);
                break;
              case 5:
                setJun6(p);
                break;
              case 6:
                setJul6(p);
                break;
              case 7:
                setAug6(p);
                break;
              case 8:
                setSep6(p);
                break;
              case 9:
                setOct6(p);
                break;
              case 10:
                setNov6(p);
                break;
              case 11:
                setDec6(p);
                break;
              case 12:
                setJann6(p);
                setReceived(p);
                break;
              default:
                // handle default
            }
          }
        }
      }
    }
  }

  if(payments.length > 0){
    funcZ();
  }
}, [payments, defaultView])

useEffect(() => {
  const funcX = () => {

    let arr = [];

    const today = new Date();

    var month = today.getMonth();

    var year = today.getFullYear();

    var _month, _year;


    if(month === 0){
      _month = 11;
      _year = year - 1;
    } else {
      _month = month - 1;
      _year = year;
    }

    let _filteredData = bills.filter((obj) => (obj.period === getCurrentMonthAndYear(_month, _year)));

    for(let i=1; i<dmas.length; i++){

      let instance_dma = dmas[i].value;

      let billsFiltered = _filteredData.filter((obj) => ( obj.dma === instance_dma) );

      const currentBills = billsFiltered.reduce((sum, obj) => (sum + obj.total_cost + parseFloat(obj?.previous_balance)), 0);

    let filteredPayments = payments.filter((obj) => (new Date(obj.createdAt).getMonth()) === month && new Date(obj.createdAt).getFullYear() === year)


    // Filter payments based on DMA
    filteredPayments = filteredPayments.filter(payment => {
      // Find the corresponding customer for the payment account
      const customer = customer_details.find(cust => cust.account === payment.account);
      // Check if the customer exists and has the target DMA
      return customer && customer.dma === instance_dma;
    });

      let currentPayments = filteredPayments.reduce((sum, obj) => sum + obj.transaction_amount, 0);


      let trend = billEfficiency(currentBills, currentPayments);

      arr.push({dma: instance_dma, gross: currentBills, net: currentPayments, trend: trend})
    }

    setRevenueByZones(arr);
  }

  if(bills.length > 0 && payments.length > 0 && customer_details.length > 0 && dmas.length > 0){
    funcX();
  }
}, [bills, payments, dmas, customer_details])

  const theme = useMantineTheme()

  const labels = getMonth(12);

  const data = {
      labels,
      datasets: [
        {
          label: 'Production',
          lineTension: 0.4,
          data: [jan1, feb1, mar1, apr1, may1, jun1, jul1, aug1, sep1, oct1, nov1, dec1, jann1],
          borderColor: theme.colors.green[9],
          backgroundColor: theme.colors.green[9],
        },
        {
          label: 'Consumption',
          lineTension: 0.4,
          data: [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec, jann],
          borderColor: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[9],
        },
      ],
    };

    const data2 = {
      labels,
      datasets: [
        {
          label: 'Gross Revenue',
          lineTension: 0.4,
          data: [jan5, feb5, mar5, apr5, may5, jun5, jul5, aug5, sep5, oct5, nov5, dec5, jann5],
          borderColor: theme.colors.red[9],
          backgroundColor: theme.colors.red[9],
        },
        {
          label: 'Net Revenue',
          lineTension: 0.4,
          data: [jan6, feb6, mar6, apr6, may6, jun6, jul6, aug6, sep6, oct6, nov6, dec6, jann6],
          borderColor: theme.colors.green[9],
          backgroundColor: theme.colors.green[9],
        },
      ],
    };

    const createTarrifData = () => {
      let arr = [];

      for(let i=0; i<tarrifs.length; i++){
        let item = tarrifs[i];

        if(item.tarrif === "Domestic/Residential"){
          arr.push(
            {
              label: 'Individuals',
              lineTension: 0.4,
              data: [jan2, feb2, mar2, apr2, may2, jun2, jul2, aug2, sep2, oct2, nov2, dec2, jann2],
              borderColor: theme.colors.blue[9],
              backgroundColor: theme.colors.blue[9],
            },
          )
        } else if(item.tarrif === "Water kiosks"){
          arr.push(
            {
              label: 'Water Kiosks',
              lineTension: 0.4,
              data: [jan4, feb4, mar4, apr4, may4, jun4, jul4, aug4, sep4, oct4, nov4, dec4, jann4],
              borderColor: theme.colors.red[9],
              backgroundColor: theme.colors.red[9],
            },
          )
        } else if(item.tarrif === "Government institutions" || item.tarrif === "Government funded public schools"){
          arr.push(
            {
              label: 'Public',
              lineTension: 0.4,
              data:[jan3, feb3, mar3, apr3, may3, jun3, jul3, aug3, sep3, oct3, nov3, dec3, jann3],
              borderColor: theme.colors.green[9],
              backgroundColor: theme.colors.green[9],
            },
          )
        }
      }
      return arr;
    }

    const data3 = {
      labels,
      datasets: createTarrifData()
    };

    const data4 = {
      labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.4,
          label: 'Water Loss',
          data: [(jan1 - jan), (feb1 - feb), (mar1 - mar), (apr1 - apr), (may1 - may), (jun1 - jun), (jul1 - jul), (aug1 - aug), (sep1 - sep), (oct1 - oct), (nov1-nov), (dec1 - dec), (jann1 - jann)],
          borderColor: theme.colors.red[9],
          backgroundColor: theme.colors.red[9],
        },
      ],
    };
  
    const handleSystemView = (val) => {
      setDefaultView(val)
    }
 
    useEffect(() => {
      ReactGA.send({hitType: "pageView", page: window.location.href})
  }, []);

  const navigate = useNavigate();

  const [visible, setVisible] = useLocalStorage({
    key: "_emita_banner_platform",
    defaultValue: "1",
    getInitialValueInEffect: true
});

  return (
    <>
      <Helmet>
      <meta charSet="utf-8" />
      <title> {state.userData.waterServiceName} | Dashboard</title>
      <meta name='description' content='Emita is a robust water utility information management system that provides a powerful approach to a comprehensive water supply management.' />
      </Helmet>
      {visible === "1" ? (
            <div id="banner" tabindex="-1" className="flex gap-8 justify-between items-start mb-4 py-3 px-4 w-full border border-b sm:items-center lg:py-4">
            <p className="text-sm font-light">
            Hi <strong>{state.userData.waterServiceName}</strong>, here at Emita, your protection and data security is our utmost priority. Your data is yours only, and only you can interact with it. Learn more about how we protect your data in our <Anchor href="#">privacy policy terms</Anchor>.
            </p>
            <button onClick={() => {setVisible("0")}} data-collapse-toggle="banner" type="button" className="flex items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
            </button>
        </div>
        ) : null}
      {!state.userData?.verified ? (
              <Alert mb={10} color='orange' title="Free Tier Subscription" icon={<IconInfoCircle />}>
              Your account is using the free tier pricing plan. Please note that the free tier plan is subject to at most three months piloting. With free tier, features such as SMS configuration, database backups etc are disabled. Upgrade now to get more out of Emita.
              <br />
              <br />
              <Button radius={28}>Upgrade Now</Button>
        </Alert>
      ) : null}
    <Alert icon={<IconInfoCircle />} mb={20} title="Account Summary">
      <SimpleGrid mb={10} mt={10} cols={4} breakpoints={[
        {maxWidth: 980, cols: 1}
      ]}>
        <Group position='apart' sx={(theme) => ({[theme.fn.largerThan("md")]: {borderRight: theme.colorScheme === "dark" ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.dark[5]}`},[theme.fn.smallerThan('md')]: { borderRight: 0 },display: "flex", justifyContent: 'space-between',  })}>
          <Group >
          <FontAwesomeIcon icon={faUsers} color='blue' />
          <Text component={Link} to={`/app/customers/customers-list#${state.userData._id}`}>
            <Center inline>
            <Text mr={10}>Customers</Text>
            </Center>
          </Text>
          </Group>

          <Text className={classes.value} mr={20}>{customers}</Text>
        </Group>

        <Group position='apart' sx={(theme) => ({[theme.fn.largerThan("md")]: {borderRight: theme.colorScheme === "dark" ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.dark[5]}`},[theme.fn.smallerThan('md')]: { borderRight: 0 },display: "flex", justifyContent: 'space-between',  })}>
        <Group>
        <FontAwesomeIcon icon={faArrowTrendUp} color='red'  />
        <Text component={Link} to={`/app/payments#${state.userData._id}`}>
            <Center inline >
            <Text mr={10}>Bills</Text>
            </Center>
          </Text>
        </Group>

        <Text className={classes.value} mr={20}>{nFormatter(cumulativeBills, 1)}</Text>
        </Group>

        <Group position='apart' sx={(theme) => ({[theme.fn.largerThan("md")]: {borderRight: theme.colorScheme === "dark" ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.dark[5]}`},[theme.fn.smallerThan('md')]: { borderRight: 0 },display: "flex", justifyContent: 'space-between',  })}>
        <Group>
        <FontAwesomeIcon icon={faArrowTrendDown} color='orange' />
        <Text component={Link} to={`/app/payments#${state.userData._id}`}>
          <Center inline>
          <Text mr={10}>Payments</Text>
          </Center>
          </Text>
        </Group>

        <Text className={classes.value} mr={20}>{nFormatter(cumulativePayments, 1)}</Text>
        </Group>

        <Group position='apart'>
        <Group position='left'>
        <FontAwesomeIcon icon={faChartLine} color='green' />
        <Text component={Link} to={`/app/payments#${state.userData._id}`}>
          <Center inline>
          <Text mr={10}>Efficiency(%)</Text>
          </Center>
          </Text>
        </Group>

        <Text className={classes.value} mr={20}>{cumulativeBills === 0 ? "0.0" : ((cumulativePayments / cumulativeBills) * 100).toFixed(1)}</Text>
        </Group>

      </SimpleGrid>
    </Alert>

    <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
    <Dialog opened={opened} withCloseButton onClose={close} size="lg" radius="md" position={{bottom: 10, right: 20}}>
      <Text size="sm" mb="xs" weight={500}>
        Is your water service area up to date?
      </Text>

      <Group align="flex-end">
        <Text size={"sm"} sx={{ flex: 1 }}>Update service area centroid</Text>
        <Button variant='default' radius={28} onClick={() => {navigate("/app/service-area?mode=update")}}>Get Started</Button>
      </Group>
    </Dialog>
    </MediaQuery>

    <MediaQuery largerThan={"md"} styles={{display: "none"}}>
    <Dialog opened={opened} withCloseButton onClose={close} size="lg" radius="md" position={{bottom: 20, left: 20}}>
      <Text size="sm" mb="xs" weight={500}>
        Is your water service area up to date?
      </Text>

      <Group align="flex-end">
        <Text size={"sm"} sx={{ flex: 1 }}>Update service area</Text>
        <Button variant='default' radius={28} onClick={() => {navigate("/app/service-area?mode=update")}}>Get Started</Button>
      </Group>
    </Dialog>
    </MediaQuery>

    <Group position='apart' mb={20}>
      <Group position='left'>
        <Text>Show analytics for:</Text>
      <Select placeholder='Number of months to show' data={[
        {label: "Last 3 Months", value: "3"},
        {label: "Last 6 Months", value: "6"},
        {label: "Last 1 Year", value: "12"}
      ]} value={months_num} onChange={(val) => {setMonthsNum(val)}} />
      </Group>
        <Group position='right'>
        <Text>MTD data for:</Text>
          <Select placeholder="Cycle" data={cycles} value={c} onChange={(val) => {setC(val)}} />
          <Button variant='default' leftIcon={<Download size={16} />} onClick={() => {navigate("reports")}} radius={28}>Reports</Button>
        </Group>
      </Group>
    <Statistics />
    <SimpleGrid
      cols={1}
      spacing="xs"
    >
      <Paper shadow="xs" p={0} withBorder>
        <Paper p="md">
        <Group position='apart'>
            <div>
            <Text size="xs" className={classes.title}>Supply and consumption trend</Text>
            <Text mt={10}>Viewing data over a period of 12 months</Text>
            </div>
            <Group>
            <SelectField value={defaultView} onChange={(e) => {handleSystemView(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
              {dmas.map((item, index) => {
                return (
                  <option value={item.value} key={`view-${index}`}>{item.label}</option>
                )
              })}
            </SelectField>
            <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Line</option>
                      <option value="bar">Bar</option>
                  </SelectField>
            </Group>
        </Group>
        </Paper>
        <Divider />
        <Grid>
          <Grid.Col md={12} lg={7} style={{width: '100%'}}>
            <Paper p="md" style={{height: 250, width: '100%', overflowX: 'auto'}}>
            {chart1 === "line" ? (
              <Line width={580} options={options} data={data} />
            ) : (
              <Bar width={580} options={options} data={data} />
            )}

          <Group position='apart' mb={20} mt={20}>
          <Group spacing="xl" position='left'>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid ${theme.colors.blue[9]}`}}></div>
              <Text color="dimmed"  size='xs' >Consumption</Text>
              </Group>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid #00ED64`}}></div>
              <Text color="dimmed"  size='xs' >Production</Text>
              </Group>
          </Group>
          </Group>
            </Paper>
          </Grid.Col>
          <Grid.Col md={12} lg={5}>
          <Paper style={{height: 250, overflowY: "auto"}} p={0} withBorder>
          <Paper p="md">
          <div>
            <Text size="xs" className={classes.title}>Suppy and consumption by zones</Text>
            <Text mt={10}>{getDate(parseInt(dates?.starts))} total supply and consumption per zone. </Text>
            </div>
          </Paper>
          <Divider />
          <div style={{overflowX: 'auto', overflowY: "auto"}}>
          <Table fontSize="xs">
            <thead>
              <tr>
                <th>Zone</th>
                <th>Supply</th>
                <th>Consumption</th>
                <th>NRW</th>
                <th>Trend</th>
              </tr>
            </thead>
            <tbody>
              {zones_data.map((item, index) => {
                return (
                  <tr key={`trend-production-consumption-${index}`}>
                  <td>{item.dma}</td>
                  <td>{item.production}</td>
                  <td>{item.consumption}</td>
                  <td>{item.loss}</td>
                  <td>{item.trend}</td>
                </tr>
                )
              })}
            </tbody>
          </Table>
          </div>
          </Paper>
          </Grid.Col>
        </Grid>
      </Paper>

      <SimpleGrid mt={20} cols={3} breakpoints={[
            {maxWidth: 980, cols: 1}
        ]}>
        <Paper p="md" withBorder>
            <div style={{height: 200}}>
            <Title2 order={4} mt={10} mb={20}>Tarrifs</Title2>
            <Text mb={20}>Administer water tariffs efficiently. Set and adjust water rates, fees, and pricing tiers.</Text>
            </div>
            <Button variant="outline" component={Link} to="/app/configure/tarrifs" leftIcon={<Plus />} radius={28}>Manage Tarrifs</Button>
 
        </Paper>

        <Paper p="md" withBorder>
            <div style={{height: 200}}>
            <Title2 order={4} mt={10} mb={20}>Billing</Title2>
            <Text mb={20}>Manage your Emita billing pipeline effortlessly. Configure new shortcodes, view payment information in real-time.</Text>
            </div>
            <Button variant="outline" leftIcon={<Plus />} component={Link} to="/app/configure/billing-methods" radius={28}>Payment Pipelines</Button>
 
        </Paper>

        <Paper p="md" withBorder>
            <div style={{height: 200}}>
            <Title2 order={4} mt={10} mb={20}>Cycles</Title2>
            <Text mb={20}>Manage your billing and meter reading cycles effortlessly.</Text>
            </div>
            <Button variant="outline" leftIcon={<Plus />} component={Link} to="/app/configure/dates" radius={28}>Manage Cycles</Button>
 
        </Paper>
      </SimpleGrid>

      {/*-------- Per tarrif consumption ------- */}

      <Paper mt={20} shadow="xs" p={0} withBorder>
        <Paper p="md">
          <Group position='apart'>
            <div>
            <Text size="xs" className={classes.title}>Consumption trend by customer category</Text>
            <Text mt={10}>Viewing data over a period of 12 months</Text>
            </div>
            <Group>
            <SelectField value={defaultView} onChange={(e) => {handleSystemView(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
              {dmas.map((item, index) => {
                return (
                  <option value={item.value} key={`view-${index}`}>{item.label}</option>
                )
              })}
            </SelectField>
            <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Line</option>
                      <option value="bar">Bar</option>
                  </SelectField>

            </Group>
          </Group>
        </Paper>
        <Divider />
        <Grid>
          <Grid.Col md={12} lg={7} style={{width: '100%'}}>
            <Paper p="md" style={{height: 250, width: '100%', overflowX: 'auto'}}>
            {chart1 === "bar" ? (
              <Bar width={580} options={options} data={data3} />
            ) : (
              <Line width={580} options={options} data={data3} />
            )}

        <Group position='apart' mb={20} mt={20}>
            <Group spacing="xl" position='left'>
                <Group spacing={3} >
                <div style={{width: 40,borderBottom: `2px solid ${theme.colors.blue[8]}`}}></div>
                <Text color="dimmed"  size='xs' >Individuals</Text>
                </Group>
                <Group spacing={3} >
                <div style={{width: 40,borderBottom: `2px solid ${theme.colors.red[8]}`}}></div>
                <Text color="dimmed"  size='xs' >Water Kiosks</Text>
                </Group>
                <Group spacing={3} >
                <div style={{width: 40,borderBottom: `2px solid ${theme.colors.green[8]}`}}></div>
                <Text color="dimmed"  size='xs' >Public Institutions</Text>
                </Group>
            </Group>
        </Group>
            </Paper>
          </Grid.Col>

          <Grid.Col md={12} lg={5}>
          <Paper style={{height: 250, overflowY: 'auto'}} p={0} withBorder>
          <Paper p="md">
          <div>
            <Text size="xs" className={classes.title}>Customer consumption by categories & zones</Text>
            <Text mt={10}>{getDate(parseInt(dates?.starts))} total customer category consumption per zone </Text>
            </div>
          </Paper>
          <Divider />
          <div style={{overflowX: 'auto', overflowY: "auto"}}>
          <Table fontSize="xs">
            <thead>
              <tr>
                <th>Zone</th>
                <th>Domestic</th>
                <th>Public</th>
                <th>Water Kiosks</th>
              </tr>
            </thead>
            <tbody>
              {zones_data2.length === 0 ? (
                              <tr>
                              <td colSpan={5}><Center>No data found</Center></td>
                            </tr>
              ) : (
                zones_data2.map((item, index) => {
                  return (
                    <tr key={`consumption-by-customer-${index}`}>
                    <td>{item.dma}</td>
                    <td>{item.tarrif1}</td>
                    <td>{item.tarrif2}</td>
                    <td>{item.tarrif3}</td>
                  </tr>
                  )
                })
              )}
            </tbody>
          </Table>
          </div>
          </Paper>
          </Grid.Col>
        </Grid>
      </Paper>
      {/*----------End of per tarrif consumption ------ */}

      <Paper mt={20} shadow="xs" p={0} withBorder>
      <Paper p="md">
          <Group position='apart'>
            <div>
                <Group>
                <Text size="xs" className={classes.title}> Highest NRW zone </Text>
                <HoverCard width={280} shadow='md'>
                  <HoverCard.Target>
                  <Anchor size="xs" href='#'>info</Anchor>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                  <Text size={"sm"}>
                  This panel shows details of the zone/DMA with the highest value of NRW in the most recent meter reading cycle.
                  </Text>
                </HoverCard.Dropdown>
                </HoverCard>
                </Group>
              <Text mt={10}>Viewing the zone with the highest NRW </Text>
            </div>
          </Group>
        </Paper>
        <Divider />
        <Paper p="md">
        <SimpleGrid cols={3}>
          <div style={{borderRight: '1px solid gray'}}>
            <Text>Zone</Text>
            <Text className={classes.value}>{zone}</Text>
          </div>

          <div style={{borderRight: '1px solid gray'}}>
            <Text>Total NRW(M³)</Text>
            <Text className={classes.value}>{total}</Text>
          </div>

          <div>
            <Text>Prior month for the same period with trend(%)</Text>
            <Text className={classes.value}>{prior_trend}</Text>
          </div>

        </SimpleGrid>
        <SimpleGrid cols={3} mt={50}>
        <div >

          </div>

          <div style={{borderRight: '1px solid gray'}}>
            <Text>Total supply(M³)</Text>
            <Text className={classes.value}>{zone_prod}</Text>
          </div>

          <div>
            <Text>Comparison with previous month(%)</Text>
            <Text className={classes.value}>{zone_prod_trend.toFixed(1)}</Text>
          </div>
        </SimpleGrid>

        <SimpleGrid cols={3} mt={50}>
        <div >

          </div>

          <div style={{borderRight: '1px solid gray'}}>
            <Text>Total usage(M³)</Text>
            <Text className={classes.value}>{zone_cons}</Text>
          </div>

          <div>
            <Text>Comparison with previous month(%)</Text>
            <Text className={classes.value}>{zone_cons_trend.toFixed(1)}</Text>
          </div>
        </SimpleGrid>
        </Paper>
      </Paper>

      {/**------- Start of non-revenue water summary----- */}
        <Paper mt={20} shadow="xs" p={0} withBorder>
        <Paper p="md">
          <Group position='apart'>
            <div>
            <Text size="xs" className={classes.title}>Non-revenue water trend</Text>
            <Text mt={10}>Viewing data over a period of 12 months</Text>
            </div>
            <Group>
            <SelectField value={defaultView} onChange={(e) => {handleSystemView(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="area" labelHidden icon={<ChevronDown />}>
              {dmas.map((item, index) => {
                return (
                  <option value={item.value} key={`view-${index}`}>{item.label}</option>
                )
              })}
            </SelectField>
            <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Area</option>
                      <option value="bar">Bar</option>
                  </SelectField>
            </Group>
          </Group>
        </Paper>
        <Divider />
        <Grid>
          <Grid.Col md={12} lg={7} style={{width: '100%'}}>
          <Paper p="md" style={{height: 250, width: '100%', overflowX: 'auto'}}>
          {chart1 === "bar" ? (
            <Bar width={580} data={data4}  options={options} />
          ) : (
            <Line width={580} data={data4}  options={options} />
          )}
          <Group position='apart' mb={20} mt={20}>
          <Group spacing="xl" position='left'>
              <Group spacing={3} >
              <div style={{width: 40,borderBottom: `2px solid ${theme.colors.red[9]}`}}></div>
              <Text color="dimmed"  size='xs' >Non-revenue water</Text>
              </Group>
          </Group>
          </Group>
          </Paper>
          </Grid.Col>
          <Grid.Col md={12} lg={5}>
          <Paper style={{height: 250,overflowY: "auto" }} p={0} withBorder>
          <Paper p="md">
          <div>
            <Text size="xs" className={classes.title}>Non-revenue water by zones</Text>
            <Text mt={10}>{getDate(parseInt(dates?.starts))} total NRW per zone. </Text>
            </div>
          </Paper>
          <Divider />
          <div style={{overflowX: 'auto', overflowY: "auto"}}>
          <Table fontSize="xs">
            <thead>
              <tr>
                <th>Zone</th>
                <th>Loss</th>
                <th>Trend</th>
              </tr>
            </thead>
            <tbody>
              {zones_data.map((item, index) => {
                return (
                  <tr key={`nrw-${index}`}>
                  <td>{item.dma}</td>
                  <td>{item.loss}</td>
                  <td>{item.trend}</td>
                </tr>
                )
              })}
            </tbody>
          </Table>
          </div>
          </Paper>
          </Grid.Col>
        </Grid>
        </Paper>
      {/**--------End of the non-revenue water summary---- */}

      <Paper mt={20} shadow="xs" p={0} withBorder>
        <Paper p="md">
          <Group position='apart'>
            <div>
                <Group>
                <Text size="xs" className={classes.title}>Current Month Revenue Summary</Text>
                <HoverCard width={280} shadow='md'>
                  <HoverCard.Target>
                  <Anchor size="xs" href='#'>info</Anchor>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                  <Text size={"sm"}>
                  This section shows a brief summary concerning revenue i.e the total amount expected from the most recent billing cycle, the total amount received so far, the billing efficiency and its comparison with the previous month.
                  </Text>
                </HoverCard.Dropdown>
                </HoverCard>
                </Group>
            </div>
          </Group>
        </Paper>
        <Divider />
        <Paper p="md">
        <SimpleGrid cols={4} breakpoints={[{maxWidth: 980, cols: 2}]}>

          <div>
            <Text mb={20}>Total Expected</Text>
            <Text className={classes.value}>KSH. {(expected).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
          </div>

          <div>
            <Text mb={20}>Total Recieved</Text>
            <Text className={classes.value}>KSH. {(received).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
          </div>

          <div>
            <Text mb={20}>Billing Efficiency</Text>
            <Text className={classes.value}>{billEfficiency(expected, received).toFixed(1)}%</Text>
          </div>

          <div>
            <Text mb={20} style={{display: "flex"}}>
              <span>Billing Efficiency Trend</span>
              <HoverCard width={280} shadow='md'>
                  <HoverCard.Target>
                  <InfoCircle size={15} />
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                  <Text size={"sm"}>
                  Displays the rate at which billing efficiency has improved or deteriorated.
                  </Text>
                </HoverCard.Dropdown>
                </HoverCard>
            </Text>
            <Text color={billing_efficiency_summary_trend > 0 ? "green" : "red"} className={classes.value} style={{display: "flex", alignItems: "center", lineHeight: 1}}>
              <span>{billing_efficiency_summary_trend.toFixed(1)}%</span>
              {billing_efficiency_summary_trend > 0 ? <IconArrowUpRight /> : <IconArrowDownLeft />}
              </Text>
          </div>
        </SimpleGrid>

        </Paper>
      </Paper>
      

      {/** --- Start of revenue summary ---- */}
      <Paper mt={20} shadow="xs" p={0} withBorder>
        <Paper p="md">
          <Group position='apart'>
            <div>
            <Text size="xs" className={classes.title}>Revenue trend</Text>
            <Text mt={10}>Viewing data over a period of 12 months</Text>
            </div>
            <Group>
            <SelectField value={defaultView} onChange={(e) => {handleSystemView(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
              {dmas.map((item, index) => {
                return (
                  <option value={item.value} key={`view-${index}`}>{item.label}</option>
                )
              })}
            </SelectField>
            <SelectField value={chart1} onChange={(e) => {setChart1(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                      <option value="line">Line</option>
                      <option value="bar">Bar</option>
                  </SelectField>

            </Group>
          </Group>
        </Paper>
        <Divider />
        <Grid>
          <Grid.Col md={12} lg={7} style={{width: '100%'}}>
          <Paper p="md" style={{height: 250, width: '100%', overflowX: 'auto'}}>
            {chart1 === "line" ? (
              <Line width={580} options={options} data={data2} />
            ) : (
              <Bar width={580} options={options} data={data2} />
            )}

          <Group position='apart' mt={20} mb={20}>
            <Group spacing={5} position='left'>
            <Group spacing={3} >
            <div style={{width: 40,borderBottom: `2px solid ${theme.colors.green[9]}`}}></div>
                <Text color="dimmed"  size='xs' >Net Revenue</Text>
                </Group>
                <Group spacing={3} >
                <div style={{width: 40,borderBottom: `2px solid ${theme.colors.red[9]}`}}></div>
                <Text color="dimmed"  size='xs' >Gross Revenue</Text>
                </Group>
            </Group>
          </Group>
          </Paper>
          </Grid.Col>
          <Grid.Col md={12} lg={5}>
          <Paper style={{height: 250, overflowY: "auto"}} p={0} withBorder>
          <Paper p="md">
          <div>
            <Text size="xs" className={classes.title}>Revenue by zones</Text>
            <Text mt={10}>{getDate()} total revenue per zone. </Text>
            </div>
          </Paper>
          <Divider />
          <div style={{overflowX: 'auto', overflow: "auto"}}>
          <Table fontSize="xs">
            <thead>
              <tr>
                <th>Zone</th>
                <th>Gross Revenue(KES)</th>
                <th>Net Revenue(KES)</th>
                <th>Billing Efficiency(%)</th>
              </tr>
            </thead>
            <tbody>
              {revenue_by_zones.map((item, index) => {
                return (
                  <tr key={`revenue-by-zones-${index}`}>
                  <td>{item.dma}</td>
                  <td>{item.gross.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td>{item.net.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td>{item.trend.toFixed(1)}</td>
                </tr>
                )
              })}
            </tbody>
          </Table>
          </div>
          </Paper>
          </Grid.Col>
        </Grid>
      </Paper>
      {/** ---- End of revenue summary ---- */}
      
        <SimpleGrid cols={3} breakpoints={[
          {maxWidth: 980, cols: 1}
        ]}>
          <Paper mt={20} shadow="xs" p={0} withBorder>
          <Paper p="xl">
          <Text size="xs" className={classes.title}>WASREB Billing Requirements</Text>
          </Paper>
          <Divider />
          <Paper p="md">
            <Text mb={10} color="dimmed">What is WASREB billing requirements?</Text>
            <Text mb={20}>
            WASREB billing requirements are a list of conditions and requirements set by the Water Services Regulatory Board (WASREB) in Kenya. WSP are supposed to meet the requirements to ensure an effective, transparent, accurate, and reliable billing system.
            </Text>
            <Anchor href="jascript:void(0)" target="_blank">
            <Center inline>
              <Box ml={5}>Learn more</Box>
              <ExternalLink />
            </Center>
          </Anchor>
          </Paper>
          </Paper>

          <Paper mt={20} shadow="xs" p={0} withBorder>
          <Paper p="xl">
          <Text size="xs" className={classes.title}>E-mita Asset Mapper</Text>
          </Paper>
          <Divider />
          <Paper p="md">
            <Text mb={10} color="dimmed">What is E-mita asset mapper?</Text>
            <Text mb={20}>
            E-mita asset mapper is an offline-first mobile application that allows utilities to map the geographic extents and locations of their key infrastructure assets such as customer meters, pipe networks and storage assets.
            </Text>
            <Anchor href="jaascript:void(0)" target="_blank">
            <Center inline>
              <Box ml={5}>Learn more</Box>
              <ExternalLink />
            </Center>
          </Anchor>
          </Paper>
          </Paper>

          <Paper mt={20} shadow="xs" p={0} withBorder>
          <Paper p="xl">
          <Text size="xs" className={classes.title}>E-mita billing Conductor</Text>
          </Paper>
          <Divider />
          <Paper p="md">
            <Text mb={10} color="dimmed">What is E-mita billing conductor?</Text>
            <Text mb={20}>
            E-mita billing conductor is a configurable billing pipeline that simplifies bills management by segmenting customers into different logical groups such that each group can have a different pricing plan.
            </Text>
            <Anchor href="jaascript:void(0)" target="_blank">
            <Center inline>
              <Box ml={5}>Learn more</Box>
              <ExternalLink />
            </Center>
          </Anchor>
          </Paper>
          </Paper>
        </SimpleGrid>
    </SimpleGrid>
    </>
  )
}

export default Analytics;