import { Button, Heading, SearchField, SelectField } from "@aws-amplify/ui-react";
import { ActionIcon, Alert, Anchor, Center, Divider, Grid, Group, Menu, Paper, Select, SimpleGrid, Space, Table, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ChevronDown, DotsVertical } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
  } from 'chart.js';
  import { Line, Bar } from 'react-chartjs-2';
import { getDate, getMonth } from "../../../utils/getMonth";
import diffInMonths from "../../../utils/monthsToNow";
import getCycles, { parseCycle } from "../../../utils/getCycles";
import { IconInfoCircle } from "@tabler/icons";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  );
  
  const options = {
    responsive: false,
    maintainAspectRation: false,
    borderWidth: 1,
    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
  
      title: {
        display: false,
        text: 'E-mita customers summary',
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
            display: true
        }
      },
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        }
      }
    }
  };
export default function CustomersSummary(){
    const { state, dispatch } = useContext(AuthContext);
    const theme = useMantineTheme();
    const [dmas, setDMAs] = useState([]);
    let diff_in_months = diffInMonths(new Date(state.userData.createdAt));
    let [c, setC] = useState(getCycles(diff_in_months)[0].value);
    const [customers, setCustomers] = useState([]);
    const [graph_data, setGraphData] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [active, setActive] = useState(0);
    const [, setInactive] = useState(0);
    const [chart, setChart] = useState("bar");
    const [zones_data, setZonesData] = useState([]);
    const [zone, setZone] = useState("general-system");
    const [bills, setBills] = useState([]);
    const [bills_count, setBillsCount] = useState(0)
    const [total, setTotal] = useState(0);
    const [new_connections, setNewConnections] = useState(0);
    const [payments, setPayments] = useState(0);

    useEffect(() => {
        axios.get("/payments/all-valid", {
            headers: {
               'Authorization': `Bearer ${state.userToken}` 
            },
            params: {
                month: parseCycle(c),
                year: 2023
            }
        }).then(function(res){
            if(res.status === 200){
                setPayments(res.data.data[0].total)
            }
        }).catch(function(error){
            console.log(error);
        })
    }, [c]);

    useEffect(() => {
        let arr = [];
        for(let i=1; i<dmas.length; i++){
            let instance_dma = dmas[i].value;
            let sum_active = 0;
            let sum_inactive = 0;

            for(let k=0; k<customers.length; k++){
                let item = customers[k];

                if(item.dma === instance_dma){
                    if(!item.disconnected){
                        sum_active += 1;
                    } else {
                        sum_inactive += 1;
                    }
                }
            }

            arr.push({dma: instance_dma, active: sum_active, inactive: sum_inactive});
        }

        setZonesData(arr);
    }, [dmas, customers])

    useEffect(() => {
        if(customers.length > 0){
            axios.post("/master-invoices/invoices", {
                period: c,
                parent: state.userData._id,
                size: customers.length,
                page: 1
            }).then(function(res){
                if(res.status === 200){
                    setBills(res.data.data);
                    setBillsCount(res.data.count);
                }
            }).catch(function(error){
                console.log(error);
            });
        }
    }, [customers]);
    
    const fetchDMAs = () => {
        try{
          axios.post("/dmas/getdmas", {
            username: state.userData._id
          }).then(function(res){
            if(res.status === 200){
              let arr = [{label: "Overall Area", value: "general-system"}];
              for(let i=0; i<res.data.data.length; i++){
                let item = res.data.data[i];
                arr.push({label: item.name, value: item.name})
              }
  
              setDMAs(arr);
            }
          })
        } catch(error){
          console.log(error);
        }
      }

      const fetchCustomers = () => {
        setCustomers([]);

        const body = {
            parent: state.userData._id
        };
        
        axios.post("/customers/getCustomers",body ).then(({data: {data: customers}, }) => {
            setCustomers(customers);

        }).catch(function(error){
            console.log(error);
        })
    }
  
      useEffect(() => {
        fetchDMAs();
        fetchCustomers();
      }, []);

      const labels = getMonth(12);

      const summation = (month, item, year) => {
        if(zone !== "general-system"){
            if(item.dma !== zone){
                return false;
            } else {
                let createdAt = new Date(item.createdAt);
                let m = createdAt.getMonth();
                let y = createdAt.getFullYear();
        
                if(m === month && year === y){
                    return true
                } else {
                    return false;
                }
            }
        } else {
            let createdAt = new Date(item.createdAt);
            let m = createdAt.getMonth();
            let y = createdAt.getFullYear();
    
            if(m === month && year === y){
                return true
            } else {
                return false;
            }
        }
      }

      useEffect(() => {
        let s1 = 0;
        let s2=0;
        let s3=0;
        let s4=0;
        let s5=0;
        let s6=0;
        let s7=0;
        let s9 = 0;
        let s10 = 0;
        let s11 = 0;
        let s12 = 0;
        let s13 = 0;
        let s14 = 0;

        let s8 = 0;


        for(let i=0; i<customers.length; i++){
            let item = customers[i];
            if(!item.disconnected){
                s8 += 1; // active
            }

            for(let k=0; k<labels.length; k++){
                let m1 = labels[k];
                let m1Arr = m1.split("/");
    
                let y1 = "20"+m1Arr[1];
                let year = parseInt(y1);
                let mm1 = parseInt(m1Arr[0]);
                
                let check = summation(mm1 - 1, item, year);
                if(check){
                    if( k === 0){
                        s1 += 1;
                    } else if(k === 1){
                        s2 += 1;;
                    } else if(k === 2){
                        s3 += 1;
                    } else if(k === 3){
                        s4 += 1;
                    } else if(k === 4){
                        s5 += 1;
                    } else if(k === 5){
                        s6 += 1;
                    } else if(k === 6){
                        s7 += 1
                    } else if(k === 7){
                        s9 += 1;
                    } else if(k === 8){
                        s10 += 1;
                    } else if(k === 9){
                        s11 += 1;
                    } else if(k === 10){
                        s12 += 1;
                    } else if(k === 11){
                        s13 += 1;
                    } else if(k === 12){
                        s14 += 1;
                    }
                }
            }
            
        }

        setActive(s8);
        setInactive(customers.length - s8);
        setGraphData([s1, s2, s3, s4, s5, s6, s7, s9, s10, s11, s12, s13, s14]);
      }, [customers, zone])

      useEffect(() => {
        let month = new Date().getMonth();
        let year = new Date().getFullYear();

        if(zone === "general-system"){
            setTotal(customers.length);

            let s = 0;
            let n = 0;

            for(let i=0; i<customers.length; i++){
                let item = customers[i];
                if(!item.disconnected){
                    s += 1;
                }

                if((new Date(item.createdAt).getMonth() === month) && (new Date(item.createdAt).getFullYear() === year)){
                    n += 1;
                }
            }

            setNewConnections(n);
            setActive(s);
        } else {
            let s2=0;
            let s3=0;
            let n2 = 0;
            for(let k=0; k<customers.length; k++){
                let item = customers[k];

                if(item.dma === zone){
                    s2 += 1;

                    if((new Date(item.createdAt).getMonth() === month) && (new Date(item.createdAt).getFullYear() === year)){
                        n2 += 1;
                    }

                    if(!item.disconnected){
                        s3 += 1;
                    }
                }
            }

            setNewConnections(n2);
            setTotal(s2);
            setActive(s3);
        }
      }, [customers,zone])

      const data = {
        labels,
        datasets: [
          {
            label: 'Customers',
            data: graph_data,
            borderColor: theme.colors.blue[9],
            backgroundColor: theme.colors.blue[9],
          }
        ],
      };
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Customers Management Dashboard</title>
        </Helmet>
        <Alert mb={20} mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Customer management console allows you to keep track of your customers. Get to know how many customers you acquired this month, the trend in customer's connection status, manage specific customer details and much more.
        </Alert>
        <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Connections Summary</Heading>
        <SimpleGrid cols={1} spacing="xs" style={{overflow: 'hidden'}}>
            <Paper shadow="xs" p={0} withBorder>
                <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                    <Group position="apart">
                        <div>
                        <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : null}}>
                            <Group>
                                Summary
                                <Anchor size="xs" href="javascript:void(0)">info</Anchor>
                            </Group>
                        </Heading>
                        </div>
                    </Group>
                </Paper>
                <Divider />
                <Paper p="md">
                    <SimpleGrid cols={2}>
                    <div style={{borderRight: '1px solid gray'}}>
                        <Text>Total Connections</Text>
                        <Heading style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>{total}</Heading>
                    </div>
                    <div>
                        <Text>Total active connections</Text>
                        <Heading style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>{active}</Heading>
                    </div>
                    <div style={{borderRight: '1px solid gray'}}>
                        <Text>Total inactive connections</Text>
                        <Heading style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>{total - active}</Heading>
                    </div>
                    <div>
                        <Text>New Connections</Text>
                        <Heading style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>{new_connections}</Heading>
                    </div>
                    </SimpleGrid>
                </Paper>
            </Paper>

            <Paper mt={20} shadow="xs" p={0} withBorder>
                <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                    <Group position="apart">
                        <div>
                            <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Customers' Trends</Heading>
                            <Text mt={10}>Customer metrics</Text>
                        </div>
                        <Group>
                        <SelectField value={zone} onChange={(e) => {setZone(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="area" labelHidden icon={<ChevronDown />}>
                            {dmas.map((item, index) => {
                            return (
                                <option value={item.value} key={item.value}>{item.label}</option>
                            )
                            })}
                        </SelectField>
                        <SelectField value={chart} onChange={(e) => {setChart(e.target.value)}} style={{color: theme.colorScheme === "dark" ? "white" : null}} label="Chart" labelHidden icon={<ChevronDown />}>
                            <option value="line">Line</option>
                            <option value="bar">Bar</option>
                        </SelectField>
                        </Group>
                    </Group>
                </Paper>
                <Divider />
                <Grid>
                    <Grid.Col md={12} lg={7} style={{width: '100%'}}>
                        <Paper p="md" style={{height: 250, width: '100%', overflowX: 'auto'}}>
                        {chart === "line" ? (
                            <Line width={580} data={data}  options={options} />
                        ) : (
                            <Bar width={580} data={data} options={options} />
                        )}
                        <Group position="apart" mb={20} mt={20}>
                            <Group spacing="xl">
                                <Group spacing={3}>
                                <div style={{width: 40,borderBottom: `2px solid ${theme.colors.blue[9]}`}}></div>
                                <Text color="dimmed"  size='xs' >Customers</Text>
                                </Group>
                            </Group>
                        </Group>
                        </Paper>
                    </Grid.Col>
                    <Grid.Col md={12} lg={5}>
                        <Paper style={{height: 250, overflowY: 'auto'}} p={0} withBorder>
                            <Paper p="md" style={{backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]}}>
                            <div>
                            <Heading fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Customers per zone</Heading>
                            <Text mt={10}>{getDate()} customers by zone </Text>
                            </div>
                            </Paper>
                            <Divider />
                            <div style={{overflowX: 'auto', overflowY: 'auto'}}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Zone</th>
                                            <th>Active Accounts</th>
                                            <th>Inactive Accounts</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{overflowY: 'auto'}}>
                                    {zones_data.length === 0 ? (
                                        <tr>
                                        <td colSpan={4}>
                                            <Center>No data found</Center>
                                        </td>
                                    </tr>
                                    ) : (
                                        zones_data.map((item, index) => {
                                            return (
                                                <tr key={item.dma}>
                                                    <td>{item.dma}</td>
                                                    <td><Center>{item.active}</Center></td>
                                                    <td><Center>{item.inactive}</Center></td>
                                                </tr>
                                            )
                                        })
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Paper>

        </SimpleGrid>
        </>
    )
}